import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/slices/authSclice";
import Header from "./Header";
import Sidebar from "./Sidebar";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SpinnerPage from "./SpinnerPage";

export default function Layout() {
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login"); 
  };
  return (
    <>
      {isAuthenticated ? (
        <Box sx={{ display: "grid", gridTemplateColumns: "295px 1fr" }}>
          <div />
          <Sidebar />
          <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
            <Header logout={handleLogout} />
            <Outlet />
          </Box>
        </Box>
      ) : (
        <SpinnerPage />
      )}
    </>
  );
}
