import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Table } from "react-bootstrap";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  vehiculeInterface,
  getAllVehicule,
} from "../../redux/slices/vehiculeSlice";
import { Pagination } from "@mui/material";
import { Meta } from "../../redux/interfaces";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../styles/global";
import Spinner from "../../components/Spinner";
import BoxFilter from "../../components/BoxFilter";
import TableSiaModal from "../TableSiaModal";

const headSet = ["Num Police Assurance", "Type Vehicule", "Crée le"];
interface vehiculePaginationInterface {
  meta: Meta;
  data: vehiculeInterface[];
}

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleChangeValue: (value: string) => void;
  handleChangeDisplay: (value: string) => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "5%",
  left: "2%",
  width: "96%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};
const dataToShow = [
  {
    vkey: ["numpoliceassurance"],
    type: "link",
  },
  {
    vkey: ["TypeVehicule", "libelle"],
    type: "string",
  },
];
export default function VehiculeModal(props: ModalProps) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [numpoliceassurance, setNumpoliceassurance] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.vehicule.errorListe);
  const loading = useSelector((state: any) => state.vehicule.loadingListe);
  const vehicules: vehiculePaginationInterface = useSelector(
    (state: any) => state.vehicule.vehicules
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllVehicule(1, numpoliceassurance));
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllVehicule(value, numpoliceassurance));
  };
  const handleChangeNumpoliceassurance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNumpoliceassurance(event.target.value);
  };
  const ResetFilters = () => {
    setNumpoliceassurance("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllVehicule(1, numpoliceassurance));
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [numpoliceassurance]);

  const LinkButton = (id: string, libelle: string) => {
    props.handleChangeValue(id);
    props.handleChangeDisplay(libelle);
    props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <BoxFilter
          boxDisplayed={["numpoliceassurance"]}
          numpoliceassurance={numpoliceassurance}
          setNumpoliceassurance={handleChangeNumpoliceassurance}
          ResetFilters={ResetFilters}
          loading={loading}
        />
        <TableSiaModal
          loading={loading}
          headSet={headSet}
          dataDisplay={vehicules}
          page={page}
          id_type="id_vehicule"
          handleChange={handleChange}
          dataToShow={dataToShow}
          title="Véhicule"
          LinkButton={LinkButton}
        />
      </Box>
    </Modal>
  );
}
