import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  audienceInterface,
  getAllAudience,
} from "../../redux/slices/audienceSlice";
import { AppDispatch } from "../../redux/store";
import { Table } from "react-bootstrap";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Pagination } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Meta } from "../../redux/interfaces";
import { theme } from "../../styles/global";
import Spinner from "../../components/Spinner";
import BoxFilter from "../../components/BoxFilter";
import TableSiaModal from "../TableSiaModal";

const headSet = ["Procedure", "Resultat Audience", "Date Audience", "Crée le"];
interface audiencePaginationInterface {
  meta: Meta;
  data: audienceInterface[];
}

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleChangeValue: (value: string) => void;
  handleChangeDisplay: (value: string) => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "5%",
  left: "2%",
  width: "96%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};
const dataToShow = [
  {
    vkey: ["Procedure", "reference"],
    type: "link",
  },
  {
    vkey: ["ResultatAudience", "libelle"],
    type: "string",
  },
  {
    vkey: ["dateaudience"],
    type: "date",
  },
];
export default function AudienceModal(props: ModalProps) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [dateaudience, setDateaudience] = useState<Date | null | string>(null);
  const [dateaudiencemax, setDateaudiencemax] = useState<Date | null | string>(
    null
  );
  const [dateaudiencemin, setDateaudiencemin] = useState<Date | null | string>(
    null
  );
  const [resultataudience_id, setResultataudience_id] = useState("");
  const [typetribunal_id, setTypeTribunal_id] = useState("");
  const [genreaffaire_id, setGenreaffaireid] = useState("");
  const [siege_id, setSiege_id] = useState("");
  const [typeprocedure_id, setTypeProcedure_id] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.audience.errorListe);
  const loading = useSelector((state: any) => state.audience.loadingListe);
  const audiences: audiencePaginationInterface = useSelector(
    (state: any) => state.audience.audiences
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllAudience(
        1,
        dateaudience,
        dateaudiencemax,
        dateaudiencemin,
        resultataudience_id,
        genreaffaire_id,
        typeprocedure_id,
        typetribunal_id,
        siege_id
      )
    );
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllAudience(
        value,
        dateaudience,
        dateaudiencemax,
        dateaudiencemin,
        resultataudience_id,
        genreaffaire_id,
        typeprocedure_id,
        typetribunal_id,
        siege_id
      )
    );
  };
  const handleChangeResultataudienceId = (value: string) => {
    setResultataudience_id(value);
  };
  const handleChangeDateaudience = (date: Date | null | string) => {
    setDateaudience(date);
  };
  const handleChangeDateaudiencemax = (date: Date | null | string) => {
    setDateaudiencemax(date);
  };
  const handleChangeDateaudiencemin = (date: Date | null | string) => {
    setDateaudiencemin(date);
  };
  const handleChangeGenreaffaireid = (value: string) => {
    setGenreaffaireid(value);
  };
  const handleChangeTypeTribunalId = (value: string) => {
    setTypeTribunal_id(value);
  };
  const handleChangeTypeProcedureId = (value: string) => {
    setTypeProcedure_id(value);
  };
  const handleChangeSiegeId = (value: string) => {
    setSiege_id(value);
  };

  const ResetFilters = () => {
    setDateaudience(null);
    setDateaudiencemax(null);
    setDateaudiencemin(null);
    setResultataudience_id("");
    setGenreaffaireid("");
    setTypeTribunal_id("");
    setTypeProcedure_id("");
    setSiege_id("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllAudience(
            1,
            dateaudience,
            dateaudiencemax,
            dateaudiencemin,
            resultataudience_id,
            genreaffaire_id,
            typeprocedure_id,
            typetribunal_id,
            siege_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [dateaudience, dateaudiencemax, dateaudiencemin]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllAudience(
          1,
          dateaudience,
          dateaudiencemax,
          dateaudiencemin,
          resultataudience_id,
          genreaffaire_id,
          typeprocedure_id,
          typetribunal_id,
          siege_id
        )
      );
    }
  }, [
    resultataudience_id,
    genreaffaire_id,
    siege_id,
    typeprocedure_id,
    typetribunal_id,
  ]);

  const LinkButton = (id: string, libelle: string) => {
    props.handleChangeValue(id);
    props.handleChangeDisplay(libelle);
    props.handleClose();
  };
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <BoxFilter
          boxDisplayed={[
            "dateaudience",
            "dateaudiencemax",
            "dateaudiencemin",
            "resultataudience",
            "typetribunal",
            "siege",
            "typeprocedure",
            "genreaffaire",
            "exportdata",
          ]}
          dateaudience={dateaudience}
          dateaudiencemax={dateaudiencemax}
          dateaudiencemin={dateaudiencemin}
          resultataudience_id={resultataudience_id}
          genreaffaire_id={genreaffaire_id}
          typetribunal_id={typetribunal_id}
          ville_id={siege_id}
          typeprocedure_id={typeprocedure_id}
          setResultataudienceid={handleChangeResultataudienceId}
          setTypetribunalid={handleChangeTypeTribunalId}
          setGenreaffaireid={handleChangeGenreaffaireid}
          setVilleid={handleChangeSiegeId}
          setTypeprocedureid={handleChangeTypeProcedureId}
          setDateaudience={handleChangeDateaudience}
          setDateaudiencemax={handleChangeDateaudiencemax}
          setDateaudiencemin={handleChangeDateaudiencemin}
          ResetFilters={ResetFilters}
          loading={loading}
        />
        <TableSiaModal
          loading={loading}
          headSet={headSet}
          dataDisplay={audiences}
          page={page}
          id_type="id_audience"
          handleChange={handleChange}
          dataToShow={dataToShow}
          title="Audience"
          LinkButton={LinkButton}
        />
      </Box>
    </Modal>
  );
}
