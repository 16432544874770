import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import NewTypeProcedure from "../../pages/Type-Procedure/NewTypeProcedure";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleChangeValue: (value: string) => void;
  handleChangeDisplay: (value: string) => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};

export default function TypeProcedureCreateModal(props: ModalProps) {
  const valueClose = (value: string, displayValue: string) => {
    props.handleChangeValue(value);
    props.handleChangeDisplay(displayValue);
    props.handleClose();
  };
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <NewTypeProcedure modal={true} handleClose={valueClose} />
      </Box>
    </Modal>
  );
}
