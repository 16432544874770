import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  editProcedure,
  procedureInterface,
  getOneProcedure,
} from "../../redux/slices/procedureSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import AffaireModal from "../../components/Modals/AffaireModal";
import TypeProcedureModal from "../../components/Modals/TypeProcedureModal";
import SousTypeProcedureModal from "../../components/Modals/SousTypeProcedureModal";
import VilleModal from "../../components/Modals/VilleModal";
import TypeTribunalModal from "../../components/Modals/TypeTribunalModal";
import TypeJuridictionModal from "../../components/Modals/TypeJuridictionModal";
import FormControlLabel from "@mui/material/FormControlLabel";
import ProcedureModal from "../../components/Modals/ProcedureModal";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import TypePoursuiteCreateModal from "../../components/CreateModals/TypePoursuiteCreateModal";
import SousTypeProcedureCreateModal from "../../components/CreateModals/SousTypeProcedureCreateModal";
import TypeJuridictionCreateModal from "../../components/CreateModals/TypeJuridictionCreateModal";
import TypeTribunalCreateModal from "../../components/CreateModals/TypeTribunalCreateModal";
import VilleCreateModal from "../../components/CreateModals/VilleCreateModal";
import Spinner from "../../components/Spinner";
import ProcedureDetail from "./ProcedureDetail";
import TypeProcedureCreateModal from "../../components/CreateModals/TypeProcedureCreateModal";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

export default function EditProcedure() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [showFacturesCreate, setShowFacturesCreate] = useState(true);

  const [openProcedure, setOpenProcedure] = useState(false);
  const [displayProcedure, setDisplayProcedure] = useState("");
  const [parentExist, setParentExist] = useState(false);

  const [openAffaire, setOpenAffaire] = useState(false);
  const [displayAffaire, setDisplayAffaire] = useState("");

  const [openTypeProcedure, setOpenTypeProcedure] = useState(false);
  const [openCreateTypeProcedure, setOpenCreateTypeProcedure] = useState(false);
  const [displayTypeProcedure, setDisplayTypeProcedure] = useState("");

  const [openSousTypeProcedure, setOpenSousTypeProcedure] = useState(false);
  const [openCreateSousTypeProcedure, setOpenCreateSousTypeProcedure] =
    useState(false);
  const [displaySousTypeProcedure, setDisplaySousTypeProcedure] = useState("");

  const [openTypeTribunal, setOpenTypeTribunal] = useState(false);
  const [openCreateTypeTribunal, setOpenCreateTypeTribunal] = useState(false);
  const [displayTypeTribunal, setDisplayTypeTribunal] = useState("");

  const [openTypeJuridiction, setOpenTypeJuridiction] = useState(false);
  const [openCreateTypeJuridiction, setOpenCreateTypeJuridiction] =
    useState(false);
  const [displayTypeJuridiction, setDisplayTypeJuridiction] = useState("");

  const [openville, setOpenville] = useState(false);
  const [openCreateVille, setOpenCreateVille] = useState(false);
  const [displayville, setDisplayville] = useState("");

  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const procedure: procedureInterface = useSelector(
    (state: any) => state.procedure.procedure
  );
  const loading = useSelector((state: any) => state.procedure.loadingOne);
  const schema = yup.object({
    affaire_id: yup.number().required("affaire_id est obligatoire"),

    /* libelleprocedure_id: yup
      .string()
      .required("libelleprocedure_id est obligatoire"), */
    reference: yup.string().required("Reference est obligatoire"),
    //parent_id: yup.number(),
    typeprocedure_id: yup.number().required("typeprocedure_id est obligatoire"),
    soustypeprocedure_id: yup
      .number()
      .required("soustypeprocedure_id est obligatoire"),
    typetribunal_id: yup.number().required("typetribunal_id est obligatoire"),
    typejuridiction_id: yup
      .number()
      .required("typejuridiction_id est obligatoire"),
    siege_id: yup.number().required("siege_id est obligatoire"),
    //observation: yup.string().required("Observation est obligatoire"),
    datedebut: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
  });

  const handleSetOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneProcedure(id));
    } else {
      navigate("/procedures");
    }
  }, []);

  const refrechData = () => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneProcedure(id));
    }
  };

  useEffect(() => {
    setParentExist(procedure?.parent_id ? true : false);
    setDisplayAffaire(procedure?.Affaire?.reference);
    setDisplayProcedure(procedure?.parent?.reference);
    setDisplaySousTypeProcedure(procedure?.SousTypeProcedure?.libelle);
    setDisplayTypeJuridiction(procedure?.TypeJuridiction?.libelle);
    setDisplayTypeTribunal(procedure?.TypeTribunal?.libelle);
    setDisplayville(procedure?.Ville?.libelle);
    setDisplayTypeProcedure(procedure?.TypeProcedure?.libelle);
  }, [procedure]);

  const CreateAudience = () => {
    localStorage.setItem("procedure", JSON.stringify(procedure));
    navigate("/audiences/new");
  };
  const CreateJugement = () => {
    localStorage.setItem("procedure", JSON.stringify(procedure));
    navigate("/jugements/new");
  };
  const CreateFacture = () => {
    localStorage.setItem("procedure", JSON.stringify(procedure));
    navigate("/factures/new");
  };

  return (
    <>
      {openEdit ? (
        <>
          <div className={styles.ReturnList}>
            <div />
            <h2>Editer Procédure</h2>
            <div />
          </div>
          {!loading && Object.keys(procedure).length > 0 ? (
            <Formik
              validateOnChange={false}
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={procedure}
              onSubmit={async (values) => {
                let object = { ...values };
                delete object.updatedby;
                delete object.updatedon;
                setLoadingSend(true);
                const bool = await dispatch(
                  editProcedure(object, values.id_procedure)
                );
                if (bool) {
                  MySwal.fire({
                    icon: "success",
                    title: "Procedure est modifié avec succes",
                    customClass: {
                      confirmButton: "shadow-none",
                    },
                  }).then(() => {
                    setLoadingSend(false);
                    refrechData();
                    setOpenEdit(false);
                    //navigate("/procedures");
                  });
                }
              }}
            >
              {({
                errors,
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        label="Reference"
                        name="reference"
                        onChange={handleChange}
                        value={values.reference}
                        fullWidth
                        error={!!errors.reference}
                        helperText={errors.reference ? errors.reference : ""}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        className={
                          !displayAffaire
                            ? styles.backgroudGrey
                            : styles.backgroudGrey2
                        }
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Affaire
                        </InputLabel>
                        <OutlinedInput
                          type="text"
                          disabled
                          fullWidth
                          value={displayAffaire}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setOpenAffaire(true)}
                                edge="end"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Affaire"
                        />
                      </FormControl>
                      {openAffaire && (
                        <AffaireModal
                          open={openAffaire}
                          handleClose={() => setOpenAffaire(false)}
                          handleChangeValue={(value) =>
                            setFieldValue("affaire_id", value)
                          }
                          handleChangeDisplay={setDisplayAffaire}
                        />
                      )}
                      {errors.affaire_id && (
                        <span className={styles.errorHandle}>
                          {errors.affaire_id}
                        </span>
                      )}
                    </Grid>
                    {/* <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayLibelleProcedure
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Libelle Procedure
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayLibelleProcedure}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenLibelleProcedure(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Libelle Procedure"
                          />
                        </FormControl>
                        {openLibelleProcedure && (
                          <LibelleProcedureModal
                            open={openLibelleProcedure}
                            handleClose={() => setOpenLibelleProcedure(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("libelleprocedure_id", value)
                            }
                            handleChangeDisplay={setDisplayLibelleProcedure}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateLibelleProcedure(true)}
                        >
                          +
                        </Button>
                        {openCreateLibelleProcedure && (
                          <LibelleProcedureCreateModal
                            open={openCreateLibelleProcedure}
                            handleClose={() =>
                              setOpenCreateLibelleProcedure(false)
                            }
                            handleChangeValue={(value) =>
                              setFieldValue("libelleprocedure_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayLibelleProcedure(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.libelleprocedure_id && (
                        <span className={styles.errorHandle}>
                          {errors.libelleprocedure_id}
                        </span>
                      )}
                    </Grid> */}
                    <Grid item xs={12}>
                      <TextField
                        label="Observation"
                        name="observation"
                        onChange={handleChange}
                        value={values.observation}
                        fullWidth
                        error={!!errors.observation}
                        helperText={
                          errors.observation ? errors.observation : ""
                        }
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayTypeProcedure
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Type Procédure
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayTypeProcedure}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenTypeProcedure(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Type Procédure"
                          />
                        </FormControl>
                        {openTypeProcedure && (
                          <TypeProcedureModal
                            open={openTypeProcedure}
                            handleClose={() => setOpenTypeProcedure(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typeprocedure_id", value)
                            }
                            handleChangeDisplay={setDisplayTypeProcedure}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateTypeProcedure(true)}
                        >
                          +
                        </Button>
                        {openCreateTypeProcedure && (
                          <TypeProcedureCreateModal
                            open={openCreateTypeProcedure}
                            handleClose={() =>
                              setOpenCreateTypeProcedure(false)
                            }
                            handleChangeValue={(value) =>
                              setFieldValue("typeprocedure_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayTypeProcedure(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.typeprocedure_id && (
                        <span className={styles.errorHandle}>
                          {errors.typeprocedure_id}
                        </span>
                      )}
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displaySousTypeProcedure
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Sous Type Procédure
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displaySousTypeProcedure}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenSousTypeProcedure(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Sous Type Procédure"
                          />
                        </FormControl>
                        {openSousTypeProcedure && (
                          <SousTypeProcedureModal
                            open={openSousTypeProcedure}
                            handleClose={() => setOpenSousTypeProcedure(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("soustypeprocedure_id", value)
                            }
                            handleChangeDisplay={setDisplaySousTypeProcedure}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateSousTypeProcedure(true)}
                        >
                          +
                        </Button>
                        {openCreateSousTypeProcedure && (
                          <SousTypeProcedureCreateModal
                            open={openCreateSousTypeProcedure}
                            handleClose={() =>
                              setOpenCreateSousTypeProcedure(false)
                            }
                            handleChangeValue={(value) =>
                              setFieldValue("soustypeprocedure_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplaySousTypeProcedure(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.soustypeprocedure_id && (
                        <span className={styles.errorHandle}>
                          {errors.soustypeprocedure_id}
                        </span>
                      )}
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayTypeJuridiction
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Type Juridiction
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayTypeJuridiction}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenTypeJuridiction(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Type Juridiction"
                          />
                        </FormControl>
                        {openTypeJuridiction && (
                          <TypeJuridictionModal
                            open={openTypeJuridiction}
                            handleClose={() => setOpenTypeJuridiction(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typejuridiction_id", value)
                            }
                            handleChangeDisplay={setDisplayTypeJuridiction}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateTypeJuridiction(true)}
                        >
                          +
                        </Button>
                        {openCreateTypeJuridiction && (
                          <TypeJuridictionCreateModal
                            open={openCreateTypeJuridiction}
                            handleClose={() =>
                              setOpenCreateTypeJuridiction(false)
                            }
                            handleChangeValue={(value) =>
                              setFieldValue("typejuridiction_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayTypeJuridiction(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.typejuridiction_id && (
                        <span className={styles.errorHandle}>
                          {errors.typejuridiction_id}
                        </span>
                      )}
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayTypeTribunal
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Type Tribunal
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayTypeTribunal}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenTypeTribunal(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Type Tribunal"
                          />
                        </FormControl>
                        {openTypeTribunal && (
                          <TypeTribunalModal
                            open={openTypeTribunal}
                            handleClose={() => setOpenTypeTribunal(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typetribunal_id", value)
                            }
                            handleChangeDisplay={setDisplayTypeTribunal}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateTypeTribunal(true)}
                        >
                          +
                        </Button>
                        {openCreateTypeTribunal && (
                          <TypeTribunalCreateModal
                            open={openCreateTypeTribunal}
                            handleClose={() => setOpenCreateTypeTribunal(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typetribunal_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayTypeTribunal(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.typetribunal_id && (
                        <span className={styles.errorHandle}>
                          {errors.typetribunal_id}
                        </span>
                      )}
                    </Grid>
                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayville
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Siege
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayville}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenville(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Siege"
                          />
                        </FormControl>
                        {openville && (
                          <VilleModal
                            open={openville}
                            handleClose={() => setOpenville(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("siege_id", value)
                            }
                            handleChangeDisplay={setDisplayville}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateVille(true)}
                        >
                          +
                        </Button>
                        {openCreateVille && (
                          <VilleCreateModal
                            open={openCreateVille}
                            handleClose={() => setOpenCreateVille(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("siege_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayville(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.siege_id && (
                        <span className={styles.errorHandle}>
                          {errors.siege_id}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(value) => {
                            const date = moment(value).format("YYYY-MM-DD");
                            setFieldValue("datedebut", date);
                          }}
                          value={moment(values.datedebut)}
                          label="Date Procedure"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.datedebut,
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {errors.datedebut && (
                        <span className={styles.errorHandle}>
                          {errors.datedebut}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(value) => {
                            const date = moment(value).format("YYYY-MM-DD");
                            setFieldValue("datefin", date);
                          }}
                          value={values.datefin ? moment(values.datefin) : null}
                          label="Date Fin"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.datefin,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 32 },
                            }}
                          />
                        }
                        label="Procedure Parent"
                        checked={parentExist}
                        onChange={() => setParentExist(!parentExist)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {parentExist && (
                        <>
                          <FormControl
                            className={
                              !displayProcedure
                                ? styles.backgroudGrey
                                : styles.backgroudGrey2
                            }
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Procédure Parent
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              disabled
                              fullWidth
                              value={displayProcedure}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setOpenProcedure(true)}
                                    edge="end"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Procédure Parent"
                            />
                          </FormControl>
                          {openProcedure && (
                            <ProcedureModal
                              open={openProcedure}
                              handleClose={() => setOpenProcedure(false)}
                              handleChangeValue={(value) =>
                                setFieldValue("parent_id", value)
                              }
                              handleChangeDisplay={setDisplayProcedure}
                            />
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} className={styles.ButtonGrid}>
                      <Button
                        className={styles.Annuler}
                        variant="contained"
                        onClick={() => setOpenEdit(false)}
                      >
                        Annuler
                      </Button>
                      <Button
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <Spinner />
          )}
        </>
      ) : !loading && Object.keys(procedure).length > 0 ? (
        <>
          <div className={styles.ReturnList2}>
            <Link to="/procedures" relative="path">
              <ArrowBackIosIcon /> Liste Des Procédures
            </Link>
            <div>
              <Button className="me-3" onClick={CreateAudience}>
                Créer Une Audience
              </Button>
              <Button className="me-3" onClick={CreateJugement}>
                Créer Un Jugement
              </Button>
              {showFacturesCreate && (
                <Button onClick={CreateFacture}>Créer Une Facture</Button>
              )}
            </div>
          </div>
          <ProcedureDetail
            procedure={procedure}
            setEditProcedure={handleSetOpenEdit}
            setShowFacturesCreate={setShowFacturesCreate}
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}
