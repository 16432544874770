// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar_buttonSideBar__nWHvz {\n  color: white;\n}\n.Sidebar_buttonSideBar__nWHvz svg, .Sidebar_buttonSideBar__nWHvz span {\n  color: white;\n}\n\n.Sidebar_ItemActive__NUprc {\n  background-color: #fff !important;\n  border-radius: 10px;\n}\n.Sidebar_ItemActive__NUprc span, .Sidebar_ItemActive__NUprc svg {\n  color: rgb(48, 99, 255) !important;\n}\n\n.Sidebar_SideBarGlobal__VIUEB {\n  padding: 7px;\n  width: 295px;\n  height: 100vh;\n  position: fixed;\n}\n.Sidebar_SideBarGlobal__VIUEB .Sidebar_SideBar__wTFz3 {\n  background-color: rgb(48, 99, 255);\n  border-radius: 15px;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n}\n.Sidebar_SideBarGlobal__VIUEB .Sidebar_SideBar__wTFz3 img {\n  margin-top: 20px;\n  width: 130px;\n  height: 50px;\n}", "",{"version":3,"sources":["webpack://./src/styles/Sidebar.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,YAAA;AAER;;AACA;EACI,iCAAA;EACA,mBAAA;AAEJ;AADI;EACI,kCAAA;AAGR;;AAAA;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AAGJ;AAFI;EACI,kCAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;AAIR;AAHQ;EACI,gBAAA;EACA,YAAA;EACA,YAAA;AAKZ","sourcesContent":[".buttonSideBar{\n    color: white;\n    svg, span{\n        color: white;\n    }\n}\n.ItemActive{\n    background-color:#fff!important;\n    border-radius: 10px;\n    span, svg{\n        color: rgb(48 99 255)!important;\n    }\n}\n.SideBarGlobal{\n    padding: 7px;\n    width: 295px;\n    height: 100vh;\n    position: fixed;\n    .SideBar{\n        background-color: rgb(48 99 255);\n        border-radius: 15px;\n        height: 100%;\n        display: flex;\n        flex-direction: column;\n        justify-content: space-around;\n        align-items: center;\n        img{\n            margin-top: 20px;\n            width: 130px;\n            height: 50px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonSideBar": "Sidebar_buttonSideBar__nWHvz",
	"ItemActive": "Sidebar_ItemActive__NUprc",
	"SideBarGlobal": "Sidebar_SideBarGlobal__VIUEB",
	"SideBar": "Sidebar_SideBar__wTFz3"
};
export default ___CSS_LOADER_EXPORT___;
