import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  editAccidentCirculation,
  accidentCirculationInterface,
  getOneAccidentCirculation,
} from "../../redux/slices/accidentCirculationSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import AffaireModal from "../../components/Modals/AffaireModal";
import TypePoursuiteModal from "../../components/Modals/TypePoursuiteModal";
import TypeGarantieModal from "../../components/Modals/TypeGarantieModal";
import TypeGarantieCreateModal from "../../components/CreateModals/TypeGarantieCreateModal";
import TypePoursuiteCreateModal from "../../components/CreateModals/TypePoursuiteCreateModal";
import Spinner from "../../components/Spinner";
import AccidentCirculationDetail from "./AccidentCirculationDetail";
import VehiculeclientEditModal from "../../components/EditModals/VehiculeClientEditModal";
import VehiculeadversaireEditModal from "../../components/EditModals/VehiculeAdversaireEditModal";
import VehiculeClientCreateModal from "../../components/CreateModals/VehiculeClientCreateModal";
import VehiculeAdversaireCreateModal from "../../components/CreateModals/VehiculeAdversaireCreateModal";
import { deleteVehiculeclient } from "../../redux/slices/vehiculeClientSlice";
import { deleteVehiculeadversaire } from "../../redux/slices/vehiculeAdversaireSlice";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

export default function EditAccidentCirculation() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreateVehiculeClient, setOpenCreateVehiculeClient] =
    useState(false);

  const [openCreateVehiculeAdversaire, setOpenCreateVehiculeAdversaire] =
    useState(false);

  const [openEditVehiculeClient, setOpenEditVehiculeClient] = useState(false);
  const [editClient, setEditClient] = useState("");
  const [editVehiculeClient, setEditVehiculeClient] = useState("");
  const [editDisplayClient, setEditDisplayClient] = useState("");
  const [editDisplayVehiculeClient, setEditDisplayVehiculeClient] =
    useState("");

  const [openEditVehiculeAdversaire, setOpenEditVehiculeAdversaire] =
    useState(false);
  const [editAdversaire, setEditAdversaire] = useState("");
  const [editVehiculeAdversaire, setEditVehiculeAdversaire] = useState("");
  const [editDisplayAdversaire, setEditDisplayAdversaire] = useState("");
  const [editDisplayVehiculeAdversaire, setEditDisplayVehiculeAdversaire] =
    useState("");

  const [openCreateTypeGarantie, setOpenCreateTypeGarantie] = useState(false);
  const [openTypeGarantie, setOpenTypeGarantie] = useState(false);
  const [displayTypeGarantie, setDisplayTypeGarantie] = useState("");

  const [openCreateTypePoursuite, setOpenCreateTypePoursuite] = useState(false);
  const [openTypePoursuite, setOpenTypePoursuite] = useState(false);
  const [displayTypePoursuite, setDisplayTypePoursuite] = useState("");

  const [openAffaire, setOpenAffaire] = useState(false);
  const [displayAffaire, setDisplayAffaire] = useState("");

  const [idEdit, setIdEdit] = useState("");

  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const accidentCirculation: accidentCirculationInterface = useSelector(
    (state: any) => state.accidentCirculation.accidentCirculation
  );
  const loading = useSelector(
    (state: any) => state.accidentCirculation.loadingOne
  );

  const schema = yup.object({
    typepoursuite_id: yup.string().required("Type poursuite est obligatoire"),
    affaire_id: yup.string().required("Affaire est obligatoire"),
    typegarantie_id: yup.string().required("Type garantie est obligatoire"),
    dateaccident: yup.string().required("date accident est obligatoire"),
    //dateparquet: yup.string().required("date Parquet est obligatoire"),
  });

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneAccidentCirculation(id));
    } else {
      navigate("/accident-circulations");
    }
  }, []);

  const refrechData = () => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneAccidentCirculation(id));
    }
  };

  const handleEditClient = (
    client: string,
    vehicule: string,
    dclient: string,
    dvehicule: string,
    idAEdit: string
  ) => {
    setEditClient(client);
    setEditDisplayClient(dclient);
    setEditVehiculeClient(vehicule);
    setEditDisplayVehiculeClient(dvehicule);
    setOpenEditVehiculeClient(true);
    setIdEdit(idAEdit);
  };

  const handleEditAdversaire = (
    adversaire: string,
    vehicule: string,
    dadversaire: string,
    dvehicule: string,
    idAEdit: string
  ) => {
    setEditAdversaire(adversaire);
    setEditDisplayAdversaire(dadversaire);
    setEditVehiculeAdversaire(vehicule);
    setEditDisplayVehiculeAdversaire(dvehicule);
    setOpenEditVehiculeAdversaire(true);
    setIdEdit(idAEdit);
  };

  const deleteClient = (id: string) => {
    MySwal.fire({
      icon: "warning",
      title: "Voulez vous supprimer cette véhicule client",
      showCancelButton: true,
      confirmButtonColor: "#BC6DBD",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      customClass: {
        confirmButton: "shadow-none",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteVehiculeclient(id));
        refrechData();
      }
    });
  };

  const deleteAdversaire = (id: string) => {
    MySwal.fire({
      icon: "warning",
      title: "Voulez vous supprimer cette véhicule adversaire",
      showCancelButton: true,
      confirmButtonColor: "#BC6DBD",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      customClass: {
        confirmButton: "shadow-none",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteVehiculeadversaire(id));
        refrechData();
      }
    });
  };

  const handleSetOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  useEffect(() => {
    setDisplayTypeGarantie(accidentCirculation?.TypeGarantie?.libelle);
    setDisplayTypePoursuite(accidentCirculation?.TypePoursuite?.libelle);
    setDisplayAffaire(accidentCirculation?.Affaire?.reference);
  }, [accidentCirculation]);

  return (
    <>
      {openEdit ? (
        <>
          <div className={styles.ReturnList}>
            <div />
            <h2>Editer Accident Circulation</h2>
            <div />
          </div>
          {!loading && Object.keys(accidentCirculation).length > 0 ? (
            <Formik
              validateOnChange={false}
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={accidentCirculation}
              onSubmit={async (values) => {
                let object = { ...values };
                delete object.updatedby;
                delete object.updatedon;
                setLoadingSend(true);
                const bool = await dispatch(
                  editAccidentCirculation(object, values.id_accidentcirculation)
                );
                if (bool) {
                  MySwal.fire({
                    icon: "success",
                    title: "Accident Circulation est modifié avec succes",
                    customClass: {
                      confirmButton: "shadow-none",
                    },
                  }).then(() => {
                    setLoadingSend(false);
                    navigate("/accident-circulations");
                  });
                }
              }}
            >
              {({
                errors,
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Observation"
                        name="observation"
                        onChange={handleChange}
                        value={values.observation}
                        fullWidth
                        error={!!errors.observation}
                        helperText={
                          errors.observation ? errors.observation : ""
                        }
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        className={
                          !displayAffaire
                            ? styles.backgroudGrey
                            : styles.backgroudGrey2
                        }
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Affaire
                        </InputLabel>
                        <OutlinedInput
                          type="text"
                          disabled
                          fullWidth
                          value={displayAffaire}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setOpenAffaire(true)}
                                edge="end"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Affaire"
                        />
                      </FormControl>
                      {openAffaire && (
                        <AffaireModal
                          open={openAffaire}
                          handleClose={() => setOpenAffaire(false)}
                          handleChangeValue={(value) =>
                            setFieldValue("affaire_id", value)
                          }
                          handleChangeDisplay={setDisplayAffaire}
                        />
                      )}
                    </Grid>

                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayTypePoursuite
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Type Poursuite
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayTypePoursuite}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenTypePoursuite(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Type Poursuite"
                          />
                        </FormControl>
                        {openTypePoursuite && (
                          <TypePoursuiteModal
                            open={openTypePoursuite}
                            handleClose={() => setOpenTypePoursuite(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typepoursuite_id", value)
                            }
                            handleChangeDisplay={setDisplayTypePoursuite}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateTypePoursuite(true)}
                        >
                          +
                        </Button>
                        {openCreateTypePoursuite && (
                          <TypePoursuiteCreateModal
                            open={openCreateTypePoursuite}
                            handleClose={() =>
                              setOpenCreateTypePoursuite(false)
                            }
                            handleChangeValue={(value) =>
                              setFieldValue("typepoursuite_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayTypePoursuite(value)
                            }
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayTypeGarantie
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Type Garantie
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayTypeGarantie}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenTypeGarantie(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Type Garantie"
                          />
                        </FormControl>
                        {openTypeGarantie && (
                          <TypeGarantieModal
                            open={openTypeGarantie}
                            handleClose={() => setOpenTypeGarantie(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typegarantie_id", value)
                            }
                            handleChangeDisplay={setDisplayTypeGarantie}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateTypeGarantie(true)}
                        >
                          +
                        </Button>
                        {openCreateTypeGarantie && (
                          <TypeGarantieCreateModal
                            open={openCreateTypeGarantie}
                            handleClose={() => setOpenCreateTypeGarantie(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typegarantie_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayTypeGarantie(value)
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(value) => {
                            const date = moment(value).format("YYYY-MM-DD");
                            setFieldValue("dateaccident", date);
                          }}
                          value={moment(values.dateaccident)}
                          label="Date Accident"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.dateaccident,
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {errors.dateaccident && (
                        <span className={styles.errorHandle}>
                          {errors.dateaccident}
                        </span>
                      )}
                    </Grid>
                    {/* <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(value) => {
                            const date = moment(value).format("YYYY-MM-DD");
                            setFieldValue("dateparquet", date);
                          }}
                          value={moment(values.dateparquet)}
                          label="Date Parquet"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.dateparquet,
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {errors.dateparquet && (
                        <span className={styles.errorHandle}>
                          {errors.dateparquet}
                        </span>
                      )}
                    </Grid> */}
                    <Grid item xs={12} className={styles.ButtonGrid}>
                      <Button
                        className={styles.Annuler}
                        variant="contained"
                        onClick={() => setOpenEdit(false)}
                      >
                        Annuler
                      </Button>
                      <Button
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <Spinner />
          )}
        </>
      ) : !loading && Object.keys(accidentCirculation).length > 0 ? (
        <>
          <div className={styles.ReturnList2}></div>
          <AccidentCirculationDetail
            accidentCirculation={accidentCirculation}
            setEditAccidentCirculation={handleSetOpenEdit}
            setOpenCreateVehiculeClient={setOpenCreateVehiculeClient}
            deleteClient={deleteClient}
            handleEditClient={handleEditClient}
            setOpenCreateVehiculeAdversaire={setOpenCreateVehiculeAdversaire}
            deleteAdversaire={deleteAdversaire}
            handleEditAdversaire={handleEditAdversaire}
          />
        </>
      ) : (
        <Spinner />
      )}
      {openCreateVehiculeClient && (
        <VehiculeClientCreateModal
          open={openCreateVehiculeClient}
          handleClose={() => setOpenCreateVehiculeClient(false)}
          handleCloseUpdate={refrechData}
          affaire_id={accidentCirculation.affaire_id.toString()}
          affaire={accidentCirculation.Affaire}
        />
      )}
      {openEditVehiculeClient && (
        <VehiculeclientEditModal
          open={openEditVehiculeClient}
          handleClose={() => setOpenEditVehiculeClient(false)}
          handleCloseUpdate={refrechData}
          affaire_id={accidentCirculation.affaire_id.toString()}
          client_id={editClient}
          vehicule_id={editVehiculeClient}
          displayClient={editDisplayClient}
          displayVehicule={editDisplayVehiculeClient}
          idEdit={idEdit}
          affaire={accidentCirculation.Affaire}
        />
      )}
      {openCreateVehiculeAdversaire && (
        <VehiculeAdversaireCreateModal
          open={openCreateVehiculeAdversaire}
          handleClose={() => setOpenCreateVehiculeAdversaire(false)}
          handleCloseUpdate={refrechData}
          affaire_id={accidentCirculation.affaire_id.toString()}
          affaire={accidentCirculation.Affaire}
        />
      )}
      {openEditVehiculeAdversaire && (
        <VehiculeadversaireEditModal
          open={openEditVehiculeAdversaire}
          handleClose={() => setOpenEditVehiculeAdversaire(false)}
          handleCloseUpdate={refrechData}
          affaire_id={accidentCirculation.affaire_id.toString()}
          adversaire_id={editAdversaire}
          vehicule_id={editVehiculeAdversaire}
          displayAdversaire={editDisplayAdversaire}
          displayVehicule={editDisplayVehiculeAdversaire}
          idEdit={idEdit}
          affaire={accidentCirculation.Affaire}
        />
      )}
    </>
  );
}
