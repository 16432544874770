import { Routes, Route, Link, Navigate } from "react-router-dom";
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import "./App.css";
import routes from "./routes";

function App() {
  return (
    <div className="App">
      <Routes>
        {/*<Route path="/" element={<HomePage />} />*/}
        <Route path="/login" element={<Login />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Navigate to="/affaires" />} />
          {routes &&
            routes.map((route, index) => {
              if (route.element) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element && <route.element />}
                  />
                );
              }
              return (
                <Route key={index} path={route.path}>
                  {route.children &&
                    route.children.length > 0 &&
                    route.children.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={route.element && <route.element />}
                      />
                    ))}
                </Route>
              );
            })}
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}
function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
