import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import styles from "../styles/login.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/slices/authSclice";
import { AppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

interface MyFormValues {
  username: string;
  password: string;
}

export default function LoginForm() {
  const [loadingSend, setLoadingSend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.auth.error);
  const navigate = useNavigate();

  const schema = yup.object({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });
  const initialValues: MyFormValues = { username: "", password: "" };

  return (
    <div className={"animate__animated animate__fadeInDown"}>
      <h1 className={styles.title}>Connectez-vous.</h1>
      <p className={styles.text}>Entrez vos coordonnées ci-dessous.</p>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const bool = await dispatch(login(values));
          if (bool) {
            setLoadingSend(false);
            window.location.href = "/affaires"; //to update bearer token
          } else {
            setLoadingSend(false);
          }
        }}
      >
        {({ errors, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicUsernameLogin">
              <Form.Label>Username</Form.Label>
              <Form.Control
                className="shadow-none"
                name="username"
                type="text"
                placeholder="Username"
                onChange={handleChange}
                isInvalid={!!errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPasswordLogin">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                className="shadow-none"
                name="password"
                type="password"
                placeholder="votre mot de passe"
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            {error && <p className={styles.error}>{error}</p>}
            <Button
              disabled={loadingSend}
              className={
                loadingSend ? styles.buttonFormDisabled : styles.buttonForm
              }
              type="submit"
            >
              Se connecter{" "}
              {loadingSend && <CircularProgress color="secondary" size={20} />}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
