import { useEffect, useState } from "react";
import { Formik } from "formik";
import { Grid, TextField, Button } from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  editTypeGarantie,
  typeGarantieInterface,
  getOneTypeGarantie,
} from "../../redux/slices/typeGarantieSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Spinner from "../../components/Spinner";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

export default function EditTypeGarantie() {
  const [loadingSend, setLoadingSend] = useState(false);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const typeGarantie: typeGarantieInterface = useSelector(
    (state: any) => state.typeGarantie.typeGarantie
  );
  const loading = useSelector((state: any) => state.typeGarantie.loadingOne);

  const schema = yup.object({
    libelle: yup.string().required("Label est obligatoire"),
  });

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneTypeGarantie(id));
    } else {
      navigate("/type-garanties");
    }
  }, []);

  return (
    <>
      <div className={styles.ReturnList}>
        <Link to="/type-garanties" relative="path">
          <ArrowBackIosIcon /> Liste Type Garanties
        </Link>
        <h2>Editer Type Garantie</h2>
        <div />
      </div>
      {!loading && Object.keys(typeGarantie).length > 0 ? (
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          validateOnBlur={false}
          initialValues={typeGarantie}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            setLoadingSend(true);
            const bool = await dispatch(
              editTypeGarantie(object, values.id_typegarantie)
            );
            if (bool) {
              MySwal.fire({
                icon: "success",
                title: "Type Garantie est modifié avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then(() => {
                setLoadingSend(false);
                navigate("/type-garanties");
              });
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <TextField
                    label="Code"
                    name="code"
                    onChange={handleChange}
                    value={values.code}
                    fullWidth
                    error={!!errors.code}
                    helperText={errors.code ? errors.code : ""}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    label="Libelle"
                    name="libelle"
                    onChange={handleChange}
                    value={values.libelle}
                    fullWidth
                    error={!!errors.libelle}
                    helperText={errors.libelle ? errors.libelle : ""}
                  />
                </Grid>
                <Grid item xs={12} className={styles.ButtonGrid}>
                  <Button
                    startIcon={loadingSend && <CircularProgress size={20} />}
                    disabled={loadingSend}
                    variant="contained"
                    type="submit"
                  >
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner />
      )}
    </>
  );
}
