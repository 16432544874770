import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Table } from "react-bootstrap";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  accidentTravailInterface,
  getAllAccidentTravail,
} from "../../redux/slices/accidentTravailSlice";
import { Pagination } from "@mui/material";
import { Meta } from "../../redux/interfaces";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../styles/global";
import Spinner from "../../components/Spinner";
import BoxFilter from "../../components/BoxFilter";
import TableSiaModal from "../TableSiaModal";

const headSet = ["date accident", "Type accident travail", "Crée le"];
interface accidentTravailPaginationInterface {
  meta: Meta;
  data: accidentTravailInterface[];
}
interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleChangeValue: (value: string) => void;
  handleChangeDisplay: (value: string) => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "5%",
  left: "2%",
  width: "96%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};
const dataToShow = [
  {
    vkey: ["Affaire", "reference"],
    type: "link",
  },
  {
    vkey: ["dateaccident"],
    type: "date",
  },
  {
    vkey: ["TypeAccidentTravail", "libelle"],
    type: "string",
  },
];

export default function AccidentTravailModal(props: ModalProps) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [reference, setReference] = useState("");
  const [dateaccident, setDateaccident] = useState<Date | null | string>(null);
  const [numpoliceassurance, setNumpoliceassurance] = useState("");
  const [typeaccidenttravail_id, setTypeaccidenttravailid] = useState("");
  const [affaire_id, setAffaireid] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.accidentTravail.errorListe);
  const loading = useSelector(
    (state: any) => state.accidentTravail.loadingListe
  );
  const accidentTravails: accidentTravailPaginationInterface = useSelector(
    (state: any) => state.accidentTravail.accidentTravails
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllAccidentTravail(
        1,
        reference,
        dateaccident,
        numpoliceassurance,
        typeaccidenttravail_id,
        affaire_id
      )
    );
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllAccidentTravail(
        value,
        reference,
        dateaccident,
        numpoliceassurance,
        typeaccidenttravail_id,
        affaire_id
      )
    );
  };
  const handleChangeReference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReference(event.target.value);
  };
  const handleChangeNumpoliceassurance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNumpoliceassurance(event.target.value);
  };
  const handleChangeDateaccident = (date: Date | null | string) => {
    setDateaccident(date);
  };
  const handleChangeTypeAccidentTravailId = (value: string) => {
    setTypeaccidenttravailid(value);
  };
  const handleChangeAffaireId = (value: string) => {
    setAffaireid(value);
  };
  const ResetFilters = () => {
    setDateaccident(null);
    setReference("");
    setNumpoliceassurance("");
    setTypeaccidenttravailid("");
    setAffaireid("");
  };
  const LinkButton = (id: string, libelle: string) => {
    props.handleChangeValue(id);
    props.handleChangeDisplay(libelle);
    props.handleClose();
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllAccidentTravail(
            1,
            reference,
            dateaccident,
            numpoliceassurance,
            typeaccidenttravail_id,
            affaire_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [reference, dateaccident, numpoliceassurance]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllAccidentTravail(
          1,
          reference,
          dateaccident,
          numpoliceassurance,
          typeaccidenttravail_id,
          affaire_id
        )
      );
    }
  }, [typeaccidenttravail_id, affaire_id]);
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <BoxFilter
          boxDisplayed={[
            "reference",
            "dateaccident",
            "numpoliceassurance",
            "typeaccidenttravail",
            "affaire",
          ]}
          reference={reference}
          dateaccident={dateaccident}
          numpoliceassurance={numpoliceassurance}
          typeaccidenttravail_id={typeaccidenttravail_id}
          affaire_id={affaire_id}
          setReference={handleChangeReference}
          setDateaccident={handleChangeDateaccident}
          setNumpoliceassurance={handleChangeNumpoliceassurance}
          setTypeaccidenttravailid={handleChangeTypeAccidentTravailId}
          setAffaireid={handleChangeAffaireId}
          ResetFilters={ResetFilters}
          loading={loading}
        />
        <TableSiaModal
          loading={loading}
          headSet={headSet}
          dataDisplay={accidentTravails}
          page={page}
          id_type="id_accidenttravail"
          handleChange={handleChange}
          dataToShow={dataToShow}
          title="Accident de Travail"
          LinkButton={LinkButton}
        />
      </Box>
    </Modal>
  );
}
