import React, { useRef, useState, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";
import { addProcedure } from "../../redux/slices/procedureSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import AffaireModal from "../../components/Modals/AffaireModal";
import TypeProcedureModal from "../../components/Modals/TypeProcedureModal";
import SousTypeProcedureModal from "../../components/Modals/SousTypeProcedureModal";
import VilleModal from "../../components/Modals/VilleModal";
import TypeTribunalModal from "../../components/Modals/TypeTribunalModal";
import TypeJuridictionModal from "../../components/Modals/TypeJuridictionModal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ProcedureModal from "../../components/Modals/ProcedureModal";
import LibelleProcedureModal from "../../components/Modals/LibelleProcedureModal";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import LibelleProcedureCreateModal from "../../components/CreateModals/LibelleProcedureCreateModal";
import TypePoursuiteCreateModal from "../../components/CreateModals/TypePoursuiteCreateModal";
import SousTypeProcedureCreateModal from "../../components/CreateModals/SousTypeProcedureCreateModal";
import TypeJuridictionCreateModal from "../../components/CreateModals/TypeJuridictionCreateModal";
import TypeTribunalCreateModal from "../../components/CreateModals/TypeTribunalCreateModal";
import VilleCreateModal from "../../components/CreateModals/VilleCreateModal";
import TypeProcedureCreateModal from "../../components/CreateModals/TypeProcedureCreateModal";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  affaire_id: string;
  parent_id: string;
  typeprocedure_id: string;
  soustypeprocedure_id: string;
  typetribunal_id: string;
  typejuridiction_id: string;
  libelleprocedure_id: string | null;
  siege_id: string;
  reference: string;
  observation: string;
  //code: string;
  datedebut: Date | string | null;
  datefin: Date | string | null | null;
}

export default function NewProcedure() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [idAffaire, setIdAffaire] = useState(null);

  const [openProcedure, setOpenProcedure] = useState(false);
  const [displayProcedure, setDisplayProcedure] = useState("");
  const [parentExist, setParentExist] = useState(false);

  const [openAffaire, setOpenAffaire] = useState(false);
  const [displayAffaire, setDisplayAffaire] = useState("");

  const [openTypeProcedure, setOpenTypeProcedure] = useState(false);
  const [openCreateTypeProcedure, setOpenCreateTypeProcedure] = useState(false);
  const [displayTypeProcedure, setDisplayTypeProcedure] = useState("");

  const [openSousTypeProcedure, setOpenSousTypeProcedure] = useState(false);
  const [openCreateSousTypeProcedure, setOpenCreateSousTypeProcedure] =
    useState(false);
  const [displaySousTypeProcedure, setDisplaySousTypeProcedure] = useState("");

  const [openTypeTribunal, setOpenTypeTribunal] = useState(false);
  const [openCreateTypeTribunal, setOpenCreateTypeTribunal] = useState(false);
  const [displayTypeTribunal, setDisplayTypeTribunal] = useState("");

  const [openTypeJuridiction, setOpenTypeJuridiction] = useState(false);
  const [openCreateTypeJuridiction, setOpenCreateTypeJuridiction] =
    useState(false);
  const [displayTypeJuridiction, setDisplayTypeJuridiction] = useState("");

  const [openville, setOpenville] = useState(false);
  const [openCreateVille, setOpenCreateVille] = useState(false);
  const [displayville, setDisplayville] = useState("");

  const formikRef = useRef<FormikProps<MyFormValues>>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const initialValues: MyFormValues = {
    //code: "",
    datedebut: null,
    datefin: null,
    libelleprocedure_id: null,
    affaire_id: "",
    parent_id: "",
    typeprocedure_id: "",
    soustypeprocedure_id: "",
    typetribunal_id: "",
    typejuridiction_id: "",
    siege_id: "",
    reference: "",
    observation: "",
  };
  const schema = yup.object({
    affaire_id: yup.string().required("Affaire est obligatoire"),

    /*libelleprocedure_id: yup
      .string()
      .required("Libelle procedure est obligatoire"),*/
    reference: yup.string().required("Reference est obligatoire"),
    //parent_id: yup.string().required("parent_id est obligatoire"),
    typeprocedure_id: yup.string().required("Type procedure est obligatoire"),
    soustypeprocedure_id: yup
      .string()
      .required("Sous type procedure est obligatoire"),
    typetribunal_id: yup.string().required("Type tribunal est obligatoire"),
    typejuridiction_id: yup
      .string()
      .required("Type juridiction est obligatoire"),
    siege_id: yup.string().required("siege est obligatoire"),
    //observation: yup.string().required("Observation est obligatoire"),
    datedebut: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
  });

  useEffect(() => {
    const localStorageAffaire = localStorage.getItem("affaire");
    if (localStorageAffaire) {
      let affaireObject = JSON.parse(localStorageAffaire);
      if (formikRef.current) {
        const updateValues = {
          ...formikRef.current.values,
          affaire_id: affaireObject.id_affaire,
        };
        formikRef.current.setValues(updateValues);
        setIdAffaire(affaireObject.id_affaire);
      }
      setDisplayAffaire(affaireObject.reference);
    } else {
      navigate("/affaires");
    }
    return () => {
      localStorage.removeItem("affaire");
    };
  }, []);

  return (
    <>
      <div className={styles.ReturnList}>
        <Link to={"/affaires/" + idAffaire} relative="path">
          <ArrowBackIosIcon /> Retour Affaire
        </Link>
        <h2>Nouveau Procédure</h2>
        <div />
      </div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const Newprocedure = await dispatch(
            addProcedure(values, parentExist)
          );
          if (Newprocedure?.status) {
            MySwal.fire({
              icon: "success",
              title: "Procedure est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              const url = "/procedures/" + Newprocedure?.data.id_procedure;
              navigate(url);
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Reference"
                  name="reference"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.reference}
                  helperText={errors.reference ? errors.reference : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  className={
                    !displayAffaire
                      ? styles.backgroudGrey
                      : styles.backgroudGrey2
                  }
                  fullWidth
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Affaire
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    disabled
                    fullWidth
                    value={displayAffaire}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setOpenAffaire(true)}
                          edge="end"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Affaire"
                  />
                </FormControl>
                {openAffaire && (
                  <AffaireModal
                    open={openAffaire}
                    handleClose={() => setOpenAffaire(false)}
                    handleChangeValue={(value) =>
                      setFieldValue("affaire_id", value)
                    }
                    handleChangeDisplay={setDisplayAffaire}
                  />
                )}
                {errors.affaire_id && (
                  <span className={styles.errorHandle}>
                    {errors.affaire_id}
                  </span>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observation"
                  name="observation"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.observation}
                  helperText={errors.observation ? errors.observation : ""}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayTypeProcedure
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Type Procédure
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayTypeProcedure}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenTypeProcedure(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Type Procédure"
                    />
                  </FormControl>
                  {openTypeProcedure && (
                    <TypeProcedureModal
                      open={openTypeProcedure}
                      handleClose={() => setOpenTypeProcedure(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typeprocedure_id", value)
                      }
                      handleChangeDisplay={setDisplayTypeProcedure}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateTypeProcedure(true)}
                  >
                    +
                  </Button>
                  {openCreateTypeProcedure && (
                    <TypeProcedureCreateModal
                      open={openCreateTypeProcedure}
                      handleClose={() => setOpenCreateTypeProcedure(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typeprocedure_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayTypeProcedure(value)
                      }
                    />
                  )}
                </Grid>
                {errors.typeprocedure_id && (
                  <span className={styles.errorHandle}>
                    {errors.typeprocedure_id}
                  </span>
                )}
              </Grid>
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displaySousTypeProcedure
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Sous Type Procédure
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displaySousTypeProcedure}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenSousTypeProcedure(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Sous Type Procédure"
                    />
                  </FormControl>
                  {openSousTypeProcedure && (
                    <SousTypeProcedureModal
                      open={openSousTypeProcedure}
                      handleClose={() => setOpenSousTypeProcedure(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("soustypeprocedure_id", value)
                      }
                      handleChangeDisplay={setDisplaySousTypeProcedure}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateSousTypeProcedure(true)}
                  >
                    +
                  </Button>
                  {openCreateSousTypeProcedure && (
                    <SousTypeProcedureCreateModal
                      open={openCreateSousTypeProcedure}
                      handleClose={() => setOpenCreateSousTypeProcedure(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("soustypeprocedure_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplaySousTypeProcedure(value)
                      }
                    />
                  )}
                </Grid>
                {errors.soustypeprocedure_id && (
                  <span className={styles.errorHandle}>
                    {errors.soustypeprocedure_id}
                  </span>
                )}
              </Grid>
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayTypeJuridiction
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Type Juridiction
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayTypeJuridiction}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenTypeJuridiction(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Type Juridiction"
                    />
                  </FormControl>
                  {openTypeJuridiction && (
                    <TypeJuridictionModal
                      open={openTypeJuridiction}
                      handleClose={() => setOpenTypeJuridiction(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typejuridiction_id", value)
                      }
                      handleChangeDisplay={setDisplayTypeJuridiction}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateTypeJuridiction(true)}
                  >
                    +
                  </Button>
                  {openCreateTypeJuridiction && (
                    <TypeJuridictionCreateModal
                      open={openCreateTypeJuridiction}
                      handleClose={() => setOpenCreateTypeJuridiction(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typejuridiction_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayTypeJuridiction(value)
                      }
                    />
                  )}
                </Grid>
                {errors.typejuridiction_id && (
                  <span className={styles.errorHandle}>
                    {errors.typejuridiction_id}
                  </span>
                )}
              </Grid>
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayTypeTribunal
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Type Tribunal
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayTypeTribunal}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenTypeTribunal(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Type Tribunal"
                    />
                  </FormControl>
                  {openTypeTribunal && (
                    <TypeTribunalModal
                      open={openTypeTribunal}
                      handleClose={() => setOpenTypeTribunal(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typetribunal_id", value)
                      }
                      handleChangeDisplay={setDisplayTypeTribunal}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateTypeTribunal(true)}
                  >
                    +
                  </Button>
                  {openCreateTypeTribunal && (
                    <TypeTribunalCreateModal
                      open={openCreateTypeTribunal}
                      handleClose={() => setOpenCreateTypeTribunal(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typetribunal_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayTypeTribunal(value)
                      }
                    />
                  )}
                </Grid>
                {errors.typetribunal_id && (
                  <span className={styles.errorHandle}>
                    {errors.typetribunal_id}
                  </span>
                )}
              </Grid>
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayville
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Siege
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayville}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenville(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Siege"
                    />
                  </FormControl>
                  {openville && (
                    <VilleModal
                      open={openville}
                      handleClose={() => setOpenville(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("siege_id", value)
                      }
                      handleChangeDisplay={setDisplayville}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateVille(true)}
                  >
                    +
                  </Button>
                  {openCreateVille && (
                    <VilleCreateModal
                      open={openCreateVille}
                      handleClose={() => setOpenCreateVille(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("siege_id", value)
                      }
                      handleChangeDisplay={(value) => setDisplayville(value)}
                    />
                  )}
                </Grid>
                {errors.siege_id && (
                  <span className={styles.errorHandle}>{errors.siege_id}</span>
                )}
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setFieldValue("datedebut", date);
                    }}
                    value={moment(values.datedebut)}
                    label="Date Debut"
                    slotProps={{
                      textField: { fullWidth: true, error: !!errors.datedebut },
                    }}
                  />
                </LocalizationProvider>
                {errors.datedebut && (
                  <span className={styles.errorHandle}>{errors.datedebut}</span>
                )}
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setFieldValue("datefin", date);
                    }}
                    value={values.datefin ? moment(values.datefin) : null}
                    label="Date Fin"
                    slotProps={{
                      textField: { fullWidth: true, error: !!errors.datefin },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 32 },
                      }}
                    />
                  }
                  label="Procedure Parent"
                  checked={parentExist}
                  onChange={() => setParentExist(!parentExist)}
                />
              </Grid>
              <Grid item xs={4}>
                {parentExist && (
                  <>
                    <FormControl
                      className={
                        !displayProcedure
                          ? styles.backgroudGrey
                          : styles.backgroudGrey2
                      }
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Procédure Parent
                      </InputLabel>
                      <OutlinedInput
                        type="text"
                        disabled
                        fullWidth
                        value={displayProcedure}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setOpenProcedure(true)}
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Procédure Parent"
                      />
                    </FormControl>
                    {openProcedure && (
                      <ProcedureModal
                        open={openProcedure}
                        handleClose={() => setOpenProcedure(false)}
                        handleChangeValue={(value) =>
                          setFieldValue("parent_id", value)
                        }
                        handleChangeDisplay={setDisplayProcedure}
                      />
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} className={styles.ButtonGrid}>
                <Button
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                  variant="contained"
                  type="submit"
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
