import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  affaireInterface,
  getAllAffaire,
} from "../../redux/slices/affaireSlice";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import TableSia from "../../components/TableSia";

const headSet = [
  "Reference",
  "Reference Provisoire",
  "Reference definitive",
  "Genre affaire",
  "Crée le",
];
const dataToShow = [
  {
    vkey: ["reference"],
    type: "link",
  },
  {
    vkey: ["referenceprovisoire"],
    type: "string",
  },
  {
    vkey: ["referencedefinitive"],
    type: "string",
  },
  {
    vkey: ["GenreAffaire", "libelle"],
    type: "string",
  },
];
interface affairePaginationInterface {
  meta: Meta;
  data: affaireInterface[];
}
export default function Affaires() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [reference, setReference] = useState("");
  const [referenceprovisoire, setReferenceprovisoire] = useState("");
  const [referencedefinitive, setReferencedefinitive] = useState("");
  const [genreaffaire_id, setGenreaffaireid] = useState("");
  const [typetribunal_id, setTypeTribunal_id] = useState("");
  const [siege_id, setSiege_id] = useState("");
  const [typejuridiction_id, setTypejuridiction_id] = useState("");
  const [procedure_id, setProcedure_id] = useState("");
  const [numpoliceassurance, setNumpoliceassurance] = useState("");
  const [client_id, setClient_id] = useState("");
  const [adversaire_id, setAdversaire_id] = useState("");
  const [matricule, setMatricule] = useState("");
  const [dateaudience, setDateaudience] = useState<Date | null | string>(null);
  const [dateaccident, setDateaccident] = useState<Date | null | string>(null);
  const [datejugement, setDatejugement] = useState<Date | null | string>(null);
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.affaire.errorListe);
  const loading = useSelector((state: any) => state.affaire.loadingListe);
  const affaires: affairePaginationInterface = useSelector(
    (state: any) => state.affaire.affaires
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllAffaire(
        1,
        reference,
        referenceprovisoire,
        referencedefinitive,
        genreaffaire_id,
        dateaccident,
        datejugement,
        dateaudience,
        numpoliceassurance,
        procedure_id,
        typetribunal_id,
        siege_id,
        matricule,
        typejuridiction_id,
        client_id,
        adversaire_id
      )
    );
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllAffaire(
        value,
        reference,
        referenceprovisoire,
        referencedefinitive,
        genreaffaire_id,
        dateaccident,
        datejugement,
        dateaudience,
        numpoliceassurance,
        procedure_id,
        typetribunal_id,
        siege_id,
        matricule,
        typejuridiction_id,
        client_id,
        adversaire_id
      )
    );
  };
  const handleChangeReference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReference(event.target.value);
  };
  const handleChangeReferenceprovisoire = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferenceprovisoire(event.target.value);
  };
  const handleChangeReferencedefinitive = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferencedefinitive(event.target.value);
  };
  const handleChangeGenreaffaireid = (value: string) => {
    setGenreaffaireid(value);
  };
  const handleChangeDateaudience = (date: Date | null | string) => {
    setDateaudience(date);
  };
  const handleChangeDatejugement = (date: Date | null | string) => {
    setDatejugement(date);
  };
  const handleChangeDateaccident = (date: Date | null | string) => {
    setDateaccident(date);
  };
  const handleChangeProcedureId = (value: string) => {
    setProcedure_id(value);
  };
  const handleChangeTypeTribunalId = (value: string) => {
    setTypeTribunal_id(value);
  };

  const handleChangeSiegeId = (value: string) => {
    setSiege_id(value);
  };
  const handleChangeTypeJuridictionId = (value: string) => {
    setTypejuridiction_id(value);
  };
  const handleChangeNumpoliceassurance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNumpoliceassurance(event.target.value);
  };
  const handleChangeMatricule = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMatricule(event.target.value);
  };
  const handleChangeClientId = (value: string) => {
    setClient_id(value);
  };
  const handleChangeAdversaireId = (value: string) => {
    setAdversaire_id(value);
  };
  const ResetFilters = () => {
    setReference("");
    setReferenceprovisoire("");
    setReferencedefinitive("");
    setGenreaffaireid("");
    setDateaccident(null);
    setDateaudience(null);
    setDatejugement(null);
    setNumpoliceassurance("");
    setProcedure_id("");
    setTypeTribunal_id("");
    setSiege_id("");
    setMatricule("");
    setTypejuridiction_id("");
    setClient_id("");
    setAdversaire_id("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllAffaire(
          1,
          reference,
          referenceprovisoire,
          referencedefinitive,
          genreaffaire_id,
          dateaccident,
          datejugement,
          dateaudience,
          numpoliceassurance,
          procedure_id,
          typetribunal_id,
          siege_id,
          matricule,
          typejuridiction_id,
          client_id,
          adversaire_id
        )
      );
    }
  }, [
    genreaffaire_id,
    dateaccident,
    datejugement,
    dateaudience,
    procedure_id,
    typetribunal_id,
    siege_id,
    typejuridiction_id,
    client_id,
    adversaire_id
  ]);
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllAffaire(
            1,
            reference,
            referenceprovisoire,
            referencedefinitive,
            genreaffaire_id,
            dateaccident,
            datejugement,
            dateaudience,
            numpoliceassurance,
            procedure_id,
            typetribunal_id,
            siege_id,
            matricule,
            typejuridiction_id,
            client_id,
            adversaire_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [
    reference,
    referencedefinitive,
    referenceprovisoire,
    numpoliceassurance,
    matricule,
  ]);
  return (
    <div>
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Affaires</h2>
        <Link to="new" relative="path">
          + Ajouter Une Affaire
        </Link>
      </div>
      <BoxFilter
        boxDisplayed={[
          "reference",
          "genreaffaire",
          "referenceprovisoire",
          "referencedefinitive",
          "dateaccident",
          "datejugement",
          "dateaudience",
          "numpoliceassurance",
          "procedure",
          "siege",
          "typetribunal",
          "matricule",
          "typejuridiction",
          "client",
          "adversaire"
        ]}
        reference={reference}
        client_id={client_id}
        adversaire_id={adversaire_id}
        referencedefinitive={referencedefinitive}
        referenceprovisoire={referenceprovisoire}
        genreaffaire_id={genreaffaire_id}
        dateaccident={dateaccident}
        dateaudience={dateaudience}
        datejugement={datejugement}
        numpoliceassurance={numpoliceassurance}
        matricule={matricule}
        procedure_id={procedure_id}
        ville_id={siege_id}
        typetribunal_id={typetribunal_id}
        typejuridiction_id={typejuridiction_id}
        setReference={handleChangeReference}
        setReferencedefinitive={handleChangeReferencedefinitive}
        setReferenceprovisoire={handleChangeReferenceprovisoire}
        setGenreaffaireid={handleChangeGenreaffaireid}
        setDateaccident={handleChangeDateaccident}
        setDateaudience={handleChangeDateaudience}
        setDatejugement={handleChangeDatejugement}
        setNumpoliceassurance={handleChangeNumpoliceassurance}
        setMatricule={handleChangeMatricule}
        setProcedureid={handleChangeProcedureId}
        setTypejuridictionid={handleChangeTypeJuridictionId}
        setVilleid={handleChangeSiegeId}
        setTypetribunalid={handleChangeTypeTribunalId}
        setClientid={handleChangeClientId}
        setAdversaireid={handleChangeAdversaireId}
        ResetFilters={ResetFilters}
        loading={loading}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={affaires}
        page={page}
        id_type="id_affaire"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Affaire"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
