import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import {
  vehiculeInterface,
  getAllVehicule,
} from "../../redux/slices/vehiculeSlice";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import TableSia from "../../components/TableSia";

const headSet = ["Num Police Assurance", "Type Vehicule", "Crée le"];
interface vehiculePaginationInterface {
  meta: Meta;
  data: vehiculeInterface[];
}
const dataToShow = [
  {
    vkey: ["numpoliceassurance"],
    type: "link",
  },
  {
    vkey: ["TypeVehicule", "libelle"],
    type: "string",
  },
];
export default function Vehicules() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [numpoliceassurance, setNumpoliceassurance] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.vehicule.errorListe);
  const loading = useSelector((state: any) => state.vehicule.loadingListe);
  const vehicules: vehiculePaginationInterface = useSelector(
    (state: any) => state.vehicule.vehicules
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllVehicule(1, numpoliceassurance));
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllVehicule(value, numpoliceassurance));
  };
  const handleChangeNumpoliceassurance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNumpoliceassurance(event.target.value);
  };
  const ResetFilters = () => {
    setNumpoliceassurance("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllVehicule(1, numpoliceassurance));
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [numpoliceassurance]);
  return (
    <div>
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Vehicules</h2>
        <Link to="new" relative="path">
          + Ajouter Une Vehicule
        </Link>
      </div>
      <BoxFilter
        boxDisplayed={["numpoliceassurance"]}
        numpoliceassurance={numpoliceassurance}
        setNumpoliceassurance={handleChangeNumpoliceassurance}
        ResetFilters={ResetFilters}
        loading={loading}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={vehicules}
        page={page}
        id_type="id_vehicule"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Véhicule"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
