import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  audienceInterface,
  getAllAudience,
} from "../../redux/slices/audienceSlice";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableSia from "../../components/TableSia";
import axios from "axios";
import SpinnerPage from "../../components/SpinnerPage";
const MySwal = withReactContent(Swal);

const headSet = ["Procedure", "Resultat Audience", "Date Audience", "Crée le"];
interface audiencePaginationInterface {
  meta: Meta;
  data: audienceInterface[];
}
const dataToShow = [
  {
    vkey: ["Procedure", "reference"],
    type: "link",
  },
  {
    vkey: ["ResultatAudience", "libelle"],
    type: "string",
  },
  {
    vkey: ["dateaudience"],
    type: "date",
  },
];
export default function Audiences() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [loadingOne, setLoadingOne] = useState(false);
  const [dateaudience, setDateaudience] = useState<Date | null | string>(null);
  const [dateaudiencemax, setDateaudiencemax] = useState<Date | null | string>(
    null
  );
  const [dateaudiencemin, setDateaudiencemin] = useState<Date | null | string>(
    null
  );
  const [resultataudience_id, setResultataudience_id] = useState("");
  const [typetribunal_id, setTypeTribunal_id] = useState("");
  const [genreaffaire_id, setGenreaffaireid] = useState("");
  const [siege_id, setSiege_id] = useState("");
  const [typeprocedure_id, setTypeProcedure_id] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.audience.errorListe);
  const loading = useSelector((state: any) => state.audience.loadingListe);
  const audiences: audiencePaginationInterface = useSelector(
    (state: any) => state.audience.audiences
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllAudience(
        1,
        dateaudience,
        dateaudiencemax,
        dateaudiencemin,
        resultataudience_id,
        genreaffaire_id,
        typeprocedure_id,
        typetribunal_id,
        siege_id
      )
    );
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllAudience(
        value,
        dateaudience,
        dateaudiencemax,
        dateaudiencemin,
        resultataudience_id,
        genreaffaire_id,
        typeprocedure_id,
        typetribunal_id,
        siege_id
      )
    );
  };
  const handleChangeResultataudienceId = (value: string) => {
    setResultataudience_id(value);
  };
  const handleChangeDateaudience = (date: Date | null | string) => {
    setDateaudience(date);
  };
  const handleChangeDateaudiencemax = (date: Date | null | string) => {
    setDateaudiencemax(date);
  };
  const handleChangeDateaudiencemin = (date: Date | null | string) => {
    setDateaudiencemin(date);
  };
  const handleChangeGenreaffaireid = (value: string) => {
    setGenreaffaireid(value);
  };
  const handleChangeTypeTribunalId = (value: string) => {
    setTypeTribunal_id(value);
  };
  const handleChangeTypeProcedureId = (value: string) => {
    setTypeProcedure_id(value);
  };
  const handleChangeSiegeId = (value: string) => {
    setSiege_id(value);
  };

  const ResetFilters = () => {
    setDateaudience(null);
    setDateaudiencemax(null);
    setDateaudiencemin(null);
    setResultataudience_id("");
    setGenreaffaireid("");
    setTypeTribunal_id("");
    setTypeProcedure_id("");
    setSiege_id("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllAudience(
            1,
            dateaudience,
            dateaudiencemax,
            dateaudiencemin,
            resultataudience_id,
            genreaffaire_id,
            typeprocedure_id,
            typetribunal_id,
            siege_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [dateaudience, dateaudiencemax, dateaudiencemin]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllAudience(
          1,
          dateaudience,
          dateaudiencemax,
          dateaudiencemin,
          resultataudience_id,
          genreaffaire_id,
          typeprocedure_id,
          typetribunal_id,
          siege_id
        )
      );
    }
  }, [
    resultataudience_id,
    genreaffaire_id,
    siege_id,
    typeprocedure_id,
    typetribunal_id,
  ]);

  const handleExportData = () => {
    if (
      dateaudience === null &&
      dateaudiencemax === null &&
      dateaudiencemin === null &&
      resultataudience_id === "" &&
      genreaffaire_id === "" &&
      typeprocedure_id === "" &&
      typetribunal_id === "" &&
      siege_id === ""
    ) {
      MySwal.fire({
        icon: "warning",
        title: "Choisir Au Moins Un Filtre",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/audience/export?all=true${
            dateaudience ? "&dateaudience=" + dateaudience : ""
          }${dateaudiencemax ? "&dateaudiencemax=" + dateaudiencemax : ""}${
            dateaudiencemin ? "&dateaudiencemin=" + dateaudiencemin : ""
          }${
            resultataudience_id
              ? "&resultataudience_id=" + resultataudience_id
              : ""
          }${typetribunal_id ? "&typetribunal_id=" + typetribunal_id : ""}${
            siege_id ? "&siege_id=" + siege_id : ""
          }${typeprocedure_id ? "&typeprocedure_id=" + typeprocedure_id : ""}${
            genreaffaire_id ? "&genreaffaire_id=" + genreaffaire_id : ""
          }`,
          {
            responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");
          const currentDate = new Date();
          const dateString = currentDate
            .toISOString()
            .slice(0, 19)
            .replace(/[-T:]/g, "");
          const filename = `audiences_${dateString}.xlsx`;
          link.href = url;
          link.download = filename; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoadingOne(false);
        })
        .catch((error) => {
          console.error("Error downloading Excel:", error);
        });
    }
  };
  return (
    <div>
      {loadingOne && <SpinnerPage />}
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Audiences</h2>
        <div></div>
        {/*<Link to="new" relative="path">
          + Ajouter Une Audience
        </Link>*/}
      </div>
      <BoxFilter
        boxDisplayed={[
          "dateaudience",
          "dateaudiencemax",
          "dateaudiencemin",
          "resultataudience",
          "typetribunal",
          "siege",
          "typeprocedure",
          "genreaffaire",
          "exportdata",
        ]}
        dateaudience={dateaudience}
        dateaudiencemax={dateaudiencemax}
        dateaudiencemin={dateaudiencemin}
        resultataudience_id={resultataudience_id}
        genreaffaire_id={genreaffaire_id}
        typetribunal_id={typetribunal_id}
        ville_id={siege_id}
        typeprocedure_id={typeprocedure_id}
        setResultataudienceid={handleChangeResultataudienceId}
        setTypetribunalid={handleChangeTypeTribunalId}
        setGenreaffaireid={handleChangeGenreaffaireid}
        setVilleid={handleChangeSiegeId}
        setTypeprocedureid={handleChangeTypeProcedureId}
        setDateaudience={handleChangeDateaudience}
        setDateaudiencemax={handleChangeDateaudiencemax}
        setDateaudiencemin={handleChangeDateaudiencemin}
        ResetFilters={ResetFilters}
        loading={loading}
        handleExport={handleExportData}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={audiences}
        page={page}
        id_type="id_audience"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Audience"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
