const ListApi = {
  baseApi: process.env.REACT_APP_API_URL,
  adversaireApi: "api/adversaire",
  affaireApi: "api/affaire",
  affaireclientApi: "api/affaireclient",
  affaireadversaireApi: "api/affaireadversaire",
  loginApi: "api/auth/login",
  clientApi: "api/client",
  genreAffaireApi: "api/genreaffaire",
  procedureApi: "api/procedure",
  jugementApi: "api/jugement",
  audienceApi: "api/audience",
  resultatAudienceApi: "api/resultataudience",
  typeJugementApi: "api/typejugement",
  typeGarantieApi: "api/typegarantie",
  typePoursuiteApi: "api/typepoursuite",
  typeProcedureApi: "api/typeprocedure",
  libelleProcedureApi: "api/libelleprocedure",
  typeTribunalApi: "api/typetribunal",
  situationApi: "api/situation",
  villeApi: "api/ville",
  typeJuridictionApi: "api/typejuridiction",
  typeAccidentTravailApi: "api/typeaccidenttravail",
  accidentTravailApi: "api/accidenttravail",
  accidentCirculationApi: "api/accidentcirculation",
  sousTypeProcedureApi: "api/soustypeprocedure",
  factureActeApi: "api/factureacte",
  factureApi: "api/facture",
  acteApi: "api/acte",
  tvaApi: "api/tva",
  typeVehiculeApi: "api/typevehicule",
  vehiculeApi: "api/vehicule",
  vehiculeclientApi: "api/vehiculeclient",
  vehiculeadversaireApi: "api/vehiculeadversaire",
};
export default ListApi;
