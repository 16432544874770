import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface typePoursuiteInterface {
  id_typepoursuite: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtypePoursuiteInterface {
  code: string;
  libelle: string;
}

const initialState = {
  typePoursuites: [],
  allTypePoursuites: [],
  typePoursuite: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const typePoursuiteSlice = createSlice({
  name: "typePoursuite",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTypePoursuite(state, action) {
      state.typePoursuites = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTypePoursuiteTrue(state, action) {
      state.allTypePoursuites = action.payload;
      state.error = "";
    },
    oneTypePoursuite(state, action) {
      state.typePoursuite = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTypePoursuite,
  allTypePoursuiteTrue,
  oneTypePoursuite,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = typePoursuiteSlice.actions;

export const getAllTypePoursuite =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.typePoursuiteApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let typePoursuites: any;
      typePoursuites = page ? response.data : response.data.data;
      page
        ? dispatch(allTypePoursuite(typePoursuites))
        : dispatch(allTypePoursuiteTrue(typePoursuites));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTypePoursuite = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.typePoursuiteApi + "/" + id
    );
    let typePoursuite = response.data;
    dispatch(oneTypePoursuite(typePoursuite));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addTypePoursuite =
  (typePoursuite: NewtypePoursuiteInterface) => async (dispatch: any) => {
    try {
      const newTypePoursuite = await axios.post(
        api.baseApi + api.typePoursuiteApi,
        typePoursuite
      );
      return newTypePoursuite;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editTypePoursuite =
  (typePoursuite: typePoursuiteInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.typePoursuiteApi + "/" + id,
        typePoursuite
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default typePoursuiteSlice.reducer;
