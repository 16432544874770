import React from "react";
import { Image } from "react-bootstrap";
import logoutImage from "../assets/logout.png";
import LogoutIcon from "@mui/icons-material/Logout";

interface HeaderValues {
  logout: () => void;
}
export default function Header({ logout }: HeaderValues) {
  return (
    <div
      style={{
        width: "100%",
        padding: "0px 10px",
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "20px",
        cursor: "pointer",
      }}
    >
      <LogoutIcon height="18" width="17" onClick={() => logout()} />
    </div>
  );
}
