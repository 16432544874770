import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { typeJugementInterface } from "./typeJugementSlice";
import { procedureInterface } from "./procedureSlice";
import { affaireInterface } from "./affaireSlice";

export interface jugementInterface {
  id_jugement: number;
  typejugement_id: number;
  TypeJugement: typeJugementInterface;
  procedure_id: number;
  affaire_id: number;
  Procedure: procedureInterface;
  Affaire: affaireInterface;
  reference: string;
  nbmoisprison: number;
  indemnite: number;
  amende: number;
  resp_pourcentage: string;
  prisonferme: boolean;
  numero: string;
  //code: string;
  texte: string;
  datejugement: Date | string | null;
  datereception: Date | string | null;
  auprofitduclient: boolean;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewjugementInterface {
  affaire_id: string;
  procedure_id: string;
  typejugement_id: string;
  reference: string;
  nbmoisprison: number;
  indemnite: number;
  amende: number;
  resp_pourcentage: string;
  prisonferme: boolean;
  //numero: string;
  //code: string;
  texte: string;
  //auprofitduclient: boolean;
  datejugement: Date | string | null;
}

const initialState = {
  jugements: [],
  allJugements: [],
  jugement: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const jugementSlice = createSlice({
  name: "jugement",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allJugement(state, action) {
      state.jugements = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allJugementTrue(state, action) {
      state.allJugements = action.payload;
      state.error = "";
    },
    oneJugement(state, action) {
      state.jugement = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allJugement,
  allJugementTrue,
  oneJugement,
  getError,
  setLoadingFalse,
  setLoadingOneFalse,
} = jugementSlice.actions;

export const getAllJugement =
  (
    page?: number,
    reference?: string,
    texte?: string,
    auprofitduclient?: string,
    datejugement?: Date | null | string,
    datereception?: Date | null | string,
    procedure_id?: string,
    typejugement_id?: string,
    affaire_id?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());

      const auprofitduclientBool =
        auprofitduclient === ""
          ? null
          : auprofitduclient === "oui"
          ? true
          : false;
      const response = await axios.get(
        `${api.baseApi}${api.jugementApi}${
          page ? "/?page=" + page : "/?all=true"
        }${reference ? "&reference=" + reference : ""}${
          texte ? "&texte=" + texte : ""
        }${
          auprofitduclientBool !== null
            ? "&auprofitduclient=" + auprofitduclientBool
            : ""
        }${datejugement ? "&datejugement=" + datejugement : ""}${
          datereception ? "&datereception=" + datereception : ""
        }${procedure_id ? "&procedure_id=" + procedure_id : ""}${
          typejugement_id ? "&typejugement_id=" + typejugement_id : ""
        }${affaire_id ? "&affaire_id=" + affaire_id : ""}`
      );
      let jugements: any;
      jugements = page ? response.data : response.data.data;
      page
        ? dispatch(allJugement(jugements))
        : dispatch(allJugementTrue(jugements));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneJugement = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.jugementApi + "/" + id);
    let jugement = response.data;
    dispatch(oneJugement(jugement));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addJugement =
  (jugement: NewjugementInterface) => async (dispatch: any) => {
    try {
      const Newjugement = await axios.post(
        api.baseApi + api.jugementApi,
        jugement
      );
      return Newjugement;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editJugement =
  (jugement: jugementInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.jugementApi + "/" + id, jugement);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default jugementSlice.reducer;
