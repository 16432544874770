interface SubMenuObject {
  name: string;
  link: string;
}

const Menu: SubMenuObject[] = [
  {
    name: "Clients",
    link: "/clients",
  },
  {
    name: "Adversaires",
    link: "/adversaires",
  },

  {
    name: "Accident de travail",
    link: "/accident-travails",
  },
  {
    name: "Accident de circulation",
    link: "/accident-circulations",
  },
  {
    name: "Véhicule",
    link: "/vehicules",
  },
  {
    name: "Genre Affaire",
    link: "/genre-affaires",
  },
  {
    name: "Type Jugement",
    link: "/type-jugements",
  },
  {
    name: "Type Accident Travail",
    link: "/type-accident-travails",
  },
  {
    name: "Type Juridiction",
    link: "/type-juridictions",
  },
  {
    name: "Resultat Audience",
    link: "/resultat-audiences",
  },
  {
    name: "Type Poursuite",
    link: "/type-poursuites",
  },
  {
    name: "Type Procedure",
    link: "/type-procedures",
  },
  {
    name: "Type Garantie",
    link: "/type-garanties",
  },
  {
    name: "Libelle Procedure",
    link: "/libelle-procedures",
  },
  {
    name: "Sous Type Procedure",
    link: "/sous-type-procedures",
  },
  {
    name: "Type Tribunal",
    link: "/type-tribunals",
  },
  {
    name: "Ville",
    link: "/villes",
  },
  {
    name: "Qualite",
    link: "/qualites",
  },
  {
    name: "Actes",
    link: "/actes",
  },
  {
    name: "TVA",
    link: "/tvas",
  },
  {
    name: "Types Vehicule",
    link: "/type-vehicules",
  },
];

export default Menu;
