import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { adversaireInterface } from "./adversaireSlice";
import { situationInterface } from "./situationSlice";
import { affaireInterface } from "./affaireSlice";

export interface affaireadversaireInterface {
  id_affaire_adversaire: number;
  code: string;
  adversaire_id: number;
  Adversaire: adversaireInterface;
  affaire_id: number;
  Affaire: affaireInterface;
  situation_id: number;
  Situation: situationInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewaffaireadversaireInterface {
  code: string;
  adversaire_id: string;
  affaire_id: string;
  situation_id: string;
}

const initialState = {
  affaireadversaires: [],
  allAffaireadversaires: [],
  affaireadversaire: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const affaireadversaireSlice = createSlice({
  name: "affaireadversaire",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allAffaireadversaire(state, action) {
      state.affaireadversaires = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAffaireadversaireTrue(state, action) {
      state.allAffaireadversaires = action.payload;
      state.error = "";
    },
    oneAffaireadversaire(state, action) {
      state.affaireadversaire = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allAffaireadversaire,
  allAffaireadversaireTrue,
  oneAffaireadversaire,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = affaireadversaireSlice.actions;

export const getAllAffaireadversaire =
  (
    page?: number,
    code?: string,
    adversaire_id?: number,
    affaire_id?: number,
    situation_id?: number
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.affaireadversaireApi}${
          page ? "/?page=" + page : "/?all=true"
        }${code ? "&code=" + code : ""}${
          adversaire_id ? "&adversaire_id=" + adversaire_id : ""
        }${affaire_id ? "&affaire_id=" + affaire_id : ""}${
          situation_id ? "&situation_id=" + situation_id : ""
        }`
      );
      let affaireadversaires: any;
      affaireadversaires = page ? response.data : response.data.data;
      page
        ? dispatch(allAffaireadversaire(affaireadversaires))
        : dispatch(allAffaireadversaireTrue(affaireadversaires));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneAffaireadversaire =
  (id: number) => async (dispatch: any) => {
    try {
      dispatch(setLoadingOneFalse());
      const response = await axios.get(
        api.baseApi + api.affaireadversaireApi + "/" + id
      );
      let affaireadversaire = response.data;
      dispatch(oneAffaireadversaire(affaireadversaire));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const addAffaireadversaire =
  (affaireadversaire: NewaffaireadversaireInterface) =>
  async (dispatch: any) => {
    try {
      const newAffaireadversaire = await axios.post(
        api.baseApi + api.affaireadversaireApi,
        affaireadversaire
      );
      return newAffaireadversaire;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editAffaireadversaire =
  (affaireadversaire: NewaffaireadversaireInterface, id: string) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.affaireadversaireApi + "/" + id,
        affaireadversaire
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const deleteAffaireadversaire =
  (id: string) => async (dispatch: any) => {
    try {
      await axios.delete(api.baseApi + api.affaireadversaireApi + "/" + id);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default affaireadversaireSlice.reducer;
