import React from "react";
import styles from "../../styles/Affaire.module.scss";
import WorkIcon from "@mui/icons-material/Work";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { accidentCirculationInterface } from "../../redux/slices/accidentCirculationSlice";

interface AccidentCirculationDetailInterface {
  accidentCirculation: accidentCirculationInterface;
  setEditAccidentCirculation: () => void;
  setOpenCreateVehiculeClient: (open: boolean) => void;
  deleteClient: (id: string) => void;
  handleEditClient: (
    client: string,
    vehicule: string,
    dclient: string,
    dvehicule: string,
    idAEdit: string
  ) => void;
  setOpenCreateVehiculeAdversaire: (open: boolean) => void;
  deleteAdversaire: (id: string) => void;
  handleEditAdversaire: (
    adversaire: string,
    vehicule: string,
    dadversaire: string,
    dvehicule: string,
    idAEdit: string
  ) => void;
}
const headSet = ["Client", "Véhicule", ""];
const headSet2 = ["Adversaire", "Véhicule", ""];

export default function AccidentCirculationDetail({
  accidentCirculation,
  setEditAccidentCirculation,
  setOpenCreateVehiculeClient,
  deleteClient,
  handleEditClient,
  setOpenCreateVehiculeAdversaire,
  deleteAdversaire,
  handleEditAdversaire,
}: AccidentCirculationDetailInterface) {
  return (
    <div>
      <div className={styles.ListeInformation}>
        <Button
          className={styles.ButtonEditInfo}
          onClick={setEditAccidentCirculation}
        >
          <EditIcon />
        </Button>
        <div className={styles.TitleIcone}>
          <WorkIcon />
          <h2>
            <span>Accident Circulation : </span>
            {accidentCirculation.Affaire.reference}
          </h2>
        </div>
        <div className={styles.SupInfo}>
          <div></div>
          <div className={styles.ListeSupInfo}>
            <p>
              <span>Affaire : </span>
              <Link to={`/affaires/` + accidentCirculation.Affaire?.id_affaire}>
                {accidentCirculation.Affaire?.reference}
              </Link>
            </p>
            <p>
              <span>Type Poursuite : </span>
              {accidentCirculation.TypePoursuite?.libelle}
            </p>
            <p>
              <span>Type Garantie : </span>
              {accidentCirculation.TypeGarantie?.libelle}
            </p>
            <p>
              <span>Num Pparquet : </span>
              {accidentCirculation.numparquet}
            </p>
            <p>
              <span>Num pv : </span>
              {accidentCirculation.numpv}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "50px",
        }}
      >
        <Table className="caption-top" bordered responsive="sm">
          <caption className={styles.Caption}>
            <div className={styles.CaptionContent}>
              <span>Véhicules Clients</span>
              <div>
                <Button onClick={() => setOpenCreateVehiculeClient(true)}>
                  <AddIcon />
                </Button>
              </div>
            </div>
          </caption>
          <thead>
            <tr>
              {headSet.map((head, key) => {
                return (
                  <th className={styles.th} key={key}>
                    {head}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {accidentCirculation.Affaire?.vehiculeclients.length > 0 &&
              accidentCirculation.Affaire?.vehiculeclients.map(
                (client, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        <Link
                          className={styles.reference}
                          to={`/clients/` + client.Client?.id_client + ``}
                          relative="path"
                        >
                          <span>
                            {!client.Client?.nom && !client.Client?.prenom
                              ? "-----"
                              : ""}
                            {client.Client?.nom
                              ? client.Client?.nom + " "
                              : "" + client.Client?.prenom
                              ? client.Client?.prenom
                              : ""}
                          </span>
                        </Link>
                      </td>
                      <td className={styles.bold}>
                        {client.Vehicule?.numpoliceassurance
                          ? client.Vehicule.numpoliceassurance
                          : "-----"}
                      </td>
                      <td width={110}>
                        <div className={styles.GroupeButtonEdit}>
                          <span className={styles.ButtonDelete}>
                            <Button
                              onClick={() =>
                                deleteClient(
                                  client.id_vehicule_client
                                    ? client.id_vehicule_client.toString()
                                    : ""
                                )
                              }
                            >
                              <DeleteIcon />
                            </Button>
                          </span>
                          <span className={styles.ButtonEdit}>
                            <Button
                              onClick={() => {
                                const id_client = client.Client?.id_client
                                  ? client.Client?.id_client.toString()
                                  : "";
                                const clientD = client.Client?.nom
                                  ? client.Client?.nom + " "
                                  : "" + client.Client?.prenom
                                  ? client.Client?.prenom
                                  : "";
                                const id_vehicule = client.Vehicule?.id_vehicule
                                  ? client.Vehicule?.id_vehicule.toString()
                                  : "";
                                const vehicule = client.Vehicule
                                  ?.numpoliceassurance
                                  ? client.Vehicule?.numpoliceassurance
                                  : "";
                                const idAEdit = client.id_vehicule_client
                                  ? client.id_vehicule_client.toString()
                                  : "";
                                handleEditClient(
                                  id_client,
                                  id_vehicule,
                                  clientD ? clientD : "",
                                  vehicule,
                                  idAEdit
                                );
                              }}
                            >
                              <EditIcon />
                            </Button>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </Table>
        <Table className="caption-top" bordered responsive="sm">
          <caption className={styles.Caption}>
            <div className={styles.CaptionContent}>
              <span>Véhicules Adversaires</span>
              <div>
                <Button onClick={() => setOpenCreateVehiculeAdversaire(true)}>
                  <AddIcon />
                </Button>
              </div>
            </div>
          </caption>
          <thead>
            <tr>
              {headSet2.map((head, key) => {
                return (
                  <th className={styles.th} key={key}>
                    {head}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {accidentCirculation.Affaire?.vehiculeadversaires.length > 0 &&
              accidentCirculation.Affaire?.vehiculeadversaires.map(
                (adversaire, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        <Link
                          className={styles.reference}
                          to={
                            `/adversaires/` +
                            adversaire.Adversaire?.id_adversaire +
                            ``
                          }
                          relative="path"
                        >
                          <span>
                            {!adversaire.Adversaire?.nom &&
                            !adversaire.Adversaire?.prenom
                              ? "-----"
                              : ""}
                            {adversaire.Adversaire?.nom
                              ? adversaire.Adversaire?.nom + " "
                              : "" + adversaire.Adversaire?.prenom
                              ? adversaire.Adversaire?.prenom
                              : ""}
                          </span>
                        </Link>
                      </td>
                      <td className={styles.bold}>
                        {adversaire.Vehicule?.numpoliceassurance
                          ? adversaire.Vehicule.numpoliceassurance
                          : "-----"}
                      </td>
                      <td width={110}>
                        <div className={styles.GroupeButtonEdit}>
                          <span className={styles.ButtonDelete}>
                            <Button
                              onClick={() =>
                                deleteAdversaire(
                                  adversaire.id_vehicule_adversaire
                                    ? adversaire.id_vehicule_adversaire.toString()
                                    : ""
                                )
                              }
                            >
                              <DeleteIcon />
                            </Button>
                          </span>
                          <span className={styles.ButtonEdit}>
                            <Button
                              onClick={() => {
                                const id_adversaire = adversaire.Adversaire
                                  ?.id_adversaire
                                  ? adversaire.Adversaire?.id_adversaire.toString()
                                  : "";
                                const adversaireD = adversaire.Adversaire?.nom
                                  ? adversaire.Adversaire?.nom + " "
                                  : "" + adversaire.Adversaire?.prenom
                                  ? adversaire.Adversaire?.prenom
                                  : "";
                                const id_vehicule = adversaire.Vehicule
                                  ?.id_vehicule
                                  ? adversaire.Vehicule?.id_vehicule.toString()
                                  : "";
                                const vehicule = adversaire.Vehicule
                                  ?.numpoliceassurance
                                  ? adversaire.Vehicule?.numpoliceassurance
                                  : "";
                                const idAEdit =
                                  adversaire.id_vehicule_adversaire
                                    ? adversaire.id_vehicule_adversaire.toString()
                                    : "";
                                handleEditAdversaire(
                                  id_adversaire,
                                  id_vehicule,
                                  adversaireD ? adversaireD : "",
                                  vehicule,
                                  idAEdit
                                );
                              }}
                            >
                              <EditIcon />
                            </Button>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
