import React, { useEffect, useState } from "react";
import { procedureInterface } from "../../redux/slices/procedureSlice";
import styles from "../../styles/Affaire.module.scss";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Button, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  audienceInterface,
  getAllAudience,
} from "../../redux/slices/audienceSlice";
import { Meta } from "../../redux/interfaces";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Pagination } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AppDispatch } from "../../redux/store";
import { theme } from "../../styles/global";
import Spinner from "../../components/Spinner";
import {
  factureInterface,
  getAllFacture,
  getFactureExport,
} from "../../redux/slices/factureSlice";
import {
  getAllJugement,
  jugementInterface,
} from "../../redux/slices/jugementSlice";
import axios from "axios";
import SpinnerPage from "../../components/SpinnerPage";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
interface ProcedureDetailInterface {
  procedure: procedureInterface;
  setEditProcedure: () => void;
  setShowFacturesCreate: (bool: boolean) => void;
}

const headSetAudience = ["Date Audience", "Resultat Audience", "Crée le"];
interface audiencePaginationInterface {
  meta: Meta;
  data: audienceInterface[];
}
const headSetFacture = ["Numero", "Affaire", "Client", "Crée le", ""];
interface facturePaginationInterface {
  meta: Meta;
  data: factureInterface[];
}
const headSetJugement = ["Date Jugement", "Type Jugement", "Crée le"];

interface jugementPaginationInterface {
  meta: Meta;
  data: jugementInterface[];
}

export default function ProcedureDetail({
  procedure,
  setEditProcedure,
  setShowFacturesCreate,
}: ProcedureDetailInterface) {
  const loadingAudience = useSelector(
    (state: any) => state.audience.loadingListe
  );
  const audiences: audiencePaginationInterface = useSelector(
    (state: any) => state.audience.audiences
  );
  const loadingFacture = useSelector(
    (state: any) => state.facture.loadingListe
  );
  const factures: facturePaginationInterface = useSelector(
    (state: any) => state.facture.factures
  );
  const loadingJugement = useSelector(
    (state: any) => state.jugement.loadingListe
  );
  const jugements: jugementPaginationInterface = useSelector(
    (state: any) => state.jugement.jugements
  );
  const [loadingOne, setLoadingOne] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [expandAudience, setExpandAudience] = useState(true);
  const [expandJugement, setExpandJugement] = useState(true);
  const [expandFacture, setExpandFacture] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const [pageAudience, setPageAudience] = useState(1);
  const [pageJugement, setPageJugement] = useState(1);
  const [pageFacture, setPageFacture] = useState(1);
  const navigate = useNavigate();
  const facture: any = useSelector((state: any) => state.facture.factureExport);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      dispatch(
        getAllAudience(
          1,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          procedure.id_procedure.toString()
        )
      );
      dispatch(
        getAllFacture(1, "", "", "", procedure.id_procedure.toString(), "")
      );
      dispatch(
        getAllJugement(
          1,
          "",
          "",
          "",
          "",
          "",
          procedure.id_procedure.toString(),
          "",
          ""
        )
      );
    }
  }, []);
  const handleChangeAudience = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageAudience(value);
    dispatch(
      getAllAudience(
        value,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        procedure.id_procedure.toString()
      )
    );
  };
  const handleChangeJugement = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageJugement(value);
    dispatch(
      getAllJugement(
        value,
        "",
        "",
        "",
        "",
        "",
        procedure.id_procedure.toString(),
        "",
        ""
      )
    );
  };
  const handleChangeFacture = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageFacture(value);
    dispatch(
      getAllFacture(value, "", "", "", procedure.id_procedure.toString(), "")
    );
  };
  const handleDownloadPDF = (facture: factureInterface) => {
    dispatch(getFactureExport(facture.id_facture));
    setLoadingOne(true);
  };
  useEffect(() => {
    if (!isFirstRender && facture) {
      axios
        .post(process.env.REACT_APP_API_FACTURE_URL + "generate_pdf", facture, {
          responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");

          link.href = url;
          const noSpecialChars = facture.numero.replace(/[^a-zA-Z0-9 ]/g, "");
          link.download = "facture_" + noSpecialChars + ".pdf"; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoadingOne(false);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    }
  }, [facture]);

  useEffect(() => {
    if (!isFirstRender && factures) {
      if (factures.data.length > 0) {
        setShowFacturesCreate(false);
      }
    }
  }, [factures]);
  return (
    <div>
      {loadingOne && <SpinnerPage />}

      <div className={styles.ListeInformation}>
        <Button className={styles.ButtonEditInfo} onClick={setEditProcedure}>
          <EditIcon />
        </Button>
        <div className={styles.TitleIcone}>
          <AccountTreeIcon />
          <h2>
            <span>Procedure : </span>
            {procedure.reference}
          </h2>
        </div>
        <div className={styles.SupInfo}>
          <div></div>
          <div className={styles.ListeSupInfo}>
            <p>
              <span>Affaire : </span>
              <Link to={`/affaires/` + procedure.Affaire?.id_affaire}>
                {procedure.Affaire?.reference}
              </Link>
            </p>
            <p>
              <span>Type Procedure : </span>
              {procedure.TypeProcedure?.libelle}
            </p>
            <p>
              <span>Type Juridiction : </span>
              {procedure.TypeJuridiction?.libelle}
            </p>
            <p>
              <span>Sous Type Procedure : </span>
              {procedure.SousTypeProcedure?.libelle}
            </p>
            <p>
              <span>Type Tribunal : </span>
              {procedure.TypeTribunal?.libelle}
            </p>
            <p>
              <span>Ville : </span>
              {procedure.Ville?.libelle}
            </p>
            {procedure.parent_id && (
              <p>
                <span>Procédure Parent : </span>
                <Link to={`/procedures/` + procedure.parent_id}>
                  {procedure.parent?.reference}
                </Link>
              </p>
            )}
          </div>
        </div>
      </div>
      {!loadingAudience ? (
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Audiences</span>
                <span
                  onClick={() => setExpandAudience(!expandAudience)}
                  className={styles.ButtonExpand}
                >
                  {!expandAudience ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>

            {expandAudience && (
              <>
                <thead>
                  <tr>
                    {headSetAudience.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {audiences.data.length > 0 ? (
                    audiences.data.map((audience, key) => {
                      let date = new Date(audience.createdon);
                      let dateau = audience.dateaudience
                        ? new Date(audience.dateaudience)
                        : null;
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={`/audiences/` + audience.id_audience + ``}
                              relative="path"
                            >
                              <span>
                                {audience.dateaudience ? (
                                  <>
                                    <CalendarMonthIcon />
                                    <span className={styles.bold}>
                                      {moment(dateau).format("DD/MM/YYYY")}
                                    </span>
                                  </>
                                ) : (
                                  "-----"
                                )}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            {audience.ResultatAudience?.libelle
                              ? audience.ResultatAudience?.libelle
                              : "-----"}
                          </td>
                          <td>
                            <div className={styles.TdwithIcon}>
                              {audience.createdon ? (
                                <>
                                  <CalendarMonthIcon />
                                  <span className={styles.bold}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </span>
                                </>
                              ) : (
                                "-----"
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className={styles.bold}>
                        <Alert
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          severity="error"
                        >
                          Aucune Audience Trouvé
                        </Alert>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
          {expandAudience && audiences.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={audiences.meta.totalPages}
                  page={pageAudience}
                  onChange={handleChangeAudience}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
      {!loadingJugement ? (
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Jugements</span>
                <span
                  onClick={() => setExpandJugement(!expandJugement)}
                  className={styles.ButtonExpand}
                >
                  {!expandJugement ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>
            {expandJugement && (
              <>
                <thead>
                  <tr>
                    {headSetJugement.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {jugements.data.length > 0 ? (
                    jugements.data.map((jugement, key) => {
                      let date = new Date(jugement.createdon);
                      let datejugement = jugement.datejugement
                        ? new Date(jugement.datejugement)
                        : null;
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={`/jugements/` + jugement.id_jugement + ``}
                              relative="path"
                            >
                              <span>
                                {jugement.datejugement ? (
                                  <>
                                    <CalendarMonthIcon />
                                    <span className={styles.bold}>
                                      {moment(datejugement).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  "-----"
                                )}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            {jugement.TypeJugement?.libelle
                              ? jugement.TypeJugement?.libelle
                              : "-----"}
                          </td>
                          <td>
                            <div className={styles.TdwithIcon}>
                              {jugement.createdon ? (
                                <>
                                  <CalendarMonthIcon />
                                  <span className={styles.bold}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </span>
                                </>
                              ) : (
                                "-----"
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className={styles.bold}>
                        <Alert
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          severity="error"
                        >
                          Aucune Jugement Trouvé
                        </Alert>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
          {expandJugement && jugements.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={jugements.meta.totalPages}
                  page={pageJugement}
                  onChange={handleChangeJugement}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
      {!loadingFacture ? (
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Factures</span>
                <span
                  onClick={() => setExpandFacture(!expandFacture)}
                  className={styles.ButtonExpand}
                >
                  {!expandFacture ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>
            {expandFacture && (
              <>
                <thead>
                  <tr>
                    {headSetFacture.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {factures.data.length > 0 ? (
                    factures.data.map((facture, key) => {
                      let date = new Date(facture.createdon);
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={`/factures/` + facture.id_facture + ``}
                              relative="path"
                            >
                              <span>
                                {facture.numero ? facture.numero : "-----"}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            {facture.Affaire?.reference
                              ? facture.Affaire?.reference
                              : "-----"}
                          </td>
                          <td className={styles.bold}>
                            <span>
                              {!facture.Client?.nom && !facture.Client?.prenom
                                ? "-----"
                                : ""}
                              {facture.Client?.nom
                                ? facture.Client?.nom + " "
                                : "" + facture.Client?.prenom
                                ? facture.Client?.prenom
                                : ""}
                            </span>
                          </td>
                          <td>
                            <div className={styles.TdwithIcon}>
                              {facture.createdon ? (
                                <>
                                  <CalendarMonthIcon />
                                  <span className={styles.bold}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </span>
                                </>
                              ) : (
                                "-----"
                              )}
                            </div>
                          </td>
                          <td width={50}>
                            <Button
                              className={styles.ButtonDownload}
                              onClick={() => handleDownloadPDF(facture)}
                            >
                              <DownloadIcon />
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className={styles.bold}>
                        <Alert
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          severity="error"
                        >
                          Aucune Facture Trouvé
                        </Alert>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
          {expandFacture && factures.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={factures.meta.totalPages}
                  page={pageFacture}
                  onChange={handleChangeFacture}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
