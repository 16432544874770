import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { procedureInterface } from "./procedureSlice";
import { resultatAudienceInterface } from "./resultatAudienceSlice";
import { affaireInterface } from "./affaireSlice";

export interface audienceInterface {
  id_audience: number;
  procedure_id: number;
  affaire_id: number;
  Procedure: procedureInterface;
  Affaire: affaireInterface;
  resultataudience_id: number;
  ResultatAudience: resultatAudienceInterface;
  reference: string;
  //code: string;
  dateaudience: Date | string | null;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewaudienceInterface {
  procedure_id: string;
  affaire_id: string;
  resultataudience_id: string;
  reference: string;
  //code: string;
  dateaudience: Date | string | null;
}

const initialState = {
  audiences: [],
  allAudiences: [],
  audience: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const audienceSlice = createSlice({
  name: "audience",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allAudience(state, action) {
      state.audiences = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAudienceTrue(state, action) {
      state.allAudiences = action.payload;
      state.error = "";
    },
    oneAudience(state, action) {
      state.audience = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allAudience,
  allAudienceTrue,
  oneAudience,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = audienceSlice.actions;

export const getAllAudience =
  (
    page?: number,
    dateaudience?: Date | null | string,
    dateaudiencemax?: Date | null | string,
    dateaudiencemin?: Date | null | string,
    resultataudience_id?: string,
    genreaffaire_id?: string,
    typeprocedure_id?: string,
    typetribunal_id?: string,
    siege_id?: string,
    affaire_id?: string,
    procedure_id?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.audienceApi}${
          page ? "/?page=" + page : "/?all=true"
        }${dateaudience ? "&dateaudience=" + dateaudience : ""}${
          dateaudiencemax ? "&dateaudiencemax=" + dateaudiencemax : ""
        }${dateaudiencemin ? "&dateaudiencemin=" + dateaudiencemin : ""}${
          resultataudience_id
            ? "&resultataudience_id=" + resultataudience_id
            : ""
        }${typetribunal_id ? "&typetribunal_id=" + typetribunal_id : ""}${
          siege_id ? "&siege_id=" + siege_id : ""
        }${typeprocedure_id ? "&typeprocedure_id=" + typeprocedure_id : ""}${
          genreaffaire_id ? "&genreaffaire_id=" + genreaffaire_id : ""
        }${affaire_id ? "&affaire_id=" + affaire_id : ""}${
          procedure_id ? "&procedure_id=" + procedure_id : ""
        }`
      );
      let audiences: any;
      audiences = page ? response.data : response.data.data;
      page
        ? dispatch(allAudience(audiences))
        : dispatch(allAudienceTrue(audiences));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneAudience = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.audienceApi + "/" + id);
    let audience = response.data;
    dispatch(oneAudience(audience));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addAudience =
  (audience: NewaudienceInterface) => async (dispatch: any) => {
    try {
      const Newaudience = await axios.post(
        api.baseApi + api.audienceApi,
        audience
      );
      return Newaudience;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editAudience =
  (audience: audienceInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.audienceApi + "/" + id, audience);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default audienceSlice.reducer;
