import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface tvaInterface {
  id_tva: number;
  code: string;
  libelle: string;
  valeur: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtvaInterface {
  code: string;
  libelle: string;
  valeur: string;
}

const initialState = {
  tvas: [],
  allTvas: [],
  tva: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const tvaSlice = createSlice({
  name: "tva",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTva(state, action) {
      state.tvas = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTvaTrue(state, action) {
      state.allTvas = action.payload;
      state.error = "";
    },
    oneTva(state, action) {
      state.tva = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTva,
  allTvaTrue,
  oneTva,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = tvaSlice.actions;

export const getAllTva =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.tvaApi}${page ? "/?page=" + page : "/?all=true"}${
          libelle && "&libelle=" + libelle
        }${code && "&code=" + code}`
      );
      let tvas: any;
      tvas = page ? response.data : response.data.data;
      page ? dispatch(allTva(tvas)) : dispatch(allTvaTrue(tvas));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTva = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.tvaApi + "/" + id);
    let tva = response.data;
    dispatch(oneTva(tva));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addTva = (tva: NewtvaInterface) => async (dispatch: any) => {
  try {
    const newtva = await axios.post(api.baseApi + api.tvaApi, tva);
    return newtva;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const editTva =
  (tva: tvaInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.tvaApi + "/" + id, tva);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default tvaSlice.reducer;
