import React, { useEffect } from "react";
import styles from "../styles/login.module.scss";
import LoginForm from "../components/LoginForm";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import logo from "../assets/logo.png";
import SpinnerPage from "../components/SpinnerPage";
export default function Login() {
  const navigate = useNavigate();

  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/affaires");
    }
  }, []);
  return (
    <>
      {!isAuthenticated ? (
        <div className={styles.LoginAppContainer}>
          <div className={styles.LoginImageContainer}>
            <img src={logo} alt="Sia" />
            <div className={styles.Middle}>
              <h2>Système d'information Avocat </h2>
              <p>
                Un système d'information efficace pour une gestion juridique
                optimale.
              </p>
              <Link to="/" target="_blank">
                Lire la suite
              </Link>
            </div>
          </div>
          <div className={styles.LoginContainer}>
            <div className={styles.Middle}>
              <LoginForm />
            </div>
          </div>
        </div>
      ) : (
        <SpinnerPage />
      )}
    </>
  );
}
