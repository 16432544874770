import { useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { addAudience } from "../../redux/slices/audienceSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ResultatAudienceModal from "../../components/Modals/ResultatAudienceModal";
import SearchIcon from "@mui/icons-material/Search";
import ProcedureModal from "../../components/Modals/ProcedureModal";
import ResultatAudienceCreateModal from "../../components/CreateModals/ResultatAudienceCreateModal";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  procedure_id: string;
  affaire_id: string;
  resultataudience_id: string;
  reference: string;
  //code: string;
  dateaudience: Date | string | null;
}

export default function NewAudience() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [idProc, setIdProc] = useState(null);

  const [openCreateResultatAudience, setOpenCreateResultatAudience] =
    useState(false);
  const [openResultatAudience, setOpenResultatAudience] = useState(false);
  const [displayResultatAudience, setDisplayResultatAudience] = useState("");

  const [openProcedure, setOpenProcedure] = useState(false);
  const [displayProcedure, setDisplayProcedure] = useState("");

  const formikRef = useRef<FormikProps<MyFormValues>>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const schema = yup.object({
    //reference: yup.string().required("Reference est obligatoire"),

    dateaudience: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
    procedure_id: yup.string().required("Procedure est obligatoire"),
    resultataudience_id: yup
      .string()
      .required("Resultat Audience est obligatoire"),
  });

  const initialValues: MyFormValues = {
    reference: "",
    //code: "",
    dateaudience: null,
    procedure_id: "",
    affaire_id: "",
    resultataudience_id: "",
  };

  useEffect(() => {
    const localStorageProcedure = localStorage.getItem("procedure");
    if (localStorageProcedure) {
      let affaireObject = JSON.parse(localStorageProcedure);
      if (formikRef.current) {
        const updateValues = {
          ...formikRef.current.values,
          procedure_id: affaireObject.id_procedure,
          affaire_id: affaireObject.Affaire.id_affaire,
        };
        formikRef.current.setValues(updateValues);
        setIdProc(affaireObject.id_procedure);
      }
      setDisplayProcedure(affaireObject.reference);
    } else {
      navigate("/procedures");
    }
    return () => {
      localStorage.removeItem("procedure");
    };
  }, []);

  return (
    <>
      <div className={styles.ReturnList}>
        <Link to={"/procedures/" + idProc} relative="path">
          <ArrowBackIosIcon /> Retour Procédure
        </Link>
        <h2>Nouveau Audience</h2>
        <div />
      </div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const Newaudience = await dispatch(addAudience(values));
          if (Newaudience?.status) {
            MySwal.fire({
              icon: "success",
              title: "Audience est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              const url = "/procedures/" + Newaudience?.data.procedure_id;
              navigate(url);
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* <Grid item xs={6}>
                <TextField
                  label="Reference"
                  name="reference"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.reference}
                  helperText={errors.reference ? errors.reference : ""}
                />
              </Grid> */}
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setFieldValue("dateaudience", date);
                    }}
                    value={moment(values.dateaudience)}
                    label="Date Audience"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.dateaudience,
                      },
                    }}
                  />
                </LocalizationProvider>
                {errors.dateaudience && (
                  <span className={styles.errorHandle}>
                    {errors.dateaudience}
                  </span>
                )}
              </Grid>
              <Grid container item xs={6}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayResultatAudience
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Resultat Audience
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayResultatAudience}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenResultatAudience(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Resultat Audience"
                    />
                  </FormControl>
                  {openResultatAudience && (
                    <ResultatAudienceModal
                      open={openResultatAudience}
                      handleClose={() => setOpenResultatAudience(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("resultataudience_id", value)
                      }
                      handleChangeDisplay={setDisplayResultatAudience}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateResultatAudience(true)}
                  >
                    +
                  </Button>
                  {openCreateResultatAudience && (
                    <ResultatAudienceCreateModal
                      open={openCreateResultatAudience}
                      handleClose={() => setOpenCreateResultatAudience(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("resultataudience_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayResultatAudience(value)
                      }
                    />
                  )}
                </Grid>
                {errors.resultataudience_id && (
                  <span className={styles.errorHandle}>
                    {errors.resultataudience_id}
                  </span>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  className={
                    !displayProcedure
                      ? styles.backgroudGrey
                      : styles.backgroudGrey2
                  }
                  fullWidth
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Procédure
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    disabled
                    fullWidth
                    value={displayProcedure}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setOpenProcedure(true)}
                          edge="end"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Procédure"
                  />
                </FormControl>
                {openProcedure && (
                  <ProcedureModal
                    open={openProcedure}
                    handleClose={() => setOpenProcedure(false)}
                    handleChangeValue={(value) =>
                      setFieldValue("procedure_id", value)
                    }
                    handleChangeValueAffaire={(value) =>
                      setFieldValue("affaire_id", value)
                    }
                    handleChangeDisplay={setDisplayProcedure}
                  />
                )}
                {errors.procedure_id && (
                  <span className={styles.errorHandle}>
                    {errors.procedure_id}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} className={styles.ButtonGrid}>
                <Button
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                  variant="contained"
                  type="submit"
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
