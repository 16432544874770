import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import Cookies from "universal-cookie";
import moment from "moment";
const cookies = new Cookies();

const initialState = {
  isAuthenticated: !!cookies.get("token"),
  user: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isAuthenticated = !!cookies.get("token");
      state.user = action.payload;
      state.error = null;
    },
    loginFailure(state, action) {
      cookies.remove("token");
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    logout(state) {
      cookies.remove("token");
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
  },
});

export const { loginSuccess, loginFailure, logout } = authSlice.actions;

export const login =
  (credentials: { username: string; password: string }) =>
  async (dispatch: any) => {
    try {
      const response = await axios.post(
        api.baseApi + api.loginApi,
        credentials
      );
      const user = response.data.user;
      let mois = new Date(moment().add(30, "d").format());
      let optionCookies = { path: "/", expires: mois };
      cookies.set("token", response.data.accessToken, optionCookies);
      dispatch(loginSuccess(user));
      return true;
    } catch (error: any) {
      dispatch(loginFailure(error.response.data.message));
      return false;
    }
  };

export default authSlice.reducer;
