import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  editFacture,
  factureInterface,
  getFactureExport,
  getOneFacture,
} from "../../redux/slices/factureSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import ProcedureModal from "../../components/Modals/ProcedureModal";
import Spinner from "../../components/Spinner";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  deleteFactureacte,
  factureActeInterface,
} from "../../redux/slices/factureActeSlice";
import FactureActeCreateModal from "../../components/CreateModals/FactureActereateModal";
import FactureActeEditModal from "../../components/EditModals/FactureActeEditModal";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import SpinnerPage from "../../components/SpinnerPage";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

export default function EditFacture() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [openCreateFactureActe, setOpenCreateFactureActe] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [total, setTotal] = useState(0);
  const [totalTva, setTotalTva] = useState(0);
  const [totalHT, setTotalHT] = useState(0);
  const [montantretenu, setMontantretenu] = useState<any>([]);
  const [loadingOne, setLoadingOne] = useState(false);

  const [openProcedure, setOpenProcedure] = useState(false);
  const [displayProcedure, setDisplayProcedure] = useState("");

  const [openEditFactureActe, setOpenEditFactureActe] = useState(false);
  const [editTva, setEditTva] = useState("");
  const [editDisplayTva, setEditDisplayTva] = useState("");
  const [editActe, setEditActe] = useState("");
  const [editDisplayActe, setEditDisplayActe] = useState("");
  const [editRegle, setEditRegle] = useState(false);

  const [editQuantite, setEditQuantite] = useState(1);
  const [editPrixUnitaire, setEditPrixUnitaire] = useState(0);
  const [editDateDesignation, setEditDateDesignation] = useState("");

  const [idEdit, setIdEdit] = useState("");

  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const facture: factureInterface = useSelector(
    (state: any) => state.facture.facture
  );
  const factureExport: any = useSelector(
    (state: any) => state.facture.factureExport
  );
  const loading = useSelector((state: any) => state.facture.loadingOne);
  const schema = yup.object({
    procedure_id: yup.string().required("Procedure est obligatoire"),
    client_id: yup.string().required("Client est obligatoire"),
    numero: yup.string().required("Numero est obligatoire"),
    //numseq: yup.string().required("Num Seq est obligatoire"),
    /* dateemmission: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }), */
  });

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneFacture(id));
    } else {
      navigate("/factures");
    }
  }, []);

  const refrechData = () => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneFacture(id));
    }
  };
  const handleDownloadPDF = (facture: factureInterface) => {
    dispatch(getFactureExport(facture.id_facture));
    setLoadingOne(true);
  };
  useEffect(() => {
    if (!isFirstRender && factureExport) {
      axios
        .post(
          process.env.REACT_APP_API_FACTURE_URL + "generate_pdf",
          factureExport,
          {
            responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");

          link.href = url;
          const noSpecialChars = factureExport.numero.replace(
            /[^a-zA-Z0-9 ]/g,
            ""
          );
          link.download = "facture_" + noSpecialChars + ".pdf"; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoadingOne(false);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    }
  }, [factureExport]);

  useEffect(() => {
    setIsFirstRender(false);
    setDisplayProcedure(facture?.Procedure?.reference);
    calculTotal(facture.factureactes, facture.retenir);
  }, [facture]);

  const deleteFactureActe = (id: string) => {
    MySwal.fire({
      icon: "warning",
      title: "Voulez vous supprimer cet ligne",
      showCancelButton: true,
      confirmButtonColor: "#BC6DBD",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      customClass: {
        confirmButton: "shadow-none",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFactureacte(id));
        refrechData();
      }
    });
  };

  const handleEditFactureActe = (
    id_tva: string,
    tva: string,
    id_acte: string,
    acte: string,
    idAEdit: string
  ) => {
    setEditTva(id_tva);
    setEditDisplayTva(tva);
    setEditActe(id_acte);
    setEditDisplayActe(acte);
    setOpenEditFactureActe(true);
    setIdEdit(idAEdit);
  };
  const calculTotal = (array: factureActeInterface[], retenir: boolean) => {
    let totalFacture = 0;
    let totalFactureHT = 0;
    let totalFactureTVA = 0;
    setMontantretenu([]);
    array?.length > 0 &&
      array?.forEach((element, _index) => {
        let tva = element.Tva
          ? element.prixunitaire *
            element.quantite *
            (parseFloat(element.Tva.valeur) / 100)
          : 0;
        totalFactureTVA += tva;
        totalFactureHT += element.prixunitaire * element.quantite;
        totalFacture += element.prixunitaire * element.quantite + tva;
        setMontantretenu((prevState: any) => [
          ...prevState,
          retenir && element.Acte.typeacte === "HONORAIRES"
            ? ((element.prixunitaire * element.quantite) / 10).toFixed(2)
            : 0,
        ]);
      });
    setTotal(parseFloat(totalFacture.toFixed(2)));
    setTotalTva(parseFloat(totalFactureTVA.toFixed(2)));
    setTotalHT(parseFloat(totalFactureHT.toFixed(2)));
  };
  return (
    <>
      <div className={styles.ReturnList}>
        <Link to="/factures" relative="path">
          <ArrowBackIosIcon /> Liste Factures
        </Link>
        <div className={styles.TitleButton}>
          <h2>Editer Facture</h2>
          <Button
            className={styles.ButtonDownload2}
            onClick={() => handleDownloadPDF(facture)}
          >
            <DownloadIcon />
          </Button>
        </div>
        <div />
      </div>
      {loadingOne && <SpinnerPage />}
      {!loading && Object.keys(facture).length > 0 ? (
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          validateOnBlur={false}
          initialValues={facture}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            setLoadingSend(true);
            const bool = await dispatch(editFacture(object, values.id_facture));
            if (bool) {
              MySwal.fire({
                icon: "success",
                title: "Facture est modifié avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then(() => {
                setLoadingSend(false);
                navigate("/factures");
              });
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    className={styles.TextFieldDisabled}
                    label="Numero"
                    name="numero"
                    onChange={handleChange}
                    value={values.numero}
                    fullWidth
                    disabled
                    error={!!errors.numero}
                    helperText={errors.numero ? errors.numero : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    className={
                      !displayProcedure
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Procédure
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayProcedure}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenProcedure(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Procédure"
                    />
                  </FormControl>
                  {openProcedure && (
                    <ProcedureModal
                      open={openProcedure}
                      handleClose={() => setOpenProcedure(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("procedure_id", value)
                      }
                      handleChangeValueAffaire={(value) =>
                        setFieldValue("affaire_id", value)
                      }
                      handleChangeDisplay={setDisplayProcedure}
                    />
                  )}
                  {errors.procedure_id && (
                    <span className={styles.errorHandle}>
                      {errors.procedure_id}
                    </span>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={styles.backgroudWhite} fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Client
                    </InputLabel>
                    <Select
                      name="client_id"
                      labelId="demo-simple-select-label"
                      label="Client"
                      onChange={handleChange}
                      value={values.client_id}
                    >
                      <MenuItem value="">-</MenuItem>
                      {facture.Affaire?.clients?.length > 0 &&
                        facture.Affaire?.clients.map((client, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={client.Client?.id_client}
                            >
                              {!client.Client?.nom && !client.Client?.prenom
                                ? "-----"
                                : ""}
                              {client.Client?.nom
                                ? client.Client?.nom + " "
                                : "" + client.Client?.prenom
                                ? client.Client?.prenom
                                : ""}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 32 },
                        }}
                      />
                    }
                    label="Retenir"
                    checked={values.retenir}
                    onChange={() => {
                      setFieldValue("retenir", !values.retenir);
                      calculTotal(values.factureactes, !values.retenir);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={styles.ArrayInput}>
                  <Table bordered responsive="sm">
                    <thead>
                      <tr>
                        <th className={styles.th}>Acte</th>
                        {/* <th className={styles.th}>Quantité</th> */}
                        <th className={styles.th}>Prix Unitaire</th>
                        <th className={styles.th}>Tva</th>
                        <th className={styles.th}>Date Désignation</th>
                        <th className={styles.th}>Montant Retenue</th>
                        <th className={styles.th}>Réglé</th>
                        <th className={styles.th}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.factureactes.map((element, index) => {
                        return (
                          <tr>
                            <td>
                              <FormControl
                                className={styles.backgroudGrey}
                                fullWidth
                                size="small"
                              >
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={element.Acte.libelle}
                                />
                              </FormControl>
                            </td>
                            {/* <td width={70}>
                              <FormControl
                                className={styles.backgroudGrey}
                                fullWidth
                                size="small"
                              >
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={element.quantite}
                                />
                              </FormControl>
                            </td> */}
                            <td>
                              <FormControl
                                className={styles.backgroudGrey}
                                fullWidth
                                size="small"
                              >
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={element.prixunitaire}
                                />
                              </FormControl>
                            </td>
                            <td width={70}>
                              <FormControl
                                className={styles.backgroudGrey}
                                fullWidth
                                size="small"
                              >
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={element.Tva ? element.Tva.valeur : 0}
                                />
                              </FormControl>
                            </td>
                            <td>
                              <FormControl
                                className={styles.backgroudGrey}
                                fullWidth
                                size="small"
                              >
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={element.datedesignation}
                                />
                              </FormControl>
                            </td>
                            {/* <td>
                              <FormControl
                                className={styles.backgroudGrey}
                                fullWidth
                                size="small"
                              >
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={element.montantregle}
                                />
                              </FormControl>
                            </td> */}
                            <td>
                              <FormControl
                                className={styles.backgroudGrey}
                                fullWidth
                                size="small"
                              >
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={montantretenu[index]}
                                />
                              </FormControl>
                            </td>
                            <td width={120}>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 24 },
                                }}
                                disabled
                                checked={element.regle}
                              />
                            </td>
                            <td width={150}>
                              <div className={styles.GroupeButtonEditFacture}>
                                <span className={styles.ButtonDelete}>
                                  <Button
                                    onClick={() =>
                                      deleteFactureActe(
                                        element.id_facture_acte
                                          ? element.id_facture_acte.toString()
                                          : ""
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </span>
                                <span className={styles.ButtonEdit}>
                                  <Button
                                    onClick={() => {
                                      const id_tva = element.Tva?.id_tva
                                        ? element.Tva?.id_tva.toString()
                                        : "";
                                      console.log(element.Tva);
                                      const tva = element.Tva?.valeur.toString()
                                        ? element.Tva?.valeur
                                        : "";
                                      const id_acte = element.Acte?.id_acte
                                        ? element.Acte?.id_acte.toString()
                                        : "";
                                      const acte = element.Acte?.libelle
                                        ? element.Acte?.libelle
                                        : "";
                                      const idAEdit = element.id_facture_acte
                                        ? element.id_facture_acte.toString()
                                        : "";
                                      setEditQuantite(element.quantite);
                                      setEditDateDesignation(
                                        element.datedesignation
                                      );
                                      setEditPrixUnitaire(element.prixunitaire);
                                      setEditRegle(element.regle);
                                      handleEditFactureActe(
                                        id_tva,
                                        tva,
                                        id_acte,
                                        acte,
                                        idAEdit
                                      );
                                    }}
                                  >
                                    <EditIcon />
                                  </Button>
                                </span>
                                {index === 0 ? (
                                  <span className={styles.ButtonArray}>
                                    <Button
                                      onClick={() =>
                                        setOpenCreateFactureActe(true)
                                      }
                                    >
                                      <AddIcon />
                                    </Button>
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  {openCreateFactureActe && (
                    <FactureActeCreateModal
                      open={openCreateFactureActe}
                      handleClose={() => setOpenCreateFactureActe(false)}
                      handleCloseUpdate={refrechData}
                      facture_id={facture.id_facture.toString()}
                    />
                  )}
                  {openEditFactureActe && (
                    <FactureActeEditModal
                      open={openEditFactureActe}
                      handleClose={() => setOpenEditFactureActe(false)}
                      handleCloseUpdate={refrechData}
                      facture_id={facture.id_facture.toString()}
                      tva_id={editTva}
                      acte_id={editActe}
                      displayTva={editDisplayTva}
                      displayActe={editDisplayActe}
                      quantite={editQuantite}
                      regle={editRegle}
                      datedesignation={editDateDesignation}
                      prixunitaire={editPrixUnitaire}
                      idEdit={idEdit}
                    />
                  )}
                  <Grid item xs={12} className="mt-3">
                    <h5 className="mt-1">Total HT : {totalHT} Dhs</h5>
                    <h5 className="mt-1">Total TVA : {totalTva} Dhs</h5>
                    <h5 className="mt-1">TTC : {total} Dhs</h5>
                    {/* <h5 className="mt-1">
                      Montant total à régler :{" "}
                      {montantaregle.reduce(
                        (acc: number, curr: number) =>
                          Number(acc) + Number(curr),
                        0
                      )}{" "}
                      Dhs
                    </h5> */}
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.ButtonGrid}>
                  <Button
                    startIcon={loadingSend && <CircularProgress size={20} />}
                    disabled={loadingSend}
                    variant="contained"
                    type="submit"
                  >
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner />
      )}
    </>
  );
}
