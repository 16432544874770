import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { affaireInterface } from "./affaireSlice";
import { procedureInterface } from "./procedureSlice";
import { clientInterface } from "./clientSlice";
import { factureActeInterface } from "./factureActeSlice";
import { jugementInterface } from "./jugementSlice";

export interface factureInterface {
  id_facture: number;
  affaire_id: number;
  Affaire: affaireInterface;
  procedure_id: number;
  Procedure: procedureInterface;
  client_id: number;
  Client: clientInterface;
  factureactes: factureActeInterface[];
  numero: string;
  numseq: number;
  retenir: boolean;
  jugements: jugementInterface[];
  dateemmission: Date | string | null;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewfactureInterface {
  affaire_id: string;
  procedure_id: string;
  client_id: string;
  numero: string;
  retenir: boolean;
  //numseq: number;
  dateemmission: Date | string | null;
}

const initialState = {
  factures: [],
  allFactures: [],
  facture: {},
  factureExport: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const factureSlice = createSlice({
  name: "facture",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allFacture(state, action) {
      state.factures = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allFactureTrue(state, action) {
      state.allFactures = action.payload;
      state.error = "";
    },
    oneFacture(state, action) {
      state.facture = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    oneFactureExport(state, action) {
      state.factureExport = action.payload;
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allFacture,
  allFactureTrue,
  oneFacture,
  oneFactureExport,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = factureSlice.actions;

export const getAllFacture =
  (
    page?: number,
    dateemmission?: Date | null | string,
    numero?: string,
    affaire_id?: string,
    procedure_id?: string,
    client_id?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.factureApi}${
          page ? "/?page=" + page : "/?all=true"
        }${dateemmission ? "&dateemmission=" + dateemmission : ""}${
          numero ? "&numero=" + numero : ""
        }${affaire_id ? "&affaire_id=" + affaire_id : ""}${
          procedure_id ? "&procedure_id=" + procedure_id : ""
        }${client_id ? "&client_id=" + client_id : ""}`
      );
      let factures: any;
      factures = page ? response.data : response.data.data;
      page
        ? dispatch(allFacture(factures))
        : dispatch(allFactureTrue(factures));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneFacture = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.factureApi + "/" + id);
    let facture = response.data;
    dispatch(oneFacture(facture));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addFacture =
  (facture: NewfactureInterface) => async (dispatch: any) => {
    try {
      const Newfacture = await axios.post(
        api.baseApi + api.factureApi,
        facture
      );
      return Newfacture;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editFacture =
  (facture: factureInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.factureApi + "/" + id, facture);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getNumeroFacture = () => async (dispatch: any) => {
  try {
    const numero = await axios.get(
      api.baseApi + api.factureApi + "/nextnumero"
    );
    return numero.data.nextnum;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const getFactureExport = (id: number) => async (dispatch: any) => {
  try {
    const facture = await axios.get(
      api.baseApi + api.factureApi + "/export/" + id
    );
    dispatch(oneFactureExport(facture.data));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export default factureSlice.reducer;
