import { useRef, useState } from "react";
import { Formik, FormikErrors, FormikProps } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { addAffaire } from "../../redux/slices/affaireSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenreAffaireModal from "../../components/Modals/GenreAffaireModal";
import SearchIcon from "@mui/icons-material/Search";
import ClientModal from "../../components/Modals/ClientModal";
import AdversaireModal from "../../components/Modals/AdversaireModal";
import SituationModal from "../../components/Modals/SituationModal";
import GenreAffaireCreateModal from "../../components/CreateModals/GenreAffaireCreateModal";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ClientCreateModal from "../../components/CreateModals/ClientCreateModal";
import SituationCreateModal from "../../components/CreateModals/SituationCreateModal";
import AdversaireCreateModal from "../../components/CreateModals/AdversaireCreateModal";
import NewAccidentCirculationAffaire from "../Accident-Circulation/NewAccidentCirculationAffaire";
import NewAccidentTravailAffaire from "../Accident-Travail/NewAccidentTravailAffaire";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  reference: string;
  referenceprovisoire: string;
  referencedefinitive: string;
  libelle: string;
  genreaffaire_id: string;
  clients: ClientAffaire[];
  adversaires: AdversaireAffaire[];
}

interface ClientAffaire {
  client_id: string;
  situation_id: string;
}

interface AdversaireAffaire {
  adversaire_id: string;
  situation_id: string;
}

export default function NewAffaire() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [listeClient, setListeClient] = useState<any>([]);
  const [listeAdversaire, setListeAdversaire] = useState<any>([]);
  const childFormRef = useRef<FormikProps<any>>(null);
  const [IsSubmited, setIsSubmited] = useState({});

  const [openGenreAffaire, setOpenGenreAffaire] = useState(false);
  const [displayGenreAffaire, setDisplayGenreAffaire] = useState("");

  const [openCreateGenreAffaire, setOpenCreateGenreAffaire] = useState(false);

  const [openClient, setOpenClient] = useState([false]);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [clientIndex, setClientIndex] = useState(0);
  const [displayClient, setDisplayClient] = useState([""]);

  const [openSituationClient, setOpenSituationClient] = useState([false]);
  const [openCreateSituationClient, setOpenCreateSituationClient] =
    useState(false);
  const [situationClientIndex, setSituationClientIndex] = useState(0);
  const [displaySituationClient, setDisplaySituationClient] = useState([""]);

  const [openAdversaire, setOpenAdversaire] = useState([false]);
  const [openCreateAdversaire, setOpenCreateAdversaire] = useState(false);
  const [adversaireIndex, setAdversaireIndex] = useState(0);
  const [displayAdversaire, setDisplayAdversaire] = useState([""]);

  const [openSituationAdversaire, setOpenSituationAdversaire] = useState([
    false,
  ]);
  const [openCreateSituationAdversaire, setOpenCreateSituationAdversaire] =
    useState(false);
  const [situationAdversaireIndex, setSituationAdversaireIndex] = useState(0);
  const [displaySituationAdversaire, setDisplaySituationAdversaire] = useState([
    "",
  ]);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const schema = yup.object({
    reference: yup.string().required("Reference est obligatoire"),
    referenceprovisoire: yup
      .string()
      .required("Reference Provisoire est obligatoire"),
    referencedefinitive: yup
      .string()
      .required("Reference Définitive est obligatoire"),
    genreaffaire_id: yup.string().required("Genre Affaire est obligatoire"),
    clients: yup
      .array()
      .of(
        yup.object().shape({
          client_id: yup.string().required("Client est obligatoire"),
          situation_id: yup.string().required("Qualité est obligatoire"),
        })
      )
      .min(1, "Client Affaire est obligatoire"),
    adversaires: yup
      .array()
      .of(
        yup.object().shape({
          adversaire_id: yup.string().required("Adversaire est obligatoire"),
          situation_id: yup.string().required("Qualité est obligatoire"),
        })
      )
      .min(1, "Adversaire Affaire est obligatoire"),
  });

  const initialValues: MyFormValues = {
    reference: "",
    referenceprovisoire: "",
    referencedefinitive: "",
    libelle: "",
    genreaffaire_id: "",
    clients: [
      {
        client_id: "",
        situation_id: "",
      },
    ],
    adversaires: [
      {
        adversaire_id: "",
        situation_id: "",
      },
    ],
  };

  const handleRemoveClientAffaire = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    let arrayListeClient = listeClient;
    arrayListeClient = arrayListeClient.filter(
      (_ele: any, index: number) => index !== indexNum
    );
    setListeClient(arrayListeClient);
    let arrayOpenClient = openClient.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayDisplayClient = displayClient.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayOpenSituationClient = openSituationClient.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayDisplaySituationClient = displaySituationClient.filter(
      (_ele, index) => index !== indexNum
    );

    const currentClientAffaire = values.clients;
    const updatedClientAffaire = currentClientAffaire.filter(
      (_ele, index) => index !== indexNum
    );

    setOpenClient(arrayOpenClient);
    setDisplayClient(arrayDisplayClient);
    setOpenSituationClient(arrayOpenSituationClient);
    setDisplaySituationClient(arrayDisplaySituationClient);
    setFieldValue("clients", updatedClientAffaire);
  };

  const handleRemoveAdversaireAffaire = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    let arrayListeAdversaire = listeAdversaire;
    arrayListeAdversaire = arrayListeAdversaire.filter(
      (_ele: any, index: number) => index !== indexNum
    );
    setListeAdversaire(arrayListeAdversaire);
    let arrayOpenAdversaire = openAdversaire.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayDisplayAdversaire = displayAdversaire.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayOpenSituationAdversaire = openSituationAdversaire.filter(
      (_ele, index) => index !== indexNum
    );
    let arrayDisplaySituationAdversaire = displaySituationAdversaire.filter(
      (_ele, index) => index !== indexNum
    );
    const currentAdversaireAffaire = values.adversaires;
    const updatedAdversaireAffaire = currentAdversaireAffaire.filter(
      (_ele, index) => index !== indexNum
    );
    setOpenAdversaire(arrayOpenAdversaire);
    setDisplayAdversaire(arrayDisplayAdversaire);
    setOpenSituationAdversaire(arrayOpenSituationAdversaire);
    setDisplaySituationAdversaire(arrayDisplaySituationAdversaire);
    setFieldValue("adversaires", updatedAdversaireAffaire);
  };

  const handleAddClientAffaire = (
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    const newClientAffaire = {
      client_id: "",
      situation_id: "",
    };
    let arrayOpenClient = [...openClient, false];
    let arrayDisplayClient = [...displayClient, ""];
    let arrayOpenSituationClient = [...openSituationClient, false];
    let arrayDisplaySituationClient = [...displaySituationClient, ""];

    setOpenClient(arrayOpenClient);
    setDisplayClient(arrayDisplayClient);
    setOpenSituationClient(arrayOpenSituationClient);
    setDisplaySituationClient(arrayDisplaySituationClient);
    const currentClientAffaire = values.clients;
    const updatedClientAffaire = [...currentClientAffaire, newClientAffaire];
    setFieldValue("clients", updatedClientAffaire);
  };
  const handleAddAdversaireAffaire = (
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    const newAdversaireAffaire = {
      adversaire_id: "",
      situation_id: "",
    };
    let arrayOpenAdversaire = [...openAdversaire, false];
    let arrayDisplayAdversaire = [...displayAdversaire, ""];
    let arrayOpenSituationAdversaire = [...openSituationAdversaire, false];
    let arrayDisplaySituationAdversaire = [...displaySituationAdversaire, ""];

    setOpenAdversaire(arrayOpenAdversaire);
    setDisplayAdversaire(arrayDisplayAdversaire);
    setOpenSituationAdversaire(arrayOpenSituationAdversaire);
    setDisplaySituationAdversaire(arrayDisplaySituationAdversaire);

    const currentAdversaireAffaire = values.adversaires;
    const updatedAdversaireAffaire = [
      ...currentAdversaireAffaire,
      newAdversaireAffaire,
    ];
    setFieldValue("adversaires", updatedAdversaireAffaire);
  };
  const Submited = () => {
    childFormRef.current?.submitForm();
  };
  return (
    <>
      <div className={styles.ReturnList}>
        <Link to="/affaires" relative="path">
          <ArrowBackIosIcon /> Liste Affaires
        </Link>
        <h2>Nouvelle Affaire</h2>
        <div />
      </div>
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (
            displayGenreAffaire === "Accidents de Travail" ||
            displayGenreAffaire === "Accidents de Circulation"
          ) {
            setIsSubmited(values);
            Submited();
          } else {
            setLoadingSend(true);
            const affaire = await dispatch(addAffaire(values));
            if (affaire?.status) {
              MySwal.fire({
                icon: "success",
                title: "Affaire est ajouté avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then((_result) => {
                setLoadingSend(false);
                navigate("/affaires/" + affaire.data.id_affaire);
              });
            }
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Reference"
                  name="reference"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.reference}
                  helperText={errors.reference ? errors.reference : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Reference Provisoire"
                  name="referenceprovisoire"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.referenceprovisoire}
                  helperText={
                    errors.referenceprovisoire ? errors.referenceprovisoire : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Reference Definitive"
                  name="referencedefinitive"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.referencedefinitive}
                  helperText={
                    errors.referencedefinitive ? errors.referencedefinitive : ""
                  }
                />
              </Grid>
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayGenreAffaire
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Genre Affaire
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayGenreAffaire}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenGenreAffaire(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Genre Affaire"
                    />
                  </FormControl>
                  {openGenreAffaire && (
                    <GenreAffaireModal
                      open={openGenreAffaire}
                      handleClose={() => setOpenGenreAffaire(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("genreaffaire_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayGenreAffaire(value)
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateGenreAffaire(true)}
                  >
                    <AddIcon />
                  </Button>
                  {openCreateGenreAffaire && (
                    <GenreAffaireCreateModal
                      open={openCreateGenreAffaire}
                      handleClose={() => setOpenCreateGenreAffaire(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("genreaffaire_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayGenreAffaire(value)
                      }
                    />
                  )}
                </Grid>
                {errors.genreaffaire_id && (
                  <span className={styles.errorHandle}>
                    {errors.genreaffaire_id}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} className={styles.ArrayInput}>
                {values.clients.map((_element, index) => {
                  return (
                    <Grid key={index} container spacing={2} className="mb-1">
                      <Grid item xs={2}>
                        <span className={styles.LabelArray}>
                          {index === 0 ? "Clients :" : ""}
                        </span>
                      </Grid>
                      <Grid container item xs={4}>
                        <Grid item xs={10}>
                          <FormControl
                            className={
                              !displayClient[index]
                                ? styles.backgroudGrey
                                : styles.backgroudGrey2
                            }
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Client
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              disabled
                              fullWidth
                              value={displayClient[index]}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setOpenClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = true;
                                        return newState;
                                      })
                                    }
                                    edge="end"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Client"
                            />
                          </FormControl>
                          {openClient[index] && (
                            <ClientModal
                              open={openClient[index]}
                              handleClose={() =>
                                setOpenClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                })
                              }
                              handleChangeValue={(value) => {
                                const currentClientAffaire = values.clients;
                                const currentClient =
                                  currentClientAffaire[index];
                                currentClient.client_id = value;
                                const updatedClientAffaire = [
                                  ...currentClientAffaire,
                                ];
                                setFieldValue("clients", updatedClientAffaire);
                              }}
                              handleChangeDisplay={(value) =>
                                setDisplayClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = value;
                                  return newState;
                                })
                              }
                              handleChangeClient={(client) => {
                                let currentClient = listeClient;
                                currentClient[index] = client;
                                setListeClient(currentClient);
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            className={styles.AddButtonModal}
                            onClick={() => {
                              setClientIndex(index);
                              setOpenCreateClient(true);
                            }}
                          >
                            +
                          </Button>
                          {openCreateClient && (
                            <ClientCreateModal
                              open={openCreateClient}
                              handleClose={() => setOpenCreateClient(false)}
                              handleChangeValue={(value) => {
                                const currentClientAffaire = values.clients;
                                const currentClient =
                                  currentClientAffaire[clientIndex];

                                currentClient.client_id = value;
                                const updatedClientAffaire = [
                                  ...currentClientAffaire,
                                ];
                                setFieldValue("clients", updatedClientAffaire);
                              }}
                              handleChangeDisplay={(value) =>
                                setDisplayClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[clientIndex] = value;
                                  return newState;
                                })
                              }
                              handleChangeClient={(client) => {
                                let currentClient = listeClient;
                                currentClient[index] = client;
                                setListeClient(currentClient);
                              }}
                            />
                          )}
                        </Grid>
                        {errors.clients && errors.clients[index] && (
                          <span className={styles.errorHandle}>
                            {(errors.clients[index] as any)?.client_id}
                          </span>
                        )}
                      </Grid>
                      <Grid container item xs={4}>
                        <Grid item xs={10}>
                          <FormControl
                            className={
                              !displaySituationClient[index]
                                ? styles.backgroudGrey
                                : styles.backgroudGrey2
                            }
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Qualité
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              disabled
                              fullWidth
                              value={displaySituationClient[index]}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setOpenSituationClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = true;
                                        return newState;
                                      })
                                    }
                                    edge="end"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Qualité"
                            />
                          </FormControl>
                          {openSituationClient[index] && (
                            <SituationModal
                              open={openSituationClient[index]}
                              handleClose={() =>
                                setOpenSituationClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                })
                              }
                              handleChangeValue={(value) => {
                                const currentClientAffaire = values.clients;
                                const currentClient =
                                  currentClientAffaire[index];
                                currentClient.situation_id = value;
                                const updatedClientAffaire = [
                                  ...currentClientAffaire,
                                ];
                                setFieldValue("clients", updatedClientAffaire);
                              }}
                              handleChangeDisplay={(value) =>
                                setDisplaySituationClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = value;
                                  return newState;
                                })
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            className={styles.AddButtonModal}
                            onClick={() => {
                              setSituationClientIndex(index);
                              setOpenCreateSituationClient(true);
                            }}
                          >
                            +
                          </Button>
                          {openCreateSituationClient && (
                            <SituationCreateModal
                              open={openCreateSituationClient}
                              handleClose={() =>
                                setOpenCreateSituationClient(false)
                              }
                              handleChangeValue={(value) => {
                                const currentClientAffaire = values.clients;
                                const currentClient =
                                  currentClientAffaire[situationClientIndex];
                                currentClient.situation_id = value;
                                const updatedClientAffaire = [
                                  ...currentClientAffaire,
                                ];
                                setFieldValue("clients", updatedClientAffaire);
                              }}
                              handleChangeDisplay={(value) =>
                                setDisplaySituationClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[situationClientIndex] = value;
                                  return newState;
                                })
                              }
                            />
                          )}
                        </Grid>

                        {errors.clients && errors.clients[index] && (
                          <span className={styles.errorHandle}>
                            {(errors.clients[index] as any)?.situation_id}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <div className={styles.GroupeButton}>
                          {index === 0 ? (
                            <span className={styles.ButtonArray}>
                              <Button
                                onClick={() =>
                                  handleAddClientAffaire(values, setFieldValue)
                                }
                              >
                                <AddIcon />
                              </Button>
                            </span>
                          ) : (
                            ""
                          )}
                          {index !== 0 ? (
                            <span className={styles.ButtonDelete}>
                              <Button
                                onClick={() =>
                                  handleRemoveClientAffaire(
                                    index,
                                    values,
                                    setFieldValue
                                  )
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} className={styles.ArrayInput}>
                {values.adversaires.map((_element, index) => {
                  return (
                    <Grid key={index} container spacing={2} className="mb-1">
                      <Grid item xs={2}>
                        <span className={styles.LabelArray}>
                          {index === 0 ? "Adversaires :" : ""}
                        </span>
                      </Grid>
                      <Grid container item xs={4}>
                        <Grid item xs={10}>
                          <FormControl
                            className={
                              !displayAdversaire[index]
                                ? styles.backgroudGrey
                                : styles.backgroudGrey2
                            }
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Adversaire
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              disabled
                              fullWidth
                              value={displayAdversaire[index]}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setOpenAdversaire((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = true;
                                        return newState;
                                      })
                                    }
                                    edge="end"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Adversaire"
                            />
                          </FormControl>
                          {openAdversaire[index] && (
                            <AdversaireModal
                              open={openAdversaire[index]}
                              handleClose={() =>
                                setOpenAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                })
                              }
                              handleChangeValue={(value) => {
                                const currentAdversaireAffaire =
                                  values.adversaires;
                                const currentAdversaire =
                                  currentAdversaireAffaire[index];
                                currentAdversaire.adversaire_id = value;
                                const updatedAdversaireAffaire = [
                                  ...currentAdversaireAffaire,
                                ];
                                setFieldValue(
                                  "adversaires",
                                  updatedAdversaireAffaire
                                );
                              }}
                              handleChangeDisplay={(value) =>
                                setDisplayAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = value;
                                  return newState;
                                })
                              }
                              handleChangeAdversaire={(adversaire) => {
                                let currentAdversaire = listeAdversaire;
                                currentAdversaire[index] = adversaire;
                                setListeAdversaire(currentAdversaire);
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            className={styles.AddButtonModal}
                            onClick={() => {
                              setAdversaireIndex(index);
                              setOpenCreateAdversaire(true);
                            }}
                          >
                            +
                          </Button>
                          {openCreateAdversaire && (
                            <AdversaireCreateModal
                              open={openCreateAdversaire}
                              handleClose={() => setOpenCreateAdversaire(false)}
                              handleChangeValue={(value) => {
                                const currentAdversaireAffaire =
                                  values.adversaires;
                                const currentAdversaire =
                                  currentAdversaireAffaire[adversaireIndex];
                                currentAdversaire.adversaire_id = value;
                                const updatedAdversaireAffaire = [
                                  ...currentAdversaireAffaire,
                                ];
                                setFieldValue(
                                  "adversaires",
                                  updatedAdversaireAffaire
                                );
                              }}
                              handleChangeDisplay={(value) => {
                                setDisplayAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[adversaireIndex] = value;
                                  return newState;
                                });
                              }}
                              handleChangeAdversaire={(adversaire) => {
                                let currentAdversaire = listeAdversaire;
                                currentAdversaire[index] = adversaire;
                                setListeAdversaire(currentAdversaire);
                              }}
                            />
                          )}
                        </Grid>
                        {errors.adversaires && errors.adversaires[index] && (
                          <span className={styles.errorHandle}>
                            {(errors.adversaires[index] as any)?.adversaire_id}
                          </span>
                        )}
                      </Grid>
                      <Grid container item xs={4}>
                        <Grid item xs={10}>
                          <FormControl
                            className={
                              !displaySituationAdversaire[index]
                                ? styles.backgroudGrey
                                : styles.backgroudGrey2
                            }
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Qualité
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              disabled
                              fullWidth
                              value={displaySituationAdversaire[index]}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setOpenSituationAdversaire(
                                        (prevState) => {
                                          const newState = [...prevState];
                                          newState[index] = true;
                                          return newState;
                                        }
                                      )
                                    }
                                    edge="end"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Qualité"
                            />
                          </FormControl>
                          {openSituationAdversaire[index] && (
                            <SituationModal
                              open={openSituationAdversaire[index]}
                              handleClose={() =>
                                setOpenSituationAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                })
                              }
                              handleChangeValue={(value) => {
                                const currentAdversaireAffaire =
                                  values.adversaires;
                                const currentAdversaire =
                                  currentAdversaireAffaire[index];
                                currentAdversaire.situation_id = value;
                                const updatedAdversaireAffaire = [
                                  ...currentAdversaireAffaire,
                                ];
                                setFieldValue(
                                  "adversaires",
                                  updatedAdversaireAffaire
                                );
                              }}
                              handleChangeDisplay={(value) =>
                                setDisplaySituationAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = value;
                                  return newState;
                                })
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            className={styles.AddButtonModal}
                            onClick={() => {
                              setSituationAdversaireIndex(index);
                              setOpenCreateSituationAdversaire(true);
                            }}
                          >
                            +
                          </Button>
                          {openCreateSituationAdversaire && (
                            <SituationCreateModal
                              open={openCreateSituationAdversaire}
                              handleClose={() =>
                                setOpenCreateSituationAdversaire(false)
                              }
                              handleChangeValue={(value) => {
                                const currentAdversaireAffaire =
                                  values.adversaires;
                                const currentAdversaire =
                                  currentAdversaireAffaire[
                                    situationAdversaireIndex
                                  ];
                                currentAdversaire.situation_id = value;
                                const updatedAdversaireAffaire = [
                                  ...currentAdversaireAffaire,
                                ];
                                setFieldValue(
                                  "adversaires",
                                  updatedAdversaireAffaire
                                );
                              }}
                              handleChangeDisplay={(value) => {
                                setDisplaySituationAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[situationAdversaireIndex] = value;
                                  return newState;
                                });
                              }}
                            />
                          )}
                        </Grid>

                        {errors.adversaires && errors.adversaires[index] && (
                          <span className={styles.errorHandle}>
                            {(errors.adversaires[index] as any)?.situation_id}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <div className={styles.GroupeButton}>
                          {index === 0 ? (
                            <span className={styles.ButtonArray}>
                              <Button
                                onClick={() =>
                                  handleAddAdversaireAffaire(
                                    values,
                                    setFieldValue
                                  )
                                }
                              >
                                <AddIcon />
                              </Button>
                            </span>
                          ) : (
                            ""
                          )}
                          {index !== 0 ? (
                            <span className={styles.ButtonDelete}>
                              <Button
                                onClick={() =>
                                  handleRemoveAdversaireAffaire(
                                    index,
                                    values,
                                    setFieldValue
                                  )
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            {displayGenreAffaire === "Accidents de Circulation" && (
              <NewAccidentCirculationAffaire
                IsSubmited={IsSubmited}
                formikRef={childFormRef}
                listeClient={listeClient}
                listeAdversaire={listeAdversaire}
                setLoadingSend={setLoadingSend}
              />
            )}
            {displayGenreAffaire === "Accidents de Travail" && (
              <NewAccidentTravailAffaire
                IsSubmited={IsSubmited}
                setLoadingSend={setLoadingSend}
                formikRef={childFormRef}
              />
            )}
            <Grid item xs={12} className={styles.ButtonGrid}>
              <Button
                startIcon={loadingSend && <CircularProgress size={20} />}
                disabled={loadingSend}
                className="mt-1"
                variant="contained"
                type="submit"
              >
                Ajouter
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
