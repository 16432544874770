import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  editVehicule,
  vehiculeInterface,
  getOneVehicule,
} from "../../redux/slices/vehiculeSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Spinner from "../../components/Spinner";
import SearchIcon from "@mui/icons-material/Search";
import TypeVehiculeModal from "../../components/Modals/TypeVehiculeModal";
import TypeVehiculeCreateModal from "../../components/CreateModals/TypeVehiculeCreateModal";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

export default function EditVehicule() {
  const [loadingSend, setLoadingSend] = useState(false);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [openTypeVehicule, setOpenTypeVehicule] = useState(false);
  const [openCreateTypeVehicule, setOpenCreateTypeVehicule] = useState(false);
  const [displayTypeVehicule, setDisplayTypeVehicule] = useState("");

  const vehicule: vehiculeInterface = useSelector(
    (state: any) => state.vehicule.vehicule
  );
  const loading = useSelector((state: any) => state.vehicule.loadingOne);

  const schema = yup.object({
    numpoliceassurance: yup
      .string()
      .required("Num Police Assurance est obligatoire"),
    appartenance: yup.string().required("Appartenance est obligatoire"),
    matricul0: yup.string().required("Matricul 0 est obligatoire"),
    matricul1: yup.string().required("Matricul 1 est obligatoire"),
    matricul2: yup.string().required("Matricul 2 est obligatoire"),
    matricul3: yup.string().required("Matricul 3 est obligatoire"),
    matricul4: yup.string().required("Matricul 4 est obligatoire"),
    observation: yup.string().required("Observation est obligatoire"),
    typevehicule_id: yup.string().required("Type vehicule est obligatoire"),
  });

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneVehicule(id));
    } else {
      navigate("/vehicules");
    }
  }, []);

  useEffect(() => {
    setDisplayTypeVehicule(vehicule?.TypeVehicule?.libelle);
  }, [vehicule]);

  return (
    <>
      <div className={styles.ReturnList}>
        <Link to="/vehicules" relative="path">
          <ArrowBackIosIcon /> Liste des Vehicules
        </Link>
        <h2>Editer Vehicule</h2>
        <div />
      </div>
      {!loading && Object.keys(vehicule).length > 0 ? (
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          validateOnBlur={false}
          initialValues={vehicule}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            setLoadingSend(true);
            const bool = await dispatch(
              editVehicule(object, values.id_vehicule)
            );
            if (bool) {
              MySwal.fire({
                icon: "success",
                title: "Vehicule est modifié avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then(() => {
                setLoadingSend(false);
                navigate("/vehicules");
              });
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* <Grid item xs={4}>
                  <TextField
                    label="Code"
                    name="code"
                    onChange={handleChange}
                    value={values.code}
                    fullWidth
                    error={!!errors.code}
                    helperText={errors.code ? errors.code : ""}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl className={styles.backgroudWhite} fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Appartenance
                    </InputLabel>
                    <Select
                      name="typeentite"
                      labelId="demo-simple-select-label"
                      value={values.appartenance}
                      label="Appartenance"
                      onChange={handleChange}
                    >
                      <MenuItem value="">-</MenuItem>
                      <MenuItem value={"A"}>A</MenuItem>
                      <MenuItem value={"C"}>C</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={4}>
                  <TextField
                    label="Num Police Assurance"
                    name="numpoliceassurance"
                    onChange={handleChange}
                    value={values.numpoliceassurance}
                    fullWidth
                    error={!!errors.numpoliceassurance}
                    helperText={
                      errors.numpoliceassurance ? errors.numpoliceassurance : ""
                    }
                  />
                </Grid>
                <Grid container item spacing={1} xs={8}>
                  <Grid item xs={4}>
                    <TextField
                      label="Matricul 0"
                      name="matricul0"
                      onChange={handleChange}
                      value={values.matricul0}
                      fullWidth
                      error={!!errors.matricul0}
                      helperText={errors.matricul0 ? errors.matricul0 : ""}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      label="Matricul 1"
                      name="matricul1"
                      onChange={handleChange}
                      value={values.matricul1}
                      fullWidth
                      error={!!errors.matricul1}
                      helperText={errors.matricul1 ? errors.matricul1 : ""}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Matricul 2"
                      name="matricul2"
                      onChange={handleChange}
                      value={values.matricul2}
                      fullWidth
                      error={!!errors.matricul2}
                      helperText={errors.matricul2 ? errors.matricul2 : ""}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      label="Matricul 3"
                      name="matricul3"
                      onChange={handleChange}
                      value={values.matricul3}
                      fullWidth
                      error={!!errors.matricul3}
                      helperText={errors.matricul3 ? errors.matricul3 : ""}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Matricul 4"
                      name="matricul4"
                      onChange={handleChange}
                      value={values.matricul4}
                      fullWidth
                      error={!!errors.matricul4}
                      helperText={errors.matricul4 ? errors.matricul4 : ""}
                    />
                  </Grid>
                </Grid>
                {/* <Grid item xs={4}>
                  <TextField
                    label="Observation"
                    name="observation"
                    onChange={handleChange}
                    value={values.observation}
                    fullWidth
                    error={!!errors.observation}
                    helperText={errors.observation ? errors.observation : ""}
                  />
                </Grid> */}
                <Grid container item xs={4}>
                  <Grid item xs={10}>
                    <FormControl
                      className={
                        !displayTypeVehicule
                          ? styles.backgroudGrey
                          : styles.backgroudGrey2
                      }
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Type Vehicule
                      </InputLabel>
                      <OutlinedInput
                        type="text"
                        disabled
                        fullWidth
                        value={displayTypeVehicule}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setOpenTypeVehicule(true)}
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Type Vehicule"
                      />
                    </FormControl>
                    {openTypeVehicule && (
                      <TypeVehiculeModal
                        open={openTypeVehicule}
                        handleClose={() => setOpenTypeVehicule(false)}
                        handleChangeValue={(value) =>
                          setFieldValue("typevehicule_id", value)
                        }
                        handleChangeDisplay={setDisplayTypeVehicule}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      className={styles.AddButtonModal}
                      onClick={() => setOpenCreateTypeVehicule(true)}
                    >
                      +
                    </Button>
                    {openCreateTypeVehicule && (
                      <TypeVehiculeCreateModal
                        open={openCreateTypeVehicule}
                        handleClose={() => setOpenCreateTypeVehicule(false)}
                        handleChangeValue={(value) =>
                          setFieldValue("typevehicule_id", value)
                        }
                        handleChangeDisplay={(value) =>
                          setDisplayTypeVehicule(value)
                        }
                      />
                    )}
                  </Grid>
                  {errors.typevehicule_id && (
                    <span className={styles.errorHandle}>
                      {errors.typevehicule_id}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} className={styles.ButtonGrid}>
                  <Button
                    startIcon={loadingSend && <CircularProgress size={20} />}
                    disabled={loadingSend}
                    variant="contained"
                    type="submit"
                  >
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner />
      )}
    </>
  );
}
