import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface typeTribunalInterface {
  id_typetribunal: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtypeTribunalInterface {
  code: string;
  libelle: string;
}

const initialState = {
  typeTribunals: [],
  allTypeTribunals: [],
  typeTribunal: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const typeTribunalSlice = createSlice({
  name: "typeTribunal",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTypeTribunal(state, action) {
      state.typeTribunals = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTypeTribunalTrue(state, action) {
      state.allTypeTribunals = action.payload;
      state.error = "";
    },
    oneTypeTribunal(state, action) {
      state.typeTribunal = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTypeTribunal,
  allTypeTribunalTrue,
  oneTypeTribunal,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = typeTribunalSlice.actions;

export const getAllTypeTribunal =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.typeTribunalApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let typeTribunals: any;
      typeTribunals = page ? response.data : response.data.data;
      page
        ? dispatch(allTypeTribunal(typeTribunals))
        : dispatch(allTypeTribunalTrue(typeTribunals));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTypeTribunal = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.typeTribunalApi + "/" + id
    );
    let typeTribunal = response.data;
    dispatch(oneTypeTribunal(typeTribunal));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addTypeTribunal =
  (typeTribunal: NewtypeTribunalInterface) => async (dispatch: any) => {
    try {
      const newTypeTribunal = await axios.post(
        api.baseApi + api.typeTribunalApi,
        typeTribunal
      );
      return newTypeTribunal;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editTypeTribunal =
  (typeTribunal: typeTribunalInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.typeTribunalApi + "/" + id,
        typeTribunal
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default typeTribunalSlice.reducer;
