// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner_LoadingOver__8FTuW {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.3);\n  z-index: 1056;\n}\n\n.Spinner_SpinnerPage__Rexgh {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n}\n\n.Spinner_SpinnerBlock__pvDCY {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 400px;\n}", "",{"version":3,"sources":["webpack://./src/styles/Spinner.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAEJ;;AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAGJ","sourcesContent":[".LoadingOver{\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgb(0, 0, 0, 0.30);\n    z-index: 1056;\n}\n.SpinnerPage{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n.SpinnerBlock{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingOver": "Spinner_LoadingOver__8FTuW",
	"SpinnerPage": "Spinner_SpinnerPage__Rexgh",
	"SpinnerBlock": "Spinner_SpinnerBlock__pvDCY"
};
export default ___CSS_LOADER_EXPORT___;
