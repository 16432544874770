import { useState } from "react";
import styles from "../styles/BoxFilter.module.scss";
import {
  TextField,
  OutlinedInput,
  FormControl,
  FormLabel,
  FormGroup,
  InputLabel,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

import SearchIcon from "@mui/icons-material/Search";
import GenreAffaireModal from "./Modals/GenreAffaireModal";
import AffaireModal from "./Modals/AffaireModal";
import ClientModal from "./Modals/ClientModal";
import AdversaireModal from "./Modals/AdversaireModal";
import TypeProcedureModal from "./Modals/TypeProcedureModal";
import SousTypeProcedureModal from "./Modals/SousTypeProcedureModal";
import TypeJuridictionModal from "./Modals/TypeJuridictionModal";
import TypePoursuiteModal from "./Modals/TypePoursuiteModal";
import TypeTribunalModal from "./Modals/TypeTribunalModal";
import TypeJugementModal from "./Modals/TypeJugementModal";
import ProcedureModal from "./Modals/ProcedureModal";
import ResultatAudienceModal from "./Modals/ResultatAudienceModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import AudienceModal from "./Modals/AudienceModal";
import TypeAccidentTravailModal from "./Modals/TypeAccidentTravailModal";
import AccidentTravailModal from "./Modals/AccidentTravailModal";
import TypeGarantieModal from "./Modals/TypeGarantieModal";
import AccidentCirculationModal from "./Modals/AccidentCirculationModal";
import VilleModal from "./Modals/VilleModal";
import SituationModal from "./Modals/SituationModal";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import LibelleProcedureModal from "./Modals/LibelleProcedureModal";
import ClearIcon from "@mui/icons-material/Clear";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "moment/locale/fr";

interface BoxFilterInteface {
  boxDisplayed: string[];
  loading?: boolean;
  libelle?: string;
  texte?: string;
  observation?: string;
  numpv?: string;
  numseq?: string;
  numero?: string;
  numparquet?: string;
  pourcentageassurance?: string;
  numpoliceassurance?: string;
  reference?: string;
  referenceprovisoire?: string;
  referencedefinitive?: string;
  genreaffaire_id?: string;
  affaire_id?: string;
  client_id?: string;
  adversaire_id?: string;
  typeprocedure_id?: string;
  libelleprocedure_id?: string;
  soustypeprocedure_id?: string;
  typepoursuite_id?: string;
  typejuridiction_id?: string;
  typejugement_id?: string;
  typegarantie_id?: string;
  typetribunal_id?: string;
  resultataudience_id?: string;
  typeaccidenttravail_id?: string;
  accidentcirculation_id?: string;
  accidenttravail_id?: string;
  audience_id?: string;
  procedure_id?: string;
  ville_id?: string;
  situation_id?: string;
  auprofitduclient?: string;
  code?: string;
  typeentite?: string;
  nom?: string;
  prenom?: string;
  cin?: string;
  raisonsociale?: string;
  abreviation?: string;
  matricule?: string;
  dateaudience?: Date | null | string;
  dateaudiencemax?: Date | null | string;
  dateaudiencemin?: Date | null | string;
  datejugement?: Date | null | string;
  datereception?: Date | null | string;
  dateaccident?: Date | null | string;
  dateparquet?: Date | null | string;
  dateemmission?: Date | null | string;
  setLibelle?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setTexte?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setObservation?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setNumparquet?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setNumseq?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setNumpv?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setNumero?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setNumpoliceassurance?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setMatricule?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPourcentageassurance?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  setReference?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setReferenceprovisoire?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setReferencedefinitive?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setGenreaffaireid?: (value: string) => void;
  setAuprofitclient?: (value: string) => void;
  setAffaireid?: (value: string) => void;
  setClientid?: (value: string) => void;
  setAdversaireid?: (value: string) => void;
  setTypeprocedureid?: (value: string) => void;
  setLibelleprocedureid?: (value: string) => void;
  setProcedureid?: (value: string) => void;
  setAudienceid?: (value: string) => void;
  setResultataudienceid?: (value: string) => void;
  setDateaudience?: (date: Date | null | string) => void;
  setDateaudiencemax?: (date: Date | null | string) => void;
  setDateaudiencemin?: (date: Date | null | string) => void;
  setDatejugement?: (date: Date | null | string) => void;
  setDatereception?: (date: Date | null | string) => void;
  setDateaccident?: (date: Date | null | string) => void;
  setDateparquet?: (date: Date | null | string) => void;
  setDateemmission?: (date: Date | null | string) => void;
  setSoustypeprocedureid?: (value: string) => void;
  setTypepoursuiteid?: (value: string) => void;
  setTypejuridictionid?: (value: string) => void;
  setTypejugementid?: (value: string) => void;
  setTypegarantieid?: (value: string) => void;
  setTypetribunalid?: (value: string) => void;
  setTypeaccidenttravailid?: (value: string) => void;
  setAccidenttravailid?: (value: string) => void;
  setAccidentcirculationid?: (value: string) => void;
  setVilleid?: (value: string) => void;
  setSituationid?: (value: string) => void;
  ResetFilters?: () => void;
  setCode?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setTypeEntite?: (event: SelectChangeEvent) => void;
  setNom?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPrenom?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setCin?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setRaisonsociale?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setAbreviation?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleExport?: () => void;
}

export default function BoxFilter(props: BoxFilterInteface) {
  const [hideFilters, setHideFilters] = useState(true);

  const [openGenreAffaire, setOpenGenreAffaire] = useState(false);
  const [displaygenAff, setDisplaygenAff] = useState("");

  const [openAffaire, setOpenAffaire] = useState(false);
  const [displayAffaire, setDisplayAffaire] = useState("");

  const [openClient, setOpenClient] = useState(false);
  const [displayClient, setDisplayClient] = useState("");

  const [openAdversaire, setOpenAdversaire] = useState(false);
  const [displayAdversaire, setDisplayAdversaire] = useState("");

  const [openTypeProcedure, setOpenTypeProcedure] = useState(false);
  const [displayTypeProcedure, setDisplayTypeProcedure] = useState("");

  const [openLibelleProcedure, setOpenLibelleProcedure] = useState(false);
  const [displayLibelleProcedure, setDisplayLibelleProcedure] = useState("");

  const [openSousTypeProcedure, setOpenSousTypeProcedure] = useState(false);
  const [displaySousTypeProcedure, setDisplaySousTypeProcedure] = useState("");

  const [openTypePoursuite, setOpenTypePoursuite] = useState(false);
  const [displayTypePoursuite, setDisplayTypePoursuite] = useState("");

  const [openTypeTribunal, setOpenTypeTribunal] = useState(false);
  const [displayTypeTribunal, setDisplayTypeTribunal] = useState("");

  const [openTypeJuridiction, setOpenTypeJuridiction] = useState(false);
  const [displayTypeJuridiction, setDisplayTypeJuridiction] = useState("");

  const [openTypeJugement, setOpenTypeJugement] = useState(false);
  const [displayTypeJugement, setDisplayTypeJugement] = useState("");

  const [openTypeGarantie, setOpenTypeGarantie] = useState(false);
  const [displayTypeGarantie, setDisplayTypeGarantie] = useState("");

  const [openAudience, setOpenAudience] = useState(false);
  const [displayAudience, setDisplayAudience] = useState("");

  const [openResultatAudience, setOpenResultatAudience] = useState(false);
  const [displayResultatAudience, setDisplayResultatAudience] = useState("");

  const [openProcedure, setOpenProcedure] = useState(false);
  const [displayProcedure, setDisplayProcedure] = useState("");

  const [openville, setOpenville] = useState(false);
  const [displayville, setDisplayville] = useState("");

  const [openSituation, setOpenSituation] = useState(false);
  const [displaySituation, setDisplaySituation] = useState("");

  const [openTypeAccidentTravail, setOpenTypeAccidentTravail] = useState(false);
  const [displayTypeAccidentTravail, setDisplayTypeAccidentTravail] =
    useState("");

  const [openAccidentTravail, setOpenAccidentTravail] = useState(false);
  const [displayAccidentTravail, setDisplayAccidentTravail] = useState("");

  const [openAccidentCirculation, setOpenAccidentCirculation] = useState(false);
  const [displayAccidentCirculation, setDisplayAccidentCirculation] =
    useState("");

  const resetDisplay = () => {
    setDisplayAccidentCirculation("");
    setDisplaygenAff("");
    setDisplayAffaire("");
    setDisplayClient("");
    setDisplayAccidentTravail("");
    setDisplayTypeAccidentTravail("");
    setDisplaySituation("");
    setDisplayville("");
    setDisplayProcedure("");
    setDisplayResultatAudience("");
    setDisplayAudience("");
    setDisplayTypeGarantie("");
    setDisplayTypeJugement("");
    setDisplayTypeJuridiction("");
    setDisplayTypeTribunal("");
    setDisplayTypePoursuite("");
    setDisplaySousTypeProcedure("");
    setDisplayLibelleProcedure("");
    setDisplayTypeProcedure("");
    setDisplayAdversaire("");
    props.ResetFilters && props.ResetFilters();
  };
  return (
    <div className={styles.BoxFilter}>
      {props.loading && <div className={styles.StopFilter}></div>}
      {props.boxDisplayed.length > 3 && (
        <>
          <div></div>
          <div></div>
          <div className={styles.BoxReset}>
            <Button>
              <RestartAltIcon onClick={() => resetDisplay()} />
            </Button>
            <Button onClick={() => setHideFilters(!hideFilters)}>
              <FilterListIcon />
            </Button>
            {props.boxDisplayed.includes("exportdata") && (
              <Button onClick={props.handleExport}>
                <FileDownloadIcon />
              </Button>
            )}
          </div>
        </>
      )}
      {(hideFilters || props.boxDisplayed.length < 4) && (
        <>
          {props.boxDisplayed.includes("libelle") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Libelle"
                  fullWidth
                  size="small"
                  value={props.libelle}
                  onChange={props.setLibelle}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("observation") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Observtion"
                  fullWidth
                  size="small"
                  value={props.observation}
                  onChange={props.setObservation}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("numpv") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Num Pv"
                  fullWidth
                  size="small"
                  value={props.numpv}
                  onChange={props.setNumpv}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("numseq") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Num Seq"
                  fullWidth
                  size="small"
                  value={props.numseq}
                  onChange={props.setNumseq}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("numero") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Numero"
                  fullWidth
                  size="small"
                  value={props.numero}
                  onChange={props.setNumero}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("numparquet") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Num Parquet"
                  fullWidth
                  size="small"
                  value={props.numparquet}
                  onChange={props.setNumparquet}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("pourcentageassurance") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par pourcentage assurance"
                  fullWidth
                  size="small"
                  value={props.pourcentageassurance}
                  onChange={props.setPourcentageassurance}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("numpoliceassurance") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Num police assurance"
                  fullWidth
                  size="small"
                  value={props.numpoliceassurance}
                  onChange={props.setNumpoliceassurance}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("codes") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Code"
                  fullWidth
                  size="small"
                  value={props.code}
                  onChange={props.setCode}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("reference") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par reference"
                  fullWidth
                  size="small"
                  value={props.reference}
                  onChange={props.setReference}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("texte") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Texte"
                  fullWidth
                  size="small"
                  value={props.texte}
                  onChange={props.setTexte}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("referenceprovisoire") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par reference provisoire"
                  fullWidth
                  size="small"
                  value={props.referenceprovisoire}
                  onChange={props.setReferenceprovisoire}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("dateaudience") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      if (moment(value).isValid()) {
                        const date = moment(value).format("YYYY-MM-DD");
                        props.setDateaudience && props.setDateaudience(date);
                      } else {
                        props.setDateaudience && props.setDateaudience(null);
                      }
                    }}
                    value={props.dateaudience && moment(props.dateaudience)}
                    label="Date Audience"
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("dateaudiencemin") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      if (moment(value).isValid()) {
                        const date = moment(value).format("YYYY-MM-DD");
                        props.setDateaudiencemin &&
                          props.setDateaudiencemin(date);
                      } else {
                        props.setDateaudiencemin &&
                          props.setDateaudiencemin(null);
                      }
                    }}
                    value={
                      props.dateaudiencemin && moment(props.dateaudiencemin)
                    }
                    label="Date Audience Min"
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("dateaudiencemax") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      if (moment(value).isValid()) {
                        const date = moment(value).format("YYYY-MM-DD");
                        props.setDateaudiencemax &&
                          props.setDateaudiencemax(date);
                      } else {
                        props.setDateaudiencemax &&
                          props.setDateaudiencemax(null);
                      }
                    }}
                    value={
                      props.dateaudiencemax && moment(props.dateaudiencemax)
                    }
                    label="Date Audience Max"
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("dateemmission") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      if (moment(value).isValid()) {
                        const date = moment(value).format("YYYY-MM-DD");
                        props.setDateemmission && props.setDateemmission(date);
                      } else {
                        props.setDateemmission && props.setDateemmission(null);
                      }
                    }}
                    value={props.dateemmission && moment(props.dateemmission)}
                    label="Date Emission"
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("datejugement") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      if (moment(value).isValid()) {
                        const date = moment(value).format("YYYY-MM-DD");
                        props.setDatejugement && props.setDatejugement(date);
                      } else {
                        props.setDatejugement && props.setDatejugement(null);
                      }
                    }}
                    value={props.datejugement && moment(props.datejugement)}
                    label="Date Jugement"
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("datereception") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      if (moment(value).isValid()) {
                        const date = moment(value).format("YYYY-MM-DD");
                        props.setDatereception && props.setDatereception(date);
                      } else {
                        props.setDatereception && props.setDatereception(null);
                      }
                    }}
                    value={props.datereception && moment(props.datereception)}
                    label="Date Reception"
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("dateaccident") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      if (moment(value).isValid()) {
                        const date = moment(value).format("YYYY-MM-DD");
                        props.setDateaccident && props.setDateaccident(date);
                      } else {
                        props.setDateaccident && props.setDateaccident(null);
                      }
                    }}
                    value={props.dateaccident && moment(props.dateaccident)}
                    label="Date Accident"
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("dateparquet") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      if (moment(value).isValid()) {
                        const date = moment(value).format("YYYY-MM-DD");
                        props.setDateparquet && props.setDateparquet(date);
                      } else {
                        props.setDateparquet && props.setDateparquet(null);
                      }
                    }}
                    value={props.dateparquet && moment(props.dateparquet)}
                    label="Date Parquet"
                    slotProps={{
                      textField: { fullWidth: true, size: "small" },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("referencedefinitive") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par reference definitive"
                  fullWidth
                  size="small"
                  value={props.referencedefinitive}
                  onChange={props.setReferencedefinitive}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("nom") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Nom"
                  fullWidth
                  size="small"
                  value={props.nom}
                  onChange={props.setNom}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("prenom") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Prenom"
                  fullWidth
                  size="small"
                  value={props.prenom}
                  onChange={props.setPrenom}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("cin") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par CIN"
                  fullWidth
                  size="small"
                  value={props.cin}
                  onChange={props.setCin}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("matricule") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Matricule"
                  fullWidth
                  size="small"
                  value={props.matricule}
                  onChange={props.setMatricule}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("abreviation") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Abreviation"
                  fullWidth
                  size="small"
                  value={props.abreviation}
                  onChange={props.setAbreviation}
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("raisonsociale") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <TextField
                  id="outlined-basic"
                  label="Recherche par Raison Sociale"
                  fullWidth
                  size="small"
                  value={props.raisonsociale}
                  onChange={props.setRaisonsociale}
                />
              </FormControl>
            </div>
          )}

          {props.boxDisplayed.includes("genreaffaire") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displaygenAff ? styles.backgroudGrey : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Genre Affaire
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displaygenAff}
                  endAdornment={
                    <InputAdornment position="end">
                      {displaygenAff && (
                        <IconButton
                          onClick={() => {
                            props.setGenreaffaireid &&
                              props.setGenreaffaireid("");
                            setDisplaygenAff("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenGenreAffaire(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Genre Affaire"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("affaire") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayAffaire ? styles.backgroudGrey : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Affaire
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayAffaire}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayAffaire && (
                        <IconButton
                          onClick={() => {
                            props.setAffaireid && props.setAffaireid("");
                            setDisplayAffaire("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenAffaire(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Affaire"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("client") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayClient ? styles.backgroudGrey : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Client
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayClient}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayClient && (
                        <IconButton
                          onClick={() => {
                            props.setClientid && props.setClientid("");
                            setDisplayClient("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenClient(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Client"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("adversaire") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayAdversaire
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Adversaire
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayAdversaire}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayAdversaire && (
                        <IconButton
                          onClick={() => {
                            props.setAdversaireid && props.setAdversaireid("");
                            setDisplayAdversaire("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenAdversaire(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Adversaire"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("typeprocedure") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayTypeProcedure
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Type Procedure
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayTypeProcedure}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayTypeProcedure && (
                        <IconButton
                          onClick={() => {
                            props.setTypeprocedureid &&
                              props.setTypeprocedureid("");
                            setDisplayTypeProcedure("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenTypeProcedure(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Type Procedure"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("libelleprocedure") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayLibelleProcedure
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Libelle Procedure
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayLibelleProcedure}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayLibelleProcedure && (
                        <IconButton
                          onClick={() => {
                            props.setLibelleprocedureid &&
                              props.setLibelleprocedureid("");
                            setDisplayLibelleProcedure("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenLibelleProcedure(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Libelle Procedure"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("soustypeprocedure") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displaySousTypeProcedure
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Sous Type Procedure
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displaySousTypeProcedure}
                  endAdornment={
                    <InputAdornment position="end">
                      {displaySousTypeProcedure && (
                        <IconButton
                          onClick={() => {
                            props.setSoustypeprocedureid &&
                              props.setSoustypeprocedureid("");
                            setDisplaySousTypeProcedure("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenSousTypeProcedure(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Sous Type Procedure"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("typepoursuite") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayTypePoursuite
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Type Poursuite
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayTypePoursuite}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayTypePoursuite && (
                        <IconButton
                          onClick={() => {
                            props.setTypepoursuiteid &&
                              props.setTypepoursuiteid("");
                            setDisplayTypePoursuite("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenTypePoursuite(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Type Poursuite"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("typejuridiction") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayTypeJuridiction
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Type Juridiction
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayTypeJuridiction}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayTypeJuridiction && (
                        <IconButton
                          onClick={() => {
                            props.setTypejuridictionid &&
                              props.setTypejuridictionid("");
                            setDisplayTypeJuridiction("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenTypeJuridiction(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Type Juridiction"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("typetribunal") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayTypeTribunal
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Type Tribunal
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayTypeTribunal}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayTypeTribunal && (
                        <IconButton
                          onClick={() => {
                            props.setTypetribunalid &&
                              props.setTypetribunalid("");
                            setDisplayTypeTribunal("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenTypeTribunal(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Type Tribunal"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("typejugement") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayTypeJugement
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Type Jugement
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayTypeJugement}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayTypeJugement && (
                        <IconButton
                          onClick={() => {
                            props.setTypejugementid &&
                              props.setTypejugementid("");
                            setDisplayTypeJugement("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenTypeJugement(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Type Jugement"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("typegarantie") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayTypeGarantie
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Type Garantie
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayTypeGarantie}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayTypeGarantie && (
                        <IconButton
                          onClick={() => {
                            props.setTypegarantieid &&
                              props.setTypegarantieid("");
                            setDisplayTypeGarantie("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenTypeGarantie(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Type Garantie"
                />
              </FormControl>
            </div>
          )}
          {(props.boxDisplayed.includes("procedure") ||
            props.boxDisplayed.includes("parent")) && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayProcedure
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {props.boxDisplayed.includes("procedure")
                    ? "Procedure"
                    : "Procedure Parent"}
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayProcedure}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayProcedure && (
                        <IconButton
                          onClick={() => {
                            props.setProcedureid && props.setProcedureid("");
                            setDisplayProcedure("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenProcedure(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label={
                    props.boxDisplayed.includes("procedure")
                      ? "Procedure"
                      : "Procedure Parent"
                  }
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("resultataudience") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayResultatAudience
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Resultat Audience
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayResultatAudience}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayResultatAudience && (
                        <IconButton
                          onClick={() => {
                            props.setResultataudienceid &&
                              props.setResultataudienceid("");
                            setDisplayResultatAudience("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenResultatAudience(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Resultat Audience"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("audience") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayAudience
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Audience
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayAudience}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayAudience && (
                        <IconButton
                          onClick={() => {
                            props.setAudienceid && props.setAudienceid("");
                            setDisplayAudience("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenAudience(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Audience"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("typeaccidenttravail") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayTypeAccidentTravail
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Type Accident Travail
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayTypeAccidentTravail}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayTypeAccidentTravail && (
                        <IconButton
                          onClick={() => {
                            props.setTypeaccidenttravailid &&
                              props.setTypeaccidenttravailid("");
                            setDisplayTypeAccidentTravail("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenTypeAccidentTravail(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Type Accident Travail"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("accidenttravail") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayAccidentTravail
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Accident Travail
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayAccidentTravail}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayAccidentTravail && (
                        <IconButton
                          onClick={() => {
                            props.setAccidenttravailid &&
                              props.setAccidenttravailid("");
                            setDisplayAccidentTravail("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenAccidentTravail(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Accident Travail"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("accidentcirculation") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayAccidentCirculation
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Accident Circulation
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayAccidentCirculation}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayAccidentCirculation && (
                        <IconButton
                          onClick={() => {
                            props.setAccidentcirculationid &&
                              props.setAccidentcirculationid("");
                            setDisplayAccidentCirculation("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenAccidentCirculation(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Accident Circulation"
                />
              </FormControl>
            </div>
          )}
          {(props.boxDisplayed.includes("ville") ||
            props.boxDisplayed.includes("siege")) && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displayville ? styles.backgroudGrey : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {props.boxDisplayed.includes("ville") ? "Ville" : "Siege"}
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displayville}
                  endAdornment={
                    <InputAdornment position="end">
                      {displayville && (
                        <IconButton
                          onClick={() => {
                            props.setVilleid && props.setVilleid("");
                            setDisplayville("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton onClick={() => setOpenville(true)} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label={
                    props.boxDisplayed.includes("ville") ? "Ville" : "Siege"
                  }
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("situation") && (
            <div className={styles.Box}>
              <FormControl
                className={
                  !displaySituation
                    ? styles.backgroudGrey
                    : styles.backgroudGrey2
                }
                fullWidth
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Qualité
                </InputLabel>
                <OutlinedInput
                  type="text"
                  disabled
                  fullWidth
                  size="small"
                  value={displaySituation}
                  endAdornment={
                    <InputAdornment position="end">
                      {displaySituation && (
                        <IconButton
                          onClick={() => {
                            props.setSituationid && props.setSituationid("");
                            setDisplaySituation("");
                          }}
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => setOpenSituation(true)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Qualité"
                />
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("auprofitduclient") && (
            <div className={styles.BoxCheckBox}>
              <FormControl fullWidth size="small">
                <span
                  style={{
                    fontWeight: "bold",
                    width: "150px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Au profit du client
                </span>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    label="Oui"
                    checked={props.auprofitduclient === "oui"}
                    onChange={() => {
                      if (props.auprofitduclient === "oui") {
                        props.setAuprofitclient && props.setAuprofitclient("");
                      } else {
                        props.setAuprofitclient &&
                          props.setAuprofitclient("oui");
                      }
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      />
                    }
                    label="Non"
                    checked={props.auprofitduclient === "non"}
                    onChange={() => {
                      if (props.auprofitduclient === "non") {
                        props.setAuprofitclient && props.setAuprofitclient("");
                      } else {
                        props.setAuprofitclient &&
                          props.setAuprofitclient("non");
                      }
                    }}
                  />
                </FormGroup>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.includes("typeentite") && (
            <div className={styles.Box}>
              <FormControl
                className={styles.backgroudWhite}
                fullWidth
                size="small"
              >
                <InputLabel id="demo-simple-select-label">
                  Type entité
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.typeentite}
                  label="Type entité"
                  onChange={props.setTypeEntite && props.setTypeEntite}
                >
                  <MenuItem value={""}>Tout</MenuItem>
                  <MenuItem value={"P"}>Personne Physique</MenuItem>
                  <MenuItem value={"M"}>Personne Morale</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          {props.boxDisplayed.length < 3 && (
            <>
              <div className={styles.Box}></div>
              <div className={styles.Box}>
                <div className={styles.BoxReset}>
                  <Button>
                    <RestartAltIcon onClick={() => resetDisplay()} />
                  </Button>
                </div>
              </div>
            </>
          )}
          {openGenreAffaire && props.setGenreaffaireid && (
            <GenreAffaireModal
              open={openGenreAffaire}
              handleClose={() => setOpenGenreAffaire(false)}
              handleChangeValue={props.setGenreaffaireid}
              handleChangeDisplay={setDisplaygenAff}
            />
          )}
          {openAffaire && props.setAffaireid && (
            <AffaireModal
              open={openAffaire}
              handleClose={() => setOpenAffaire(false)}
              handleChangeValue={props.setAffaireid}
              handleChangeDisplay={setDisplayAffaire}
            />
          )}
          {openClient && props.setClientid && (
            <ClientModal
              open={openClient}
              handleClose={() => setOpenClient(false)}
              handleChangeValue={props.setClientid}
              handleChangeDisplay={setDisplayClient}
            />
          )}
          {openAdversaire && props.setAdversaireid && (
            <AdversaireModal
              open={openAdversaire}
              handleClose={() => setOpenAdversaire(false)}
              handleChangeValue={props.setAdversaireid}
              handleChangeDisplay={setDisplayAdversaire}
            />
          )}
          {openLibelleProcedure && props.setLibelleprocedureid && (
            <LibelleProcedureModal
              open={openLibelleProcedure}
              handleClose={() => setOpenLibelleProcedure(false)}
              handleChangeValue={props.setLibelleprocedureid}
              handleChangeDisplay={setDisplayLibelleProcedure}
            />
          )}
          {openTypeProcedure && props.setTypeprocedureid && (
            <TypeProcedureModal
              open={openTypeProcedure}
              handleClose={() => setOpenTypeProcedure(false)}
              handleChangeValue={props.setTypeprocedureid}
              handleChangeDisplay={setDisplayTypeProcedure}
            />
          )}
          {openSousTypeProcedure && props.setSoustypeprocedureid && (
            <SousTypeProcedureModal
              open={openSousTypeProcedure}
              handleClose={() => setOpenSousTypeProcedure(false)}
              handleChangeValue={props.setSoustypeprocedureid}
              handleChangeDisplay={setDisplaySousTypeProcedure}
            />
          )}
          {openTypePoursuite && props.setTypepoursuiteid && (
            <TypePoursuiteModal
              open={openTypePoursuite}
              handleClose={() => setOpenTypePoursuite(false)}
              handleChangeValue={props.setTypepoursuiteid}
              handleChangeDisplay={setDisplayTypePoursuite}
            />
          )}
          {openTypeTribunal && props.setTypetribunalid && (
            <TypeTribunalModal
              open={openTypeTribunal}
              handleClose={() => setOpenTypeTribunal(false)}
              handleChangeValue={props.setTypetribunalid}
              handleChangeDisplay={setDisplayTypeTribunal}
            />
          )}
          {openTypeJuridiction && props.setTypejuridictionid && (
            <TypeJuridictionModal
              open={openTypeJuridiction}
              handleClose={() => setOpenTypeJuridiction(false)}
              handleChangeValue={props.setTypejuridictionid}
              handleChangeDisplay={setDisplayTypeJuridiction}
            />
          )}
          {openTypeJugement && props.setTypejugementid && (
            <TypeJugementModal
              open={openTypeJugement}
              handleClose={() => setOpenTypeJugement(false)}
              handleChangeValue={props.setTypejugementid}
              handleChangeDisplay={setDisplayTypeJugement}
            />
          )}
          {openTypeGarantie && props.setTypegarantieid && (
            <TypeGarantieModal
              open={openTypeGarantie}
              handleClose={() => setOpenTypeGarantie(false)}
              handleChangeValue={props.setTypegarantieid}
              handleChangeDisplay={setDisplayTypeGarantie}
            />
          )}
          {openville && props.setVilleid && (
            <VilleModal
              open={openville}
              handleClose={() => setOpenville(false)}
              handleChangeValue={props.setVilleid}
              handleChangeDisplay={setDisplayville}
            />
          )}
          {openSituation && props.setSituationid && (
            <SituationModal
              open={openSituation}
              handleClose={() => setOpenSituation(false)}
              handleChangeValue={props.setSituationid}
              handleChangeDisplay={setDisplaySituation}
            />
          )}
          {openProcedure && props.setProcedureid && (
            <ProcedureModal
              open={openProcedure}
              handleClose={() => setOpenProcedure(false)}
              handleChangeValue={props.setProcedureid}
              handleChangeDisplay={setDisplayProcedure}
            />
          )}
          {openResultatAudience && props.setResultataudienceid && (
            <ResultatAudienceModal
              open={openResultatAudience}
              handleClose={() => setOpenResultatAudience(false)}
              handleChangeValue={props.setResultataudienceid}
              handleChangeDisplay={setDisplayResultatAudience}
            />
          )}
          {openAudience && props.setAudienceid && (
            <AudienceModal
              open={openAudience}
              handleClose={() => setOpenAudience(false)}
              handleChangeValue={props.setAudienceid}
              handleChangeDisplay={setDisplayAudience}
            />
          )}
          {openTypeAccidentTravail && props.setTypeaccidenttravailid && (
            <TypeAccidentTravailModal
              open={openTypeAccidentTravail}
              handleClose={() => setOpenTypeAccidentTravail(false)}
              handleChangeValue={props.setTypeaccidenttravailid}
              handleChangeDisplay={setDisplayTypeAccidentTravail}
            />
          )}
          {openAccidentTravail && props.setAccidenttravailid && (
            <AccidentTravailModal
              open={openAccidentTravail}
              handleClose={() => setOpenAccidentTravail(false)}
              handleChangeValue={props.setAccidenttravailid}
              handleChangeDisplay={setDisplayAccidentTravail}
            />
          )}
          {openAccidentCirculation && props.setAccidentcirculationid && (
            <AccidentCirculationModal
              open={openAccidentCirculation}
              handleClose={() => setOpenAccidentCirculation(false)}
              handleChangeValue={props.setAccidentcirculationid}
              handleChangeDisplay={setDisplayAccidentCirculation}
            />
          )}
        </>
      )}
    </div>
  );
}
