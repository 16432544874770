import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { adversaireInterface } from "./adversaireSlice";
import { vehiculeInterface } from "./vehiculeSlice";
import { affaireInterface } from "./affaireSlice";

export interface vehiculeadversaireInterface {
  id_vehicule_adversaire: number;
  code: string;
  adversaire_id: number;
  Adversaire: adversaireInterface;
  affaire_id: number;
  Affaire: affaireInterface;
  vehicule_id: number;
  Vehicule: vehiculeInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewvehiculeadversaireInterface {
  code: string;
  adversaire_id: string;
  affaire_id: string;
  vehicule_id: string;
}

const initialState = {
  vehiculeadversaires: [],
  allVehiculeadversaires: [],
  vehiculeadversaire: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const vehiculeadversaireSlice = createSlice({
  name: "vehiculeadversaire",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allVehiculeadversaire(state, action) {
      state.vehiculeadversaires = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allVehiculeadversaireTrue(state, action) {
      state.allVehiculeadversaires = action.payload;
      state.error = "";
    },
    oneVehiculeadversaire(state, action) {
      state.vehiculeadversaire = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allVehiculeadversaire,
  allVehiculeadversaireTrue,
  oneVehiculeadversaire,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = vehiculeadversaireSlice.actions;

export const getAllVehiculeadversaire =
  (
    page?: number,
    code?: string,
    adversaire_id?: number,
    affaire_id?: number,
    vehicule_id?: number
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.vehiculeadversaireApi}${
          page ? "/?page=" + page : "/?all=true"
        }${code ? "&code=" + code : ""}${
          adversaire_id ? "&adversaire_id=" + adversaire_id : ""
        }${affaire_id ? "&affaire_id=" + affaire_id : ""}${
          vehicule_id ? "&vehicule_id=" + vehicule_id : ""
        }`
      );
      let vehiculeadversaires: any;
      vehiculeadversaires = page ? response.data : response.data.data;
      page
        ? dispatch(allVehiculeadversaire(vehiculeadversaires))
        : dispatch(allVehiculeadversaireTrue(vehiculeadversaires));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneVehiculeadversaire =
  (id: number) => async (dispatch: any) => {
    try {
      dispatch(setLoadingOneFalse());
      const response = await axios.get(
        api.baseApi + api.vehiculeadversaireApi + "/" + id
      );
      let vehiculeadversaire = response.data;
      dispatch(oneVehiculeadversaire(vehiculeadversaire));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const addVehiculeadversaire =
  (vehiculeadversaire: NewvehiculeadversaireInterface) =>
  async (dispatch: any) => {
    try {
      const newVehiculeadversaire = await axios.post(
        api.baseApi + api.vehiculeadversaireApi,
        vehiculeadversaire
      );
      return newVehiculeadversaire;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editVehiculeadversaire =
  (vehiculeadversaire: NewvehiculeadversaireInterface, id: string) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.vehiculeadversaireApi + "/" + id,
        vehiculeadversaire
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const deleteVehiculeadversaire =
  (id: string) => async (dispatch: any) => {
    try {
      await axios.delete(api.baseApi + api.vehiculeadversaireApi + "/" + id);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default vehiculeadversaireSlice.reducer;
