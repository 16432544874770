import { Link } from "react-router-dom";
import Affaires from "./pages/Affaire/Affaires";
import NewAffaire from "./pages/Affaire/NewAffaire";
import EditAffaire from "./pages/Affaire/EditAffaire";
import GenreAffaires from "./pages/Genre-Affaire/GenreAffaires";
import NewGenreAffaire from "./pages/Genre-Affaire/NewGenreAffaire";
import EditGenreAffaire from "./pages/Genre-Affaire/EditGenreAffaire";
import Adversaires from "./pages/Adversaire/Adversaire";
import NewAdversaire from "./pages/Adversaire/NewAdversaire";
import EditAdversaire from "./pages/Adversaire/EditAdversaire";
import Clients from "./pages/Client/Client";
import NewClient from "./pages/Client/NewClient";
import EditClient from "./pages/Client/EditClient";
import Villes from "./pages/Ville/Villes";
import NewVille from "./pages/Ville/NewVille";
import EditVille from "./pages/Ville/EditVille";
import Audiences from "./pages/Audience/Audiences";
import NewAudience from "./pages/Audience/NewAudience";
import EditAudience from "./pages/Audience/EditAudience";
import TypeJugements from "./pages/Type-Jugement/TypeJugements";
import NewTypeJugement from "./pages/Type-Jugement/NewTypeJugement";
import EditTypeJugement from "./pages/Type-Jugement/EditTypeJugement";
import TypeJuridictions from "./pages/Type-Juridiction/TypeJuridictions";
import NewTypeJuridiction from "./pages/Type-Juridiction/NewTypeJuridiction";
import EditTypeJuridiction from "./pages/Type-Juridiction/EditTypeJuridiction";
import TypePoursuites from "./pages/Type-Poursuite/TypePoursuites";
import NewTypePoursuite from "./pages/Type-Poursuite/NewTypePoursuite";
import EditTypePoursuite from "./pages/Type-Poursuite/EditTypePoursuite";
import TypeProcedures from "./pages/Type-Procedure/TypeProcedures";
import NewTypeProcedure from "./pages/Type-Procedure/NewTypeProcedure";
import EditTypeProcedure from "./pages/Type-Procedure/EditTypeProcedure";
import TypeTribunals from "./pages/Type-Tribunal/TypeTribunals";
import NewTypeTribunal from "./pages/Type-Tribunal/NewTypeTribunal";
import EditTypeTribunal from "./pages/Type-Tribunal/EditTypeTribunal";
import ResultatAudiences from "./pages/Resultat-Audience/ResultatAudience";
import NewResultatAudience from "./pages/Resultat-Audience/NewResultatAudience";
import EditResultatAudience from "./pages/Resultat-Audience/EditResultatAudience";
import SousTypeProcedures from "./pages/Sous-Type-Procedure/SousTypeProcedure";
import NewSousTypeProcedure from "./pages/Sous-Type-Procedure/NewSousTypeProcedure";
import EditSousTypeProcedure from "./pages/Sous-Type-Procedure/EditSousTypeProcedure";
import Procedures from "./pages/Procedure/Procedures";
import NewProcedure from "./pages/Procedure/NewProcedure";
import EditProcedure from "./pages/Procedure/EditProcedure";
import Jugements from "./pages/Jugement/Jugements";
import NewJugement from "./pages/Jugement/NewJugement";
import EditJugement from "./pages/Jugement/EditJugement";
import Dashboard from "./pages/Dashboard";
import TypeAccidentTravails from "./pages/Type-Accident-Travail/TypeAccidentTravails";
import NewTypeAccidentTravail from "./pages/Type-Accident-Travail/NewTypeAccidentTravail";
import EditTypeAccidentTravail from "./pages/Type-Accident-Travail/EditTypeAccidentTravail";
import AccidentTravails from "./pages/Accident-Travail/AccidentTravails";
import NewAccidentTravail from "./pages/Accident-Travail/NewAccidentTravail";
import EditAccidentTravail from "./pages/Accident-Travail/EditAccidentTravail";
import LibelleProcedures from "./pages/Libelle-Procedure/LibelleProcedures";
import NewLibelleProcedure from "./pages/Libelle-Procedure/NewLibelleProcedure";
import EditLibelleProcedure from "./pages/Libelle-Procedure/EditLibelleProcedure";
import TypeGaranties from "./pages/Type-Garantie/TypeGaranties";
import NewTypeGarantie from "./pages/Type-Garantie/NewTypeGarantie";
import EditTypeGarantie from "./pages/Type-Garantie/EditTypeGarantie";
import AccidentCirculations from "./pages/Accident-Circulation/AccidentCirculations";
import NewAccidentCirculation from "./pages/Accident-Circulation/NewAccidentCirculation";
import EditAccidentCirculation from "./pages/Accident-Circulation/EditAccidentCirculation";
import Situations from "./pages/Situation/Situation";
import NewSituation from "./pages/Situation/NewSituation";
import EditSituation from "./pages/Situation/EditSituation";
import { New } from "./redux/interfaces";
import Tvas from "./pages/Tva/Tvas";
import NewTva from "./pages/Tva/NewTva";
import EditTva from "./pages/Tva/EditTva";
import Actes from "./pages/Acte/Actes";
import NewActe from "./pages/Acte/NewActe";
import EditActe from "./pages/Acte/EditActe";
import Factures from "./pages/Facture/Factures";
import NewFacture from "./pages/Facture/NewFacture";
import EditFacture from "./pages/Facture/EditFacture";
import Vehicules from "./pages/Vehicule/Vehicules";
import NewVehicule from "./pages/Vehicule/NewVehicule";
import EditVehicule from "./pages/Vehicule/EditVehicule";
import TypeVehicules from "./pages/Type-Vehicule/TypeVehicules";
import NewTypeVehicule from "./pages/Type-Vehicule/NewTypeVehicule";
import EditTypeVehicule from "./pages/Type-Vehicule/EditTypeVehicule";

interface ChildRoute {
  path: string;
  element: (props?: New) => JSX.Element;
}

interface Route {
  path: string;
  element?: (props?: New) => JSX.Element;
  children?: ChildRoute[];
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

const routes: Route[] = [
  {
    path: "/referentiel",
    element: Dashboard,
  },
  {
    path: "/affaires",
    children: [
      {
        path: "",
        element: () => <Affaires />,
      },
      {
        path: "new",

        element: () => <NewAffaire />,
      },
      {
        path: ":id",
        element: EditAffaire,
      },
    ],
  },
  {
    path: "/adversaires",
    children: [
      {
        path: "",
        element: () => <Adversaires />,
      },
      {
        path: "new",
        element: () => <NewAdversaire />,
      },
      {
        path: ":id",
        element: EditAdversaire,
      },
    ],
  },
  {
    path: "/clients",
    children: [
      {
        path: "",
        element: () => <Clients />,
      },
      {
        path: "new",
        element: () => <NewClient />,
      },
      {
        path: ":id",
        element: EditClient,
      },
    ],
  },
  {
    path: "/villes",
    children: [
      {
        path: "",
        element: () => <Villes />,
      },
      {
        path: "new",
        element: () => <NewVille />,
      },
      {
        path: ":id",
        element: EditVille,
      },
    ],
  },
  {
    path: "/qualites",
    children: [
      {
        path: "",
        element: () => <Situations />,
      },
      {
        path: "new",
        element: () => <NewSituation />,
      },
      {
        path: ":id",
        element: EditSituation,
      },
    ],
  },
  {
    path: "/audiences",
    children: [
      {
        path: "",
        element: () => <Audiences />,
      },
      {
        path: "new",
        element: () => <NewAudience />,
      },
      {
        path: ":id",
        element: EditAudience,
      },
    ],
  },
  {
    path: "/jugements",
    children: [
      {
        path: "",
        element: () => <Jugements />,
      },
      {
        path: "new",
        element: () => <NewJugement />,
      },
      {
        path: ":id",
        element: EditJugement,
      },
    ],
  },
  {
    path: "/procedures",
    children: [
      {
        path: "",
        element: () => <Procedures />,
      },
      {
        path: "new",
        element: () => <NewProcedure />,
      },
      {
        path: ":id",
        element: EditProcedure,
      },
    ],
  },
  {
    path: "/type-jugements",
    children: [
      {
        path: "",
        element: () => <TypeJugements />,
      },
      {
        path: "new",
        element: () => <NewTypeJugement />,
      },
      {
        path: ":id",
        element: EditTypeJugement,
      },
    ],
  },
  {
    path: "/accident-travails",
    children: [
      {
        path: "",
        element: () => <AccidentTravails />,
      },
      {
        path: "new",
        element: () => <NewAccidentTravail />,
      },
      {
        path: ":id",
        element: EditAccidentTravail,
      },
    ],
  },
  {
    path: "/accident-circulations",
    children: [
      {
        path: "",
        element: () => <AccidentCirculations />,
      },
      {
        path: "new",
        element: () => <NewAccidentCirculation />,
      },
      {
        path: ":id",
        element: EditAccidentCirculation,
      },
    ],
  },
  {
    path: "/type-accident-travails",
    children: [
      {
        path: "",
        element: () => <TypeAccidentTravails />,
      },
      {
        path: "new",
        element: () => <NewTypeAccidentTravail />,
      },
      {
        path: ":id",
        element: EditTypeAccidentTravail,
      },
    ],
  },
  {
    path: "/type-juridictions",
    children: [
      {
        path: "",
        element: () => <TypeJuridictions />,
      },
      {
        path: "new",
        element: () => <NewTypeJuridiction />,
      },
      {
        path: ":id",
        element: EditTypeJuridiction,
      },
    ],
  },
  {
    path: "/type-poursuites",
    children: [
      {
        path: "",
        element: () => <TypePoursuites />,
      },
      {
        path: "new",
        element: () => <NewTypePoursuite />,
      },
      {
        path: ":id",
        element: EditTypePoursuite,
      },
    ],
  },
  {
    path: "/type-procedures",
    children: [
      {
        path: "",
        element: () => <TypeProcedures />,
      },
      {
        path: "new",
        element: () => <NewTypeProcedure />,
      },
      {
        path: ":id",
        element: EditTypeProcedure,
      },
    ],
  },
  {
    path: "/libelle-procedures",
    children: [
      {
        path: "",
        element: () => <LibelleProcedures />,
      },
      {
        path: "new",
        element: () => <NewLibelleProcedure />,
      },
      {
        path: ":id",
        element: EditLibelleProcedure,
      },
    ],
  },
  {
    path: "/type-tribunals",
    children: [
      {
        path: "",
        element: () => <TypeTribunals />,
      },
      {
        path: "new",
        element: () => <NewTypeTribunal />,
      },
      {
        path: ":id",
        element: EditTypeTribunal,
      },
    ],
  },
  {
    path: "/type-garanties",
    children: [
      {
        path: "",
        element: () => <TypeGaranties />,
      },
      {
        path: "new",
        element: () => <NewTypeGarantie />,
      },
      {
        path: ":id",
        element: EditTypeGarantie,
      },
    ],
  },
  {
    path: "/resultat-audiences",
    children: [
      {
        path: "",
        element: () => <ResultatAudiences />,
      },
      {
        path: "new",
        element: () => <NewResultatAudience />,
      },
      {
        path: ":id",
        element: EditResultatAudience,
      },
    ],
  },
  {
    path: "/sous-type-procedures",
    children: [
      {
        path: "",
        element: () => <SousTypeProcedures />,
      },
      {
        path: "new",
        element: () => <NewSousTypeProcedure />,
      },
      {
        path: ":id",
        element: EditSousTypeProcedure,
      },
    ],
  },
  {
    path: "/genre-affaires",
    children: [
      {
        path: "",
        element: () => <GenreAffaires />,
      },
      {
        path: "new",
        element: () => <NewGenreAffaire />,
      },
      {
        path: ":id",
        element: EditGenreAffaire,
      },
    ],
  },
  {
    path: "/tvas",
    children: [
      {
        path: "",
        element: () => <Tvas />,
      },
      {
        path: "new",
        element: () => <NewTva />,
      },
      {
        path: ":id",
        element: EditTva,
      },
    ],
  },
  {
    path: "/actes",
    children: [
      {
        path: "",
        element: () => <Actes />,
      },
      {
        path: "new",
        element: () => <NewActe />,
      },
      {
        path: ":id",
        element: EditActe,
      },
    ],
  },
  {
    path: "/factures",
    children: [
      {
        path: "",
        element: () => <Factures />,
      },
      {
        path: "new",
        element: () => <NewFacture />,
      },
      {
        path: ":id",
        element: EditFacture,
      },
    ],
  },
  {
    path: "/vehicules",
    children: [
      {
        path: "",
        element: () => <Vehicules />,
      },
      {
        path: "new",
        element: () => <NewVehicule />,
      },
      {
        path: ":id",
        element: EditVehicule,
      },
    ],
  },
  {
    path: "/type-vehicules",
    children: [
      {
        path: "",
        element: () => <TypeVehicules />,
      },
      {
        path: "new",
        element: () => <NewTypeVehicule />,
      },
      {
        path: ":id",
        element: EditTypeVehicule,
      },
    ],
  },
  {
    path: "*",
    element: NoMatch,
  },
];

export default routes;
