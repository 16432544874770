import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { typePoursuiteInterface } from "./typePoursuiteSlice";
import { affaireInterface } from "./affaireSlice";
import { typeGarantieInterface } from "./typeGarantieSlice";

export interface accidentCirculationInterface {
  id_accidentcirculation: number;
  code: string;
  dateaccident: Date | string | null;
  dateparquet: Date | string | null;
  numpv: string;
  numparquet: string;
  observation: string;
  typepoursuite_id: number;
  affaire_id: number;
  typegarantie_id: number;
  TypePoursuite: typePoursuiteInterface;
  Affaire: affaireInterface;
  TypeGarantie: typeGarantieInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewaccidentCirculationInterface {
  dateaccident: Date | string | null;
  dateparquet: Date | string | null;
  numpv: string;
  numparquet: string;
  observation: string;
  typepoursuite_id: string;
  affaire_id: string;
  typegarantie_id: string;
}

const initialState = {
  accidentCirculations: [],
  allAccidentCirculations: [],
  accidentCirculation: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const accidentCirculationSlice = createSlice({
  name: "accidentCirculation",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allAccidentCirculation(state, action) {
      state.accidentCirculations = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAccidentCirculationTrue(state, action) {
      state.allAccidentCirculations = action.payload;
      state.error = "";
    },
    oneAccidentCirculation(state, action) {
      state.accidentCirculation = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allAccidentCirculation,
  allAccidentCirculationTrue,
  oneAccidentCirculation,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = accidentCirculationSlice.actions;

export const getAllAccidentCirculation =
  (
    page?: number,
    dateaccident?: Date | null | string,
    dateparquet?: Date | null | string,
    numpv?: string,
    numparquet?: string,
    typepoursuite_id?: string,
    affaire_id?: string,
    typegarantie_id?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.accidentCirculationApi}${
          page ? "/?page=" + page : "/?all=true"
        }${dateaccident ? "&dateaccident=" + dateaccident : ""}${
          dateparquet ? "&dateparquet=" + dateparquet : ""
        }${numpv ? "&numpv=" + numpv : ""}${
          numparquet ? "&numparquet=" + numparquet : ""
        }${typepoursuite_id ? "&typepoursuite_id=" + typepoursuite_id : ""}${
          affaire_id ? "&affaire_id=" + affaire_id : ""
        }${typegarantie_id ? "&typegarantie_id=" + typegarantie_id : ""}`
      );
      let accidentCirculations: any;
      accidentCirculations = page ? response.data : response.data.data;
      page
        ? dispatch(allAccidentCirculation(accidentCirculations))
        : dispatch(allAccidentCirculationTrue(accidentCirculations));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneAccidentCirculation =
  (id: number) => async (dispatch: any) => {
    try {
      dispatch(setLoadingOneFalse());
      const response = await axios.get(
        api.baseApi + api.accidentCirculationApi + "/" + id
      );
      let accidentCirculation = response.data;
      dispatch(oneAccidentCirculation(accidentCirculation));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const addAccidentCirculation =
  (accidentCirculation: NewaccidentCirculationInterface) =>
  async (dispatch: any) => {
    try {
      await axios.post(
        api.baseApi + api.accidentCirculationApi,
        accidentCirculation
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editAccidentCirculation =
  (accidentCirculation: accidentCirculationInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.accidentCirculationApi + "/" + id,
        accidentCirculation
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default accidentCirculationSlice.reducer;
