import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import authSlice from "./slices/authSclice";
import affaireSlice from "./slices/affaireSlice";
import genreAffaireSlice from "./slices/genreAffaireSlice";
import adversaireSlice from "./slices/adversaireSlice";
import audienceSlice from "./slices/audienceSlice";
import clientSlice from "./slices/clientSlice";
import jugementSlice from "./slices/jugementSlice";
import procedureSlice from "./slices/procedureSlice";
import resultatAudienceSlice from "./slices/resultatAudienceSlice";
import typeProcedureSlice from "./slices/typeProcedureSlice";
import sousTypeProcedureSlice from "./slices/sousTypeProcedureSlice";
import typePoursuiteSlice from "./slices/typePoursuiteSlice";
import typeJuridictionSlice from "./slices/typeJuridictionSlice";
import typeJugementSlice from "./slices/typeJugementSlice";
import typeTribunalSlice from "./slices/typeTribunalSlice";
import villeSlice from "./slices/villeSlice";
import typeAccidentTravailSlice from "./slices/typeAccidentTravailSlice";
import accidentTravailSlice from "./slices/accidentTravailSlice";
import libelleProcedureSlice from "./slices/libelleProcedureSlice";
import typeGarantieSlice from "./slices/typeGarantieSlice";
import accidentCirculationSlice from "./slices/accidentCirculationSlice";
import situationSlice from "./slices/situationSlice";
import affaireAdversaireSlice from "./slices/affaireAdversaireSlice";
import affaireClientSlice from "./slices/affaireClientSlice";
import Cookies from "universal-cookie";
import factureSlice from "./slices/factureSlice";
import tvaSlice from "./slices/tvaSlice";
import acteSlice from "./slices/acteSlice";
import factureActeSlice from "./slices/factureActeSlice";
import vehiculeSlice from "./slices/vehiculeSlice";
import typeVehiculeSlice from "./slices/typeVehiculeSlice";
import vehiculeClientSlice from "./slices/vehiculeClientSlice";
import vehiculeAdversaireSlice from "./slices/vehiculeAdversaireSlice";
const cookies = new Cookies();

const token = cookies.get("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const store = configureStore({
  reducer: {
    auth: authSlice,
    affaire: affaireSlice,
    genreAffaire: genreAffaireSlice,
    adversaire: adversaireSlice,
    audience: audienceSlice,
    client: clientSlice,
    jugement: jugementSlice,
    procedure: procedureSlice,
    resultatAudience: resultatAudienceSlice,
    typeProcedure: typeProcedureSlice,
    libelleProcedure: libelleProcedureSlice,
    sousTypeProcedure: sousTypeProcedureSlice,
    typePoursuite: typePoursuiteSlice,
    typeJuridiction: typeJuridictionSlice,
    typeJugement: typeJugementSlice,
    typeGarantie: typeGarantieSlice,
    typeTribunal: typeTribunalSlice,
    typeAccidentTravail: typeAccidentTravailSlice,
    accidentTravail: accidentTravailSlice,
    accidentCirculation: accidentCirculationSlice,
    ville: villeSlice,
    situation: situationSlice,
    affaireclient: affaireClientSlice,
    affaireadversaire: affaireAdversaireSlice,
    facture: factureSlice,
    tva: tvaSlice,
    acte: acteSlice,
    factureActe: factureActeSlice,
    vehicule: vehiculeSlice,
    typeVehicule: typeVehiculeSlice,
    vehiculeclient: vehiculeClientSlice,
    vehiculeadversaire: vehiculeAdversaireSlice,
  },
});
export type AppDispatch = typeof store.dispatch;

export default store;
