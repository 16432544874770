import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  editAccidentTravail,
  accidentTravailInterface,
  getOneAccidentTravail,
} from "../../redux/slices/accidentTravailSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import TypeAccidentTravailModal from "../../components/Modals/TypeAccidentTravailModal";
import SearchIcon from "@mui/icons-material/Search";
import TypeAccidentTravailCreateModal from "../../components/CreateModals/TypeAccidentTravailCreateModal";
import AffaireModal from "../../components/Modals/AffaireModal";
import Spinner from "../../components/Spinner";

const MySwal = withReactContent(Swal);

export default function EditAccidentTravail() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [openCreateTypeAccidentTravail, setOpenCreateTypeAccidentTravail] =
    useState(false);
  const [openTypeAccidentTravail, setOpenTypeAccidentTravail] = useState(false);
  const [displayTypeAccidentTravail, setDisplayTypeAccidentTravail] =
    useState("");

  const [openAffaire, setOpenAffaire] = useState(false);
  const [displayAffaire, setDisplayAffaire] = useState("");
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const accidentTravail: accidentTravailInterface = useSelector(
    (state: any) => state.accidentTravail.accidentTravail
  );
  const loading = useSelector((state: any) => state.accidentTravail.loadingOne);

  const schema = yup.object({
    numpoliceassurance: yup
      .string()
      .required("Num police assurance est obligatoire"),
    typeaccidenttravail_id: yup
      .string()
      .required("Type accident travail est obligatoire"),
    affaire_id: yup.string().required("Affaire est obligatoire"),
    dateaccident: yup.string().required("Date accident est obligatoire"),
  });

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneAccidentTravail(id));
    } else {
      navigate("/accident-travails");
    }
  }, []);

  useEffect(() => {
    setDisplayTypeAccidentTravail(
      accidentTravail?.TypeAccidentTravail?.libelle
    );
    setDisplayAffaire(accidentTravail?.Affaire?.reference);
  }, [accidentTravail]);

  return (
    <>
      <div className={styles.ReturnList}>
        <Link to="/accident-travails" relative="path">
          <ArrowBackIosIcon /> Liste Accident Travails
        </Link>
        <h2>Editer Accident Travail</h2>
        <div />
      </div>
      {!loading && Object.keys(accidentTravail).length > 0 ? (
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          validateOnBlur={false}
          initialValues={accidentTravail}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            const bool = await dispatch(
              editAccidentTravail(object, values.id_accidenttravail)
            );
            if (bool) {
              MySwal.fire({
                icon: "success",
                title: "Accident Travail est modifié avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then(() => {
                navigate("/accident-travails");
              });
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    label="Pourcentage Assurance"
                    name="pourcentageassurance"
                    onChange={handleChange}
                    fullWidth
                    value={values.pourcentageassurance}
                    error={!!errors.pourcentageassurance}
                    helperText={
                      errors.pourcentageassurance
                        ? errors.pourcentageassurance
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    className={
                      !displayAffaire
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Affaire
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayAffaire}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenAffaire(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Affaire"
                    />
                  </FormControl>
                  {openAffaire && (
                    <AffaireModal
                      open={openAffaire}
                      handleClose={() => setOpenAffaire(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("affaire_id", value)
                      }
                      handleChangeDisplay={setDisplayAffaire}
                    />
                  )}
                </Grid>
                <Grid container item xs={4}>
                  <Grid item xs={10}>
                    <FormControl
                      className={
                        !displayTypeAccidentTravail
                          ? styles.backgroudGrey
                          : styles.backgroudGrey2
                      }
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Type Accident Travail
                      </InputLabel>
                      <OutlinedInput
                        type="text"
                        disabled
                        fullWidth
                        value={displayTypeAccidentTravail}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setOpenTypeAccidentTravail(true)}
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Type Accident Travail"
                      />
                    </FormControl>
                    {openTypeAccidentTravail && (
                      <TypeAccidentTravailModal
                        open={openTypeAccidentTravail}
                        handleClose={() => setOpenTypeAccidentTravail(false)}
                        handleChangeValue={(value) =>
                          setFieldValue("typeaccidenttravail_id", value)
                        }
                        handleChangeDisplay={setDisplayTypeAccidentTravail}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      className={styles.AddButtonModal}
                      onClick={() => setOpenCreateTypeAccidentTravail(true)}
                    >
                      +
                    </Button>
                    {openCreateTypeAccidentTravail && (
                      <TypeAccidentTravailCreateModal
                        open={openCreateTypeAccidentTravail}
                        handleClose={() =>
                          setOpenCreateTypeAccidentTravail(false)
                        }
                        handleChangeValue={(value) =>
                          setFieldValue("typeaccidenttravail_id", value)
                        }
                        handleChangeDisplay={(value) =>
                          setDisplayTypeAccidentTravail(value)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Observation"
                    name="observation"
                    onChange={handleChange}
                    fullWidth
                    value={values.observation}
                    error={!!errors.observation}
                    helperText={errors.observation ? errors.observation : ""}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(value) => {
                        const date = moment(value).format("YYYY-MM-DD");
                        setFieldValue("dateaccident", date);
                      }}
                      value={moment(values.dateaccident)}
                      label="Date Accident"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.dateaccident,
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {errors.dateaccident && (
                    <span className={styles.errorHandle}>
                      {errors.dateaccident}
                    </span>
                  )}
                </Grid>
                <Grid item xs={12} className={styles.ButtonGrid}>
                  <Button variant="contained" type="submit">
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner />
      )}
    </>
  );
}
