import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(48 99 255)", // Set your primary color here
      contrastText: "#fff",
      dark: "rgba(108 100 109)",
    },
  },
});
