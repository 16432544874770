import { useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { addClient } from "../../redux/slices/clientSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";
import { New } from "../../redux/interfaces";
import { CircularProgress } from "@mui/material";
const MySwal = withReactContent(Swal);

interface MyFormValues {
  typeentite: string;
  nom: string;
  prenom: string;
  cin: string;
  genre: string;
  raisonsociale: string;
  representantlegal: string;
  abreviation: string;
  siegesocial: string;
}

export default function NewClient(props: New) {
  const [loadingSend, setLoadingSend] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const schema = yup.object({
    typeentite: yup.string().required("Type Entite est obligatoire"),
    nom: yup.string().required("Nom est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
  });

  const initialValues: MyFormValues = {
    nom: "",
    typeentite: "P",
    prenom: "",
    cin: "",
    genre: "P",
    raisonsociale: "",
    representantlegal: "",
    abreviation: "",
    siegesocial: "",
  };

  return (
    <>
      {!props.modal ? (
        <div className={styles.ReturnList}>
          <Link to="/clients" relative="path">
            <ArrowBackIosIcon /> Liste Clients
          </Link>
          <h2>Nouveau Client</h2>
          <div />
        </div>
      ) : (
        <div className={styles.TitleAdd}>
          <h2>Nouveau Client</h2>
        </div>
      )}
      <Formik
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const newClient = await dispatch(addClient(values));
          if (newClient?.status) {
            MySwal.fire({
              icon: "success",
              title: "Client est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              if (props.handleClose) {
                let clientName = newClient?.data.nom
                  ? newClient?.data.nom + " "
                  : "" + newClient?.data.prenom
                  ? newClient?.data.prenom
                  : "";
                props.handleClose(
                  newClient?.data.id_client,
                  clientName,
                  newClient.data
                );
              } else {
                navigate("/clients");
              }
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl className={styles.backgroudWhite} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Type entité
                  </InputLabel>
                  <Select
                    name="typeentite"
                    labelId="demo-simple-select-label"
                    value={values.typeentite}
                    label="Type entité"
                    onChange={handleChange}
                  >
                    <MenuItem value="">-</MenuItem>
                    <MenuItem value={"P"}>Personne Physique</MenuItem>
                    <MenuItem value={"M"}>Personne Morale</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Nom"
                  name="nom"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.nom}
                  helperText={errors.nom ? errors.nom : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Prenom"
                  name="prenom"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.prenom}
                  helperText={errors.prenom ? errors.prenom : ""}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <TextField
                  label="CIN"
                  name="cin"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.cin}
                  helperText={errors.cin ? errors.cin : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl className={styles.backgroudWhite} fullWidth>
                  <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                  <Select
                    name="genre"
                    labelId="demo-simple-select-label"
                    value={values.genre}
                    label="Genre"
                    onChange={handleChange}
                  >
                    <MenuItem value={"H"}>H</MenuItem>
                    <MenuItem value={"F"}>F</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Abreviation"
                  name="abreviation"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.abreviation}
                  helperText={errors.abreviation ? errors.abreviation : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Siege Social"
                  name="siegesocial"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.siegesocial}
                  helperText={errors.siegesocial ? errors.siegesocial : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Raison Sociale"
                  name="raisonsociale"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.raisonsociale}
                  helperText={errors.raisonsociale ? errors.raisonsociale : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Representant lLegal"
                  name="representantlegal"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.representantlegal}
                  helperText={
                    errors.representantlegal ? errors.representantlegal : ""
                  }
                />
              </Grid> */}
              <Grid item xs={12} className={styles.ButtonGrid}>
                <Button
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                  variant="contained"
                  type="submit"
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
