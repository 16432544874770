import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { AffaireAdversaireInterface } from "../interfaces";
import { situationInterface } from "./situationSlice";

export interface adversaireInterface {
  adversaire_id: any;
  situation_id: any;
  id_adversaire: number;
  typeentite: string;
  nom: string;
  prenom: string;
  cin: string;
  id_affaire_adversaire?: string;
  Adversaire?: adversaireInterface;
  Situation?: situationInterface;
  raisonsociale: string;
  abreviation: string;
  siegesocial: string;
  representantlegal: string;
  genre: string;
  AffaireAdversaire: AffaireAdversaireInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewadversaireInterface {
  typeentite: string;
  nom: string;
  prenom: string;
  cin: string;
  genre: string;
  raisonsociale: string;
  abreviation: string;
  siegesocial: string;
  representantlegal: string;
}

const initialState = {
  adversaires: [],
  allAdversaires: [],
  adversaire: {},
  error: "",
  loading: true,
  loadingOne: true,
  loadingListe: true,
  errorListe: "",
};

const adversaireSlice = createSlice({
  name: "adversaire",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allAdversaire(state, action) {
      state.adversaires = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAdversaireTrue(state, action) {
      state.allAdversaires = action.payload;
    },
    oneAdversaire(state, action) {
      state.adversaire = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allAdversaire,
  allAdversaireTrue,
  oneAdversaire,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = adversaireSlice.actions;

export const getAllAdversaire =
  (
    page?: number,
    typeentite?: string,
    nom?: string,
    prenom?: string,
    cin?: string,
    raisonsociale?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.adversaireApi}${
          page ? "/?page=" + page : "/?all=true"
        }${typeentite ? "&typeentite=" + typeentite : ""}${
          nom ? "&nom=" + nom : ""
        }${prenom ? "&prenom=" + prenom : ""}${cin ? "&cin=" + cin : ""}${
          raisonsociale ? "&raisonsociale=" + raisonsociale : ""
        }`
      );
      let adversaires: any;
      adversaires = page ? response.data : response.data.data;
      page
        ? dispatch(allAdversaire(adversaires))
        : dispatch(allAdversaireTrue(adversaires));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneAdversaire = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.adversaireApi + "/" + id
    );
    let adversaire = response.data;
    dispatch(oneAdversaire(adversaire));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addAdversaire =
  (adversaire: NewadversaireInterface) => async (dispatch: any) => {
    try {
      const newAdversaire = await axios.post(
        api.baseApi + api.adversaireApi,
        adversaire
      );
      return newAdversaire;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editAdversaire =
  (adversaire: adversaireInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.adversaireApi + "/" + id, adversaire);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default adversaireSlice.reducer;
