import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface typeVehiculeInterface {
  id_typevehicule: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtypeVehiculeInterface {
  code: string;
  libelle: string;
}

const initialState = {
  typeVehicules: [],
  allTypeVehicules: [],
  typeVehicule: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const typeVehiculeSlice = createSlice({
  name: "typeVehicule",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTypeVehicule(state, action) {
      state.typeVehicules = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTypeVehiculeTrue(state, action) {
      state.allTypeVehicules = action.payload;
      state.error = "";
    },
    oneTypeVehicule(state, action) {
      state.typeVehicule = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTypeVehicule,
  allTypeVehiculeTrue,
  oneTypeVehicule,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = typeVehiculeSlice.actions;

export const getAllTypeVehicule =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.typeVehiculeApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let typeVehicules: any;
      typeVehicules = page ? response.data : response.data.data;
      page
        ? dispatch(allTypeVehicule(typeVehicules))
        : dispatch(allTypeVehiculeTrue(typeVehicules));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTypeVehicule = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.typeVehiculeApi + "/" + id
    );
    let typeVehicule = response.data;
    dispatch(oneTypeVehicule(typeVehicule));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addTypeVehicule =
  (typeVehicule: NewtypeVehiculeInterface) => async (dispatch: any) => {
    try {
      const newTypeVehicule = await axios.post(
        api.baseApi + api.typeVehiculeApi,
        typeVehicule
      );
      return newTypeVehicule;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editTypeVehicule =
  (typeVehicule: typeVehiculeInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.typeVehiculeApi + "/" + id,
        typeVehicule
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default typeVehiculeSlice.reducer;
