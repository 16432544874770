import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Formik } from "formik";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VehiculeModal from "../Modals/VehiculeModal";
import { addVehiculeadversaire } from "../../redux/slices/vehiculeAdversaireSlice";
import { affaireInterface } from "../../redux/slices/affaireSlice";
import VehiculeCreateModal from "./VehiculeCreateModal";

const MySwal = withReactContent(Swal);

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleCloseUpdate: () => void;
  affaire_id: string;
  affaire: affaireInterface;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};
interface MyFormValues {
  code: string;
  affaire_id: string;
  adversaire_id: string;
  vehicule_id: string;
}

export default function VehiculeAdversaireCreateModal(props: ModalProps) {
  const [openVehicule, setOpenVehicule] = useState(false);
  const [openCreateVehicule, setOpenCreateVehicule] = useState(false);
  const [displayVehicule, setDisplayVehicule] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const initialValues: MyFormValues = {
    code: "",
    affaire_id: props.affaire_id,
    adversaire_id: "",
    vehicule_id: "",
  };
  const schema = yup.object({
    adversaire_id: yup.string().required("Adversaire est obligatoire"),
    vehicule_id: yup.string().required("Véhicule est obligatoire"),
  });
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.TitleAdd}>
          <h2>Nouveau Véhicule Adversaire</h2>
        </div>
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={async (values) => {
            const vehiculeadversaire = await dispatch(
              addVehiculeadversaire(values)
            );
            if (vehiculeadversaire?.status) {
              MySwal.fire({
                icon: "success",
                title: "Véhicule Adversaire est ajouté avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then((result) => {
                props.handleCloseUpdate && props.handleCloseUpdate();
                props.handleClose();
              });
            }
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl className={styles.backgroudWhite} fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Adversaire
                    </InputLabel>
                    <Select
                      name="adversaire_id"
                      labelId="demo-simple-select-label"
                      label="Adversaire"
                      onChange={handleChange}
                    >
                      <MenuItem value="">-</MenuItem>
                      {props.affaire?.adversaires?.length > 0 &&
                        props.affaire.adversaires.map((adversaire, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={adversaire.Adversaire?.id_adversaire}
                            >
                              {!adversaire.Adversaire?.nom &&
                              !adversaire.Adversaire?.prenom
                                ? "-----"
                                : ""}
                              {adversaire.Adversaire?.nom
                                ? adversaire.Adversaire?.nom + " "
                                : "" + adversaire.Adversaire?.prenom
                                ? adversaire.Adversaire?.prenom
                                : ""}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item xs={6}>
                  <Grid item xs={10}>
                    <FormControl
                      className={
                        !displayVehicule
                          ? styles.backgroudGrey
                          : styles.backgroudGrey2
                      }
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Véhicule
                      </InputLabel>
                      <OutlinedInput
                        type="text"
                        disabled
                        fullWidth
                        value={displayVehicule}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setOpenVehicule(true)}
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Véhicule"
                      />
                    </FormControl>
                    {openVehicule && (
                      <VehiculeModal
                        open={openVehicule}
                        handleClose={() => setOpenVehicule(false)}
                        handleChangeValue={(value) =>
                          setFieldValue("vehicule_id", value)
                        }
                        handleChangeDisplay={setDisplayVehicule}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      className={styles.AddButtonModal}
                      onClick={() => setOpenCreateVehicule(true)}
                    >
                      +
                    </Button>
                    {openCreateVehicule && (
                      <VehiculeCreateModal
                        open={openCreateVehicule}
                        handleClose={() => setOpenCreateVehicule(false)}
                        handleChangeValue={(value) =>
                          setFieldValue("vehicule_id", value)
                        }
                        handleChangeDisplay={(value) =>
                          setDisplayVehicule(value)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.ButtonGrid}>
                  <Button variant="contained" type="submit">
                    Ajouter
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
