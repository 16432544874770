import React from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Menu from "./MenuArray";
import { Link, useLocation } from "react-router-dom";
import styles from "../styles/Sidebar.module.scss";
import logo from "../assets/logo.png";

const drawerWidth = 270;

export default function Sidebar() {
  const location = useLocation();
  return (
    <>
      <div className={styles.SideBarGlobal}>
        <div className={styles.SideBar}>
          <img src={logo} alt="Sia" />
          <div style={{ width: "90%" }}>
            {Menu.map((text, index) => (
              <ListItem
                className={
                  location.pathname === text.link
                    ? styles.ItemActive
                    : styles.ItemNoteActive
                }
                component={Link}
                to={text.link}
                key={text.name}
                disablePadding
              >
                <ListItemButton className={styles.buttonSideBar}>
                  <ListItemIcon>{text.logo}</ListItemIcon>
                  <ListItemText primary={text.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </div>
          <div style={{ height: "160px" }} />
        </div>
      </div>
    </>
  );
}
