import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { clientInterface } from "./clientSlice";
import { vehiculeInterface } from "./vehiculeSlice";
import { affaireInterface } from "./affaireSlice";

export interface vehiculeclientInterface {
  id_vehicule_client: number;
  code: string;
  client_id: number;
  Client: clientInterface;
  affaire_id: number;
  Affaire: affaireInterface;
  vehicule_id: number;
  Vehicule: vehiculeInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewvehiculeclientInterface {
  code: string;
  client_id: string;
  affaire_id: string;
  vehicule_id: string;
}

const initialState = {
  vehiculeclients: [],
  allVehiculeclients: [],
  vehiculeclient: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const vehiculeclientSlice = createSlice({
  name: "vehiculeclient",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allVehiculeclient(state, action) {
      state.vehiculeclients = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allVehiculeclientTrue(state, action) {
      state.allVehiculeclients = action.payload;
      state.error = "";
    },
    oneVehiculeclient(state, action) {
      state.vehiculeclient = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allVehiculeclient,
  allVehiculeclientTrue,
  oneVehiculeclient,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = vehiculeclientSlice.actions;

export const getAllVehiculeclient =
  (
    page?: number,
    code?: string,
    client_id?: number,
    affaire_id?: number,
    vehicule_id?: number
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.vehiculeclientApi}${
          page ? "/?page=" + page : "/?all=true"
        }${code ? "&code=" + code : ""}${
          client_id ? "&client_id=" + client_id : ""
        }${affaire_id ? "&affaire_id=" + affaire_id : ""}${
          vehicule_id ? "&vehicule_id=" + vehicule_id : ""
        }`
      );
      let vehiculeclients: any;
      vehiculeclients = page ? response.data : response.data.data;
      page
        ? dispatch(allVehiculeclient(vehiculeclients))
        : dispatch(allVehiculeclientTrue(vehiculeclients));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneVehiculeclient = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.vehiculeclientApi + "/" + id
    );
    let vehiculeclient = response.data;
    dispatch(oneVehiculeclient(vehiculeclient));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addVehiculeclient =
  (vehiculeclient: NewvehiculeclientInterface) => async (dispatch: any) => {
    try {
      const newVehiculeclient = await axios.post(
        api.baseApi + api.vehiculeclientApi,
        vehiculeclient
      );
      return newVehiculeclient;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editVehiculeclient =
  (vehiculeclient: NewvehiculeclientInterface, id: string) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.vehiculeclientApi + "/" + id,
        vehiculeclient
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const deleteVehiculeclient = (id: string) => async (dispatch: any) => {
  try {
    await axios.delete(api.baseApi + api.vehiculeclientApi + "/" + id);
    return true;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export default vehiculeclientSlice.reducer;
