import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Table } from "react-bootstrap";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  typeTribunalInterface,
  getAllTypeTribunal,
} from "../../redux/slices/typeTribunalSlice";
import { Pagination } from "@mui/material";
import { Meta } from "../../redux/interfaces";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../styles/global";
import Spinner from "../../components/Spinner";
import BoxFilter from "../../components/BoxFilter";
import TableSiaModal from "../TableSiaModal";

const headSet = ["Libelle", "Crée le"];
interface typeTribunalPaginationInterface {
  meta: Meta;
  data: typeTribunalInterface[];
}

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleChangeValue: (value: string) => void;
  handleChangeDisplay: (value: string) => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "5%",
  left: "2%",
  width: "96%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};
const dataToShow = [
  {
    vkey: ["libelle"],
    type: "link",
  },
];
export default function TypeTribunalModal(props: ModalProps) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [code, setCode] = useState("");
  const [libelle, setLibelle] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.typeTribunal.errorListe);
  const loading = useSelector((state: any) => state.typeTribunal.loadingListe);
  const typeTribunals: typeTribunalPaginationInterface = useSelector(
    (state: any) => state.typeTribunal.typeTribunals
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllTypeTribunal(1, code, libelle));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllTypeTribunal(value, code, libelle));
  };
  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };
  const handleChangeLibelle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLibelle(event.target.value);
  };
  const ResetFilters = () => {
    setCode("");
    setLibelle("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllTypeTribunal(1, code, libelle));
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [code, libelle]);

  const LinkButton = (id: string, libelle: string) => {
    props.handleChangeValue(id);
    props.handleChangeDisplay(libelle);
    props.handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <BoxFilter
          boxDisplayed={["libelle", "code"]}
          code={code}
          libelle={libelle}
          setCode={handleChangeCode}
          setLibelle={handleChangeLibelle}
          ResetFilters={ResetFilters}
          loading={loading}
        />
        <TableSiaModal
          loading={loading}
          headSet={headSet}
          dataDisplay={typeTribunals}
          page={page}
          id_type="id_typetribunal"
          handleChange={handleChange}
          dataToShow={dataToShow}
          title="Type Tribunal"
          LinkButton={LinkButton}
        />
      </Box>
    </Modal>
  );
}
