// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dashboard_ListeLink__9YTkh {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n}\n.Dashboard_ListeLink__9YTkh .Dashboard_Link__Tu5jw {\n  margin: 5px;\n  padding: 15px;\n  background-color: rgb(48, 99, 255);\n  color: white;\n  border-radius: 10px;\n  cursor: pointer;\n  text-decoration: none;\n  font-size: 18px;\n  font-weight: bold;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/styles/Dashboard.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qCAAA;AACJ;AAAI;EACI,WAAA;EACA,aAAA;EACA,kCAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAER","sourcesContent":[".ListeLink{\n    display: grid;\n    grid-template-columns: repeat(4,1fr);\n    .Link{\n        margin: 5px;\n        padding: 15px;\n        background-color: rgb(48 99 255);\n        color: white;\n        border-radius: 10px;\n        cursor: pointer;\n        text-decoration: none;\n        font-size: 18px;\n        font-weight: bold;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListeLink": "Dashboard_ListeLink__9YTkh",
	"Link": "Dashboard_Link__Tu5jw"
};
export default ___CSS_LOADER_EXPORT___;
