import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Table } from "react-bootstrap";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  accidentCirculationInterface,
  getAllAccidentCirculation,
} from "../../redux/slices/accidentCirculationSlice";
import { Pagination } from "@mui/material";
import { Meta } from "../../redux/interfaces";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../styles/global";
import Spinner from "../../components/Spinner";
import BoxFilter from "../../components/BoxFilter";
import TableSiaModal from "../TableSiaModal";

const headSet = [
  "code",
  "date accident",
  "Type Poursuite",
  "Type Garantie",
  "Affaire",
  "Crée le",
];
interface accidentCirculationPaginationInterface {
  meta: Meta;
  data: accidentCirculationInterface[];
}
interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleChangeValue: (value: string) => void;
  handleChangeDisplay: (value: string) => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "5%",
  left: "2%",
  width: "96%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};
const dataToShow = [
  {
    vkey: ["Affaire", "reference"],
    type: "link",
  },
  {
    vkey: ["dateaccident"],
    type: "date",
  },
  {
    vkey: ["TypePoursuite", "libelle"],
    type: "string",
  },
  {
    vkey: ["TypeGarantie", "libelle"],
    type: "string",
  },
];

export default function AccidentCirculationModal(props: ModalProps) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [dateaccident, setDateaccident] = useState<Date | null | string>(null);
  const [dateparquet, setDateparquet] = useState<Date | null | string>(null);
  const [numpv, setNumpv] = useState("");
  const [numparquet, setNumparquet] = useState("");
  const [typepoursuite_id, setTypepoursuiteid] = useState("");
  const [affaire_id, setaffaireid] = useState("");
  const [typegarantie_id, setTypegarantieid] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector(
    (state: any) => state.accidentCirculation.errorListe
  );
  const loading = useSelector(
    (state: any) => state.accidentCirculation.loadingListe
  );
  const accidentCirculations: accidentCirculationPaginationInterface =
    useSelector((state: any) => state.accidentCirculation.accidentCirculations);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllAccidentCirculation(
        1,
        dateaccident,
        dateparquet,
        numpv,
        numparquet,
        typepoursuite_id,
        affaire_id,
        typegarantie_id
      )
    );
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllAccidentCirculation(
        value,
        dateaccident,
        dateparquet,
        numpv,
        numparquet,
        typepoursuite_id,
        affaire_id,
        typegarantie_id
      )
    );
  };
  const handleChangeNumpv = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumpv(event.target.value);
  };
  const handleChangeNumparquet = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNumparquet(event.target.value);
  };
  const handleChangeDateaccident = (date: Date | null | string) => {
    setDateaccident(date);
  };
  const handleChangeDateparquet = (date: Date | null | string) => {
    setDateparquet(date);
  };
  const handleChangeAffaireId = (value: string) => {
    setaffaireid(value);
  };
  const handleChangeTypeGarantieId = (value: string) => {
    setTypegarantieid(value);
  };
  const handleChangeTypePoursuite = (value: string) => {
    setTypepoursuiteid(value);
  };
  const ResetFilters = () => {
    setDateaccident(null);
    setDateparquet(null);
    setNumpv("");
    setNumparquet("");
    setTypepoursuiteid("");
    setaffaireid("");
    setTypegarantieid("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllAccidentCirculation(
            1,
            dateaccident,
            dateparquet,
            numpv,
            numparquet,
            typepoursuite_id,
            affaire_id,
            typegarantie_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [dateaccident, dateparquet, numpv, numparquet]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllAccidentCirculation(
          1,
          dateaccident,
          dateparquet,
          numpv,
          numparquet,
          typepoursuite_id,
          affaire_id,
          typegarantie_id
        )
      );
    }
  }, [typepoursuite_id, affaire_id, typegarantie_id]);
  const LinkButton = (id: string, libelle: string) => {
    props.handleChangeValue(id);
    props.handleChangeDisplay(libelle);
    props.handleClose();
  };
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <BoxFilter
          boxDisplayed={[
            "dateaccident",
            "numpv",
            "numparquet",
            "affaire",
            "typegarantie",
            "typepoursuite",
          ]}
          dateaccident={dateaccident}
          numpv={numpv}
          numpoliceassurance={numparquet}
          affaire_id={affaire_id}
          typegarantie_id={typegarantie_id}
          typepoursuite_id={typepoursuite_id}
          setDateaccident={handleChangeDateaccident}
          setNumpv={handleChangeNumpv}
          setNumparquet={handleChangeNumparquet}
          setAffaireid={handleChangeAffaireId}
          setTypegarantieid={handleChangeTypeGarantieId}
          setTypepoursuiteid={handleChangeTypePoursuite}
          ResetFilters={ResetFilters}
          loading={loading}
        />
        <TableSiaModal
          loading={loading}
          headSet={headSet}
          dataDisplay={accidentCirculations}
          page={page}
          id_type="id_accidentcirculation"
          handleChange={handleChange}
          dataToShow={dataToShow}
          title="Accident de Circulation"
          LinkButton={LinkButton}
        />
      </Box>
    </Modal>
  );
}
