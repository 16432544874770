import React from "react";
import { theme } from "../styles/global";
import { Box, CircularProgress, ThemeProvider } from "@mui/material";

export default function Spinner() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <ThemeProvider theme={theme}>
        <CircularProgress size={100} />
      </ThemeProvider>
    </Box>
  );
}
