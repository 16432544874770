import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface typeJugementInterface {
  id_typejugement: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtypeJugementInterface {
  code: string;
  libelle: string;
}

const initialState = {
  typeJugements: [],
  allTypeJugements: [],
  typeJugement: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const typeJugementSlice = createSlice({
  name: "typeJugement",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTypeJugement(state, action) {
      state.typeJugements = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTypeJugementTrue(state, action) {
      state.allTypeJugements = action.payload;
      state.error = "";
    },
    oneTypeJugement(state, action) {
      state.typeJugement = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTypeJugement,
  allTypeJugementTrue,
  oneTypeJugement,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = typeJugementSlice.actions;

export const getAllTypeJugement =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.typeJugementApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let typeJugements: any;
      typeJugements = page ? response.data : response.data.data;
      page
        ? dispatch(allTypeJugement(typeJugements))
        : dispatch(allTypeJugementTrue(typeJugements));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTypeJugement = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.typeJugementApi + "/" + id
    );
    let typeJugement = response.data;
    dispatch(oneTypeJugement(typeJugement));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addTypeJugement =
  (typeJugement: NewtypeJugementInterface) => async (dispatch: any) => {
    try {
      const newTypeJugement = await axios.post(
        api.baseApi + api.typeJugementApi,
        typeJugement
      );
      return newTypeJugement;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editTypeJugement =
  (typeJugement: typeJugementInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.typeJugementApi + "/" + id,
        typeJugement
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default typeJugementSlice.reducer;
