import React from "react";
import { theme } from "../styles/global";
import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import styles from "../styles/Spinner.module.scss";

export default function SpinnerPage() {
  return (
    <div className={styles.LoadingOver}>
      <div className={styles.SpinnerPage}>
        <Box>
          <ThemeProvider theme={theme}>
            <CircularProgress size={100} />
          </ThemeProvider>
        </Box>
      </div>
    </div>
  );
}
