import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import {
  clientInterface,
  editClient,
  getOneClient,
} from "../../redux/slices/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, Link } from "react-router-dom";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Spinner from "../../components/Spinner";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

export default function EditClient() {
  const [loadingSend, setLoadingSend] = useState(false);
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const client: clientInterface = useSelector(
    (state: any) => state.client.client
  );

  const schema = yup.object({
    typeentite: yup.string().required("Type Entite est obligatoire"),
    nom: yup.string().required("Nom est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
  });

  const loading = useSelector((state: any) => state.client.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneClient(id));
    } else {
      navigate("/clients");
    }
  }, []);

  return (
    <>
      <div className={styles.ReturnList}>
        <Link to="/clients" relative="path">
          <ArrowBackIosIcon /> Liste Clients
        </Link>
        <h2>Editer Client</h2>
        <div />
      </div>
      {!loading && Object.keys(client).length > 0 ? (
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={client}
          onSubmit={async (values) => {
            let object = { ...values };
            delete object.updatedby;
            delete object.updatedon;
            setLoadingSend(true);
            const bool = await dispatch(editClient(object, values.id_client));
            if (bool) {
              MySwal.fire({
                icon: "success",
                title: "Client est modifié avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then(() => {
                setLoadingSend(false);
                navigate("/clients");
              });
            }
          }}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Type entité
                    </InputLabel>
                    <Select
                      name="typeentite"
                      labelId="demo-simple-select-label"
                      value={values.typeentite}
                      label="Type entité"
                      onChange={handleChange}
                    >
                      <MenuItem value="">-</MenuItem>
                      <MenuItem value={"P"}>Personne Physique</MenuItem>
                      <MenuItem value={"M"}>Personne Morale</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Nom"
                    name="nom"
                    onChange={handleChange}
                    value={values.nom}
                    fullWidth
                    error={!!errors.nom}
                    helperText={errors.nom ? errors.nom : ""}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Prenom"
                    name="prenom"
                    onChange={handleChange}
                    value={values.prenom}
                    fullWidth
                    error={!!errors.prenom}
                    helperText={errors.prenom ? errors.prenom : ""}
                  />
                </Grid>
                {/* <Grid item xs={4}>
                  <TextField
                    label="CIN"
                    name="cin"
                    onChange={handleChange}
                    value={values.cin}
                    fullWidth
                    error={!!errors.cin}
                    helperText={errors.cin ? errors.cin : ""}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                    <Select
                      name="genre"
                      labelId="demo-simple-select-label"
                      value={values.genre}
                      label="Genre"
                      onChange={handleChange}
                    >
                      <MenuItem value={"H"}>H</MenuItem>
                      <MenuItem value={"F"}>F</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Abreviation"
                    name="abreviation"
                    onChange={handleChange}
                    value={values.abreviation}
                    fullWidth
                    error={!!errors.abreviation}
                    helperText={errors.abreviation ? errors.abreviation : ""}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Siege Social"
                    name="siegesocial"
                    onChange={handleChange}
                    value={values.siegesocial}
                    fullWidth
                    error={!!errors.siegesocial}
                    helperText={errors.siegesocial ? errors.siegesocial : ""}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Raison Sociale"
                    name="raisonsociale"
                    onChange={handleChange}
                    value={values.raisonsociale}
                    fullWidth
                    error={!!errors.raisonsociale}
                    helperText={
                      errors.raisonsociale ? errors.raisonsociale : ""
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Representant lLegal"
                    name="representantlegal"
                    onChange={handleChange}
                    value={values.representantlegal}
                    fullWidth
                    error={!!errors.representantlegal}
                    helperText={
                      errors.representantlegal ? errors.representantlegal : ""
                    }
                  />
                </Grid> */}
                <Grid item xs={12} className={styles.ButtonGrid}>
                  <Button
                    startIcon={loadingSend && <CircularProgress size={20} />}
                    disabled={loadingSend}
                    variant="contained"
                    type="submit"
                  >
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner />
      )}
    </>
  );
}
