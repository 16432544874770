import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface typeGarantieInterface {
  id_typegarantie: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtypeGarantieInterface {
  code: string;
  libelle: string;
}

const initialState = {
  typeGaranties: [],
  allTypeGaranties: [],
  typeGarantie: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const typeGarantieSlice = createSlice({
  name: "typeGarantie",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTypeGarantie(state, action) {
      state.typeGaranties = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTypeGarantieTrue(state, action) {
      state.allTypeGaranties = action.payload;
      state.error = "";
    },
    oneTypeGarantie(state, action) {
      state.typeGarantie = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTypeGarantie,
  allTypeGarantieTrue,
  oneTypeGarantie,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = typeGarantieSlice.actions;

export const getAllTypeGarantie =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.typeGarantieApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let typeGaranties: any;
      typeGaranties = page ? response.data : response.data.data;
      page
        ? dispatch(allTypeGarantie(typeGaranties))
        : dispatch(allTypeGarantieTrue(typeGaranties));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTypeGarantie = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.typeGarantieApi + "/" + id
    );
    let typeGarantie = response.data;
    dispatch(oneTypeGarantie(typeGarantie));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addTypeGarantie =
  (typeGarantie: NewtypeGarantieInterface) => async (dispatch: any) => {
    try {
      const newTypeGarantie = await axios.post(
        api.baseApi + api.typeGarantieApi,
        typeGarantie
      );
      return newTypeGarantie;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editTypeGarantie =
  (typeGarantie: typeGarantieInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.typeGarantieApi + "/" + id,
        typeGarantie
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default typeGarantieSlice.reducer;
