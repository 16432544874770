import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientInterface, getAllClient } from "../../redux/slices/clientSlice";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import { SelectChangeEvent } from "@mui/material/Select";
import TableSia from "../../components/TableSia";

const headSet = ["Nom et Prenom", "Type Entite", "Crée le"];

interface clientPaginationInterface {
  meta: Meta;
  data: clientInterface[];
}
const dataToShow = [
  {
    vkey: ["fullname"],
    type: "link",
  },
  {
    vkey: ["typeentite"],
    type: "string",
  },
];
export default function Clients() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [typeentite, setTypeentite] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cin, setCin] = useState("");
  const [raisonsociale, setRaisonsociale] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.client.errorListe);
  const loading = useSelector((state: any) => state.client.loadingListe);
  const clients: clientPaginationInterface = useSelector(
    (state: any) => state.client.clients
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllClient(1, typeentite, nom, prenom, cin, raisonsociale));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllClient(value, typeentite, nom, prenom, cin, raisonsociale));
  };
  const handleChangeTypeentite = (event: SelectChangeEvent) => {
    setTypeentite(event.target.value);
  };
  const handleChangeNom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNom(event.target.value);
  };
  const handleChangePrenom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrenom(event.target.value);
  };
  const handleChangeCin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCin(event.target.value);
  };
  const handleChangeRaisonsociale = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRaisonsociale(event.target.value);
  };
  const ResetFilters = () => {
    setTypeentite("");
    setNom("");
    setPrenom("");
    setCin("");
    setRaisonsociale("");
  };

  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllClient(1, typeentite, nom, prenom, cin, raisonsociale));
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [nom, prenom, cin, raisonsociale, typeentite]);

  return (
    <div>
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Clients</h2>
        <Link to="new" relative="path">
          + Ajouter Un Client
        </Link>
      </div>
      <BoxFilter
        boxDisplayed={["typeentite", "nom", "prenom"]}
        typeentite={typeentite}
        nom={nom}
        prenom={prenom}
        cin={cin}
        raisonsociale={raisonsociale}
        setTypeEntite={handleChangeTypeentite}
        setNom={handleChangeNom}
        setPrenom={handleChangePrenom}
        setCin={handleChangeCin}
        setRaisonsociale={handleChangeRaisonsociale}
        ResetFilters={ResetFilters}
        loading={loading}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={clients}
        page={page}
        id_type="id_client"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Client"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
