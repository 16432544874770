import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Formik } from "formik";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { editFactureActe } from "../../redux/slices/factureActeSlice";
import ActeModal from "../Modals/ActeModal";
import TvaModal from "../Modals/TvaModal";

const MySwal = withReactContent(Swal);

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleCloseUpdate: () => void;

  facture_id: string;
  tva_id: string;
  acte_id: string;
  quantite: number;
  datedesignation: string;
  prixunitaire: number;
  displayActe: string;
  displayTva: string;
  idEdit: string;
  regle: boolean;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};
interface MyFormValues {
  facture_id: string;
  tva_id: string;
  acte_id: string;
  quantite: number;
  regle: boolean;
  datedesignation: string;
  prixunitaire: number;
}

export default function FactureActeEditModal(props: ModalProps) {
  const [openActe, setOpenActe] = useState(false);
  const [displayActe, setDisplayActe] = useState(props.displayActe);

  const [openTva, setOpenTva] = useState(false);
  const [displayTva, setDisplayTva] = useState(props.displayTva);
  const dispatch = useDispatch<AppDispatch>();
  const initialValues: MyFormValues = {
    facture_id: props.facture_id,
    tva_id: props.tva_id,
    acte_id: props.acte_id,
    quantite: props.quantite,
    datedesignation: props.datedesignation,
    prixunitaire: props.prixunitaire,
    regle: props.regle,
  };
  const schema = yup.object({
    acte_id: yup.string().required("Acte est obligatoire"),
    tva_id: yup.string().required("Tva est obligatoire"),
    datedesignation: yup.string().required("Date Désignation est obligatoire"),
    //quantite: yup.number().required("Quantite est obligatoire"),
    prixunitaire: yup.number().required("Prix Unitaire est obligatoire"),
  });
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.TitleAdd}>
          <h2>Modifier Facture acte</h2>
        </div>
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={async (values) => {
            const bool = await dispatch(editFactureActe(values, props.idEdit));
            if (bool) {
              MySwal.fire({
                icon: "success",
                title: "Facture Acte est modifié avec succes",
                customClass: {
                  confirmButton: "shadow-none",
                },
              }).then((result) => {
                props.handleCloseUpdate && props.handleCloseUpdate();
                props.handleClose();
              });
            }
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* <Grid item xs={6}>
                  <TextField
                    label="Quantite"
                    name="quantite"
                    type="number"
                    onChange={handleChange}
                    value={values.quantite}
                    fullWidth
                    error={!!errors.quantite}
                    helperText={errors.quantite ? errors.quantite : ""}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    label="Prix Unitaire"
                    name="prixunitaire"
                    type="number"
                    onChange={handleChange}
                    value={values.prixunitaire}
                    fullWidth
                    error={!!errors.prixunitaire}
                    helperText={errors.prixunitaire ? errors.prixunitaire : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Date Désignation"
                    name="datedesignation"
                    onChange={handleChange}
                    value={values.datedesignation}
                    fullWidth
                    error={!!errors.datedesignation}
                    helperText={
                      errors.datedesignation ? errors.datedesignation : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    className={
                      !displayActe
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Acte
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayActe}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenActe(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Acte"
                    />
                  </FormControl>
                  {openActe && (
                    <ActeModal
                      open={openActe}
                      handleClose={() => setOpenActe(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("acte_id", value)
                      }
                      handleChangeDisplay={setDisplayActe}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    className={
                      !displayTva.toString()
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Tva
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayTva.toString()}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenTva(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Tva"
                    />
                  </FormControl>
                  {openTva && (
                    <TvaModal
                      open={openTva}
                      handleClose={() => setOpenTva(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("tva_id", value)
                      }
                      handleChangeDisplay={setDisplayTva}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 32 },
                        }}
                      />
                    }
                    label="Regle"
                    checked={values.regle}
                    onChange={() => {
                      setFieldValue("regle", !values.regle);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={styles.ButtonGrid}>
                  <Button variant="contained" type="submit">
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
