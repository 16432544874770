import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clientInterface, getAllClient } from "../../redux/slices/clientSlice";
import { AppDispatch } from "../../redux/store";
import { Table } from "react-bootstrap";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Pagination } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Meta } from "../../redux/interfaces";
import { theme } from "../../styles/global";
import Spinner from "../../components/Spinner";
import BoxFilter from "../../components/BoxFilter";
import { SelectChangeEvent } from "@mui/material/Select";
import TableSiaModal from "../TableSiaModal";

const headSet = ["Nom et Prenom", "type Entite", "Crée le"];

const style = {
  position: "absolute" as "absolute",
  top: "5%",
  left: "2%",
  width: "96%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "scroll",
};

interface clientPaginationInterface {
  meta: Meta;
  data: clientInterface[];
}
interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleChangeValue: (value: string) => void;
  handleChangeDisplay: (value: string) => void;
  handleChangeClient?: (value: any) => void;
}
const dataToShow = [
  {
    vkey: ["fullname"],
    type: "link",
  },
  {
    vkey: ["typeentite"],
    type: "string",
  },
];

export default function ClientModal(props: ModalProps) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [typeentite, setTypeentite] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [cin, setCin] = useState("");
  const [raisonsociale, setRaisonsociale] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.client.errorListe);
  const loading = useSelector((state: any) => state.client.loadingListe);
  const clients: clientPaginationInterface = useSelector(
    (state: any) => state.client.clients
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllClient(1, typeentite, nom, prenom, cin, raisonsociale));
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllClient(value, typeentite, nom, prenom, cin, raisonsociale));
  };
  const handleChangeTypeentite = (event: SelectChangeEvent) => {
    setTypeentite(event.target.value);
  };
  const handleChangeNom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNom(event.target.value);
  };
  const handleChangePrenom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrenom(event.target.value);
  };
  const handleChangeCin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCin(event.target.value);
  };
  const handleChangeRaisonsociale = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRaisonsociale(event.target.value);
  };
  const ResetFilters = () => {
    setTypeentite("");
    setNom("");
    setPrenom("");
    setCin("");
    setRaisonsociale("");
  };

  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllClient(1, typeentite, nom, prenom, cin, raisonsociale));
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [nom, prenom, cin, raisonsociale, typeentite]);

  const EntiteDisplay = (entite: string) => {
    switch (entite) {
      case "P":
        return "Personne Physique";
      case "M":
        return "Personne Morale";
    }
  };
  const LinkButton = (id: string, libelle: string, client: any) => {
    props.handleChangeValue(id);
    props.handleChangeDisplay(libelle);
    props.handleClose();
    props.handleChangeClient && props.handleChangeClient(client);
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <BoxFilter
          boxDisplayed={["typeentite", "nom", "prenom"]}
          typeentite={typeentite}
          nom={nom}
          prenom={prenom}
          cin={cin}
          raisonsociale={raisonsociale}
          setTypeEntite={handleChangeTypeentite}
          setNom={handleChangeNom}
          setPrenom={handleChangePrenom}
          setCin={handleChangeCin}
          setRaisonsociale={handleChangeRaisonsociale}
          ResetFilters={ResetFilters}
          loading={loading}
        />
        <TableSiaModal
          loading={loading}
          headSet={headSet}
          dataDisplay={clients}
          page={page}
          id_type="id_client"
          handleChange={handleChange}
          dataToShow={dataToShow}
          title="Client"
          LinkButton={LinkButton}
        />
      </Box>
    </Modal>
  );
}
