import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { factureInterface } from "./factureSlice";
import { acteInterface } from "./acteSlice";
import { tvaInterface } from "./tvaSlice";

export interface factureActeInterface {
  id_facture_acte: number;
  quantite: number;
  prixunitaire: number;
  facture_id: number;
  Facture: factureInterface;
  acte_id: number;
  Acte: acteInterface;
  tva_id: number;
  regle: boolean;
  Tva: tvaInterface;
  datedesignation: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewfactureActeInterface {
  quantite: number;
  prixunitaire: number;
  facture_id: string;
  acte_id: string;
  tva_id: string;
  datedesignation: string;
}

const initialState = {
  factureActes: [],
  allFactureActes: [],
  factureActe: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const factureActeSlice = createSlice({
  name: "factureActe",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allFactureActe(state, action) {
      state.factureActes = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allFactureActeTrue(state, action) {
      state.allFactureActes = action.payload;
      state.error = "";
    },
    oneFactureActe(state, action) {
      state.factureActe = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allFactureActe,
  allFactureActeTrue,
  oneFactureActe,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = factureActeSlice.actions;

export const getAllFactureActe =
  (page?: number, facture_id?: string, acte_id?: string, tva_id?: string) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.factureActeApi}${
          page ? "/?page=" + page : "/?all=true"
        }${facture_id ? "&facture_id=" + facture_id : ""}${
          acte_id ? "&acte_id=" + acte_id : ""
        }${tva_id ? "&tva_id=" + tva_id : ""}`
      );
      let factureActes: any;
      factureActes = page ? response.data : response.data.data;
      page
        ? dispatch(allFactureActe(factureActes))
        : dispatch(allFactureActeTrue(factureActes));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneFactureActe = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.factureActeApi + "/" + id
    );
    let factureActe = response.data;
    dispatch(oneFactureActe(factureActe));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addFactureActe =
  (factureActe: NewfactureActeInterface) => async (dispatch: any) => {
    try {
      const newfactureActe = await axios.post(
        api.baseApi + api.factureActeApi,
        factureActe
      );
      return newfactureActe;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editFactureActe =
  (factureActe: NewfactureActeInterface, id: string) =>
  async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.factureActeApi + "/" + id, factureActe);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const deleteFactureacte = (id: string) => async (dispatch: any) => {
  try {
    await axios.delete(api.baseApi + api.factureActeApi + "/" + id);
    return true;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export default factureActeSlice.reducer;
