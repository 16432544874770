import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import {
  jugementInterface,
  getAllJugement,
} from "../../redux/slices/jugementSlice";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableSia from "../../components/TableSia";
import axios from "axios";
import SpinnerPage from "../../components/SpinnerPage";
const MySwal = withReactContent(Swal);

const headSet = ["Procédure", "Type Jugement", "Date Jugement", "Crée le"];

interface jugementPaginationInterface {
  meta: Meta;
  data: jugementInterface[];
}
const dataToShow = [
  {
    vkey: ["Procedure", "reference"],
    type: "link",
  },
  {
    vkey: ["TypeJugement", "libelle"],
    type: "string",
  },
  {
    vkey: ["datejugement"],
    type: "date",
  },
];
export default function Jugements() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [reference, setReference] = useState("");
  const [numero, setNumero] = useState("");
  const [auprofitduclient, setAuprofitduclient] = useState("");
  const [datejugement, setDatejugement] = useState<Date | null | string>(null);
  const [datereception, setDatereception] = useState<Date | null | string>(
    null
  );
  const [procedure_id, setProcedure_id] = useState("");
  const [affaire_id, setAffaire_id] = useState("");
  const [typejugement_id, setTypejugement_id] = useState("");
  const [loadingOne, setLoadingOne] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.jugement.errorListe);
  const loading = useSelector((state: any) => state.jugement.loadingListe);
  const jugements: jugementPaginationInterface = useSelector(
    (state: any) => state.jugement.jugements
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllJugement(
        1,
        reference,
        numero,
        auprofitduclient,
        datejugement,
        datereception,
        procedure_id,
        typejugement_id,
        affaire_id
      )
    );
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllJugement(
        value,
        reference,
        numero,
        auprofitduclient,
        datejugement,
        datereception,
        procedure_id,
        typejugement_id,
        affaire_id
      )
    );
  };

  const handleChangeReference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReference(event.target.value);
  };
  const handleChangeNumero = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumero(event.target.value);
  };
  const handleChangeProcedureId = (value: string) => {
    setProcedure_id(value);
  };
  const handleChangeAffaireId = (value: string) => {
    setAffaire_id(value);
  };
  const handleChangeTypejugementId = (value: string) => {
    setTypejugement_id(value);
  };
  const handleChangeDatejugement = (date: Date | null | string) => {
    setDatejugement(date);
  };
  const handleChangeDatereception = (date: Date | null | string) => {
    setDatereception(date);
  };
  const handleChangeAufrofitCLient = (value: string) => {
    setAuprofitduclient(value);
  };
  const ResetFilters = () => {
    setReference("");
    setNumero("");
    setDatejugement(null);
    setDatereception(null);
    setAuprofitduclient("");
    setProcedure_id("");
    setAffaire_id("");
    setTypejugement_id("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllJugement(
            1,
            reference,
            numero,
            auprofitduclient,
            datejugement,
            datereception,
            procedure_id,
            typejugement_id,
            affaire_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [reference, numero, auprofitduclient, datejugement, datereception]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllJugement(
          1,
          reference,
          numero,
          auprofitduclient,
          datejugement,
          datereception,
          procedure_id,
          typejugement_id,
          affaire_id
        )
      );
    }
  }, [procedure_id, typejugement_id, affaire_id]);
  const handleExportData = () => {
    if (
      reference === "" &&
      numero === "" &&
      auprofitduclient === "" &&
      datejugement === null &&
      affaire_id === "" &&
      procedure_id === "" &&
      typejugement_id === ""
    ) {
      MySwal.fire({
        icon: "warning",
        title: "Choisir Au Moins Un Filtre",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      const auprofitduclientBool =
        auprofitduclient === ""
          ? null
          : auprofitduclient === "oui"
          ? true
          : false;
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/jugement/export?all=true${
            reference ? "&reference=" + reference : ""
          }${reference ? "&reference=" + reference : ""}${
            auprofitduclientBool !== null
              ? "&auprofitduclient=" + auprofitduclientBool
              : ""
          }${datejugement ? "&datejugement=" + datejugement : ""}${
            procedure_id ? "&procedure_id=" + procedure_id : ""
          }${typejugement_id ? "&typejugement_id=" + typejugement_id : ""}${
            affaire_id ? "&affaire_id=" + affaire_id : ""
          }`,
          {
            responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");
          const currentDate = new Date();
          const dateString = currentDate
            .toISOString()
            .slice(0, 19)
            .replace(/[-T:]/g, "");
          const filename = `jugements_${dateString}.xlsx`;
          link.href = url;
          link.download = filename; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoadingOne(false);
        })
        .catch((error) => {
          console.error("Error downloading Excel:", error);
        });
    }
  };
  return (
    <div>
      {loadingOne && <SpinnerPage />}
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Jugements</h2>
        <div></div>
        {/*<Link to="new" relative="path">
          + Ajouter Un Jugement
        </Link>*/}
      </div>
      <BoxFilter
        boxDisplayed={[
          "datejugement",
          "procedure",
          "affaire",
          "typejugement",
          "exportdata",
        ]}
        datejugement={datejugement}
        procedure_id={procedure_id}
        affaire_id={affaire_id}
        typejugement_id={typejugement_id}
        setDatejugement={handleChangeDatejugement}
        setProcedureid={handleChangeProcedureId}
        setAffaireid={handleChangeAffaireId}
        setTypejugementid={handleChangeTypejugementId}
        ResetFilters={ResetFilters}
        loading={loading}
        handleExport={handleExportData}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={jugements}
        page={page}
        id_type="id_jugement"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Jugement"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
