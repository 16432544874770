import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkIcon from "@mui/icons-material/Work";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GavelIcon from "@mui/icons-material/Gavel";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

interface MenuObject {
  name: string;
  link: string;
  logo: any;
}

const Menu: MenuObject[] = [
  {
    name: "Référentiel",
    link: "/referentiel",
    logo: <DashboardIcon />,
  },
  {
    name: "Affaires",
    link: "/affaires",
    logo: <WorkIcon />,
  },
  {
    name: "Procédure",
    link: "/procedures",
    logo: <AccountTreeIcon />,
  },
  {
    name: "Jugements",
    link: "/jugements",
    logo: <GavelIcon />,
  },
  {
    name: "Audiences",
    link: "/audiences",
    logo: <PeopleAltIcon />,
  },
  {
    name: "Factures",
    link: "/factures",
    logo: <InsertDriveFileIcon />,
  },
];

export default Menu;
