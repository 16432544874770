import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { genreAffaireInterface } from "./genreAffaireSlice";
import { clientInterface } from "./clientSlice";
import { adversaireInterface } from "./adversaireSlice";
import { accidentTravailInterface } from "./accidentTravailSlice";
import { accidentCirculationInterface } from "./accidentCirculationSlice";
import { vehiculeadversaireInterface } from "./vehiculeAdversaireSlice";
import { vehiculeclientInterface } from "./vehiculeClientSlice";

export interface affaireInterface {
  id_affaire: number;
  reference: string;
  referenceprovisoire: string;
  referencedefinitive: string;
  libelle: string;
  genreaffaire_id: string;
  GenreAffaire: genreAffaireInterface;
  clients: clientInterface[];
  adversaires: adversaireInterface[];
  accidenttravails: accidentTravailInterface[];
  accidentcirculations: accidentCirculationInterface[];
  vehiculeadversaires: vehiculeadversaireInterface[];
  vehiculeclients: vehiculeclientInterface[];
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewaffaireInterface {
  reference: string;
  referenceprovisoire: string;
  referencedefinitive: string;
  libelle: string;
  genreaffaire_id: string;
}

const initialState = {
  affaires: [],
  allAffaires: [],
  affaire: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const affaireSlice = createSlice({
  name: "affaire",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allAffaire(state, action) {
      state.affaires = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAffaireTrue(state, action) {
      state.allAffaires = action.payload;
      state.error = "";
    },
    oneAffaire(state, action) {
      state.affaire = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allAffaire,
  allAffaireTrue,
  oneAffaire,
  getError,
  setLoadingFalse,
  setLoadingOneFalse,
} = affaireSlice.actions;

export const getAllAffaire =
  (
    page?: number,
    reference?: string,
    referenceprovisoire?: string,
    referencedefinitive?: string,
    genreaffaire_id?: string,
    dateaccident?: Date | null | string,
    datejugement?: Date | null | string,
    dateaudience?: Date | null | string,
    numpoliceassurance?: string,
    procedure_id?: string,
    typetribunal_id?: string,
    siege_id?: string,
    matricule?: string,
    typejuridiction_id?: string,
    client_id?: string,
    adversaire_id?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.affaireApi}${
          page ? "/?page=" + page : "/?all=true"
        }${reference ? "&reference=" + reference : ""}${
          referenceprovisoire
            ? "&referenceprovisoire=" + referenceprovisoire
            : ""
        }${
          referencedefinitive
            ? "&referencedefinitive=" + referencedefinitive
            : ""
        }${genreaffaire_id ? "&genreaffaire_id=" + genreaffaire_id : ""}${
          dateaccident ? "&dateaccident=" + dateaccident : ""
        }${datejugement ? "&datejugement=" + datejugement : ""}${
          dateaudience ? "&dateaudience=" + dateaudience : ""
        }${
          numpoliceassurance ? "&numpoliceassurance=" + numpoliceassurance : ""
        }${procedure_id ? "&procedure_id=" + procedure_id : ""}${
          typetribunal_id ? "&typetribunal_id=" + typetribunal_id : ""
        }${siege_id ? "&siege_id=" + siege_id : ""}${
          matricule ? "&matricule=" + matricule : ""
        }${
          typejuridiction_id ? "&typejuridiction_id=" + typejuridiction_id : ""
        }${client_id ? "&client_id=" + client_id : ""}${adversaire_id ? "&adversaire_id=" + adversaire_id : ""}`
      );
      let affaires: any;
      affaires = page ? response.data : response.data.data;
      page
        ? dispatch(allAffaire(affaires))
        : dispatch(allAffaireTrue(affaires));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneAffaire = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.affaireApi + "/" + id);
    let affaire = response.data;
    dispatch(oneAffaire(affaire));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addAffaire =
  (affaire: NewaffaireInterface) => async (dispatch: any) => {
    try {
      const newAffaire = await axios.post(
        api.baseApi + api.affaireApi,
        affaire
      );
      return newAffaire;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editAffaire =
  (affaire: NewaffaireInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.affaireApi + "/" + id, affaire);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default affaireSlice.reducer;
