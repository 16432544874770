import { useEffect, useRef, useState } from "react";
import { Formik, FormikErrors, FormikProps } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";
import { addAccidentCirculation } from "../../redux/slices/accidentCirculationSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import AffaireModal from "../../components/Modals/AffaireModal";
import TypePoursuiteModal from "../../components/Modals/TypePoursuiteModal";
import TypeGarantieModal from "../../components/Modals/TypeGarantieModal";
import TypeGarantieCreateModal from "../../components/CreateModals/TypeGarantieCreateModal";
import TypePoursuiteCreateModal from "../../components/CreateModals/TypePoursuiteCreateModal";
import VehiculeModal from "../../components/Modals/VehiculeModal";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  affaireInterface,
  getOneAffaire,
} from "../../redux/slices/affaireSlice";
import { SelectChangeEvent } from "@mui/material/Select";
import VehiculeCreateModal from "../../components/CreateModals/VehiculeCreateModal";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  dateaccident: Date | string | null;
  dateparquet: Date | string | null;
  numpv: string;
  numparquet: string;
  observation: string;
  typepoursuite_id: string;
  affaire_id: string;
  typegarantie_id: string;
  vehiculeadversaires: AdversaireVehicule[];
  vehiculeclients: ClientVehicule[];
}

interface ClientVehicule {
  client_id: string;
  vehicule_id: string;
}

interface AdversaireVehicule {
  adversaire_id: string;
  vehicule_id: string;
}

export default function NewAccidentCirculation() {
  const [loadingSend, setLoadingSend] = useState(false);
  const formikRef = useRef<FormikProps<MyFormValues>>(null);

  const [openClient, setOpenClient] = useState([false]);
  const [displayClient, setDisplayClient] = useState([""]);

  const [openVehiculeClient, setOpenVehiculeClient] = useState([false]);
  const [openCreateVehiculeClient, setOpenCreateVehiculeClient] =
    useState(false);
  const [clientVehiculeIndex, setClientVehiculeIndex] = useState(0);
  const [displayVehiculeClient, setDisplayVehiculeClient] = useState([""]);

  const [openAdversaire, setOpenAdversaire] = useState([false]);
  const [openCreateVehiculeAdversaire, setOpenCreateVehiculeAdversaire] =
    useState(false);
  const [adversaireVehiculeIndex, setAdversaireVehiculeIndex] = useState(0);
  const [displayAdversaire, setDisplayAdversaire] = useState([""]);

  const [openVehiculeAdversaire, setOpenVehiculeAdversaire] = useState([false]);
  const [displayVehiculeAdversaire, setDisplayVehiculeAdversaire] = useState([
    "",
  ]);

  const [openCreateTypeGarantie, setOpenCreateTypeGarantie] = useState(false);
  const [openTypeGarantie, setOpenTypeGarantie] = useState(false);
  const [displayTypeGarantie, setDisplayTypeGarantie] = useState("");

  const [openCreateTypePoursuite, setOpenCreateTypePoursuite] = useState(false);
  const [openTypePoursuite, setOpenTypePoursuite] = useState(false);
  const [displayTypePoursuite, setDisplayTypePoursuite] = useState("");

  const [openAffaire, setOpenAffaire] = useState(false);
  const [displayAffaire, setDisplayAffaire] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const affaire: affaireInterface = useSelector(
    (state: any) => state.affaire.affaire
  );

  const initialValues: MyFormValues = {
    observation: "",
    numpv: "",
    numparquet: "",
    typepoursuite_id: "",
    affaire_id: "",
    typegarantie_id: "",
    dateaccident: null,
    dateparquet: null,
    vehiculeclients: [
      {
        client_id: "",
        vehicule_id: "",
      },
    ],
    vehiculeadversaires: [
      {
        adversaire_id: "",
        vehicule_id: "",
      },
    ],
  };
  const schema = yup.object({
    typepoursuite_id: yup.string().required("Type poursuite est obligatoire"),
    affaire_id: yup.string().required("Affaire est obligatoire"),
    typegarantie_id: yup.string().required("Type garantie est obligatoire"),
    dateaccident: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
    /*  dateparquet: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }), */
    vehiculeclients: yup
      .array()
      .of(
        yup.object().shape({
          client_id: yup.string().required("Client est obligatoire"),
          vehicule_id: yup.string().required("Vehicule est obligatoire"),
        })
      )
      .min(1, "Véhicule Client est obligatoire"),
    /* vehiculeadversaires: yup
      .array()
      .of(
        yup.object().shape({
          adversaire_id: yup.string().required("Adversaire est obligatoire"),
          vehicule_id: yup.string().required("Vehicule est obligatoire"),
        })
      )
      .min(1, "Véhicule Adversaire est obligatoire"), */
  });

  const handleRemoveClientVehicule = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    let arrayOpenClient = openClient.filter((ele, index) => index !== indexNum);
    let arrayDisplayClient = displayClient.filter(
      (ele, index) => index !== indexNum
    );
    let arrayOpenVehiculeClient = openVehiculeClient.filter(
      (ele, index) => index !== indexNum
    );
    let arrayDisplayVehiculeClient = displayVehiculeClient.filter(
      (ele, index) => index !== indexNum
    );

    const currentClientVehicule = values.vehiculeclients;
    const updatedClientVehicule = currentClientVehicule.filter(
      (ele, index) => index !== indexNum
    );

    setOpenClient(arrayOpenClient);
    setDisplayClient(arrayDisplayClient);
    setOpenVehiculeClient(arrayOpenVehiculeClient);
    setDisplayVehiculeClient(arrayDisplayVehiculeClient);
    setFieldValue("vehiculeclients", updatedClientVehicule);
  };

  const handleRemoveAdversaireVehicule = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    let arrayOpenAdversaire = openAdversaire.filter(
      (ele, index) => index !== indexNum
    );
    let arrayDisplayAdversaire = displayAdversaire.filter(
      (ele, index) => index !== indexNum
    );
    let arrayOpenVehiculeAdversaire = openVehiculeAdversaire.filter(
      (ele, index) => index !== indexNum
    );
    let arrayDisplayVehiculeAdversaire = displayVehiculeAdversaire.filter(
      (ele, index) => index !== indexNum
    );
    const currentAdversaireVehicule = values.vehiculeadversaires;
    const updatedAdversaireVehicule = currentAdversaireVehicule.filter(
      (ele, index) => index !== indexNum
    );
    setOpenAdversaire(arrayOpenAdversaire);
    setDisplayAdversaire(arrayDisplayAdversaire);
    setOpenVehiculeAdversaire(arrayOpenVehiculeAdversaire);
    setDisplayVehiculeAdversaire(arrayDisplayVehiculeAdversaire);
    setFieldValue("vehiculeadversaires", updatedAdversaireVehicule);
  };

  const handleAddClientVehicule = (
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    const newClientVehicule = {
      client_id: "",
      vehicule_id: "",
    };
    let arrayOpenClient = [...openClient, false];
    let arrayDisplayClient = [...displayClient, ""];
    let arrayOpenVehiculeClient = [...openVehiculeClient, false];
    let arrayDisplayVehiculeClient = [...displayVehiculeClient, ""];

    setOpenClient(arrayOpenClient);
    setDisplayClient(arrayDisplayClient);
    setOpenVehiculeClient(arrayOpenVehiculeClient);
    setDisplayVehiculeClient(arrayDisplayVehiculeClient);
    const currentClientVehicule = values.vehiculeclients;
    const updatedClientVehicule = [...currentClientVehicule, newClientVehicule];
    setFieldValue("vehiculeclients", updatedClientVehicule);
  };
  const handleAddAdversaireVehicule = (
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    const newAdversaireVehicule = {
      adversaire_id: "",
      vehicule_id: "",
    };
    let arrayOpenAdversaire = [...openAdversaire, false];
    let arrayDisplayAdversaire = [...displayAdversaire, ""];
    let arrayOpenVehiculeAdversaire = [...openVehiculeAdversaire, false];
    let arrayDisplayVehiculeAdversaire = [...displayVehiculeAdversaire, ""];

    setOpenAdversaire(arrayOpenAdversaire);
    setDisplayAdversaire(arrayDisplayAdversaire);
    setOpenVehiculeAdversaire(arrayOpenVehiculeAdversaire);
    setDisplayVehiculeAdversaire(arrayDisplayVehiculeAdversaire);

    const currentAdversaireVehicule = values.vehiculeadversaires;
    const updatedAdversaireVehicule = [
      ...currentAdversaireVehicule,
      newAdversaireVehicule,
    ];
    setFieldValue("vehiculeadversaires", updatedAdversaireVehicule);
  };

  useEffect(() => {
    const localStorageAffaire = localStorage.getItem("affaire");
    if (localStorageAffaire) {
      let affaireObject = JSON.parse(localStorageAffaire);
      if (formikRef.current) {
        dispatch(getOneAffaire(affaireObject.id_affaire));
        const updateValues = {
          ...formikRef.current.values,
          affaire_id: affaireObject.id_affaire,
        };
        formikRef.current.setValues(updateValues);
      }
      setDisplayAffaire(affaireObject.reference);
      //localStorage.removeItem("affaire");
    }
  }, []);

  useEffect(() => {
    const localStorageAffaire = localStorage.getItem("affaire");
    if (localStorageAffaire) {
      let affaireObject = JSON.parse(localStorageAffaire);
      if (formikRef.current) {
        dispatch(getOneAffaire(affaireObject.id_affaire));
        const updateValues = {
          ...formikRef.current.values,
          affaire_id: affaireObject.id_affaire,
        };
        formikRef.current.setValues(updateValues);
      }
      setDisplayAffaire(affaireObject.reference);
    } else {
      navigate("/affaires");
    }
    return () => {
      localStorage.removeItem("affaire");
    };
  }, []);

  return (
    <>
      <div className={styles.ReturnList}>
        <Link to="/accident-circulations" relative="path">
          <ArrowBackIosIcon /> Liste Accident Circulations
        </Link>
        <h2>Nouveau Accident Circulation</h2>
        <div />
      </div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const bool = await dispatch(addAccidentCirculation(values));
          if (bool) {
            MySwal.fire({
              icon: "success",
              title: "Accident Circulation est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              navigate("/accident-circulations");
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Observation"
                  name="observation"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.observation}
                  helperText={errors.observation ? errors.observation : ""}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  className={
                    !displayAffaire
                      ? styles.backgroudGrey
                      : styles.backgroudGrey2
                  }
                  fullWidth
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Affaire
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    disabled
                    fullWidth
                    value={displayAffaire}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setOpenAffaire(true)}
                          edge="end"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Affaire"
                  />
                </FormControl>
                {openAffaire && (
                  <AffaireModal
                    open={openAffaire}
                    handleClose={() => setOpenAffaire(false)}
                    handleChangeValue={(value) =>
                      setFieldValue("affaire_id", value)
                    }
                    handleChangeDisplay={setDisplayAffaire}
                  />
                )}
                <span className={styles.errorHandle}>
                  {errors.affaire_id ? errors.affaire_id : ""}
                </span>
              </Grid>
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayTypePoursuite
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Type Poursuite
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayTypePoursuite}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenTypePoursuite(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Type Poursuite"
                    />
                  </FormControl>
                  {openTypePoursuite && (
                    <TypePoursuiteModal
                      open={openTypePoursuite}
                      handleClose={() => setOpenTypePoursuite(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typepoursuite_id", value)
                      }
                      handleChangeDisplay={setDisplayTypePoursuite}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateTypePoursuite(true)}
                  >
                    +
                  </Button>
                  {openCreateTypePoursuite && (
                    <TypePoursuiteCreateModal
                      open={openCreateTypePoursuite}
                      handleClose={() => setOpenCreateTypePoursuite(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typepoursuite_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayTypePoursuite(value)
                      }
                    />
                  )}
                </Grid>
                <span className={styles.errorHandle}>
                  {errors.typepoursuite_id ? errors.typepoursuite_id : ""}
                </span>
              </Grid>

              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayTypeGarantie
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Type Garantie
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayTypeGarantie}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenTypeGarantie(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Type Garantie"
                    />
                  </FormControl>
                  {openTypeGarantie && (
                    <TypeGarantieModal
                      open={openTypeGarantie}
                      handleClose={() => setOpenTypeGarantie(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typegarantie_id", value)
                      }
                      handleChangeDisplay={setDisplayTypeGarantie}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateTypeGarantie(true)}
                  >
                    +
                  </Button>
                  {openCreateTypeGarantie && (
                    <TypeGarantieCreateModal
                      open={openCreateTypeGarantie}
                      handleClose={() => setOpenCreateTypeGarantie(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typegarantie_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayTypeGarantie(value)
                      }
                    />
                  )}
                </Grid>
                <span className={styles.errorHandle}>
                  {errors.typegarantie_id ? errors.typegarantie_id : ""}
                </span>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setFieldValue("dateaccident", date);
                    }}
                    value={moment(values.dateaccident)}
                    label="Date Accident"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.dateaccident,
                      },
                    }}
                  />
                </LocalizationProvider>
                {errors.dateaccident && (
                  <span className={styles.errorHandle}>
                    {errors.dateaccident}
                  </span>
                )}
              </Grid>
              {/* <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setFieldValue("dateparquet", date);
                    }}
                    value={moment(values.dateparquet)}
                    label="Date Parquet"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.dateparquet,
                      },
                    }}
                  />
                </LocalizationProvider>
                {errors.dateparquet && (
                  <span className={styles.errorHandle}>
                    {errors.dateparquet}
                  </span>
                )}
              </Grid> */}
              <Grid item xs={12} className={styles.ArrayInput}>
                {values.vehiculeclients.map((element, index) => {
                  return (
                    <Grid key={index} container spacing={2} className="mb-1">
                      <Grid item xs={2}>
                        <span className={styles.LabelArray}>
                          {index === 0 ? "Véhicule Client :" : ""}
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          className={styles.backgroudWhite}
                          fullWidth
                        >
                          <InputLabel id="demo-simple-select-label">
                            Client
                          </InputLabel>
                          <Select
                            name="client_id"
                            labelId="demo-simple-select-label"
                            label="Client"
                            onChange={(e: SelectChangeEvent) => {
                              const currentClientVehicule =
                                values.vehiculeclients;
                              const currentClient =
                                currentClientVehicule[index];
                              currentClient.client_id = e.target
                                .value as string;
                              const updatedClientVehicule = [
                                ...currentClientVehicule,
                              ];
                              setFieldValue(
                                "vehiculeclients",
                                updatedClientVehicule
                              );
                            }}
                          >
                            <MenuItem value="">-</MenuItem>
                            {affaire?.clients?.length > 0 &&
                              affaire.clients.map((client, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={client.Client?.id_client}
                                  >
                                    {!client.Client?.nom &&
                                    !client.Client?.prenom
                                      ? "-----"
                                      : ""}
                                    {client.Client?.nom
                                      ? client.Client?.nom + " "
                                      : "" + client.Client?.prenom
                                      ? client.Client?.prenom
                                      : ""}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container item xs={4}>
                        <Grid item xs={10}>
                          <FormControl
                            className={
                              !displayVehiculeClient[index]
                                ? styles.backgroudGrey
                                : styles.backgroudGrey2
                            }
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Vehicule
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              disabled
                              fullWidth
                              value={displayVehiculeClient[index]}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setOpenVehiculeClient((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = true;
                                        return newState;
                                      })
                                    }
                                    edge="end"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Vehicule"
                            />
                          </FormControl>
                          {openVehiculeClient[index] && (
                            <VehiculeModal
                              open={openVehiculeClient[index]}
                              handleClose={() =>
                                setOpenVehiculeClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                })
                              }
                              handleChangeValue={(value) => {
                                const currentClientVehicule =
                                  values.vehiculeclients;
                                const currentClient =
                                  currentClientVehicule[index];
                                currentClient.vehicule_id = value;
                                const updatedClientVehicule = [
                                  ...currentClientVehicule,
                                ];
                                setFieldValue(
                                  "vehiculeclients",
                                  updatedClientVehicule
                                );
                              }}
                              handleChangeDisplay={(value) =>
                                setDisplayVehiculeClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = value;
                                  return newState;
                                })
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            className={styles.AddButtonModal}
                            onClick={() => {
                              setClientVehiculeIndex(index);
                              setOpenCreateVehiculeClient(true);
                            }}
                          >
                            +
                          </Button>
                          {openCreateVehiculeClient && (
                            <VehiculeCreateModal
                              open={openCreateVehiculeClient}
                              handleClose={() =>
                                setOpenCreateVehiculeClient(false)
                              }
                              handleChangeValue={(value) => {
                                const currentClientVehicule =
                                  values.vehiculeclients;
                                const currentClient =
                                  currentClientVehicule[clientVehiculeIndex];
                                currentClient.vehicule_id = value;
                                const updatedClientVehicule = [
                                  ...currentClientVehicule,
                                ];
                                setFieldValue(
                                  "vehiculeclients",
                                  updatedClientVehicule
                                );
                              }}
                              handleChangeDisplay={(value) => {
                                setDisplayVehiculeClient((prevState) => {
                                  const newState = [...prevState];
                                  newState[clientVehiculeIndex] = value;
                                  return newState;
                                });
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <div className={styles.GroupeButton}>
                          {index === 0 ? (
                            <span className={styles.ButtonArray}>
                              <Button
                                onClick={() =>
                                  handleAddClientVehicule(values, setFieldValue)
                                }
                              >
                                <AddIcon />
                              </Button>
                            </span>
                          ) : (
                            ""
                          )}
                          {index !== 0 ? (
                            <span className={styles.ButtonDelete}>
                              <Button
                                onClick={() =>
                                  handleRemoveClientVehicule(
                                    index,
                                    values,
                                    setFieldValue
                                  )
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} className={styles.ArrayInput}>
                {values.vehiculeadversaires.map((element, index) => {
                  return (
                    <Grid key={index} container spacing={2} className="mb-1">
                      <Grid item xs={2}>
                        <span className={styles.LabelArray}>
                          {index === 0 ? "Véhicule Adversaire :" : ""}
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          className={styles.backgroudWhite}
                          fullWidth
                        >
                          <InputLabel id="demo-simple-select-label">
                            Adversaire
                          </InputLabel>
                          <Select
                            name="adversaire_id"
                            labelId="demo-simple-select-label"
                            label="Adversaire"
                            onChange={(e: SelectChangeEvent) => {
                              const currentAdversaireVehicule =
                                values.vehiculeadversaires;
                              const currentAdversaire =
                                currentAdversaireVehicule[index];
                              currentAdversaire.adversaire_id = e.target
                                .value as string;
                              const updatedAdversaireVehicule = [
                                ...currentAdversaireVehicule,
                              ];
                              setFieldValue(
                                "vehiculeadversaires",
                                updatedAdversaireVehicule
                              );
                            }}
                          >
                            <MenuItem value="">-</MenuItem>
                            {affaire?.adversaires?.length > 0 &&
                              affaire.adversaires.map((adversaire, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={adversaire.Adversaire?.id_adversaire}
                                  >
                                    {!adversaire.Adversaire?.nom &&
                                    !adversaire.Adversaire?.prenom
                                      ? "-----"
                                      : ""}
                                    {adversaire.Adversaire?.nom
                                      ? adversaire.Adversaire?.nom + " "
                                      : "" + adversaire.Adversaire?.prenom
                                      ? adversaire.Adversaire?.prenom
                                      : ""}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container item xs={4}>
                        <Grid item xs={10}>
                          <FormControl
                            className={
                              !displayVehiculeAdversaire[index]
                                ? styles.backgroudGrey
                                : styles.backgroudGrey2
                            }
                            fullWidth
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              Véhicule
                            </InputLabel>
                            <OutlinedInput
                              type="text"
                              disabled
                              fullWidth
                              value={displayVehiculeAdversaire[index]}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setOpenVehiculeAdversaire((prevState) => {
                                        const newState = [...prevState];
                                        newState[index] = true;
                                        return newState;
                                      })
                                    }
                                    edge="end"
                                  >
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Vehicule"
                            />
                          </FormControl>
                          {openVehiculeAdversaire[index] && (
                            <VehiculeModal
                              open={openVehiculeAdversaire[index]}
                              handleClose={() =>
                                setOpenVehiculeAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                })
                              }
                              handleChangeValue={(value) => {
                                const currentAdversaireVehicule =
                                  values.vehiculeadversaires;
                                const currentAdversaire =
                                  currentAdversaireVehicule[index];
                                currentAdversaire.vehicule_id = value;
                                const updatedAdversaireVehicule = [
                                  ...currentAdversaireVehicule,
                                ];
                                setFieldValue(
                                  "vehiculeadversaires",
                                  updatedAdversaireVehicule
                                );
                              }}
                              handleChangeDisplay={(value) =>
                                setDisplayVehiculeAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = value;
                                  return newState;
                                })
                              }
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            className={styles.AddButtonModal}
                            onClick={() => {
                              setAdversaireVehiculeIndex(index);
                              setOpenCreateVehiculeAdversaire(true);
                            }}
                          >
                            +
                          </Button>
                          {openCreateVehiculeAdversaire && (
                            <VehiculeCreateModal
                              open={openCreateVehiculeAdversaire}
                              handleClose={() =>
                                setOpenCreateVehiculeAdversaire(false)
                              }
                              handleChangeValue={(value) => {
                                const currentAdversaireVehicule =
                                  values.vehiculeadversaires;
                                const currentAdversaire =
                                  currentAdversaireVehicule[
                                    adversaireVehiculeIndex
                                  ];
                                currentAdversaire.vehicule_id = value;
                                const updatedAdversaireVehicule = [
                                  ...currentAdversaireVehicule,
                                ];
                                setFieldValue(
                                  "vehiculeadversaires",
                                  updatedAdversaireVehicule
                                );
                              }}
                              handleChangeDisplay={(value) => {
                                setDisplayVehiculeAdversaire((prevState) => {
                                  const newState = [...prevState];
                                  newState[adversaireVehiculeIndex] = value;
                                  return newState;
                                });
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <div className={styles.GroupeButton}>
                          {index === 0 ? (
                            <span className={styles.ButtonArray}>
                              <Button
                                onClick={() =>
                                  handleAddAdversaireVehicule(
                                    values,
                                    setFieldValue
                                  )
                                }
                              >
                                <AddIcon />
                              </Button>
                            </span>
                          ) : (
                            ""
                          )}
                          {index !== 0 ? (
                            <span className={styles.ButtonDelete}>
                              <Button
                                onClick={() =>
                                  handleRemoveAdversaireVehicule(
                                    index,
                                    values,
                                    setFieldValue
                                  )
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} className={styles.ButtonGrid}>
                <Button
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                  variant="contained"
                  type="submit"
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
