import React from "react";
import { audienceInterface } from "../../redux/slices/audienceSlice";
import { Button } from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import styles from "../../styles/Affaire.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

interface AudienceDetailInterface {
  audience: audienceInterface;
  setEditAudience: () => void;
}

export default function AudienceDetail({
  audience,
  setEditAudience,
}: AudienceDetailInterface) {
  return (
    <div>
      <div className={styles.ListeInformation}>
        <Button className={styles.ButtonEditInfo} onClick={setEditAudience}>
          <EditIcon />
        </Button>
        <div className={styles.TitleIcone}>
          <GavelIcon />
          <h2>
            <span>Audience : </span>
            {audience.reference}
          </h2>
        </div>
        <div className={styles.SupInfo}>
          <div></div>
          <div className={styles.ListeSupInfo}>
            <p>
              <span>Affaire : </span>
              <Link to={`/affaires/` + audience.Affaire?.id_affaire}>
                {audience.Affaire?.reference}
              </Link>
            </p>
            <p>
              <span>Procedure : </span>
              <Link to={`/procedures/` + audience.Procedure?.id_procedure}>
                {audience.Procedure?.reference}
              </Link>
            </p>
            <p>
              <span>Resultat Audience : </span>
              {audience.ResultatAudience?.libelle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
