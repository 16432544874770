import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface genreAffaireInterface {
  id_genreaffaire: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewgenreAffaireInterface {
  code: string;
  libelle: string;
}

const initialState = {
  genreAffaires: [],
  allGenreAffaires: [],
  genreAffaire: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const genreAffaireSlice = createSlice({
  name: "genreAffaire",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allGenreAffaire(state, action) {
      state.genreAffaires = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allGenreAffaireTrue(state, action) {
      state.allGenreAffaires = action.payload;
      state.error = "";
    },
    oneGenreAffaire(state, action) {
      state.genreAffaire = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allGenreAffaire,
  allGenreAffaireTrue,
  oneGenreAffaire,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = genreAffaireSlice.actions;

export const getAllGenreAffaire =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.genreAffaireApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let genreaffaires: any;
      genreaffaires = page ? response.data : response.data.data;
      page
        ? dispatch(allGenreAffaire(genreaffaires))
        : dispatch(allGenreAffaireTrue(genreaffaires));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneGenreAffaire = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.genreAffaireApi + "/" + id
    );
    let genreaffaire = response.data;
    dispatch(oneGenreAffaire(genreaffaire));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addGenreAffaire =
  (genreAffaire: NewgenreAffaireInterface) => async (dispatch: any) => {
    try {
      const newGenreAffaire = await axios.post(
        api.baseApi + api.genreAffaireApi,
        genreAffaire
      );
      return newGenreAffaire;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };
export const editGenreAffaire =
  (genreaffaire: genreAffaireInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.genreAffaireApi + "/" + id,
        genreaffaire
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default genreAffaireSlice.reducer;
