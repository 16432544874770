import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface situationInterface {
  id_situation: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewsituationInterface {
  code: string;
  libelle: string;
}

const initialState = {
  situations: [],
  allSituations: [],
  situation: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const situationSlice = createSlice({
  name: "situation",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allSituation(state, action) {
      state.situations = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allSituationTrue(state, action) {
      state.allSituations = action.payload;
      state.error = "";
    },
    oneSituation(state, action) {
      state.situation = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allSituation,
  allSituationTrue,
  oneSituation,
  getError,
  setLoadingFalse,
  setLoadingOneFalse,
} = situationSlice.actions;

export const getAllSituation =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.situationApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle && "&libelle=" + libelle}${code && "&code=" + code}`
      );
      let situations: any;
      situations = page ? response.data : response.data.data;
      page
        ? dispatch(allSituation(situations))
        : dispatch(allSituationTrue(situations));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneSituation = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.situationApi + "/" + id);
    let situation = response.data;
    dispatch(oneSituation(situation));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addSituation =
  (situation: NewsituationInterface) => async (dispatch: any) => {
    try {
      const NewSituation = await axios.post(
        api.baseApi + api.situationApi,
        situation
      );
      return NewSituation;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editSituation =
  (situation: situationInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.situationApi + "/" + id, situation);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default situationSlice.reducer;
