import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import {
  procedureInterface,
  getAllProcedure,
} from "../../redux/slices/procedureSlice";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import TableSia from "../../components/TableSia";

const headSet = ["Reference", "Affaire", "Type Procedure", "Crée le"];
interface procedurePaginationInterface {
  meta: Meta;
  data: procedureInterface[];
}
const dataToShow = [
  {
    vkey: ["reference"],
    type: "link",
  },
  {
    vkey: ["Affaire", "reference"],
    type: "string",
  },
  {
    vkey: ["TypeProcedure", "libelle"],
    type: "string",
  },
];
export default function Procedures() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [reference, setReference] = useState("");
  const [numero, setNumero] = useState("");
  const [observation, setObservation] = useState("");
  const [affaire_id, setAffaire_id] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [typeprocedure_id, setTypeProcedure_id] = useState("");
  const [libelleprocedure_id, setLibelleProcedure_id] = useState("");
  const [soustypeprocedure_id, setSousTypeProcedure_id] = useState("");
  const [typetribunal_id, setTypeTribunal_id] = useState("");
  const [typejuridiction_id, setTypeJuridiction_id] = useState("");
  const [siege_id, setSiege_id] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.procedure.errorListe);
  const loading = useSelector((state: any) => state.procedure.loadingListe);
  const procedures: procedurePaginationInterface = useSelector(
    (state: any) => state.procedure.procedures
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllProcedure(
        1,
        reference,
        observation,
        affaire_id,
        parent_id,
        typeprocedure_id,
        soustypeprocedure_id,
        typetribunal_id,
        typejuridiction_id,
        siege_id
      )
    );
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllProcedure(
        value,
        reference,
        numero,
        observation,
        affaire_id,
        parent_id,
        typeprocedure_id,
        libelleprocedure_id,
        soustypeprocedure_id,
        typetribunal_id,
        typejuridiction_id,
        siege_id
      )
    );
  };
  const handleChangeReference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReference(event.target.value);
  };

  const handleChangeObservation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setObservation(event.target.value);
  };

  const handleChangeAffaireId = (value: string) => {
    setAffaire_id(value);
  };

  const handleChangeParentId = (value: string) => {
    setParent_id(value);
  };

  const handleChangeTypeProcedureId = (value: string) => {
    setTypeProcedure_id(value);
  };

  const handleChangeLibelleProcedureId = (value: string) => {
    setLibelleProcedure_id(value);
  };

  const handleChangeSousTypeProcedureId = (value: string) => {
    setSousTypeProcedure_id(value);
  };

  const handleChangeTypeTribunalId = (value: string) => {
    setTypeTribunal_id(value);
  };

  const handleChangeTypeJuridictionId = (value: string) => {
    setTypeJuridiction_id(value);
  };

  const handleChangeSiegeId = (value: string) => {
    setSiege_id(value);
  };
  const ResetFilters = () => {
    setReference("");
    setNumero("");
    setObservation("");
    setAffaire_id("");
    setParent_id("");
    setLibelleProcedure_id("");
    setTypeProcedure_id("");
    setSousTypeProcedure_id("");
    setTypeTribunal_id("");
    setTypeJuridiction_id("");
    setSiege_id("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllProcedure(
            1,
            reference,
            numero,
            observation,
            affaire_id,
            parent_id,
            typeprocedure_id,
            libelleprocedure_id,
            soustypeprocedure_id,
            typetribunal_id,
            typejuridiction_id,
            siege_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [reference, numero, observation]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllProcedure(
          1,
          reference,
          numero,
          observation,
          affaire_id,
          parent_id,
          typeprocedure_id,
          libelleprocedure_id,
          soustypeprocedure_id,
          typetribunal_id,
          typejuridiction_id,
          siege_id
        )
      );
    }
  }, [
    affaire_id,
    parent_id,
    typeprocedure_id,
    libelleprocedure_id,
    soustypeprocedure_id,
    typetribunal_id,
    typejuridiction_id,
    siege_id,
  ]);
  return (
    <div>
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Procédures</h2>
        <div></div>
        {/*<Link to="new" relative="path">
          + Ajouter Une Procédure
        </Link>*/}
      </div>
      <BoxFilter
        boxDisplayed={[
          "reference",
          "affaire",
          "typetribunal",
          "typejuridiction",
          "siege",
          "typeprocedure",
          "soustypeprocedure",
          "parent",
        ]}
        reference={reference}
        observation={observation}
        affaire_id={affaire_id}
        typetribunal_id={typetribunal_id}
        typejuridiction_id={typejuridiction_id}
        ville_id={siege_id}
        typeprocedure_id={typeprocedure_id}
        soustypeprocedure_id={soustypeprocedure_id}
        procedure_id={parent_id}
        setReference={handleChangeReference}
        setObservation={handleChangeObservation}
        setAffaireid={handleChangeAffaireId}
        setTypetribunalid={handleChangeTypeTribunalId}
        setTypejuridictionid={handleChangeTypeJuridictionId}
        setVilleid={handleChangeSiegeId}
        setTypeprocedureid={handleChangeTypeProcedureId}
        setSoustypeprocedureid={handleChangeSousTypeProcedureId}
        setProcedureid={handleChangeParentId}
        ResetFilters={ResetFilters}
        loading={loading}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={procedures}
        page={page}
        id_type="id_procedure"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Procedure"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
