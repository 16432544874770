import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface sousTypeProcedureInterface {
  id_soustypeprocedure: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewsousTypeProcedureInterface {
  code: string;
  libelle: string;
}

const initialState = {
  sousTypeProcedures: [],
  allSousTypeProcedures: [],
  sousTypeProcedure: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const sousTypeProcedureSlice = createSlice({
  name: "sousTypeProcedure",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allSousTypeProcedure(state, action) {
      state.sousTypeProcedures = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allSousTypeProcedureTrue(state, action) {
      state.allSousTypeProcedures = action.payload;
      state.error = "";
    },
    oneSousTypeProcedure(state, action) {
      state.sousTypeProcedure = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allSousTypeProcedure,
  allSousTypeProcedureTrue,
  oneSousTypeProcedure,
  getError,
  setLoadingFalse,
  setLoadingOneFalse,
} = sousTypeProcedureSlice.actions;

export const getAllSousTypeProcedure =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.sousTypeProcedureApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let sousTypeProcedures: any;
      sousTypeProcedures = page ? response.data : response.data.data;
      page
        ? dispatch(allSousTypeProcedure(sousTypeProcedures))
        : dispatch(allSousTypeProcedureTrue(sousTypeProcedures));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneSousTypeProcedure =
  (id: number) => async (dispatch: any) => {
    try {
      dispatch(setLoadingOneFalse());
      const response = await axios.get(
        api.baseApi + api.sousTypeProcedureApi + "/" + id
      );
      let sousTypeProcedure = response.data;
      dispatch(oneSousTypeProcedure(sousTypeProcedure));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const addSousTypeProcedure =
  (sousTypeProcedure: NewsousTypeProcedureInterface) =>
  async (dispatch: any) => {
    try {
      const newSousTypeProcedure = await axios.post(
        api.baseApi + api.sousTypeProcedureApi,
        sousTypeProcedure
      );
      return newSousTypeProcedure;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editSousTypeProcedure =
  (sousTypeProcedure: sousTypeProcedureInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.sousTypeProcedureApi + "/" + id,
        sousTypeProcedure
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default sousTypeProcedureSlice.reducer;
