import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import {
  accidentCirculationInterface,
  getAllAccidentCirculation,
} from "../../redux/slices/accidentCirculationSlice";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import TableSia from "../../components/TableSia";

const headSet = [
  "Affaire",
  "date accident",
  "Type Poursuite",
  "Type Garantie",
  "Crée le",
];
const dataToShow = [
  {
    vkey: ["Affaire", "reference"],
    type: "link",
  },
  {
    vkey: ["dateaccident"],
    type: "date",
  },
  {
    vkey: ["TypePoursuite", "libelle"],
    type: "string",
  },
  {
    vkey: ["TypeGarantie", "libelle"],
    type: "string",
  },
];
interface accidentCirculationPaginationInterface {
  meta: Meta;
  data: accidentCirculationInterface[];
}
export default function AccidentCirculations() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [dateaccident, setDateaccident] = useState<Date | null | string>(null);
  const [dateparquet, setDateparquet] = useState<Date | null | string>(null);
  const [numpv, setNumpv] = useState("");
  const [numparquet, setNumparquet] = useState("");
  const [typepoursuite_id, setTypepoursuiteid] = useState("");
  const [affaire_id, setaffaireid] = useState("");
  const [typegarantie_id, setTypegarantieid] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector(
    (state: any) => state.accidentCirculation.errorListe
  );
  const loading = useSelector(
    (state: any) => state.accidentCirculation.loadingListe
  );
  const accidentCirculations: accidentCirculationPaginationInterface =
    useSelector((state: any) => state.accidentCirculation.accidentCirculations);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllAccidentCirculation(
        1,
        dateaccident,
        dateparquet,
        numpv,
        numparquet,
        typepoursuite_id,
        affaire_id,
        typegarantie_id
      )
    );
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllAccidentCirculation(
        value,
        dateaccident,
        dateparquet,
        numpv,
        numparquet,
        typepoursuite_id,
        affaire_id,
        typegarantie_id
      )
    );
  };
  const handleChangeNumpv = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumpv(event.target.value);
  };
  const handleChangeNumparquet = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNumparquet(event.target.value);
  };
  const handleChangeDateaccident = (date: Date | null | string) => {
    setDateaccident(date);
  };
  const handleChangeDateparquet = (date: Date | null | string) => {
    setDateparquet(date);
  };
  const handleChangeAffaireId = (value: string) => {
    setaffaireid(value);
  };
  const handleChangeTypeGarantieId = (value: string) => {
    setTypegarantieid(value);
  };
  const handleChangeTypePoursuite = (value: string) => {
    setTypepoursuiteid(value);
  };
  const ResetFilters = () => {
    setDateaccident(null);
    setDateparquet(null);
    setNumpv("");
    setNumparquet("");
    setTypepoursuiteid("");
    setaffaireid("");
    setTypegarantieid("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllAccidentCirculation(
            1,
            dateaccident,
            dateparquet,
            numpv,
            numparquet,
            typepoursuite_id,
            affaire_id,
            typegarantie_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [dateaccident, dateparquet, numpv, numparquet]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllAccidentCirculation(
          1,
          dateaccident,
          dateparquet,
          numpv,
          numparquet,
          typepoursuite_id,
          affaire_id,
          typegarantie_id
        )
      );
    }
  }, [typepoursuite_id, affaire_id, typegarantie_id]);
  return (
    <div>
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Accidents Circulations</h2>
        <div></div>
      </div>
      <BoxFilter
        boxDisplayed={[
          "dateaccident",
          "numpv",
          "numparquet",
          "affaire",
          "typegarantie",
          "typepoursuite",
        ]}
        dateaccident={dateaccident}
        numpv={numpv}
        numpoliceassurance={numparquet}
        affaire_id={affaire_id}
        typegarantie_id={typegarantie_id}
        typepoursuite_id={typepoursuite_id}
        setDateaccident={handleChangeDateaccident}
        setNumpv={handleChangeNumpv}
        setNumparquet={handleChangeNumparquet}
        setAffaireid={handleChangeAffaireId}
        setTypegarantieid={handleChangeTypeGarantieId}
        setTypepoursuiteid={handleChangeTypePoursuite}
        ResetFilters={ResetFilters}
        loading={loading}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={accidentCirculations}
        page={page}
        id_type="id_accidentcirculation"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Accident de Circulation"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
