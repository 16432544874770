import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { clientInterface } from "./clientSlice";
import { situationInterface } from "./situationSlice";
import { affaireInterface } from "./affaireSlice";

export interface affaireclientInterface {
  id_affaire_client: number;
  code: string;
  client_id: number;
  Client: clientInterface;
  affaire_id: number;
  Affaire: affaireInterface;
  situation_id: number;
  Situation: situationInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewaffaireclientInterface {
  code: string;
  client_id: string;
  affaire_id: string;
  situation_id: string;
}

const initialState = {
  affaireclients: [],
  allAffaireclients: [],
  affaireclient: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const affaireclientSlice = createSlice({
  name: "affaireclient",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allAffaireclient(state, action) {
      state.affaireclients = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAffaireclientTrue(state, action) {
      state.allAffaireclients = action.payload;
      state.error = "";
    },
    oneAffaireclient(state, action) {
      state.affaireclient = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allAffaireclient,
  allAffaireclientTrue,
  oneAffaireclient,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = affaireclientSlice.actions;

export const getAllAffaireclient =
  (
    page?: number,
    code?: string,
    client_id?: number,
    affaire_id?: number,
    situation_id?: number
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.affaireclientApi}${
          page ? "/?page=" + page : "/?all=true"
        }${code ? "&code=" + code : ""}${
          client_id ? "&client_id=" + client_id : ""
        }${affaire_id ? "&affaire_id=" + affaire_id : ""}${
          situation_id ? "&situation_id=" + situation_id : ""
        }`
      );
      let affaireclients: any;
      affaireclients = page ? response.data : response.data.data;
      page
        ? dispatch(allAffaireclient(affaireclients))
        : dispatch(allAffaireclientTrue(affaireclients));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneAffaireclient = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.affaireclientApi + "/" + id
    );
    let affaireclient = response.data;
    dispatch(oneAffaireclient(affaireclient));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addAffaireclient =
  (affaireclient: NewaffaireclientInterface) => async (dispatch: any) => {
    try {
      const newAffaireclient = await axios.post(
        api.baseApi + api.affaireclientApi,
        affaireclient
      );
      return newAffaireclient;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editAffaireclient =
  (affaireclient: NewaffaireclientInterface, id: string) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.affaireclientApi + "/" + id,
        affaireclient
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const deleteAffaireclient = (id: string) => async (dispatch: any) => {
  try {
    await axios.delete(api.baseApi + api.affaireclientApi + "/" + id);
    return true;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export default affaireclientSlice.reducer;
