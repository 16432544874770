import { useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as yup from "yup";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import TypeAccidentTravailModal from "../../components/Modals/TypeAccidentTravailModal";
import SearchIcon from "@mui/icons-material/Search";
import TypeAccidentTravailCreateModal from "../../components/CreateModals/TypeAccidentTravailCreateModal";
import { addAffaire } from "../../redux/slices/affaireSlice";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  observation: string;
  reference: string;
  dateaccident: Date | string | null;
  pourcentageassurance: string;
  numpoliceassurance: string;
  typeaccidenttravail_id: string;
}

interface interfaceProps {
  IsSubmited: any;
  formikRef: React.RefObject<FormikProps<MyFormValues>>;
  setLoadingSend: (value: boolean) => void;
}

export default function NewAccidentTravailAffaire({
  IsSubmited,
  formikRef,
  setLoadingSend,
}: interfaceProps) {
  const [openCreateTypeAccidentTravail, setOpenCreateTypeAccidentTravail] =
    useState(false);
  const [openTypeAccidentTravail, setOpenTypeAccidentTravail] = useState(false);
  const [displayTypeAccidentTravail, setDisplayTypeAccidentTravail] =
    useState("");

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const initialValues: MyFormValues = {
    reference: "",
    observation: "",
    pourcentageassurance: "",
    numpoliceassurance: "",
    typeaccidenttravail_id: "",
    dateaccident: null,
  };
  const schema = yup.object({
    numpoliceassurance: yup
      .string()
      .required("Num police assurance est obligatoire"),
    typeaccidenttravail_id: yup
      .string()
      .required("Type accident travail est obligatoire"),
    dateaccident: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
  });

  return (
    <>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          let dataToSend = IsSubmited;
          dataToSend.accidenttravail = values;
          const affaire = await dispatch(addAffaire(dataToSend));
          if (affaire?.status) {
            MySwal.fire({
              icon: "success",
              title: "Affaire est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              navigate("/affaires/" + affaire.data.id_affaire);
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form className="mt-3" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Num police Assurance"
                  name="numpoliceassurance"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.numpoliceassurance}
                  helperText={
                    errors.numpoliceassurance ? errors.numpoliceassurance : ""
                  }
                />
              </Grid>
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayTypeAccidentTravail
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Type Accident Travail
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayTypeAccidentTravail}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenTypeAccidentTravail(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Type Accident Travail"
                    />
                  </FormControl>
                  {openTypeAccidentTravail && (
                    <TypeAccidentTravailModal
                      open={openTypeAccidentTravail}
                      handleClose={() => setOpenTypeAccidentTravail(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typeaccidenttravail_id", value)
                      }
                      handleChangeDisplay={setDisplayTypeAccidentTravail}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateTypeAccidentTravail(true)}
                  >
                    +
                  </Button>
                  {openCreateTypeAccidentTravail && (
                    <TypeAccidentTravailCreateModal
                      open={openCreateTypeAccidentTravail}
                      handleClose={() =>
                        setOpenCreateTypeAccidentTravail(false)
                      }
                      handleChangeValue={(value) =>
                        setFieldValue("typeaccidenttravail_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayTypeAccidentTravail(value)
                      }
                    />
                  )}
                </Grid>
                <span className={styles.errorHandle}>
                  {errors.typeaccidenttravail_id
                    ? errors.typeaccidenttravail_id
                    : ""}
                </span>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      const date = moment(value).format("YYYY-MM-DD");
                      setFieldValue("dateaccident", date);
                    }}
                    value={moment(values.dateaccident)}
                    label="Date Accident"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.dateaccident,
                      },
                    }}
                  />
                </LocalizationProvider>
                {errors.dateaccident && (
                  <span className={styles.errorHandle}>
                    {errors.dateaccident}
                  </span>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observation"
                  name="observation"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.observation}
                  helperText={errors.observation ? errors.observation : ""}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
