import React from "react";
import styles from "../styles/Dashboard.module.scss";
import { Link } from "react-router-dom";
import Menu from "../components/SubMenuArray";

export default function Dashboard() {
  return (
    <div className={styles.ListeLink}>
      {Menu.map((linkmenu, index) => {
        return (
          <Link
            key={index}
            className={styles.Link}
            tabIndex={index}
            to={linkmenu.link}
          >
            <span>{linkmenu.name}</span>
          </Link>
        );
      })}
    </div>
  );
}
