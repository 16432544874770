import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface villeInterface {
  id_ville: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewvilleInterface {
  code: string;
  libelle: string;
}

const initialState = {
  villes: [],
  allVilles: [],
  ville: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const villeSlice = createSlice({
  name: "ville",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allVille(state, action) {
      state.villes = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allVilleTrue(state, action) {
      state.allVilles = action.payload;
      state.error = "";
    },
    oneVille(state, action) {
      state.ville = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allVille,
  allVilleTrue,
  oneVille,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = villeSlice.actions;

export const getAllVille =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.villeApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle && "&libelle=" + libelle}${code && "&code=" + code}`
      );
      let villes: any;
      villes = page ? response.data : response.data.data;
      page ? dispatch(allVille(villes)) : dispatch(allVilleTrue(villes));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneVille = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.villeApi + "/" + id);
    let ville = response.data;
    dispatch(oneVille(ville));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addVille = (ville: NewvilleInterface) => async (dispatch: any) => {
  try {
    const newVille = await axios.post(api.baseApi + api.villeApi, ville);
    return newVille;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const editVille =
  (ville: villeInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.villeApi + "/" + id, ville);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default villeSlice.reducer;
