import { useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";
import { addVehicule } from "../../redux/slices/vehiculeSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { New } from "../../redux/interfaces";
import SearchIcon from "@mui/icons-material/Search";
import TypeVehiculeModal from "../../components/Modals/TypeVehiculeModal";
import TypeVehiculeCreateModal from "../../components/CreateModals/TypeVehiculeCreateModal";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  code: string;
  numpoliceassurance: string;
  appartenance: string;
  matricul0: string;
  matricul1: string;
  matricul2: string;
  matricul3: string;
  matricul4: string;
  observation: string;
  typevehicule_id: string;
}

export default function NewVehicule(props: New) {
  const [loadingSend, setLoadingSend] = useState(false);
  const formikRef = useRef<FormikProps<MyFormValues>>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [openTypeVehicule, setOpenTypeVehicule] = useState(false);
  const [openCreateTypeVehicule, setOpenCreateTypeVehicule] = useState(false);
  const [displayTypeVehicule, setDisplayTypeVehicule] = useState("");

  const initialValues: MyFormValues = {
    code: "",
    numpoliceassurance: "",
    appartenance: "",
    matricul0: "",
    matricul1: "-",
    matricul2: "",
    matricul3: "-",
    matricul4: "",
    observation: "",
    typevehicule_id: "",
  };
  const schema = yup.object({
    numpoliceassurance: yup
      .string()
      .required("Num Police Assurance est obligatoire"),
    //appartenance: yup.string().required("Appartenance est obligatoire"),
    matricul0: yup.string().required("Matricul 0 est obligatoire"),
    matricul1: yup.string().required("Matricul 1 est obligatoire"),
    matricul2: yup.string().required("Matricul 2 est obligatoire"),
    matricul3: yup.string().required("Matricul 3 est obligatoire"),
    matricul4: yup.string().required("Matricul 4 est obligatoire"),
    //observation: yup.string().required("Observation est obligatoire"),
    typevehicule_id: yup.string().required("Type vehicule est obligatoire"),
  });
  return (
    <>
      {!props.modal ? (
        <div className={styles.ReturnList}>
          <Link to="/vehicules" relative="path">
            <ArrowBackIosIcon /> Liste des Vehicules
          </Link>
          <h2>Nouvelle Véhicule</h2>
          <div />
        </div>
      ) : (
        <div className={styles.TitleAdd}>
          <h2>Nouvelle Véhicule</h2>
        </div>
      )}
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const vehicule = await dispatch(addVehicule(values));
          if (vehicule?.status) {
            MySwal.fire({
              icon: "success",
              title: "Véhicule est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              props.handleClose
                ? props.handleClose(
                    vehicule?.data.id_vehicule,
                    vehicule?.data.numpoliceassurance
                  )
                : navigate("/vehicules");
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* <Grid item xs={4}>
                <TextField
                  label="Code"
                  name="code"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.code}
                  helperText={errors.code ? errors.code : ""}
                />
              </Grid> */}
              {/* <Grid item xs={4}>
                <FormControl className={styles.backgroudWhite} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Appartenance
                  </InputLabel>
                  <Select
                    name="typeentite"
                    labelId="demo-simple-select-label"
                    value={values.appartenance}
                    label="Appartenance"
                    onChange={handleChange}
                  >
                    <MenuItem value={"A"}>A</MenuItem>
                    <MenuItem value={"C"}>C</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={4}>
                <TextField
                  label="Num Police Assurance"
                  name="numpoliceassurance"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.numpoliceassurance}
                  helperText={
                    errors.numpoliceassurance ? errors.numpoliceassurance : ""
                  }
                />
              </Grid>
              <Grid container item spacing={1} xs={8}>
                <Grid item xs={4}>
                  <TextField
                    label="Matricul 0"
                    name="matricul0"
                    onChange={handleChange}
                    value={values.matricul0}
                    fullWidth
                    error={!!errors.matricul0}
                    helperText={errors.matricul0 ? errors.matricul0 : ""}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    label="Matricul 1"
                    name="matricul1"
                    onChange={handleChange}
                    value={values.matricul1}
                    fullWidth
                    error={!!errors.matricul1}
                    helperText={errors.matricul1 ? errors.matricul1 : ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Matricul 2"
                    name="matricul2"
                    onChange={handleChange}
                    value={values.matricul2}
                    fullWidth
                    error={!!errors.matricul2}
                    helperText={errors.matricul2 ? errors.matricul2 : ""}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    label="Matricul 3"
                    name="matricul3"
                    onChange={handleChange}
                    value={values.matricul3}
                    fullWidth
                    error={!!errors.matricul3}
                    helperText={errors.matricul3 ? errors.matricul3 : ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Matricul 4"
                    name="matricul4"
                    onChange={handleChange}
                    value={values.matricul4}
                    fullWidth
                    error={!!errors.matricul4}
                    helperText={errors.matricul4 ? errors.matricul4 : ""}
                  />
                </Grid>
              </Grid>
              {/* <Grid item xs={4}>
                <TextField
                  label="Observation"
                  name="observation"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.observation}
                  helperText={errors.observation ? errors.observation : ""}
                />
              </Grid> */}
              <Grid container item xs={4}>
                <Grid item xs={10}>
                  <FormControl
                    className={
                      !displayTypeVehicule
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Type Vehicule
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayTypeVehicule}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenTypeVehicule(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Affaire"
                    />
                  </FormControl>
                  {openTypeVehicule && (
                    <TypeVehiculeModal
                      open={openTypeVehicule}
                      handleClose={() => setOpenTypeVehicule(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typevehicule_id", value)
                      }
                      handleChangeDisplay={setDisplayTypeVehicule}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={styles.AddButtonModal}
                    onClick={() => setOpenCreateTypeVehicule(true)}
                  >
                    +
                  </Button>
                  {openCreateTypeVehicule && (
                    <TypeVehiculeCreateModal
                      open={openCreateTypeVehicule}
                      handleClose={() => setOpenCreateTypeVehicule(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("typevehicule_id", value)
                      }
                      handleChangeDisplay={(value) =>
                        setDisplayTypeVehicule(value)
                      }
                    />
                  )}
                </Grid>
                {errors.typevehicule_id && (
                  <span className={styles.errorHandle}>
                    {errors.typevehicule_id}
                  </span>
                )}
              </Grid>
              <Grid item xs={12} className={styles.ButtonGrid}>
                <Button
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                  variant="contained"
                  type="submit"
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
