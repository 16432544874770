import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface acteInterface {
  id_acte: number;
  code: string;
  libelle: string;
  typeacte: string;
  classement: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewacteInterface {
  code: string;
  libelle: string;
  classement: string;
}

const initialState = {
  actes: [],
  allActes: [],
  acte: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const acteSlice = createSlice({
  name: "acte",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allActe(state, action) {
      state.actes = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allActeTrue(state, action) {
      state.allActes = action.payload;
      state.error = "";
    },
    oneActe(state, action) {
      state.acte = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allActe,
  allActeTrue,
  oneActe,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = acteSlice.actions;

export const getAllActe =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.acteApi}${page ? "/?page=" + page : "/?all=true"}${
          libelle && "&libelle=" + libelle
        }${code && "&code=" + code}`
      );
      let actes: any;
      actes = page ? response.data : response.data.data;
      page ? dispatch(allActe(actes)) : dispatch(allActeTrue(actes));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneActe = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.acteApi + "/" + id);
    let acte = response.data;
    dispatch(oneActe(acte));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addActe = (acte: NewacteInterface) => async (dispatch: any) => {
  try {
    const newacte = await axios.post(api.baseApi + api.acteApi, acte);
    return newacte;
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const editActe =
  (acte: acteInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.acteApi + "/" + id, acte);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default acteSlice.reducer;
