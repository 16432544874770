import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface typeAccidentTravailInterface {
  id_typeaccidenttravail: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtypeAccidentTravailInterface {
  code: string;
  libelle: string;
}

const initialState = {
  typeAccidentTravails: [],
  allTypeAccidentTravails: [],
  typeAccidentTravail: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const typeAccidentTravailSlice = createSlice({
  name: "typeAccidentTravail",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTypeAccidentTravail(state, action) {
      state.typeAccidentTravails = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTypeAccidentTravailTrue(state, action) {
      state.allTypeAccidentTravails = action.payload;
      state.error = "";
    },
    oneTypeAccidentTravail(state, action) {
      state.typeAccidentTravail = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTypeAccidentTravail,
  allTypeAccidentTravailTrue,
  oneTypeAccidentTravail,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = typeAccidentTravailSlice.actions;

export const getAllTypeAccidentTravail =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.typeAccidentTravailApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let typeAccidentTravails: any;
      typeAccidentTravails = page ? response.data : response.data.data;
      page
        ? dispatch(allTypeAccidentTravail(typeAccidentTravails))
        : dispatch(allTypeAccidentTravailTrue(typeAccidentTravails));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTypeAccidentTravail =
  (id: number) => async (dispatch: any) => {
    try {
      dispatch(setLoadingOneFalse());
      const response = await axios.get(
        api.baseApi + api.typeAccidentTravailApi + "/" + id
      );
      let typeAccidentTravail = response.data;
      dispatch(oneTypeAccidentTravail(typeAccidentTravail));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const addTypeAccidentTravail =
  (typeAccidentTravail: NewtypeAccidentTravailInterface) =>
  async (dispatch: any) => {
    try {
      const newTypeAccidentTravail = await axios.post(
        api.baseApi + api.typeAccidentTravailApi,
        typeAccidentTravail
      );
      return newTypeAccidentTravail;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editTypeAccidentTravail =
  (typeAccidentTravail: typeAccidentTravailInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.typeAccidentTravailApi + "/" + id,
        typeAccidentTravail
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default typeAccidentTravailSlice.reducer;
