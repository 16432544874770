import React from "react";
import { jugementInterface } from "../../redux/slices/jugementSlice";
import { Button } from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import styles from "../../styles/Affaire.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

interface JugementDetailInterface {
  jugement: jugementInterface;
  setEditJugement: () => void;
}

export default function JugementDetail({
  jugement,
  setEditJugement,
}: JugementDetailInterface) {
  return (
    <div>
      <div className={styles.ListeInformation}>
        <Button className={styles.ButtonEditInfo} onClick={setEditJugement}>
          <EditIcon />
        </Button>
        <div className={styles.TitleIcone}>
          <GavelIcon />
          <h2>
            <span>Jugement : </span>
            {jugement.reference}
          </h2>
        </div>
        <div className={styles.SupInfo}>
          <div></div>
          <div className={styles.ListeSupInfo}>
            <p>
              <span>Affaire : </span>
              <Link to={`/affaires/` + jugement.Affaire?.id_affaire}>
                {jugement.Affaire?.reference}
              </Link>
            </p>
            <p>
              <span>Procedure : </span>
              <Link to={`/procedures/` + jugement.Procedure?.id_procedure}>
                {jugement.Procedure?.reference}
              </Link>
            </p>
            <p>
              <span>Type Jugement : </span>
              {jugement.TypeJugement?.libelle}
            </p>
            <p>
              <span>Amende : </span>
              {jugement.amende} Dhs
            </p>
            <p>
              <span>Indemnité : </span>
              {jugement.indemnite} Dhs
            </p>
            <p>
              <span>Nbr Mois prison : </span>
              {jugement.nbmoisprison ? jugement.nbmoisprison : "-----"}
            </p>
            {jugement.prisonferme !== null && (
              <p>
                <span>Prison Ferme : </span>
                {jugement.prisonferme ? "Oui" : "Non"}
              </p>
            )}
          </div>
        </div>
        <p className={styles.Texte}>
          <span>Texte : </span>
          {jugement.texte ? jugement.texte : "-----"}
        </p>
      </div>
    </div>
  );
}
