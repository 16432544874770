import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface typeJuridictionInterface {
  id_typejuridiction: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtypeJuridictionInterface {
  code: string;
  libelle: string;
}

const initialState = {
  typeJuridictions: [],
  allTypeJuridictions: [],
  typeJuridiction: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const typeJuridictionSlice = createSlice({
  name: "typeJuridiction",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTypeJuridiction(state, action) {
      state.typeJuridictions = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTypeJuridictionTrue(state, action) {
      state.allTypeJuridictions = action.payload;
      state.error = "";
    },
    oneTypeJuridiction(state, action) {
      state.typeJuridiction = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTypeJuridiction,
  allTypeJuridictionTrue,
  oneTypeJuridiction,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = typeJuridictionSlice.actions;

export const getAllTypeJuridiction =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.typeJuridictionApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let typeJuridictions: any;
      typeJuridictions = page ? response.data : response.data.data;
      page
        ? dispatch(allTypeJuridiction(typeJuridictions))
        : dispatch(allTypeJuridictionTrue(typeJuridictions));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTypeJuridiction = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.typeJuridictionApi + "/" + id
    );
    let typeJuridiction = response.data;
    dispatch(oneTypeJuridiction(typeJuridiction));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addTypeJuridiction =
  (typeJuridiction: NewtypeJuridictionInterface) => async (dispatch: any) => {
    try {
      const newTypeJuridiction = await axios.post(
        api.baseApi + api.typeJuridictionApi,
        typeJuridiction
      );
      return newTypeJuridiction;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editTypeJuridiction =
  (typeJuridiction: typeJuridictionInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.typeJuridictionApi + "/" + id,
        typeJuridiction
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default typeJuridictionSlice.reducer;
