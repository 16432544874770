import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface resultatAudienceInterface {
  id_resultataudience: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewresultatAudienceInterface {
  code: string;
  libelle: string;
}

const initialState = {
  resultatAudiences: [],
  allResultatAudiences: [],
  resultatAudience: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const resultatAudienceSlice = createSlice({
  name: "resultatAudience",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allResultatAudience(state, action) {
      state.resultatAudiences = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allResultatAudienceTrue(state, action) {
      state.allResultatAudiences = action.payload;
      state.error = "";
    },
    oneResultatAudience(state, action) {
      state.resultatAudience = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allResultatAudience,
  allResultatAudienceTrue,
  oneResultatAudience,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = resultatAudienceSlice.actions;

export const getAllResultatAudience =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.resultatAudienceApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let resultatAudiences: any;
      resultatAudiences = page ? response.data : response.data.data;
      page
        ? dispatch(allResultatAudience(resultatAudiences))
        : dispatch(allResultatAudienceTrue(resultatAudiences));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneResultatAudience = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.resultatAudienceApi + "/" + id
    );
    let resultatAudience = response.data;
    dispatch(oneResultatAudience(resultatAudience));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addResultatAudience =
  (resultatAudience: NewresultatAudienceInterface) => async (dispatch: any) => {
    try {
      const NewResultatAudience = await axios.post(
        api.baseApi + api.resultatAudienceApi,
        resultatAudience
      );
      return NewResultatAudience;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editResultatAudience =
  (resultatAudience: resultatAudienceInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.resultatAudienceApi + "/" + id,
        resultatAudience
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default resultatAudienceSlice.reducer;
