import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { AffaireClientInterface } from "../interfaces";
import { situationInterface } from "./situationSlice";

export interface clientInterface {
  client_id: any;
  situation_id: any;
  id_client: number;
  typeentite: string;
  nom: string;
  prenom: string;
  cin: string;
  id_affaire_client?: string;
  genre: string;
  Client?: clientInterface;
  Situation?: situationInterface;
  AffaireClient: AffaireClientInterface;
  raisonsociale: string;
  representantlegal: string;
  abreviation: string;
  siegesocial: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewclientInterface {
  typeentite: string;
  nom: string;
  prenom: string;
  cin: string;
  genre: string;
  raisonsociale: string;
  representantlegal: string;
  abreviation: string;
  siegesocial: string;
}

const initialState = {
  clients: [],
  allClients: [],
  client: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allClient(state, action) {
      state.clients = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allClientTrue(state, action) {
      state.allClients = action.payload;
      state.error = "";
    },
    oneClient(state, action) {
      state.client = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allClient,
  allClientTrue,
  oneClient,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = clientSlice.actions;

export const getAllClient =
  (
    page?: number,
    typeentite?: string,
    nom?: string,
    prenom?: string,
    cin?: string,
    raisonsociale?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.clientApi}${
          page ? "/?page=" + page : "/?all=true"
        }${typeentite ? "&typeentite=" + typeentite : ""}${
          nom ? "&nom=" + nom : ""
        }${prenom ? "&prenom=" + prenom : ""}${cin ? "&cin=" + cin : ""}${
          raisonsociale ? "&raisonsociale=" + raisonsociale : ""
        }`
      );
      let clients: any;
      clients = page ? response.data : response.data.data;
      page ? dispatch(allClient(clients)) : dispatch(allClientTrue(clients));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneClient = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.clientApi + "/" + id);
    let client = response.data;
    dispatch(oneClient(client));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addClient =
  (client: NewclientInterface) => async (dispatch: any) => {
    try {
      const newClient = await axios.post(api.baseApi + api.clientApi, client);
      return newClient;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editClient =
  (client: clientInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.clientApi + "/" + id, client);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default clientSlice.reducer;
