import React from "react";
import { Table } from "react-bootstrap";
import styles from "../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Pagination, Button, Alert } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Meta } from "../redux/interfaces";
import { theme } from "../styles/global";
import Spinner from "./Spinner";
import DownloadIcon from "@mui/icons-material/Download";

interface TableInteface {
  loading: boolean;
  headSet: string[];
  dataToShow: dataToShow[];
  dataDisplay: dataDisplay;
  page: number;
  id_type: string;
  title: string;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  download?: boolean;
  handleDownload?: (object: any) => void;
  LinkButton: (id: string, libelle: string, data?: any | string) => void;
}

interface dataToShow {
  vkey: string[];
  type: string;
}
interface dataDisplay {
  data: any[];
  meta: Meta;
}
export default function TableSiaModal({
  loading,
  headSet,
  dataToShow,
  dataDisplay,
  page,
  handleChange,
  id_type,
  title,
  download,
  handleDownload,
  LinkButton,
}: TableInteface) {
  const EntiteDisplay = (entite: string) => {
    switch (entite) {
      case "P":
        return "Personne Physique";
      case "M":
        return "Personne Morale";
    }
  };
  return (
    <>
      {!loading ? (
        <>
          <Table bordered responsive="sm">
            <thead>
              <tr>
                {headSet.map((head, key) => {
                  return (
                    <th className={styles.th} key={key}>
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {dataDisplay.data.length > 0 ? (
                dataDisplay.data.map((dataElement, key) => {
                  let id = dataElement[id_type];
                  let date = new Date(dataElement.createdon);
                  return (
                    <tr key={key}>
                      {dataToShow.map((element, index) => {
                        if (element.type === "link") {
                          if (element.vkey.includes("fullname")) {
                            const name = dataElement.nom
                              ? dataElement.nom + " "
                              : "" + dataElement.prenom
                              ? dataElement.prenom
                              : "";
                            return (
                              <td key={index}>
                                <span
                                  className={styles.reference}
                                  onClick={() => {
                                    LinkButton(id, name, dataElement);
                                  }}
                                >
                                  {name ? name : "-----"}
                                </span>
                              </td>
                            );
                          } else {
                            let result = dataElement;
                            for (const key of element.vkey) {
                              result = result[key];
                              if (!result) {
                                break;
                              }
                            }
                            return (
                              <td>
                                <span
                                  className={styles.reference}
                                  onClick={() => {
                                    LinkButton(id, result, dataElement);
                                  }}
                                >
                                  {result ? result : "-----"}
                                </span>
                              </td>
                            );
                          }
                        }
                        if (element.type === "date") {
                          let dateToShow = new Date(
                            dataElement[element.vkey[0]]
                          );
                          return (
                            <td>
                              <div className={styles.TdwithIcon}>
                                {dataElement[element.vkey[0]] ? (
                                  <>
                                    <CalendarMonthIcon />
                                    <span className={styles.bold}>
                                      {moment(
                                        dataElement[element.vkey[0]]
                                      ).format("DD/MM/YYYY")}
                                    </span>
                                  </>
                                ) : (
                                  "-----"
                                )}
                              </div>
                            </td>
                          );
                        } else {
                          if (element.vkey.includes("fullname")) {
                            return (
                              <td className={styles.bold}>
                                <span>
                                  {!dataElement[element.vkey[0]]?.nom &&
                                  !dataElement[element.vkey[0]]?.prenom
                                    ? "-----"
                                    : ""}
                                  {dataElement[element.vkey[0]]?.nom
                                    ? dataElement[element.vkey[0]]?.nom + " "
                                    : "" + dataElement[element.vkey[0]]?.prenom
                                    ? dataElement[element.vkey[0]]?.prenom
                                    : ""}
                                </span>
                              </td>
                            );
                          } else {
                            let result = dataElement;
                            for (const key of element.vkey) {
                              result = result[key];
                              if (!result) {
                                break;
                              }
                            }
                            if (element.vkey.includes("typeentite")) {
                              return (
                                <td className={styles.bold}>
                                  {result ? EntiteDisplay(result) : "-----"}
                                </td>
                              );
                            } else {
                              return (
                                <td className={styles.bold}>
                                  {result ? result : "-----"}
                                </td>
                              );
                            }
                          }
                        }
                      })}
                      <td>
                        <div className={styles.TdwithIcon}>
                          {dataElement.createdon ? (
                            <>
                              <CalendarMonthIcon />
                              <span className={styles.bold}>
                                {moment(dataElement.createdon).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </>
                          ) : (
                            "-----"
                          )}
                        </div>
                      </td>
                      {download && (
                        <td width={50}>
                          <Button
                            className={styles.ButtonDownload}
                            onClick={() =>
                              handleDownload && handleDownload(dataElement)
                            }
                          >
                            <DownloadIcon />
                          </Button>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headSet.length} className={styles.bold}>
                    <Alert
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      severity="error"
                    >
                      Aucune {title} Trouvé
                    </Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {dataDisplay.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={dataDisplay.meta.totalPages}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}
