import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { typeAccidentTravailInterface } from "./typeAccidentTravailSlice";
import { affaireInterface } from "./affaireSlice";

export interface accidentTravailInterface {
  id_accidenttravail: number;
  code: string;
  observation: string;
  reference: string;
  dateaccident: Date | string | null;
  pourcentageassurance: string;
  numpoliceassurance: string;
  typeaccidenttravail_id: number;
  affaire_id: number;
  TypeAccidentTravail: typeAccidentTravailInterface;
  Affaire: affaireInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewaccidentTravailInterface {
  observation: string;
  reference: string;
  dateaccident: Date | string | null;
  pourcentageassurance: string;
  numpoliceassurance: string;
  typeaccidenttravail_id: string;
  affaire_id: string;
}

const initialState = {
  accidentTravails: [],
  allAccidentTravails: [],
  accidentTravail: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const accidentTravailSlice = createSlice({
  name: "accidentTravail",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allAccidentTravail(state, action) {
      state.accidentTravails = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allAccidentTravailTrue(state, action) {
      state.allAccidentTravails = action.payload;
      state.error = "";
    },
    oneAccidentTravail(state, action) {
      state.accidentTravail = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allAccidentTravail,
  allAccidentTravailTrue,
  oneAccidentTravail,
  setLoadingOneFalse,
  getError,
  setLoadingFalse,
} = accidentTravailSlice.actions;

export const getAllAccidentTravail =
  (
    page?: number,
    reference?: string,
    dateaccident?: Date | null | string,
    numpoliceassurance?: string,
    typeaccidenttravail_id?: string,
    affaire_id?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.accidentTravailApi}${
          page ? "/?page=" + page : "/?all=true"
        }${reference ? "&reference=" + reference : ""}${
          dateaccident ? "&dateaccident=" + dateaccident : ""
        }${
          numpoliceassurance ? "&numpoliceassurance=" + numpoliceassurance : ""
        }${
          typeaccidenttravail_id
            ? "&typeaccidenttravail_id=" + typeaccidenttravail_id
            : ""
        }${affaire_id ? "&affaire_id=" + affaire_id : ""}`
      );
      let accidentTravails: any;
      accidentTravails = page ? response.data : response.data.data;
      page
        ? dispatch(allAccidentTravail(accidentTravails))
        : dispatch(allAccidentTravailTrue(accidentTravails));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneAccidentTravail = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.accidentTravailApi + "/" + id
    );
    let accidentTravail = response.data;
    dispatch(oneAccidentTravail(accidentTravail));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addAccidentTravail =
  (accidentTravail: NewaccidentTravailInterface) => async (dispatch: any) => {
    try {
      await axios.post(api.baseApi + api.accidentTravailApi, accidentTravail);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editAccidentTravail =
  (accidentTravail: accidentTravailInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.accidentTravailApi + "/" + id,
        accidentTravail
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default accidentTravailSlice.reducer;
