import React, { useEffect, useState } from "react";
import { affaireInterface } from "../../redux/slices/affaireSlice";
import styles from "../../styles/Affaire.module.scss";
import WorkIcon from "@mui/icons-material/Work";
import { Alert, Button, Pagination, ThemeProvider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProcedure,
  procedureInterface,
} from "../../redux/slices/procedureSlice";
import { Meta } from "../../redux/interfaces";
import { AppDispatch } from "../../redux/store";
import moment from "moment";
import Spinner from "../../components/Spinner";
import { theme } from "../../styles/global";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  audienceInterface,
  getAllAudience,
} from "../../redux/slices/audienceSlice";
import {
  factureInterface,
  getAllFacture,
  getFactureExport,
  getOneFacture,
} from "../../redux/slices/factureSlice";
import {
  getAllJugement,
  jugementInterface,
} from "../../redux/slices/jugementSlice";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import SpinnerPage from "../../components/SpinnerPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
interface AffaireDetailInterface {
  affaire: affaireInterface;
  setEditAffaire: () => void;
}
interface procedurePaginationInterface {
  meta: Meta;
  data: procedureInterface[];
}
const headSet = ["Client", "Qualité"];
const headSet2 = ["Adversaire", "Qualité"];
const headSet3 = ["Client", "Véhicule"];
const headSet4 = ["Adversaire", "Véhicule"];

const headSetProcedure = ["Reference", "Type Procedure", "Crée le"];

const headSetAudience = [
  "Date Audience",
  "Resultat Audience",
  "Procedure",
  "Crée le",
];
interface audiencePaginationInterface {
  meta: Meta;
  data: audienceInterface[];
}
const headSetFacture = ["Numero", "Client", "Crée le", ""];
interface facturePaginationInterface {
  meta: Meta;
  data: factureInterface[];
}
const headSetJugement = [
  "Date Jugement",
  "Type Jugement",
  "Procedure",
  "Crée le",
];
interface jugementPaginationInterface {
  meta: Meta;
  data: jugementInterface[];
}
export default function AffaireDetail({
  affaire,
  setEditAffaire,
}: AffaireDetailInterface) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const [expandProcedure, setExpandProcedure] = useState(true);
  const [expandAudience, setExpandAudience] = useState(true);
  const [expandJugement, setExpandJugement] = useState(true);
  const [expandFacture, setExpandFacture] = useState(true);

  const [expandClient, setExpandClient] = useState(true);
  const [expandAdversaire, setExpandAdversaire] = useState(true);
  const [expandClientVehicule, setExpandClientVehicule] = useState(true);
  const [expandAdversaireVehicule, setExpandAdversaireVehicule] =
    useState(true);
  const [loadingOne, setLoadingOne] = useState(false);
  const [pageAudience, setPageAudience] = useState(1);
  const [pageJugement, setPageJugement] = useState(1);
  const [pageFacture, setPageFacture] = useState(1);
  const navigate = useNavigate();
  const loading = useSelector((state: any) => state.procedure.loadingListe);
  const procedures: procedurePaginationInterface = useSelector(
    (state: any) => state.procedure.procedures
  );
  const loadingAudience = useSelector(
    (state: any) => state.audience.loadingListe
  );
  const audiences: audiencePaginationInterface = useSelector(
    (state: any) => state.audience.audiences
  );
  const loadingFacture = useSelector(
    (state: any) => state.facture.loadingListe
  );
  const factures: facturePaginationInterface = useSelector(
    (state: any) => state.facture.factures
  );
  const loadingJugement = useSelector(
    (state: any) => state.jugement.loadingListe
  );
  const jugements: jugementPaginationInterface = useSelector(
    (state: any) => state.jugement.jugements
  );
  const facture: any = useSelector((state: any) => state.facture.factureExport);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      dispatch(getAllProcedure(1, "", "", "", affaire.id_affaire.toString()));
      dispatch(
        getAllAudience(
          1,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          affaire.id_affaire.toString(),
          ""
        )
      );
      dispatch(getAllFacture(1, "", "", affaire.id_affaire.toString(), "", ""));
      dispatch(
        getAllJugement(
          1,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          affaire.id_affaire.toString()
        )
      );
    }
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllProcedure(
        value,
        "",
        "",
        affaire.id_affaire.toString(),
        "",
        "",
        "",
        "",
        "",
        ""
      )
    );
  };
  const handleChangeAudience = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageAudience(value);
    dispatch(
      getAllAudience(
        value,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        affaire.id_affaire.toString(),
        ""
      )
    );
  };
  const handleChangeJugement = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageJugement(value);
    dispatch(
      getAllJugement(
        value,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        affaire.id_affaire.toString()
      )
    );
  };
  const handleChangeFacture = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageFacture(value);
    dispatch(
      getAllFacture(value, "", "", affaire.id_affaire.toString(), "", "")
    );
  };
  const handleDownloadPDF = (facture: factureInterface) => {
    dispatch(getFactureExport(facture.id_facture));
    setLoadingOne(true);
  };
  useEffect(() => {
    if (!isFirstRender && facture) {
      axios
        .post(process.env.REACT_APP_API_FACTURE_URL + "generate_pdf", facture, {
          responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");

          link.href = url;
          const noSpecialChars = facture.numero.replace(/[^a-zA-Z0-9 ]/g, "");
          link.download = "facture_" + noSpecialChars + ".pdf"; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoadingOne(false);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    }
  }, [facture]);
  return (
    <div>
      {loadingOne && <SpinnerPage />}
      <div className={styles.ListeInformation}>
        <Button className={styles.ButtonEditInfo} onClick={setEditAffaire}>
          <EditIcon />
        </Button>
        <div className={styles.TitleIcone}>
          <WorkIcon />
          <h2>
            <span>Affaire : </span>
            {affaire.reference}
          </h2>
        </div>
        <div className={styles.SupInfo}>
          <div></div>
          <div className={styles.ListeSupInfo}>
            <p>
              <span>Genre Affaire : </span>
              {affaire.GenreAffaire.libelle ?? "-"}
            </p>
            <p>
              <span>Reference Definitive : </span>
              {affaire.referencedefinitive ?? "-"}
            </p>
            <p>
              <span>Reference Provisoire : </span>
              {affaire.referenceprovisoire ?? "-"}
            </p>
            {affaire.GenreAffaire.libelle === "Accidents de Circulation" && (
              <>
                <p>
                  <span>Type Poursuite : </span>
                  {affaire.accidentcirculations[0]?.TypePoursuite?.libelle ??
                    "-"}
                </p>
                <p>
                  <span>Type Garantie : </span>
                  {affaire.accidentcirculations[0]?.TypeGarantie?.libelle ??
                    "-"}
                </p>
              </>
            )}
            {affaire.GenreAffaire.libelle === "Accidents de Travail" && (
              <>
                <p>
                  <span>Type Accident De Travail : </span>
                  {affaire.accidenttravails[0]?.TypeAccidentTravail?.libelle ??
                    "-"}
                </p>
                <p>
                  <span>Num Police Assurance : </span>
                  {affaire.accidenttravails[0]?.numpoliceassurance ?? "-"}
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "50px",
        }}
      >
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Clients</span>
                <span
                  onClick={() => setExpandClient(!expandClient)}
                  className={styles.ButtonExpand}
                >
                  {!expandClient ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>
            {expandClient && (
              <>
                <thead>
                  <tr>
                    {headSet.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {affaire.clients.length > 0 &&
                    affaire.clients.map((client, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={`/clients/` + client.Client?.id_client + ``}
                              relative="path"
                            >
                              <span>
                                {!client.Client?.nom && !client.Client?.prenom
                                  ? "-----"
                                  : ""}
                                {client.Client?.nom
                                  ? client.Client?.nom + " "
                                  : ""}
                                {client.Client?.prenom
                                  ? client.Client?.prenom
                                  : ""}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            {client.Situation?.libelle
                              ? client.Situation.libelle
                              : "-----"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </>
            )}
          </Table>
        </div>
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Adversaires</span>
                <span
                  onClick={() => setExpandAdversaire(!expandAdversaire)}
                  className={styles.ButtonExpand}
                >
                  {!expandAdversaire ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>
            {expandAdversaire && (
              <>
                <thead>
                  <tr>
                    {headSet2.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {affaire.adversaires.length > 0 &&
                    affaire.adversaires.map((adversaire, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={
                                `/adversaires/` +
                                adversaire.Adversaire?.id_adversaire +
                                ``
                              }
                              relative="path"
                            >
                              <span>
                                {!adversaire.Adversaire?.nom &&
                                !adversaire.Adversaire?.prenom
                                  ? "-----"
                                  : ""}
                                {adversaire.Adversaire?.nom
                                  ? adversaire.Adversaire?.nom + " "
                                  : ""}
                                {adversaire.Adversaire?.prenom
                                  ? adversaire.Adversaire?.prenom
                                  : ""}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            {adversaire.Situation?.libelle
                              ? adversaire.Situation.libelle
                              : "-----"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </>
            )}
          </Table>
        </div>
      </div>
      {(affaire.vehiculeclients.length > 0 ||
        affaire.vehiculeadversaires.length > 0) && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "50px",
          }}
        >
          {affaire.vehiculeclients.length > 0 && (
            <div className={styles.ListeInformation}>
              <Table className="caption-top" bordered responsive="sm">
                <caption className={styles.Caption}>
                  <div className={styles.CaptionContent}>
                    <span>Les Véhicules Clients</span>
                    <span
                      onClick={() =>
                        setExpandClientVehicule(!expandClientVehicule)
                      }
                      className={styles.ButtonExpand}
                    >
                      {!expandClientVehicule ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ExpandLessIcon />
                      )}
                    </span>
                  </div>
                </caption>
                {expandClientVehicule && (
                  <>
                    <thead>
                      <tr>
                        {headSet3.map((head, key) => {
                          return (
                            <th className={styles.th} key={key}>
                              {head}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {affaire.vehiculeclients.length > 0 &&
                        affaire.vehiculeclients.map((vehiculeclient, key) => {
                          return (
                            <tr key={key}>
                              <td className={styles.bold}>
                                {!vehiculeclient.Client?.nom &&
                                !vehiculeclient.Client?.prenom
                                  ? "-----"
                                  : ""}
                                {vehiculeclient.Client?.nom
                                  ? vehiculeclient.Client?.nom + " "
                                  : ""}
                                {vehiculeclient.Client?.prenom
                                  ? vehiculeclient.Client?.prenom
                                  : ""}
                              </td>
                              <td className={styles.bold}>
                                {vehiculeclient.Vehicule?.numpoliceassurance
                                  ? vehiculeclient.Vehicule.numpoliceassurance
                                  : "-----"}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                )}
              </Table>
            </div>
          )}
          {affaire.vehiculeadversaires.length > 0 && (
            <div className={styles.ListeInformation}>
              <Table className="caption-top" bordered responsive="sm">
                <caption className={styles.Caption}>
                  <div className={styles.CaptionContent}>
                    <span>Les Véhicules Adversaires</span>
                    <span
                      onClick={() =>
                        setExpandAdversaireVehicule(!expandAdversaireVehicule)
                      }
                      className={styles.ButtonExpand}
                    >
                      {!expandAdversaireVehicule ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ExpandLessIcon />
                      )}
                    </span>
                  </div>
                </caption>
                {expandAdversaireVehicule && (
                  <>
                    <thead>
                      <tr>
                        {headSet4.map((head, key) => {
                          return (
                            <th className={styles.th} key={key}>
                              {head}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {affaire.vehiculeadversaires.length > 0 &&
                        affaire.vehiculeadversaires.map(
                          (vehiculeadversaire, key) => {
                            return (
                              <tr key={key}>
                                <td className={styles.bold}>
                                  {!vehiculeadversaire.Adversaire?.nom &&
                                  !vehiculeadversaire.Adversaire?.prenom
                                    ? "-----"
                                    : ""}
                                  {vehiculeadversaire.Adversaire?.nom
                                    ? vehiculeadversaire.Adversaire?.nom + " "
                                    : "" + vehiculeadversaire.Adversaire?.prenom
                                    ? vehiculeadversaire.Adversaire?.prenom
                                    : ""}
                                </td>
                                <td className={styles.bold}>
                                  {vehiculeadversaire.Vehicule
                                    ?.numpoliceassurance
                                    ? vehiculeadversaire.Vehicule
                                        ?.numpoliceassurance
                                    : "-----"}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </>
                )}
              </Table>
            </div>
          )}
        </div>
      )}
      {!loading ? (
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Procédures</span>
                <span
                  onClick={() => setExpandProcedure(!expandProcedure)}
                  className={styles.ButtonExpand}
                >
                  {!expandProcedure ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>

            {expandProcedure && (
              <>
                <thead>
                  <tr>
                    {headSetProcedure.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {procedures.data.length > 0 ? (
                    procedures.data.map((procedure, key) => {
                      let date = new Date(procedure.createdon);
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={`/procedures/` + procedure.id_procedure + ``}
                              relative="path"
                            >
                              <span>
                                {procedure.reference
                                  ? procedure.reference
                                  : "-----"}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            {procedure.TypeProcedure?.libelle
                              ? procedure.TypeProcedure?.libelle
                              : "-----"}
                          </td>
                          <td>
                            <div className={styles.TdwithIcon}>
                              {procedure.createdon ? (
                                <>
                                  <CalendarMonthIcon />
                                  <span className={styles.bold}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </span>
                                </>
                              ) : (
                                "-----"
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className={styles.bold}>
                        <Alert
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          severity="error"
                        >
                          Aucune Procédure N'est Crée Avec Cette Affaire
                        </Alert>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
          {expandProcedure && procedures.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={procedures.meta.totalPages}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
      {!loadingAudience ? (
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Audiences</span>
                <span
                  onClick={() => setExpandAudience(!expandAudience)}
                  className={styles.ButtonExpand}
                >
                  {!expandAudience ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>
            {expandAudience && (
              <>
                <thead>
                  <tr>
                    {headSetAudience.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {audiences.data.length > 0 ? (
                    audiences.data.map((audience, key) => {
                      let date = new Date(audience.createdon);
                      let dateau = audience.dateaudience
                        ? new Date(audience.dateaudience)
                        : null;
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={`/audiences/` + audience.id_audience + ``}
                              relative="path"
                            >
                              <span>
                                {audience.dateaudience ? (
                                  <>
                                    <CalendarMonthIcon />
                                    <span className={styles.bold}>
                                      {moment(dateau).format("DD/MM/YYYY")}
                                    </span>
                                  </>
                                ) : (
                                  "-----"
                                )}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            {audience.ResultatAudience?.libelle
                              ? audience.ResultatAudience?.libelle
                              : "-----"}
                          </td>
                          <td className={styles.bold}>
                            {audience.Procedure?.reference
                              ? audience.Procedure?.reference
                              : "-----"}
                          </td>
                          <td>
                            <div className={styles.TdwithIcon}>
                              {audience.createdon ? (
                                <>
                                  <CalendarMonthIcon />
                                  <span className={styles.bold}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </span>
                                </>
                              ) : (
                                "-----"
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className={styles.bold}>
                        <Alert
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          severity="error"
                        >
                          Aucune Audience Trouvé
                        </Alert>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
          {expandAudience && audiences.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={audiences.meta.totalPages}
                  page={pageAudience}
                  onChange={handleChangeAudience}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
      {!loadingJugement ? (
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Jugements</span>
                <span
                  onClick={() => setExpandJugement(!expandJugement)}
                  className={styles.ButtonExpand}
                >
                  {!expandJugement ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>
            {expandJugement && (
              <>
                <thead>
                  <tr>
                    {headSetJugement.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {jugements.data.length > 0 ? (
                    jugements.data.map((jugement, key) => {
                      let date = new Date(jugement.createdon);
                      let datejugement = jugement.datejugement
                        ? new Date(jugement.datejugement)
                        : null;
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={`/jugements/` + jugement.id_jugement + ``}
                              relative="path"
                            >
                              <span>
                                {jugement.datejugement ? (
                                  <>
                                    <CalendarMonthIcon />
                                    <span className={styles.bold}>
                                      {moment(datejugement).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  "-----"
                                )}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            {jugement.TypeJugement?.libelle
                              ? jugement.TypeJugement?.libelle
                              : "-----"}
                          </td>
                          <td className={styles.bold}>
                            {jugement.Procedure?.reference
                              ? jugement.Procedure?.reference
                              : "-----"}
                          </td>
                          <td>
                            <div className={styles.TdwithIcon}>
                              {jugement.createdon ? (
                                <>
                                  <CalendarMonthIcon />
                                  <span className={styles.bold}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </span>
                                </>
                              ) : (
                                "-----"
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className={styles.bold}>
                        <Alert
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          severity="error"
                        >
                          Aucune Jugement Trouvé
                        </Alert>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
          {expandJugement && jugements.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={jugements.meta.totalPages}
                  page={pageJugement}
                  onChange={handleChangeJugement}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
      {!loadingFacture ? (
        <div className={styles.ListeInformation}>
          <Table className="caption-top" bordered responsive="sm">
            <caption className={styles.Caption}>
              <div className={styles.CaptionContent}>
                <span>Les Factures</span>
                <span
                  onClick={() => setExpandFacture(!expandFacture)}
                  className={styles.ButtonExpand}
                >
                  {!expandFacture ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </span>
              </div>
            </caption>
            {expandFacture && (
              <>
                <thead>
                  <tr>
                    {headSetFacture.map((head, key) => {
                      return (
                        <th className={styles.th} key={key}>
                          {head}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {factures.data.length > 0 ? (
                    factures.data.map((facture, key) => {
                      let date = new Date(facture.createdon);
                      return (
                        <tr key={key}>
                          <td>
                            <Link
                              className={styles.reference}
                              to={`/factures/` + facture.id_facture + ``}
                              relative="path"
                            >
                              <span>
                                {facture.numero ? facture.numero : "-----"}
                              </span>
                            </Link>
                          </td>
                          <td className={styles.bold}>
                            <span>
                              {!facture.Client?.nom && !facture.Client?.prenom
                                ? "-----"
                                : ""}
                              {facture.Client?.nom
                                ? facture.Client?.nom + " "
                                : "" + facture.Client?.prenom
                                ? facture.Client?.prenom
                                : ""}
                            </span>
                          </td>
                          <td>
                            <div className={styles.TdwithIcon}>
                              {facture.createdon ? (
                                <>
                                  <CalendarMonthIcon />
                                  <span className={styles.bold}>
                                    {moment(date).format("DD/MM/YYYY")}
                                  </span>
                                </>
                              ) : (
                                "-----"
                              )}
                            </div>
                          </td>
                          <td width={50}>
                            <Button
                              className={styles.ButtonDownload}
                              onClick={() => handleDownloadPDF(facture)}
                            >
                              <DownloadIcon />
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className={styles.bold}>
                        <Alert
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          severity="error"
                        >
                          Aucune Facture Trouvé
                        </Alert>
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </Table>
          {expandFacture && factures.meta.totalPages > 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ThemeProvider theme={theme}>
                <Pagination
                  count={factures.meta.totalPages}
                  page={pageFacture}
                  onChange={handleChangeFacture}
                  color="primary"
                />
              </ThemeProvider>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
