import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import { Link } from "react-router-dom";
import {
  typePoursuiteInterface,
  getAllTypePoursuite,
} from "../../redux/slices/typePoursuiteSlice";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import TableSia from "../../components/TableSia";

const headSet = ["Libelle", "Crée le"];
interface typePoursuitePaginationInterface {
  meta: Meta;
  data: typePoursuiteInterface[];
}
const dataToShow = [
  {
    vkey: ["libelle"],
    type: "link",
  },
];
export default function TypePoursuites() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [code, setCode] = useState("");
  const [libelle, setLibelle] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.typePoursuite.errorListe);
  const loading = useSelector((state: any) => state.typePoursuite.loadingListe);
  const typePoursuites: typePoursuitePaginationInterface = useSelector(
    (state: any) => state.typePoursuite.typePoursuites
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(getAllTypePoursuite(1, code, libelle));
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllTypePoursuite(value, code, libelle));
  };
  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };
  const handleChangeLibelle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLibelle(event.target.value);
  };
  const ResetFilters = () => {
    setCode("");
    setLibelle("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllTypePoursuite(1, code, libelle));
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [code, libelle]);
  return (
    <div>
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Types Poursuite</h2>
        <Link to="new" relative="path">
          + Ajouter Un Type Poursuite
        </Link>
      </div>
      <BoxFilter
        boxDisplayed={["libelle", "code"]}
        code={code}
        libelle={libelle}
        setCode={handleChangeCode}
        setLibelle={handleChangeLibelle}
        ResetFilters={ResetFilters}
        loading={loading}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={typePoursuites}
        page={page}
        id_type="id_typepoursuite"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Type Poursuite"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
