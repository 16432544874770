import React, { useRef, useState, useEffect } from "react";
import { Formik, FormikErrors, FormikProps } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";
import { addFacture, getNumeroFacture } from "../../redux/slices/factureSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import ProcedureModal from "../../components/Modals/ProcedureModal";
import TvaModal from "../../components/Modals/TvaModal";
import ActeModal from "../../components/Modals/ActeModal";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Spinner from "../../components/Spinner";
import {
  affaireInterface,
  getOneAffaire,
} from "../../redux/slices/affaireSlice";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  affaire_id: string;
  procedure_id: string;
  client_id: string;
  numero: string;
  retenir: boolean;
  //numseq: number;
  dateemmission: Date | string | null;
  factureactes: FactureActe[];
}

export interface FactureActe {
  acte_id: string;
  tva_id: string;
  quantite: number;
  regle: boolean;
  prixunitaire: number;
  datedesignation: string;
}

export default function NewFacture() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [idProc, setIdProc] = useState(null);

  const [total, setTotal] = useState(0);
  const [totalTva, setTotalTva] = useState(0);
  const [totalHT, setTotalHT] = useState(0);
  const [montantretenu, setMontantretenu] = useState<any>([]);
  const [loadNumero, setLoadNumero] = useState(false);

  const [openProcedure, setOpenProcedure] = useState(false);
  const [displayProcedure, setDisplayProcedure] = useState("");

  const [openTva, setOpenTva] = useState([false]);
  const [displayTva, setDisplayTva] = useState([""]);
  const [valeurTva, setValeurTva] = useState([0]);

  const [openActe, setOpenActe] = useState([false]);
  const [displayActe, setDisplayActe] = useState([""]);
  const [valeurTypeActe, setValeurTypeActe] = useState([""]);

  const formikRef = useRef<FormikProps<MyFormValues>>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const affaire: affaireInterface = useSelector(
    (state: any) => state.affaire.affaire
  );

  const initialValues: MyFormValues = {
    procedure_id: "",
    affaire_id: "",
    numero: "",
    client_id: "",
    retenir: false,
    dateemmission: null,
    factureactes: [
      {
        acte_id: "",
        tva_id: "",
        quantite: 1,
        regle: false,
        prixunitaire: 0,
        datedesignation: "",
      },
    ],
  };
  const schema = yup.object({
    procedure_id: yup.string().required("Procedure est obligatoire"),
    client_id: yup.string().required("Client est obligatoire"),
    numero: yup.string().required("Numero est obligatoire"),
    factureactes: yup
      .array()
      .of(
        yup.object().shape({
          acte_id: yup.string().required("Acte est obligatoire"),
          tva_id: yup.string().required("Tva est obligatoire"),
          //quantite: yup.number().required("Quantite est obligatoire"),
          prixunitaire: yup.number().required("Prix Unitaire est obligatoire"),
          datedesignation: yup
            .string()
            .required("Date Designation est obligatoire"),
        })
      )
      .min(1, "Facture Acte est obligatoire"),
  });

  const handleRemoveFactureActe = (
    indexNum: number,
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    let arrayOpenActe = openActe.filter((ele, index) => index !== indexNum);
    let arrayDisplayActe = displayActe.filter(
      (ele, index) => index !== indexNum
    );
    let arrayOpenTva = openTva.filter((ele, index) => index !== indexNum);
    let arrayDisplayTva = displayTva.filter((ele, index) => index !== indexNum);
    let arrayValeurTva = valeurTva.filter((ele, index) => index !== indexNum);
    let arrayValeurTypeActe = valeurTypeActe.filter(
      (ele, index) => index !== indexNum
    );

    const currentClientAffaire = values.factureactes;
    const updatedClientAffaire = currentClientAffaire.filter(
      (ele, index) => index !== indexNum
    );

    setOpenActe(arrayOpenActe);
    setDisplayActe(arrayDisplayActe);
    setOpenTva(arrayOpenTva);
    setDisplayTva(arrayDisplayTva);
    setValeurTva(arrayValeurTva);
    setValeurTypeActe(arrayValeurTypeActe);
    setFieldValue("factureactes", updatedClientAffaire);
  };

  const handleAddFactureActe = (
    values: MyFormValues,
    setFieldValue: {
      (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ): Promise<void | FormikErrors<MyFormValues>>;
      (arg0: string, arg1: any[]): void;
    }
  ) => {
    const newFactureActe = {
      acte_id: "",
      tva_id: "",
      quantite: 1,
      prixunitaire: 0,
      regle: false,
      impotinclu: false,
      montantregle: 0,
      datedesignation: "",
    };
    let arrayOpenTva = [...openTva, false];
    let arrayDisplayTva = [...displayTva, ""];
    let arrayValeurTva = [...valeurTva, 0];
    let arrayValeurTypeActe = [...valeurTypeActe, ""];
    let arrayOpenActe = [...openActe, false];
    let arrayDisplayActe = [...displayActe, ""];

    setOpenActe(arrayOpenActe);
    setDisplayActe(arrayDisplayActe);
    setOpenTva(arrayOpenTva);
    setDisplayTva(arrayDisplayTva);
    setValeurTva(arrayValeurTva);
    setValeurTypeActe(arrayValeurTypeActe);
    const currentClientAffaire = values.factureactes;
    const updatedClientAffaire = [...currentClientAffaire, newFactureActe];
    setFieldValue("factureactes", updatedClientAffaire);
  };

  const calculTotal = (array: FactureActe[], retenir: boolean) => {
    let totalFacture = 0;
    let totalFactureHT = 0;
    let totalFactureTVA = 0;
    setMontantretenu([]);
    array?.length &&
      array?.forEach((elemet, index) => {
        let tva =
          elemet.prixunitaire * elemet.quantite * (valeurTva[index] / 100);
        totalFactureTVA += tva;
        totalFactureHT += elemet.prixunitaire * elemet.quantite;
        totalFacture += elemet.prixunitaire * elemet.quantite + tva;
        setMontantretenu((prevState: any) => [
          ...prevState,
          retenir && valeurTypeActe[index] === "HONORAIRES"
            ? ((elemet.prixunitaire * elemet.quantite) / 10).toFixed(2)
            : 0,
        ]);
      });
    setTotal(parseFloat(totalFacture.toFixed(2)));
    setTotalTva(parseFloat(totalFactureTVA.toFixed(2)));
    setTotalHT(parseFloat(totalFactureHT.toFixed(2)));
  };
  const fetchNumero = async () => {
    try {
      const numero = await dispatch(getNumeroFacture());
      if (formikRef.current) {
        const updateValues = {
          ...formikRef.current.values,
          numero: numero,
        };
        formikRef.current.setValues(updateValues);
        setLoadNumero(true);
      }
      // Perform further actions with the fetched data
    } catch (error) {
      console.error("Error fetching Numero:", error);
    }
  };

  useEffect(() => {
    const localStorageProcedure = localStorage.getItem("procedure");
    if (localStorageProcedure) {
      fetchNumero();
      let affaireObject = JSON.parse(localStorageProcedure);
      dispatch(getOneAffaire(affaireObject.Affaire.id_affaire));
      if (formikRef.current) {
        const updateValues = {
          ...formikRef.current.values,
          procedure_id: affaireObject.id_procedure,
          affaire_id: affaireObject.Affaire.id_affaire,
        };
        formikRef.current.setValues(updateValues);
        setIdProc(affaireObject.id_procedure);
      }
      setDisplayProcedure(affaireObject.reference);
    } else {
      navigate("/procedures");
    }
    return () => {
      localStorage.removeItem("procedure");
    };
  }, []);

  useEffect(() => {
    if (formikRef.current?.values) {
      calculTotal(
        formikRef.current?.values.factureactes,
        formikRef.current?.values.retenir
      );
    }
  }, [valeurTva, valeurTypeActe]);

  return (
    <>
      <div className={styles.ReturnList}>
        <Link to={"/procedures/" + idProc} relative="path">
          <ArrowBackIosIcon /> Retour Procédure
        </Link>
        <h2>Nouvelle Facture</h2>
        <div />
      </div>
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const Newfacture = await dispatch(addFacture(values));
          if (Newfacture?.status) {
            MySwal.fire({
              icon: "success",
              title: "Facture est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              const url = "/procedures/" + Newfacture?.data.procedure_id;
              navigate(url);
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            {loadNumero ? (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    className={styles.TextFieldDisabled}
                    label="Numero"
                    name="numero"
                    onChange={handleChange}
                    value={values.numero}
                    fullWidth
                    disabled
                    error={!!errors.numero}
                    helperText={errors.numero ? errors.numero : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    className={
                      !displayProcedure
                        ? styles.backgroudGrey
                        : styles.backgroudGrey2
                    }
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Procédure
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      disabled
                      fullWidth
                      value={displayProcedure}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setOpenProcedure(true)}
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Procédure"
                    />
                  </FormControl>
                  {openProcedure && (
                    <ProcedureModal
                      open={openProcedure}
                      handleClose={() => setOpenProcedure(false)}
                      handleChangeValue={(value) =>
                        setFieldValue("procedure_id", value)
                      }
                      handleChangeValueAffaire={(value) =>
                        setFieldValue("affaire_id", value)
                      }
                      handleChangeDisplay={setDisplayProcedure}
                    />
                  )}
                  {errors.procedure_id && (
                    <span className={styles.errorHandle}>
                      {errors.procedure_id}
                    </span>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={styles.backgroudWhite} fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Client
                    </InputLabel>
                    <Select
                      name="client_id"
                      labelId="demo-simple-select-label"
                      label="Client"
                      onChange={handleChange}
                    >
                      <MenuItem value="">-</MenuItem>
                      {affaire?.clients?.length > 0 &&
                        affaire.clients.map((client, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={client.Client?.id_client}
                            >
                              {!client.Client?.nom && !client.Client?.prenom
                                ? "-----"
                                : ""}
                              {client.Client?.nom
                                ? client.Client?.nom + " "
                                : "" + client.Client?.prenom
                                ? client.Client?.prenom
                                : ""}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 32 },
                        }}
                      />
                    }
                    label="Retenir"
                    checked={values.retenir}
                    onChange={() => {
                      setFieldValue("retenir", !values.retenir);
                      calculTotal(values.factureactes, !values.retenir);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={styles.ArrayInput}>
                  <Table bordered responsive="sm">
                    <thead>
                      <tr>
                        <th className={styles.th}>Acte</th>
                        <th className={styles.th}>Prix Unitaire</th>
                        <th className={styles.th}>Tva</th>
                        <th className={styles.th}>Date Désignation</th>
                        <th className={styles.th}>Montant Retenue</th>
                        <th className={styles.th}>Réglé</th>
                        <th className={styles.th}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.factureactes.map((element, index) => {
                        return (
                          <tr>
                            <td>
                              <FormControl
                                className={
                                  !displayActe[index]
                                    ? styles.backgroudGrey
                                    : styles.backgroudGrey2
                                }
                                fullWidth
                                size="small"
                              >
                                <InputLabel htmlFor="outlined-adornment-password">
                                  Acte
                                </InputLabel>
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={displayActe[index]}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          setOpenActe((prevState) => {
                                            const newState = [...prevState];
                                            newState[index] = true;
                                            return newState;
                                          })
                                        }
                                        edge="end"
                                      >
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Acte"
                                />
                              </FormControl>
                              {openActe[index] && (
                                <ActeModal
                                  open={openActe[index]}
                                  handleClose={() =>
                                    setOpenActe((prevState) => {
                                      const newState = [...prevState];
                                      newState[index] = false;
                                      return newState;
                                    })
                                  }
                                  handleChangeValue={(value) => {
                                    const currentFactureActe =
                                      values.factureactes;
                                    const currentActe =
                                      currentFactureActe[index];
                                    currentActe.acte_id = value;
                                    const updatedFactureActe = [
                                      ...currentFactureActe,
                                    ];
                                    setFieldValue(
                                      "factureactes",
                                      updatedFactureActe
                                    );
                                  }}
                                  handleChangeDisplay={(value) =>
                                    setDisplayActe((prevState) => {
                                      const newState = [...prevState];
                                      newState[index] = value;
                                      return newState;
                                    })
                                  }
                                  handleChangeType={(value) => {
                                    setValeurTypeActe((prevState) => {
                                      const newState = [...prevState];
                                      newState[index] = value;
                                      return newState;
                                    });
                                  }}
                                />
                              )}
                            </td>
                            {/* <td width={70}>
                              <TextField
                                name="quantite"
                                size="small"
                                type="number"
                                onChange={(event) => {
                                  const currentFactureActe =
                                    values.factureactes;
                                  const currentActe = currentFactureActe[index];
                                  currentActe.quantite = parseFloat(
                                    event.target.value
                                  );
                                  const updatedFactureActe = [
                                    ...currentFactureActe,
                                  ];
                                  setFieldValue(
                                    "factureactes",
                                    updatedFactureActe
                                  );
                                  calculTotal(
                                    updatedFactureActe,
                                    values.retenir
                                  );
                                }}
                                value={values.factureactes[index].quantite}
                                fullWidth
                              />
                            </td> */}
                            <td>
                              <TextField
                                name="prixunitaire"
                                size="small"
                                type="number"
                                onChange={(event) => {
                                  const currentFactureActe =
                                    values.factureactes;
                                  const currentActe = currentFactureActe[index];
                                  currentActe.prixunitaire = parseFloat(
                                    event.target.value
                                  );
                                  const updatedFactureActe = [
                                    ...currentFactureActe,
                                  ];
                                  setFieldValue(
                                    "factureactes",
                                    updatedFactureActe
                                  );
                                  calculTotal(
                                    updatedFactureActe,
                                    values.retenir
                                  );
                                }}
                                value={values.factureactes[index].prixunitaire}
                                fullWidth
                              />
                            </td>
                            <td width={170}>
                              <FormControl
                                className={
                                  !displayTva[index]
                                    ? styles.backgroudGrey
                                    : styles.backgroudGrey2
                                }
                                fullWidth
                                size="small"
                              >
                                <InputLabel htmlFor="outlined-adornment-password">
                                  Tva
                                </InputLabel>
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={displayTva[index]}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          setOpenTva((prevState) => {
                                            const newState = [...prevState];
                                            newState[index] = true;
                                            return newState;
                                          })
                                        }
                                        edge="end"
                                      >
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Tva"
                                />
                              </FormControl>
                              {openTva[index] && (
                                <TvaModal
                                  open={openTva[index]}
                                  handleClose={() =>
                                    setOpenTva((prevState) => {
                                      const newState = [...prevState];
                                      newState[index] = false;
                                      return newState;
                                    })
                                  }
                                  handleChangeValue={(value) => {
                                    const currentFactureActe =
                                      values.factureactes;
                                    const currentTva =
                                      currentFactureActe[index];
                                    currentTva.tva_id = value;
                                    const updatedFactureActe = [
                                      ...currentFactureActe,
                                    ];
                                    setFieldValue(
                                      "factureactes",
                                      updatedFactureActe
                                    );
                                  }}
                                  handleChangeDisplay={(value) =>
                                    setDisplayTva((prevState) => {
                                      const newState = [...prevState];
                                      newState[index] = value;
                                      return newState;
                                    })
                                  }
                                  handleChangeTotal={(value) => {
                                    setValeurTva((prevState) => {
                                      const newState = [...prevState];
                                      newState[index] = value;
                                      return newState;
                                    });
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              <TextField
                                name="datedesignation"
                                size="small"
                                onChange={(event) => {
                                  const currentFactureActe =
                                    values.factureactes;
                                  const currentActe = currentFactureActe[index];
                                  currentActe.datedesignation =
                                    event.target.value;
                                  const updatedFactureActe = [
                                    ...currentFactureActe,
                                  ];
                                  setFieldValue(
                                    "factureactes",
                                    updatedFactureActe
                                  );
                                }}
                                value={
                                  values.factureactes[index].datedesignation
                                }
                                fullWidth
                              />
                            </td>
                            {/* <td>
                              <TextField
                                name="montantregle"
                                size="small"
                                type="number"
                                onChange={(event) => {
                                  const currentFactureActe =
                                    values.factureactes;
                                  const currentActe = currentFactureActe[index];
                                  currentActe.montantregle = parseFloat(
                                    event.target.value
                                  );
                                  const updatedFactureActe = [
                                    ...currentFactureActe,
                                  ];
                                  setFieldValue(
                                    "factureactes",
                                    updatedFactureActe
                                  );
                                }}
                                value={values.factureactes[index].montantregle}
                                fullWidth
                              />
                            </td>
                            <td width={120}>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 24 },
                                }}
                                checked={values.factureactes[index].impotinclu}
                                onChange={() => {
                                  const currentFactureActe =
                                    values.factureactes;
                                  const currentActe = currentFactureActe[index];
                                  currentActe.impotinclu =
                                    !currentActe.impotinclu;
                                  const updatedFactureActe = [
                                    ...currentFactureActe,
                                  ];
                                  setFieldValue(
                                    "factureactes",
                                    updatedFactureActe
                                  );
                                  calculTotal(updatedFactureActe);
                                }}
                              />
                            </td> */}
                            <td>
                              <FormControl
                                className={styles.backgroudGrey}
                                fullWidth
                                size="small"
                              >
                                <OutlinedInput
                                  type="text"
                                  disabled
                                  fullWidth
                                  value={
                                    valeurTypeActe[index] === "HONORAIRES"
                                      ? montantretenu[index]
                                      : "---------------------------------------------------------------------"
                                  }
                                />
                              </FormControl>
                            </td>
                            <td width={120}>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 24 },
                                }}
                                checked={values.factureactes[index].regle}
                                onChange={() => {
                                  const currentFactureActe =
                                    values.factureactes;
                                  const currentActe = currentFactureActe[index];
                                  currentActe.regle = !currentActe.regle;
                                  const updatedFactureActe = [
                                    ...currentFactureActe,
                                  ];
                                  setFieldValue(
                                    "factureactes",
                                    updatedFactureActe
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <div className={styles.GroupeButton}>
                                {index === 0 ? (
                                  <span className={styles.ButtonArray}>
                                    <Button
                                      onClick={() =>
                                        handleAddFactureActe(
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <AddIcon />
                                    </Button>
                                  </span>
                                ) : (
                                  ""
                                )}
                                {index !== 0 ? (
                                  <span className={styles.ButtonDelete}>
                                    <Button
                                      onClick={() =>
                                        handleRemoveFactureActe(
                                          index,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <Grid item xs={12} className="mt-3">
                    <h5 className="mt-1">Total HT : {totalHT} Dhs</h5>
                    <h5 className="mt-1">Total TVA : {totalTva} Dhs</h5>
                    <h5 className="mt-1">TTC : {total} Dhs</h5>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.ButtonGrid}>
                  <Button
                    startIcon={loadingSend && <CircularProgress size={20} />}
                    disabled={loadingSend}
                    variant="contained"
                    type="submit"
                  >
                    Ajouter
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Spinner />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
