import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import {
  factureInterface,
  getAllFacture,
  getFactureExport,
  getOneFacture,
} from "../../redux/slices/factureSlice";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import axios from "axios";
import SpinnerPage from "../../components/SpinnerPage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableSia from "../../components/TableSia";
const MySwal = withReactContent(Swal);

const headSet = ["Numero", "Affaire", "Procedure", "Client", "Crée le", ""];
interface facturePaginationInterface {
  meta: Meta;
  data: factureInterface[];
}
const dataToShow = [
  {
    vkey: ["numero"],
    type: "link",
  },
  {
    vkey: ["Affaire", "reference"],
    type: "string",
  },
  {
    vkey: ["Procedure", "reference"],
    type: "string",
  },
  {
    vkey: ["Client", "fullname"],
    type: "string",
  },
];
export default function Factures() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [dateemmission, setDateemmission] = useState<Date | null | string>(
    null
  );
  const [numero, setNumero] = useState("");
  const [loadingOne, setLoadingOne] = useState(false);
  const [affaire_id, setAffaire_id] = useState("");
  const [procedure_id, setProcedure_id] = useState("");
  const [client_id, setClient_id] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.facture.errorListe);
  const loading = useSelector((state: any) => state.facture.loadingListe);
  const factures: facturePaginationInterface = useSelector(
    (state: any) => state.facture.factures
  );
  const facture: any = useSelector((state: any) => state.facture.factureExport);
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllFacture(
        1,
        dateemmission,
        numero,
        affaire_id,
        procedure_id,
        client_id
      )
    );
  }, []);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllFacture(
        value,
        dateemmission,
        numero,
        affaire_id,
        procedure_id,
        client_id
      )
    );
  };

  const handleChangeNumero = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumero(event.target.value);
  };

  const handleChangeDateemmission = (date: Date | null | string) => {
    setDateemmission(date);
  };

  const handleChangeAffaireId = (value: string) => {
    setAffaire_id(value);
  };
  const handleChangeProcedureId = (value: string) => {
    setProcedure_id(value);
  };

  const handleChangeClientId = (value: string) => {
    setClient_id(value);
  };
  const ResetFilters = () => {
    setDateemmission(null);
    setNumero("");
    setAffaire_id("");
    setProcedure_id("");
    setClient_id("");
  };

  const handleDownloadPDF = (facture: factureInterface) => {
    dispatch(getFactureExport(facture.id_facture));
    setLoadingOne(true);
  };
  const handleExportData = () => {
    if (
      numero === "" &&
      dateemmission === null &&
      affaire_id === "" &&
      procedure_id === "" &&
      client_id === ""
    ) {
      MySwal.fire({
        icon: "warning",
        title: "Choisir Au Moins Un Filtre",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/facture/export?all=true${
            dateemmission ? "&dateemmission=" + dateemmission : ""
          }${numero ? "&numero=" + numero : ""}${
            affaire_id ? "&affaire_id=" + affaire_id : ""
          }${procedure_id ? "&procedure_id=" + procedure_id : ""}${
            client_id ? "&client_id=" + client_id : ""
          }`,
          {
            responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");
          const currentDate = new Date();
          const dateString = currentDate
            .toISOString()
            .slice(0, 19)
            .replace(/[-T:]/g, "");
          const filename = `factures_${dateString}.xlsx`;
          link.href = url;
          link.download = filename; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoadingOne(false);
        })
        .catch((error) => {
          console.error("Error downloading Excel:", error);
        });
    }
  };
  useEffect(() => {
    if (!isFirstRender && facture) {
      axios
        .post(process.env.REACT_APP_API_FACTURE_URL + "generate_pdf", facture, {
          responseType: "blob", // Set the responseType to 'arraybuffer' to get binary data
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");

          link.href = url;
          const noSpecialChars = facture.numero.replace(/[^a-zA-Z0-9 ]/g, "");
          link.download = "facture_" + noSpecialChars + ".pdf"; // The default name for the downloaded file
          link.click();
          URL.revokeObjectURL(url);
          setLoadingOne(false);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    }
  }, [facture]);
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllFacture(
            1,
            dateemmission,
            numero,
            affaire_id,
            procedure_id,
            client_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [dateemmission, numero]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllFacture(
          1,
          dateemmission,
          numero,
          affaire_id,
          procedure_id,
          client_id
        )
      );
    }
  }, [affaire_id, procedure_id, client_id]);
  return (
    <div>
      {loadingOne && <SpinnerPage />}
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Factures</h2>
        <div></div>
        {/*<Link to="new" relative="path">
          + Ajouter Une Facture
        </Link>*/}
      </div>
      <BoxFilter
        boxDisplayed={[
          "numero",
          "procedure",
          "affaire",
          "client",
          "exportdata",
        ]}
        numero={numero}
        affaire_id={affaire_id}
        procedure_id={procedure_id}
        client_id={client_id}
        setNumero={handleChangeNumero}
        setAffaireid={handleChangeAffaireId}
        setClientid={handleChangeClientId}
        setProcedureid={handleChangeProcedureId}
        ResetFilters={ResetFilters}
        loading={loading}
        handleExport={handleExportData}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={factures}
        page={page}
        id_type="id_facture"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Facture"
        download={true}
        handleDownload={handleDownloadPDF}
      />
      {error && <p>{error}</p>}
    </div>
  );
}
