import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import styles from "../../styles/Affaire.module.scss";
import {
  accidentTravailInterface,
  getAllAccidentTravail,
} from "../../redux/slices/accidentTravailSlice";
import { Meta } from "../../redux/interfaces";
import BoxFilter from "../../components/BoxFilter";
import TableSia from "../../components/TableSia";

const headSet = [
  "Affaire",
  "date accident",
  "Type accident travail",
  "Crée le",
];
const dataToShow = [
  {
    vkey: ["Affaire", "reference"],
    type: "link",
  },
  {
    vkey: ["dateaccident"],
    type: "date",
  },
  {
    vkey: ["TypeAccidentTravail", "libelle"],
    type: "string",
  },
];
interface accidentTravailPaginationInterface {
  meta: Meta;
  data: accidentTravailInterface[];
}
export default function AccidentTravails() {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [page, setPage] = useState(1);
  const [reference, setReference] = useState("");
  const [dateaccident, setDateaccident] = useState<Date | null | string>(null);
  const [numpoliceassurance, setNumpoliceassurance] = useState("");
  const [typeaccidenttravail_id, setTypeaccidenttravailid] = useState("");
  const [affaire_id, setAffaireid] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector((state: any) => state.accidentTravail.errorListe);
  const loading = useSelector(
    (state: any) => state.accidentTravail.loadingListe
  );
  const accidentTravails: accidentTravailPaginationInterface = useSelector(
    (state: any) => state.accidentTravail.accidentTravails
  );
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    dispatch(
      getAllAccidentTravail(
        1,
        reference,
        dateaccident,
        numpoliceassurance,
        typeaccidenttravail_id,
        affaire_id
      )
    );
  }, []);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      getAllAccidentTravail(
        value,
        reference,
        dateaccident,
        numpoliceassurance,
        typeaccidenttravail_id,
        affaire_id
      )
    );
  };
  const handleChangeReference = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReference(event.target.value);
  };
  const handleChangeNumpoliceassurance = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNumpoliceassurance(event.target.value);
  };
  const handleChangeDateaccident = (date: Date | null | string) => {
    setDateaccident(date);
  };
  const handleChangeTypeAccidentTravailId = (value: string) => {
    setTypeaccidenttravailid(value);
  };
  const handleChangeAffaireId = (value: string) => {
    setAffaireid(value);
  };
  const ResetFilters = () => {
    setDateaccident(null);
    setReference("");
    setNumpoliceassurance("");
    setTypeaccidenttravailid("");
    setAffaireid("");
  };
  useEffect(() => {
    if (!isFirstRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getAllAccidentTravail(
            1,
            reference,
            dateaccident,
            numpoliceassurance,
            typeaccidenttravail_id,
            affaire_id
          )
        );
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [reference, dateaccident, numpoliceassurance]);
  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getAllAccidentTravail(
          1,
          reference,
          dateaccident,
          numpoliceassurance,
          typeaccidenttravail_id,
          affaire_id
        )
      );
    }
  }, [typeaccidenttravail_id, affaire_id]);
  return (
    <div>
      <div className={styles.AddAffaire}>
        <div></div>
        <h2>Liste des Accidents Travails</h2>
        <div></div>
      </div>
      <BoxFilter
        boxDisplayed={[
          "reference",
          "dateaccident",
          "numpoliceassurance",
          "typeaccidenttravail",
          "affaire",
        ]}
        reference={reference}
        dateaccident={dateaccident}
        numpoliceassurance={numpoliceassurance}
        typeaccidenttravail_id={typeaccidenttravail_id}
        affaire_id={affaire_id}
        setReference={handleChangeReference}
        setDateaccident={handleChangeDateaccident}
        setNumpoliceassurance={handleChangeNumpoliceassurance}
        setTypeaccidenttravailid={handleChangeTypeAccidentTravailId}
        setAffaireid={handleChangeAffaireId}
        ResetFilters={ResetFilters}
        loading={loading}
      />
      <TableSia
        loading={loading}
        headSet={headSet}
        dataDisplay={accidentTravails}
        page={page}
        id_type="id_accidenttravail"
        handleChange={handleChange}
        dataToShow={dataToShow}
        title="Accident de Travail"
      />
      {error && <p>{error}</p>}
    </div>
  );
}
