import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";
import { affaireInterface } from "./affaireSlice";
import { typeProcedureInterface } from "./typeProcedureSlice";
import { sousTypeProcedureInterface } from "./sousTypeProcedureSlice";
import { typeTribunalInterface } from "./typeTribunalSlice";
import { typeJuridictionInterface } from "./typeJuridictionSlice";
import { villeInterface } from "./villeSlice";
import { libelleProcedureInterface } from "./libelleProcedureSlice";

export interface procedureInterface {
  id_procedure: number;
  affaire_id: number;
  Affaire: affaireInterface;
  parent_id?: number;
  parent: procedureInterface;
  typeprocedure_id: number;
  TypeProcedure: typeProcedureInterface;
  soustypeprocedure_id: number;
  SousTypeProcedure: sousTypeProcedureInterface;
  typetribunal_id: number;
  TypeTribunal: typeTribunalInterface;
  typejuridiction_id: number;
  TypeJuridiction: typeJuridictionInterface;
  siege_id: number;
  Ville: villeInterface;
  reference: string;
  observation: string;
  //code: string;
  datedebut: Date | string | null;
  datefin: Date | string | null | null;
  libelleprocedure_id: number;
  LibelleProcedure: libelleProcedureInterface;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewprocedureInterface {
  affaire_id: string;
  parent_id: string | null;
  typeprocedure_id: string;
  libelleprocedure_id: string | null;
  soustypeprocedure_id: string;
  typetribunal_id: string;
  typejuridiction_id: string;
  siege_id: string;
  reference: string;
  observation: string;
  //code: string;
  datedebut: Date | string | null;
  datefin: Date | string | null | null;
}

const initialState = {
  procedures: [],
  allProcedures: [],
  procedure: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const procedureSlice = createSlice({
  name: "procedure",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allProcedure(state, action) {
      state.procedures = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allProcedureTrue(state, action) {
      state.allProcedures = action.payload;
      state.error = "";
    },
    oneProcedure(state, action) {
      state.procedure = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allProcedure,
  allProcedureTrue,
  oneProcedure,
  getError,
  setLoadingFalse,
  setLoadingOneFalse,
} = procedureSlice.actions;

export const getAllProcedure =
  (
    page?: number,
    reference?: string,
    numero?: string,
    observation?: string,
    affaire_id?: string,
    parent_id?: string,
    typeprocedure_id?: string,
    libelleprocedure_id?: string,
    soustypeprocedure_id?: string,
    typetribunal_id?: string,
    typejuridiction_id?: string,
    siege_id?: string
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.procedureApi}${
          page ? "/?page=" + page : "/?all=true"
        }${reference ? "&reference=" + reference : ""}${
          numero ? "&numero=" + numero : ""
        }${observation ? "&observation=" + observation : ""}${
          affaire_id ? "&affaire_id=" + affaire_id : ""
        }${parent_id ? "&parent_id=" + parent_id : ""}${
          typeprocedure_id ? "&typeprocedure_id=" + typeprocedure_id : ""
        }${
          libelleprocedure_id
            ? "&libelleprocedure_id=" + libelleprocedure_id
            : ""
        }${
          soustypeprocedure_id
            ? "&soustypeprocedure_id=" + soustypeprocedure_id
            : ""
        }${typetribunal_id ? "&typetribunal_id=" + typetribunal_id : ""}${
          typejuridiction_id ? "&typejuridiction_id=" + typejuridiction_id : ""
        }${siege_id ? "&siege_id=" + siege_id : ""}`
      );
      let procedures: any;
      procedures = page ? response.data : response.data.data;
      page
        ? dispatch(allProcedure(procedures))
        : dispatch(allProcedureTrue(procedures));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneProcedure = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(api.baseApi + api.procedureApi + "/" + id);
    let procedure = response.data;
    dispatch(oneProcedure(procedure));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addProcedure =
  (procedure: NewprocedureInterface, affaireParent: boolean) =>
  async (dispatch: any) => {
    try {
      if (!affaireParent) procedure.parent_id = null;
      const Newprocedure = await axios.post(
        api.baseApi + api.procedureApi,
        procedure
      );
      return Newprocedure;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editProcedure =
  (procedure: procedureInterface, id: number) => async (dispatch: any) => {
    try {
      await axios.put(api.baseApi + api.procedureApi + "/" + id, procedure);
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default procedureSlice.reducer;
