import { useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Grid, TextField, Button } from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, Link } from "react-router-dom";
import { addSousTypeProcedure } from "../../redux/slices/sousTypeProcedureSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { New } from "../../redux/interfaces";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

interface MyFormValues {
  code: string;
  libelle: string;
}

export default function NewSousTypeProcedure(props: New) {
  const [loadingSend, setLoadingSend] = useState(false);
  const formikRef = useRef<FormikProps<MyFormValues>>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const initialValues: MyFormValues = {
    code: "",
    libelle: "",
  };
  const schema = yup.object({
    libelle: yup.string().required("Label est obligatoire"),
  });
  return (
    <>
      {!props.modal ? (
        <div className={styles.ReturnList}>
          <Link to="/sous-type-procedures" relative="path">
            <ArrowBackIosIcon /> Liste Sous Type Procedures
          </Link>
          <h2>Nouveau Sous Type Procedure</h2>
          <div />
        </div>
      ) : (
        <div className={styles.TitleAdd}>
          <h2>Nouveau Sous Type Procedure</h2>
        </div>
      )}
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoadingSend(true);
          const sousTypeProcedure = await dispatch(
            addSousTypeProcedure(values)
          );
          if (sousTypeProcedure?.status) {
            MySwal.fire({
              icon: "success",
              title: "Sous Type Procedure est ajouté avec succes",
              customClass: {
                confirmButton: "shadow-none",
              },
            }).then((result) => {
              setLoadingSend(false);
              props.handleClose
                ? props.handleClose(
                    sousTypeProcedure?.data.id_soustypeprocedure,
                    sousTypeProcedure?.data.libelle
                  )
                : navigate("/sous-type-procedure");
            });
          }
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <TextField
                  label="Code"
                  name="code"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.code}
                  helperText={errors.code ? errors.code : ""}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  label="Libelle"
                  name="libelle"
                  onChange={handleChange}
                  fullWidth
                  error={!!errors.libelle}
                  helperText={errors.libelle ? errors.libelle : ""}
                />
              </Grid>
              <Grid item xs={12} className={styles.ButtonGrid}>
                <Button
                  startIcon={loadingSend && <CircularProgress size={20} />}
                  disabled={loadingSend}
                  variant="contained"
                  type="submit"
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
