import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface typeProcedureInterface {
  id_typeprocedure: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewtypeProcedureInterface {
  code: string;
  libelle: string;
}

const initialState = {
  typeProcedures: [],
  allTypeProcedures: [],
  typeProcedure: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const typeProcedureSlice = createSlice({
  name: "typeProcedure",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allTypeProcedure(state, action) {
      state.typeProcedures = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allTypeProcedureTrue(state, action) {
      state.allTypeProcedures = action.payload;
      state.error = "";
    },
    oneTypeProcedure(state, action) {
      state.typeProcedure = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allTypeProcedure,
  allTypeProcedureTrue,
  oneTypeProcedure,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = typeProcedureSlice.actions;

export const getAllTypeProcedure =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.typeProcedureApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let typeProcedures: any;
      typeProcedures = page ? response.data : response.data.data;
      page
        ? dispatch(allTypeProcedure(typeProcedures))
        : dispatch(allTypeProcedureTrue(typeProcedures));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneTypeProcedure = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.typeProcedureApi + "/" + id
    );
    let typeProcedure = response.data;
    dispatch(oneTypeProcedure(typeProcedure));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addTypeProcedure =
  (typeProcedure: NewtypeProcedureInterface) => async (dispatch: any) => {
    try {
      const newTypeProcedure = await axios.post(
        api.baseApi + api.typeProcedureApi,
        typeProcedure
      );
      return newTypeProcedure;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editTypeProcedure =
  (typeProcedure: typeProcedureInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.typeProcedureApi + "/" + id,
        typeProcedure
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default typeProcedureSlice.reducer;
