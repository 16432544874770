import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../api";

export interface libelleProcedureInterface {
  id_libelleprocedure: number;
  code: string;
  libelle: string;
  createdon: Date;
  createdby: string;
  updatedon?: Date;
  updatedby?: string;
}

export interface NewlibelleProcedureInterface {
  code: string;
  libelle: string;
}

const initialState = {
  libelleProcedures: [],
  allLibelleProcedures: [],
  libelleProcedure: {},
  error: "",
  loading: true,
  loadingListe: true,
  loadingOne: true,
  errorListe: "",
};

const libelleProcedureSlice = createSlice({
  name: "libelleProcedure",
  initialState,
  reducers: {
    setLoadingFalse(state) {
      state.loadingListe = true;
    },
    setLoadingOneFalse(state) {
      state.loadingOne = true;
    },
    allLibelleProcedure(state, action) {
      state.libelleProcedures = action.payload;
      state.loadingListe = false;
      state.errorListe = "";
    },
    allLibelleProcedureTrue(state, action) {
      state.allLibelleProcedures = action.payload;
      state.error = "";
    },
    oneLibelleProcedure(state, action) {
      state.libelleProcedure = action.payload;
      state.loadingOne = false;
      state.error = "";
    },
    getError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  allLibelleProcedure,
  allLibelleProcedureTrue,
  oneLibelleProcedure,
  getError,
  setLoadingOneFalse,
  setLoadingFalse,
} = libelleProcedureSlice.actions;

export const getAllLibelleProcedure =
  (page?: number, code?: string, libelle?: string) => async (dispatch: any) => {
    try {
      dispatch(setLoadingFalse());
      const response = await axios.get(
        `${api.baseApi}${api.libelleProcedureApi}${
          page ? "/?page=" + page : "/?all=true"
        }${libelle ? "&libelle=" + libelle : ""}${code ? "&code=" + code : ""}`
      );
      let libelleProcedures: any;
      libelleProcedures = page ? response.data : response.data.data;
      page
        ? dispatch(allLibelleProcedure(libelleProcedures))
        : dispatch(allLibelleProcedureTrue(libelleProcedures));
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const getOneLibelleProcedure = (id: number) => async (dispatch: any) => {
  try {
    dispatch(setLoadingOneFalse());
    const response = await axios.get(
      api.baseApi + api.libelleProcedureApi + "/" + id
    );
    let libelleProcedure = response.data;
    dispatch(oneLibelleProcedure(libelleProcedure));
  } catch (error: any) {
    dispatch(getError(error.response.data.message));
  }
};

export const addLibelleProcedure =
  (libelleProcedure: NewlibelleProcedureInterface) => async (dispatch: any) => {
    try {
      const newLibelleProcedure = await axios.post(
        api.baseApi + api.libelleProcedureApi,
        libelleProcedure
      );
      return newLibelleProcedure;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export const editLibelleProcedure =
  (libelleProcedure: libelleProcedureInterface, id: number) =>
  async (dispatch: any) => {
    try {
      await axios.put(
        api.baseApi + api.libelleProcedureApi + "/" + id,
        libelleProcedure
      );
      return true;
    } catch (error: any) {
      dispatch(getError(error.response.data.message));
    }
  };

export default libelleProcedureSlice.reducer;
