import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import styles from "../../styles/Affaire.module.scss";
import {
  audienceInterface,
  editAudience,
  getOneAudience,
} from "../../redux/slices/audienceSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ResultatAudienceModal from "../../components/Modals/ResultatAudienceModal";
import SearchIcon from "@mui/icons-material/Search";
import ProcedureModal from "../../components/Modals/ProcedureModal";
import ResultatAudienceCreateModal from "../../components/CreateModals/ResultatAudienceCreateModal";
import Spinner from "../../components/Spinner";
import AudienceDetail from "./AudienceDetail";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

export default function EditAudience() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [openCreateResultatAudience, setOpenCreateResultatAudience] =
    useState(false);
  const [openResultatAudience, setOpenResultatAudience] = useState(false);
  const [displayResultatAudience, setDisplayResultatAudience] = useState("");

  const [openProcedure, setOpenProcedure] = useState(false);
  const [displayProcedure, setDisplayProcedure] = useState("");
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const audience: audienceInterface = useSelector(
    (state: any) => state.audience.audience
  );

  const schema = yup.object({
    //reference: yup.string().required("Reference est obligatoire"),

    dateaudience: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
    procedure_id: yup.string().required("Procedure est obligatoire"),
    resultataudience_id: yup
      .string()
      .required("Resultat Audience est obligatoire"),
  });

  const loading = useSelector((state: any) => state.audience.loadingOne);

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneAudience(id));
    } else {
      navigate("/audiences");
    }
  }, []);

  const refrechData = () => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneAudience(id));
    }
  };
  useEffect(() => {
    setDisplayResultatAudience(audience?.ResultatAudience?.libelle);
    setDisplayProcedure(audience?.Procedure?.reference);
  }, [audience]);
  const handleSetOpenEdit = () => {
    setOpenEdit(!openEdit);
  };
  return (
    <>
      {openEdit ? (
        <>
          <div className={styles.ReturnList}>
            <Link to="/audiences" relative="path">
              <ArrowBackIosIcon /> Liste Audiences
            </Link>
            <h2>Editer Audience</h2>
            <div />
          </div>
          {!loading && Object.keys(audience).length > 0 ? (
            <Formik
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={audience}
              onSubmit={async (values) => {
                let object = { ...values };
                delete object.updatedby;
                delete object.updatedon;
                setLoadingSend(true);
                const bool = await dispatch(
                  editAudience(object, values.id_audience)
                );
                if (bool) {
                  MySwal.fire({
                    icon: "success",
                    title: "Audience est modifié avec succes",
                    customClass: {
                      confirmButton: "shadow-none",
                    },
                  }).then(() => {
                    setLoadingSend(false);
                    refrechData();
                    setOpenEdit(false);
                  });
                }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
                      <TextField
                        label="Reference"
                        name="reference"
                        onChange={handleChange}
                        value={values.reference}
                        fullWidth
                        error={!!errors.reference}
                        helperText={errors.reference ? errors.reference : ""}
                      />
                    </Grid> */}
                    {/*<Grid item xs={6}>
                  <TextField
                    label="Code"
                    name="code"
                    onChange={handleChange}
                    value={values.code}
                    fullWidth
                    error={!!errors.code}
                    helperText={errors.code ? errors.code : ""}
                  />
          </Grid>*/}
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(value) => {
                            const date = moment(value).format("YYYY-MM-DD");
                            setFieldValue("dateaudience", date);
                          }}
                          value={moment(values.dateaudience)}
                          label="Date Audience"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.dateaudience,
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {errors.dateaudience && (
                        <span className={styles.errorHandle}>
                          {errors.dateaudience}
                        </span>
                      )}
                    </Grid>
                    <Grid container item xs={6}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayResultatAudience
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Resultat Audience
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayResultatAudience}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenResultatAudience(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Resultat Audience"
                          />
                        </FormControl>
                        {openResultatAudience && (
                          <ResultatAudienceModal
                            open={openResultatAudience}
                            handleClose={() => setOpenResultatAudience(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("resultataudience_id", value)
                            }
                            handleChangeDisplay={setDisplayResultatAudience}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateResultatAudience(true)}
                        >
                          +
                        </Button>
                        {openCreateResultatAudience && (
                          <ResultatAudienceCreateModal
                            open={openCreateResultatAudience}
                            handleClose={() =>
                              setOpenCreateResultatAudience(false)
                            }
                            handleChangeValue={(value) =>
                              setFieldValue("resultataudience_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayResultatAudience(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.resultataudience_id && (
                        <span className={styles.errorHandle}>
                          {errors.resultataudience_id}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        className={
                          !displayProcedure
                            ? styles.backgroudGrey
                            : styles.backgroudGrey2
                        }
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Procédure
                        </InputLabel>
                        <OutlinedInput
                          type="text"
                          disabled
                          fullWidth
                          value={displayProcedure}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setOpenProcedure(true)}
                                edge="end"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Procédure"
                        />
                      </FormControl>
                      {openProcedure && (
                        <ProcedureModal
                          open={openProcedure}
                          handleClose={() => setOpenProcedure(false)}
                          handleChangeValue={(value) =>
                            setFieldValue("procedure_id", value)
                          }
                          handleChangeValueAffaire={(value) =>
                            setFieldValue("affaire_id", value)
                          }
                          handleChangeDisplay={setDisplayProcedure}
                        />
                      )}
                      {errors.procedure_id && (
                        <span className={styles.errorHandle}>
                          {errors.procedure_id}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12} className={styles.ButtonGrid}>
                      <Button
                        className={styles.Annuler}
                        variant="contained"
                        onClick={() => setOpenEdit(false)}
                      >
                        Annuler
                      </Button>
                      <Button
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <Spinner />
          )}
        </>
      ) : !loading && Object.keys(audience).length > 0 ? (
        <>
          <div className={styles.ReturnList2}>
            <Link to="/audiences" relative="path">
              <ArrowBackIosIcon /> Liste Des Audiences
            </Link>
          </div>
          <AudienceDetail
            audience={audience}
            setEditAudience={handleSetOpenEdit}
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}
