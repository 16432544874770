import { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import styles from "../../styles/Affaire.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  editJugement,
  jugementInterface,
  getOneJugement,
} from "../../redux/slices/jugementSlice";
import * as yup from "yup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TypeJugementModal from "../../components/Modals/TypeJugementModal";
import SearchIcon from "@mui/icons-material/Search";
import ProcedureModal from "../../components/Modals/ProcedureModal";
import Spinner from "../../components/Spinner";
import JugementDetail from "./JugementDetail";
import { CircularProgress } from "@mui/material";

const MySwal = withReactContent(Swal);

export default function EditJugement() {
  const [loadingSend, setLoadingSend] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [openCreateTypeJugement, setOpenCreateTypeJugement] = useState(false);
  const [openTypeJugement, setOpenTypeJugement] = useState(false);
  const [displayTypeJug, setDisplayTypeJug] = useState("");

  const [openProcedure, setOpenProcedure] = useState(false);
  const [displayProcedure, setDisplayProcedure] = useState("");

  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const jugement: jugementInterface = useSelector(
    (state: any) => state.jugement.jugement
  );
  const loading = useSelector((state: any) => state.jugement.loadingOne);
  const schema = yup.object({
    typejugement_id: yup.string().required("Type of judgment is required"),
    procedure_id: yup.string().required("Procedure is required"),
    //numero: yup.string().required("Numero is required"),
    indemnite: yup.number().required("Indemnite is required"),
    //auprofitduclient: yup.boolean().required("Au profit du client is required"),
    prisonferme: yup.boolean().required("Prison ferme is required"),
    datejugement: yup
      .string()
      .test("valid-date", "Invalid date", function (value) {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }),
  });

  useEffect(() => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneJugement(id));
    } else {
      navigate("/jugements");
    }
  }, []);

  const refrechData = () => {
    let id: number;
    if (params.id) {
      id = parseInt(params.id);
      dispatch(getOneJugement(id));
    }
  };

  useEffect(() => {
    setDisplayTypeJug(jugement?.TypeJugement?.libelle);
    setDisplayProcedure(jugement?.Procedure?.reference);
  }, [jugement]);

  const handleSetOpenEdit = () => {
    setOpenEdit(!openEdit);
  };
  return (
    <>
      {openEdit ? (
        <>
          <div className={styles.ReturnList}>
            <Link to="/jugements" relative="path">
              <ArrowBackIosIcon /> Liste Des Jugements
            </Link>
            <h2>Editer Jugement</h2>
            <div />
          </div>
          {!loading && Object.keys(jugement).length > 0 ? (
            <Formik
              validateOnChange={false}
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={jugement}
              onSubmit={async (values) => {
                let object = { ...values };
                delete object.updatedby;
                delete object.updatedon;
                setLoadingSend(true);
                const bool = await dispatch(
                  editJugement(object, values.id_jugement)
                );
                if (bool) {
                  MySwal.fire({
                    icon: "success",
                    title: "Jugement est modifié avec succes",
                    customClass: {
                      confirmButton: "shadow-none",
                    },
                  }).then(() => {
                    setLoadingSend(false);
                    refrechData();
                    setOpenEdit(false);
                  });
                }
              }}
            >
              {({
                errors,
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={4}>
                      <TextField
                        label="Reference"
                        name="reference"
                        onChange={handleChange}
                        value={values.reference}
                        fullWidth
                        error={!!errors.reference}
                        helperText={errors.reference ? errors.reference : ""}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Numéro"
                        name="numero"
                        onChange={handleChange}
                        value={values.numero}
                        fullWidth
                        error={!!errors.numero}
                        helperText={errors.numero ? errors.numero : ""}
                      />
                    </Grid> */}
                    <Grid item xs={4}>
                      <TextField
                        label="Nombre de mois de prison"
                        name="nbmoisprison"
                        type="number"
                        onChange={handleChange}
                        value={values.nbmoisprison}
                        fullWidth
                        error={!!errors.nbmoisprison}
                        helperText={
                          errors.nbmoisprison ? errors.nbmoisprison : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Indemnité"
                        name="indemnite"
                        type="number"
                        onChange={handleChange}
                        value={values.indemnite}
                        fullWidth
                        error={!!errors.indemnite}
                        helperText={errors.indemnite ? errors.indemnite : ""}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Amende"
                        name="amende"
                        type="number"
                        onChange={handleChange}
                        value={values.amende}
                        fullWidth
                        error={!!errors.amende}
                        helperText={errors.amende ? errors.amende : ""}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Texte"
                        name="texte"
                        onChange={handleChange}
                        value={values.texte}
                        fullWidth
                        error={!!errors.texte}
                        helperText={errors.texte ? errors.texte : ""}
                        multiline
                        rows={3}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Pourcentage de responsabilité"
                        name="resp_pourcentage"
                        onChange={handleChange}
                        value={values.resp_pourcentage}
                        fullWidth
                        error={!!errors.resp_pourcentage}
                        helperText={
                          errors.resp_pourcentage ? errors.resp_pourcentage : ""
                        }
                      />
                    </Grid>
                    {/*<Grid item xs={4}>
                  <TextField
                    label="Code"
                    name="code"
                    onChange={handleChange}
                    value={values.code}
                    fullWidth
                    error={!!errors.code}
                    helperText={errors.code ? errors.code : ""}
                  />
                  </Grid>*/}
                    <Grid container item xs={4}>
                      <Grid item xs={10}>
                        <FormControl
                          className={
                            !displayTypeJug
                              ? styles.backgroudGrey
                              : styles.backgroudGrey2
                          }
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            Type Jugement
                          </InputLabel>
                          <OutlinedInput
                            type="text"
                            disabled
                            fullWidth
                            value={displayTypeJug}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setOpenTypeJugement(true)}
                                  edge="end"
                                >
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Type Jugement"
                          />
                        </FormControl>
                        {openTypeJugement && (
                          <TypeJugementModal
                            open={openTypeJugement}
                            handleClose={() => setOpenTypeJugement(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typejugement_id", value)
                            }
                            handleChangeDisplay={setDisplayTypeJug}
                          />
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          className={styles.AddButtonModal}
                          onClick={() => setOpenCreateTypeJugement(true)}
                        >
                          +
                        </Button>
                        {openCreateTypeJugement && (
                          <TypeJugementModal
                            open={openCreateTypeJugement}
                            handleClose={() => setOpenCreateTypeJugement(false)}
                            handleChangeValue={(value) =>
                              setFieldValue("typejugement_id", value)
                            }
                            handleChangeDisplay={(value) =>
                              setDisplayTypeJug(value)
                            }
                          />
                        )}
                      </Grid>
                      {errors.typejugement_id && (
                        <span className={styles.errorHandle}>
                          {errors.typejugement_id}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        className={
                          !displayProcedure
                            ? styles.backgroudGrey
                            : styles.backgroudGrey2
                        }
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Procédure
                        </InputLabel>
                        <OutlinedInput
                          type="text"
                          disabled
                          fullWidth
                          value={displayProcedure}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setOpenProcedure(true)}
                                edge="end"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Procédure"
                        />
                      </FormControl>
                      {openProcedure && (
                        <ProcedureModal
                          open={openProcedure}
                          handleClose={() => setOpenProcedure(false)}
                          handleChangeValue={(value) =>
                            setFieldValue("procedure_id", value)
                          }
                          handleChangeValueAffaire={(value) =>
                            setFieldValue("affaire_id", value)
                          }
                          handleChangeDisplay={setDisplayProcedure}
                        />
                      )}
                      {errors.procedure_id && (
                        <span className={styles.errorHandle}>
                          {errors.procedure_id}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          onChange={(value) => {
                            const date = moment(value).format("YYYY-MM-DD");
                            setFieldValue("datejugement", date);
                          }}
                          value={moment(values.datejugement)}
                          label="Date Jugement"
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              error: !!errors.datejugement,
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {errors.datejugement && (
                        <span className={styles.errorHandle}>
                          {errors.datejugement}
                        </span>
                      )}
                    </Grid>
                    {/* <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 32 },
                            }}
                          />
                        }
                        label="Au profit du client"
                        checked={values.auprofitduclient}
                        onChange={() =>
                          setFieldValue(
                            "auprofitduclient",
                            !values.auprofitduclient
                          )
                        }
                      />
                    </Grid> */}
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 32 },
                            }}
                          />
                        }
                        label="Prison Ferme"
                        checked={values.prisonferme}
                        onChange={() =>
                          setFieldValue("prisonferme", !values.prisonferme)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className={styles.ButtonGrid}>
                      <Button
                        className={styles.Annuler}
                        variant="contained"
                        onClick={() => setOpenEdit(false)}
                      >
                        Annuler
                      </Button>
                      <Button
                        startIcon={
                          loadingSend && <CircularProgress size={20} />
                        }
                        disabled={loadingSend}
                        variant="contained"
                        type="submit"
                      >
                        Modifier
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) : (
            <Spinner />
          )}
        </>
      ) : !loading && Object.keys(jugement).length > 0 ? (
        <>
          <div className={styles.ReturnList2}>
            <Link to="/jugements" relative="path">
              <ArrowBackIosIcon /> Liste Des Jugements
            </Link>
          </div>
          <JugementDetail
            jugement={jugement}
            setEditJugement={handleSetOpenEdit}
          />
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}
